import React, { useEffect } from 'react'
// import { useHistory } from 'react-router-dom'
import { Footer} from '../../components'
import style from './CustomerSupport.module.scss'
import {preservation, helpCenter, claims} from '../../assets/index'
export function CustomerSupport () {
  // const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return <div className={style['customer-support-container']}>
    {/* <Header /> */}
    {/* <div className={style['nav-bar']}>
      <div className={style['nav-back']}>
        <span onClick={() => history.replace('/')}>首頁</span>
        <img src={rightArrow } alt=''/>
        <span>索償及客戶支援</span>
      </div>
    </div> */}
    <div className={style['main']}>
      <p className={style['title']}>索偿及客户支援</p>
      <p className={style['desc']}>我們努力通過我們的客戶服務中心用心照顧您的需要。您可以隨時聯絡我們，我們的客戶服務代表非常樂意為您提供專業協助。</p>
      <div className={style['support-list']}>
        <div className={style['support-item']}>
          <img className={style['preservation']} src={preservation} alt=''/>
          <p>理赔</p>
        </div>
        <div className={style['support-item']}>
          <img className={style['claims']} src={claims} alt=''/>
          <p>保全</p>
        </div>
        <div className={style['support-item']}>
          <img className={style['help-center']} src={helpCenter} alt=''/>
          <p>帮助中心</p>
        </div>
      </div>
      <span className={style['contact-us']}>聯繫我們</span>
    </div>
    <Footer />
  </div>
}