import React, { useState, useImperativeHandle} from 'react'
import ReactDOM from 'react-dom'
import { Tooltip, message } from 'antd'
import { compareInsurance } from '../../services/api'
import { assetsUrl } from '../../utils/constants'
import style from './ProductComparisonModal.module.scss'
export function ProductComparisonModal ({onRef, compareProduct, categoryName, updateAction}) {
  const menuNormalStyle = {
    height: 0,
    transition: 'all 0.3s',
    overflow: 'hidden',
  }
  const menuAnimationStyle = {
    maxHeight: '10000px',
    overflow: 'hidden',
    transition: 'all 0.3s'
  }
  const [compareResult, setCompareResult] = useState(null)
  const compare = (callBack) => {
    const ids = compareProduct.map(el => {
      return el.productId
    })
    if (ids.length === 0) {
      return
    }
    callBack(true)
    compareInsurance({
      productIds: ids
    }).then(res => {
      callBack(false)
      if (res.data.code === 0) {
        document.body.style.overflow = 'hidden'
        let result = res.data.data
        console.log(result)
        if (result) {
          const coveragePackage = result.coveragePackage.map(el => {
            return {
              title: el,
              expansion: true
            }
          })
          result.coveragePackage = coveragePackage
          setCompareResult(result)
          document.getElementById('modal-content').scrollTop = 0
        }
      } else {
        document.body.style.overflow = 'inherit'
        message.error(res.data.message)
      }
    }).catch(e => {
      callBack(false)
      document.body.style.overflow = 'inherit'
    })
  }
  useImperativeHandle(onRef, () => {
    return{
      compare
    }
	})
  return ReactDOM.createPortal(<div className={style['modal-dialog']} style={{display: compareResult && compareProduct.length > 0 ? 'flex' : 'none'}}>
    <div className={style['modal-container']}>
      <div id='modal-content' className={style['modal-content']}>
        <div className={style['modal-header']} >
          <img src={assetsUrl+'images/icon_close_compare_modal.png'} alt='' onClick={e => {
            document.body.style.overflow = 'inherit'
            setCompareResult(null)
          }}/>
        </div>
        <div className={style['modal-body']}>
          <div className={style['container-product']}>
            <div className={style['product-type']}>{categoryName}產品比較</div>
            <div className={style['product-list']}>
            {
            [1, 2, 3, 4].map((el, idx) => {
              return <div className={style['product-item']} key={idx}>
                {
                  compareProduct.length > idx ?
                  <div className={style['select-item']}>
                    <div className={style['close-btn']}>
                      <img src={assetsUrl+'images/icon_delete_compare_result.png'} alt='' onClick={e => {
                        if (compareProduct.length === 1) {
                          document.body.style.overflow = 'inherit'
                          setCompareResult(null)
                        } else {
                          const result = compareResult
                          const packages = result.coveragePackage
                          const details = result.coverageDetails
                          packages.forEach((p) => {
                            const products = details[p.title]
                            products.forEach(e => {
                              const ps = e.productDetails
                              ps.splice(idx, 1)
                            })
                          })
                          setCompareResult(state => {
                            return {
                              ...state,
                              coverageDetails: details
                            }
                          })
                        }
                        updateAction(idx)
                      }}/>
                    </div>
                    <div className={style['name']}>
                      <Tooltip zIndex={9999} title={compareProduct[idx].productName}>
                        <p>{compareProduct[idx].productName}</p>
                      </Tooltip>
                    </div>
                    <span className={style['check-btn']}>
                      <a href={window.location.origin + '/product-detail/' + compareProduct[idx].productId} target="view_window">查看詳情</a>
                    </span>
                  </div> : <div className={style['normal-item']} onClick={e => {
                    document.body.style.overflow = 'inherit'
                    setCompareResult(null)
                  }}>
                    <span>加入產品</span>
                  </div>
                }
                </div>
              })
            }
            </div>
          </div>
          <div className={style['scroll-view']}>
          {
            compareResult ?
            <div className={style['product-detail']}>
              {
                compareResult.coveragePackage.map((el, idx) => {
                  return <div className={style['menu-list']} key={el.title}>
                    <div className={style['menu-title']} onClick={e => {
                      let coverage = compareResult.coveragePackage
                      coverage[idx].expansion = !coverage[idx].expansion
                      setCompareResult(state => {
                        return {
                          ...state,
                          coveragePackage: coverage
                        }
                      })
                    }}>
                      <p>{el.title}</p>
                      {/* <img src={ assetsUrl + (extend ? 'images/icon_open_menu.png' : 'images/icon_hidden_menu.png') } alt=''/> */}
                      <img className={[el.expansion ? `${style['arrow-animate']}` : '']} src={ assetsUrl + 'images/icon_hidden_menu.png' } alt=''/>
                    </div>
                    <ul style={el.expansion ? menuAnimationStyle : menuNormalStyle}>
                      {
                        compareResult.coverageDetails[el.title].map((item, i) => {
                          return <li key={item.coverageName}>
                            <div className={style['insured-amount-box']}>
                              <p>{item.coverageName}</p>
                              <div className={style['insured-amount']}>
                                {
                                  item.productDetails.map((el, idx) => {
                                    return <span key={idx}>{el ? el : ''}</span>
                                  })
                                }
                              </div>
                            </div>
                          </li>
                        })
                      }
                    </ul>
                  </div>
                })
              }
            </div> : <div></div>
          }
          <div className={style['remark-box']}>
            <p className={style['remark-title']}>備註﹕</p>
            <p className={style['remark-content']}>以上資料僅供參考之用，有關詳細保障範圍或不承保事項的內容，請參閱保險公司的詳細保單條款及內容。</p>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>, document.body)
}