export const channels = ['全部', '講座', '聚會', '電影', '展覽', '運動', '公益', '課程', '其他']
export const times = [
  {
    id: '',
    name: '全部'
  },
  {
    id: 'today',
    name: '今天'
  },
  {
    id: 'tomorrow',
    name: '明天'
  },
  // {
  //   id: 'weekend',
  //   name: '週末'
  // },
  {
    id: 'oneweek',
    name: '最近一周'
  },
  {
    id: 'lastest',
    name: '最新發佈'
  },
  // {
  //   id: 'history',
  //   name: '歷史活動'
  // }
]
export const activities = [
  {
    id: 10001,
    cover: 'https://img2.baidu.com/it/u=2333290141,2382641672&fm=26&fmt=auto&gp=0.jpg',
    title: '免費專業諮詢 協助理賠免費專業諮詢 協助理賠免費專業諮詢 協助理賠免費專業諮詢 協助理賠免費專業諮詢 協助理賠免費專業諮詢 協助理賠',
    date: '2021年6月22日（星期二）',
    time: '16:30-18:00',
    address: '香港乳癌基金會乳健中心',
    cost: '全免',
    quota: 20,
    isGift: true,
    overdue: false,
  },
  {
    id: 10002,
    cover: 'https://img2.baidu.com/it/u=2965555279,2573446691&fm=26&fmt=auto&gp=0.jpg',
    title: '有得玩有禮物 免費創意摺紙工作坊',
    date: '2021年6月25日（星期五）',
    time: '15:00-17:00',
    address: '慧擇香港辦公室',
    cost: '全免',
    quota: 0,
    isGift: false,
    overdue: false,
  },
  {
    id: 10003,
    cover: 'https://img2.baidu.com/it/u=3548191255,205695184&fm=26&fmt=auto&gp=0.jpg',
    title: '慧擇樂齡學堂理財篇：善用儲備',
    date: '2021年6月28日（星期一）',
    time: '16:00-15:30',
    address: '慧擇香港辦公室',
    cost: '全免',
    quota: 0,
    isGift: true,
    overdue: false,
  },
  {
    id: 10004,
    cover: 'https://img1.baidu.com/it/u=3118943204,2441411556&fm=26&fmt=auto&gp=0.jpg',
    title: '慧擇樂齡學堂理財篇：善用儲備',
    date: '2021年6月28日（星期一）',
    time: '16:00-15:30',
    address: '慧擇香港辦公室',
    cost: '全免',
    quota: 0,
    isGift: false,
    overdue: true,
  },
]