import React, { useEffect } from 'react'
import LazyLoad from 'react-lazyload'
import { Footer} from '../../components'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import { Pagination, Spin, message, Empty, ConfigProvider } from 'antd'
import { useHistory } from 'react-router-dom'
import styles from './NewsList.module.scss'
import { assetsUrl } from '../../utils/constants'
// import { getNewsList } from '../../services/api'
import { fetchNewsList } from '../../redux/news/slice'
import { useSelector, useDispatch } from 'react-redux'
import { setFontSize } from '../../utils'
export function NewsList () {
  const dispatch = useDispatch()
  const history = useHistory()
  // const [loading, setLoading] = useState(false)
  // const [newsData, setNewsData] = useState(null)
  const newsState = useSelector(state => state.newsList)
  if (newsState.message) {
    message.error(newsState.message)
  }
  // const fetchNewsList = (pageNum, pageSize) => {
  //   setLoading(true)
  //   getNewsList({
  //     pageNum, 
  //     pageSize
  //   }).then(res => {
  //     setLoading(false)
  //     if (res.data.code === 0) {
  //       if (res.data.data) {
  //         setNewsData(res.data.data)
  //       }
  //     } else {
  //       setNewsData(null)
  //       message.error(res.data.message)
  //     }
  //   }).catch(e => {
  //     setLoading(false)
  //   })
  // }
  const scale = useSelector(state => state.font.scale)
  useEffect(() => {
    window.scrollTo(0, 0)
    // fetchNewsList()
    dispatch(fetchNewsList({ pageNum: newsState.data ? newsState.data.current : 1, pageSize: 10}))
  }, [])
  return <div className={styles['news-container']}>
    <Spin spinning={newsState.loading} style={{height: '65vh', position: 'static'}}>
      {
        newsState.data ? (newsState.data.records.length > 0 ? 
        <div className={styles['main']}>
          <div className={styles['news-list']}>
            {
              newsState.data.records.map((el, idx) => {
                return <div className={styles['news-item']} key={idx} onClick={e => {
                  if (el.id) {
                    history.push(`/news-detail/${el.id}`)
                  }
                }}>
                  <LazyLoad height={200} once style={{width: '384px'}}>
                    <img src={el.coverImageUrl} alt='' />
                  </LazyLoad>
                  <div className={styles['news-content']}>
                    <div className={styles['top']}>
                      <p style={setFontSize(24, scale)}>{el.title}</p>
                      <div style={setFontSize(18, scale)} className={styles['detail-desc']} dangerouslySetInnerHTML={{__html: el.detailContentText}}></div>
                    </div>
                    <div className={styles['bottom']}>
                      <img src={assetsUrl + (el.deliveryDate.indexOf('前') !== -1 ?  'images/icon_news_clock.png' : 'images/icon_news_date.png')} alt='' />
                      <p style={setFontSize(16, scale)}>{el.deliveryDate}</p>
                    </div>
                  </div>
                </div>
              })
            }
          </div>
          <ConfigProvider locale={zh_CN}>
            <Pagination style={{display: newsState.data.total > 10 ? 'block' : 'none', marginTop: '40px'}} current={newsState.data.current} total={newsState.data.total} onChange={(page, size) => {
              window.scrollTo(0, 0)
              dispatch(fetchNewsList({ pageNum: page, pageSize: size}))
            }}/>
          </ConfigProvider>
        </div> : <Empty style={{height: '45vh', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}} description='暫無資訊' image={Empty.PRESENTED_IMAGE_SIMPLE} />) : <div></div>
      }
    </Spin>
    <Footer />
  </div>
}