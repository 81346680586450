import React, {useEffect, useState} from 'react'
import LazyLoad from 'react-lazyload'
import { Spin, message, Empty } from 'antd'
import { useHistory } from 'react-router-dom'
import { Footer} from '../../components'
import { arrow, rightArrow } from '../../assets'
import styles from './ActivityList.module.scss'
import { useScrollTop } from '../../utils/hook'
import { times } from './mockup'
import { getActivityCategoryList, getActivityList } from '../../services/api'
import address from '../../assets/images/icon_activity_address.png'
import cost from '../../assets/images/icon_activity_cost.png'
import date from '../../assets/images/icon_activity_date.png'
import quota from '../../assets/images/icon_activity_quota.png'
import time from '../../assets/images/icon_activity_time.png'
import { setFontSize } from '../../utils'
import { useSelector } from 'react-redux'
export function ActivityList () {
  const scale = useSelector(state => state.font.scale)
  const history = useHistory()
  const [channelIdx, setChannelIdx] = useState(0)
  const [timeIdx, setTimeIdx] = useState(0)
  const [loading, setLoading] = useState(true)
  const [categoryList, setCategoryList] = useState([])
  const [activityList, setActivityList] = useState([])
  const fetchActivityList = () => {
    const categoryId = categoryList.length > 0 ? categoryList[channelIdx].id : null
    const dateType = times[timeIdx].id
    getActivityList({
      pageNum: 1, 
      pageSize: 20,
      categoryId,
      dateType
    }).then(res => {
      setLoading(false)
      if (res.data.code === 0) {
        if (res.data.data) {
          setActivityList(res.data.data)
        }
      } else {
        message.error(res.data.message)
      }
    }).catch(e => {
      setLoading(false)
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getActivityCategoryList().then(res => {
      if (res.data.code === 0) {
        if (res.data.data) {
          setCategoryList(res.data.data)
        }
      }
    }).catch(e => {
      console.log(e)
    })
    // fetchActivityList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    fetchActivityList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[channelIdx, timeIdx])
  useScrollTop()
  return (
    <div id='activity-container' className={styles['activity-container']}>
      {/* <Header /> */}
      <div className={styles['nav-back']} style={setFontSize(16, scale)}>
        <span onClick={() => history.replace('/')}>首頁</span>
        <img src={rightArrow} alt=''/>
        <span>全部活動</span>
      </div>
      <div className={styles['main']}>
        <div className={styles['filter-box']}>
          <span className={styles['filter-title']} style={setFontSize(32, scale)}>全部活動</span>
          <div className={styles['filter-item-box']}>
            <span className={styles['filter-type']} style={setFontSize(18, scale)}>類型：</span>
            {
              categoryList.length > 0 ? (<div className={styles['filter-item']} style={setFontSize(18, scale)}>
                {
                  categoryList.map((el, index) => {
                    return <span key={index} className={channelIdx === index ? styles['filter-item-active'] : {}} onClick={() => {
                      if (index === channelIdx) {
                        return
                      }
                      setChannelIdx(index)
                      // fetchActivityList(index, timeIdx)
                    }}>{el.name}</span>
                  })
                }
              </div>) : <div style={{display: 'none'}}></div>
            }
          </div>
          <div className={styles['filter-item-box']} style={{marginTop: '16px'}}>
            <span className={styles['filter-type']} style={setFontSize(18, scale)}>時間：</span>
            <div className={styles['filter-item']} style={setFontSize(18, scale)}>
              {
                times.map((el, index) => {
                  return <span key={index} className={timeIdx === index ? styles['filter-item-active'] : {}} onClick={() => {
                    if (index === timeIdx) {
                      return
                    }
                    setTimeIdx(index)
                    // fetchActivityList(channelIdx, index)
                  }}>{el.name}</span>
                })
              }
            </div>
          </div>
        </div>
        { 
          loading ? <div className={styles['loading-box']}> <Spin /></div>
           : (activityList.length > 0 ? <div className={styles['activity-list']}>
              {
                activityList.map((el, idx) => {
                  return <div className={styles['activity-item']} key={idx} onClick={() => {
                    history.push(`/activity-detail/${el.id}`)
                  }}>
                    <LazyLoad height={260} once>
                      <img className={styles['activity-cover']} src={el.coverImageUrl} alt=''/>
                    </LazyLoad>
                    <div className={styles['activity-info']}>
                      <div className={styles['activity-title-box']}>
                        <span className={styles['activity-title']} style={setFontSize(32, scale)}>{el.title}</span>
                        <div style={{display: el.tag ? 'block' : 'none', ...setFontSize(14, scale)}} className={styles['gift-box']} >
                          <span>{el.tag}</span>
                        </div>
                      </div>
                      <div className={styles['activity-date']} style={setFontSize(16, scale)}>
                        <img src={date} alt=''/>
                        <p style={{marginRight: '32px'}}>日期：{el.activityDate}</p>
                        <img src={time} alt=''/>
                        <p>時間：{el.activityTime}</p>
                      </div>
                      <div className={styles['activity-address']} style={setFontSize(16, scale)}>
                        <img src={address} alt=''/>
                        <p>地點：{el.address}</p>
                      </div>
                      <div className={styles['activity-quota']} style={setFontSize(16, scale)}>
                        <img src={cost} alt=''/>
                        <p>費用：</p>
                        <p className={styles['highlight-quota']} style={{marginRight: '32px'}}>{el.priceNm}</p>
                        <div className={styles['activity-cost']} style={{display: el.limitCountNm ? 'flex' : 'none'}}>
                          <img src={quota} alt=''/>
                          <p>名額：{el.limitCountNm}</p>
                        </div>
                      </div>
                      {
                        el.status === 'COMPLETED' ? <div className={styles['activity-end']} style={setFontSize(16, scale)} onClick={(e) => e.stopPropagation()}>
                          <p>活動已結束</p>
                        </div> : <a href={el.enterUrl} target='view_window' style={{pointerEvents: el.enterUrl ? 'auto' : 'none'}} className={styles['activity-apply']} onClick={(e) => {
                          e.stopPropagation()
                        }}>
                          <p style={setFontSize(16, scale)}>立即報名</p>
                          <img src={arrow} alt=''/>
                        </a>
                      }
                    </div>
                  </div>
                })
              }
            </div> : <Empty style={{height: '45vh', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}} description='暂无活动' image={Empty.PRESENTED_IMAGE_SIMPLE} />)
        }
      </div>
      <Footer />
    </div>
  )
}