import React, { useEffect, useState } from 'react'
import { Footer} from '../../components'
import style from './ProductDetail.module.scss'
import { getInsuranceProductDetail } from '../../services/api'
import { assetsUrl, K_ZURICH_TSP_CALC, K_ZURICH_SINGLE_TRAVEL_PLAN } from '../../utils/constants'
import { productTitle, claimsSettlementFlow } from './mockup'
import { menus } from '../../components/header/mockup'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setHeaderSelectIdx } from '../../redux/globalData/slice'
import { setFontSize, removeItem } from '../../utils'
import { useParams } from 'react-router'

const contactUsPath = '/contact'
// 調用後台並回調
const requireFrontApi = (require, callback) => {
  require.then(res => {
    if (res.data.code === 0) {
      callback(res.data.data)
    } else {
      console.error(res.data.message)
    }
  }).catch(e => {
    console.error(e)
  });
}
const getInsuranceLink = (param) => {
  let keyValue = param.insuranceLink ? param.insuranceLink : ''
  let linkParam = []

  if ('/travelPlusProcess' === keyValue) {
    if (!param.insuranceCategoryId || !param.productId) {
      console.log(param.insuranceCategoryId + '_' + param.productId)
      return ''
    }
    linkParam.push('categoryId=' + param.insuranceCategoryId)
    linkParam.push('productId=' + param.productId)

    return param.insuranceLink + '?' + linkParam.join('&')
  } else {
    return contactUsPath
  }
}
export function ProductDetail () {
  const { id } = useParams()
  const scale = useSelector(state => state.font.scale)
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedTitleIndex, setSelectedTitleIndex] = useState(0);
  const productElement = menus.filter(item => item.title === '保險產品')[0];
  const showProductDetail = (index) => {
    if (selectedTitleIndex === index) {
      return {display: 'block'}
    } else {
      return {display: 'none'}
    }
  }
  const [productDetail, setProductDetail] = useState({
    insuranceLink: '',
    productId: -1,
    insuranceCategoryId: -1,
    productName: '',
    feature: [],
    coverageDetails: {},
    interpretation: '',
    classicCase: '',
    fqaList: [],
  });
  const [productTitleList, setProductTitleList] = useState([])
  const [coverageDetailKeys, setCoverageDetailKeys] = useState([])

  // 獲取保險產品詳情
  const createInsuranceProductDetail = () => {
    requireFrontApi(
      getInsuranceProductDetail({productId: id}),
      (data) => {
        let product = data ? JSON.parse(JSON.stringify(data)) : {};
        // 沒有獲取導數據
        if (!product.productId) {
          console.log('沒有獲取到保險產品詳情')
          return;
        }
        // 保障詳情的產品特色
        if (product.feature !== '') {
          product.feature = JSON.parse(product.feature);
        } else {
          product.feature = [];
        }
        setProductDetail({...product});

        // 標題初期化
        // 複製數據
        const titleList = JSON.parse(JSON.stringify(productTitle));
        let idx = -1;
        // 保障詳情
        ++idx;
        titleList[idx].active = true;
        // 產品解讀
        ++idx;
        if (!product.isShowInterpretation) {
          titleList[idx].active = false;
        }
        // 典型案例
        ++idx;
        if (!product.isShowClassicCase) {
          titleList[idx].active = false;
        }
        // 投保須知
        ++idx;
        titleList[idx].active = false;
        // 理賠指引
        ++idx;
        titleList[idx].active = false;
        // FQA
        ++idx;
        if (product.fqaList.length === 0) {
          titleList[idx].active = false;
        }
        setProductTitleList([...titleList]);
        // 表格信息
        let coverageDetailKeys = Object.keys(product.coverageDetails);
        setCoverageDetailKeys(coverageDetailKeys);
      });
  }

  useEffect(() => {
    createInsuranceProductDetail()

    removeItem(K_ZURICH_TSP_CALC)
    removeItem(K_ZURICH_SINGLE_TRAVEL_PLAN)
    window.scrollTo(0, 0)
  }, [])

  return <div className={style['product-container']}>
    <div className={style['banner-box']}>
      <p style={setFontSize(50, scale)} className={style['title']}>{productDetail.productName}</p>
    </div>
    <div className={style['title-content-box']}>
      <div style={setFontSize(20, scale)} className={style['title-content']}>
        <span className={style['title-link']} onClick={() => {
          history.replace('/')
          dispatch(setHeaderSelectIdx(-1))
        }}>首頁</span>
        <span>{'>'}</span>
        <span className={style['title-link']} onClick={() => {
          // dispatch(setHeaderSelectIdx(productElement.id));
          history.push(productElement.path);
        }}>{productElement.title}</span>
        <span>{'>'}</span>
        <span>{productDetail.productName}</span>
      </div>
    </div>
    <div className={style['title-tab-box']}>
      <div id='tab-box' style={setFontSize(24, scale)} className={style['tab-box']}>
        {
          productTitleList.map(el => {
            return !el.active ? '' : <div key={el.id} className={[style['tab-item'], selectedTitleIndex !== el.id ? '' : style['tab-item-active']].join(' ')} onClick={() => {
              setSelectedTitleIndex(el.id);
              // window.scrollTo(0, 0);
            }}>
              {el.name}
            </div>
          })
        }
        <div className={style['immediate-insurance-box']}>
          <div className={style['immediate-insurance-item']} onClick={() => {
            let insuranceLink = getInsuranceLink(productDetail)
            if (insuranceLink !== '') {
              history.push(insuranceLink);
            }
          }}>
            <span>{ productDetail.insuranceLink === contactUsPath ? '預約投保' : '立即投保'}</span>
            <img src={assetsUrl + 'images/icon_enter_blue.png'} alt='' />
          </div>
        </div>
      </div>
    </div>

    <div className={style['product-detail-container']}>
      <div className={style['product-detail-box']}>
        {/* 保障詳情 */}
        <div className={style['guarantee-detail-box']} style={showProductDetail(0)}>
          <div style={setFontSize(40, scale)} className={style['guarantee-detail-title']}>
            {productDetail.productName}
          </div>
          <div style={setFontSize(20, scale)} className={style['guarantee-detail-desc']}>
            {
              productDetail.feature.map((item, index) => {
                return <div key={index} className={style['guarantee-detail-desc-item']}>
                <img src={assetsUrl + 'images/icon_correct_blue.png'} alt='' />
                <span>{item}</span>
              </div>
              })
            }
          </div>
          {
            coverageDetailKeys.map((detailKey, index) => {
              return <table key={detailKey} style={setFontSize(20, scale)} className={index === 0 ? style['guarantee-detail-table'] : [style['guarantee-detail-table'], style['guarantee-detail-other-table']].join(' ')}>
              <thead>
                <tr style={setFontSize(26, scale)}>
                  <td>{detailKey}</td>
                  <td>保障內容</td>
                </tr>
              </thead>
              <tbody>
                {
                  productDetail.coverageDetails[detailKey].map((item, idx) => {
                    return !item.coverageValue ? '' : <tr key={idx}>
                      <td>
                        <div className={style['guarantee-detail-fst-cell']}>
                          <img src={assetsUrl + 'images/icon_content_blue.png'} alt='' />
                          <div className={style['guarantee-detail-fst-cell-item']}>
                            <span>{item.coverageName}</span>
                            <span></span>
                          </div>
                        </div>
                      </td>
                      <td>{item.coverageValue}</td>
                    </tr>
                  })
                }
              </tbody>
            </table>
            })
          }
        </div>

        {/* 產品解讀 1112 */}
        <div className={style['product-introduce-box']} style={showProductDetail(1)} dangerouslySetInnerHTML={{__html: productDetail.interpretation}}></div>

        {/* 典型案例 */}
        <div className={style['product-introduce-box']} style={showProductDetail(2)} dangerouslySetInnerHTML={{__html: productDetail.classicCase}}></div>

        {/* 投保須知 */}
        <div className={style['insurance-policy-box']} style={showProductDetail(3)}>
          <table style={setFontSize(20, scale)} className={style['insurance-policy-table']}>
            <thead>
              <tr style={setFontSize(26, scale)}>
                <td colSpan='2'>須知事項</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style['insurance-policy-fst-cell']}>
                  費用扣除
                </td>
                <td>
                  乘坐公共交通工具時或於恐怖活動中發生之意外乘坐公共交通工具時或於恐怖活動中發生之意外乘坐公共交通工具時或於恐怖活動中發生之意外乘坐公共交通工具時或於恐怖活動中發生之意外乘坐公共交通工具時或於恐怖活動中發生之意外乘坐公共交通工具時或於恐怖活動中發生之意外乘坐公共交通工具時或於恐怖活動中發生之意外乘坐公共交通工具時或於恐怖活動中發生之意外乘坐公共交通工具時或於恐怖活動。    生之意外乘坐公共交通工具時或於恐怖活動中發生之意外
                </td>
              </tr>
              <tr>
                <td style={{width: '288px'}}>
                承包機構承包機構
                </td>
                <td>
                乘坐公共交通工具時或於恐怖活動中發生之意外乘坐公共交通工具時或於恐怖活動中發生之意外乘坐公共交通工具時或於恐怖活動中發生之意外乘坐公共交通工具時乘坐公共交通工具時或於恐怖活動中發生之意外乘坐公共交通工具時或於恐。
                </td>
              </tr>
              <tr>
                <td colSpan='2'>
                  <div className={style['insurance-policy-one-cell']}>
                    <p>其餘事項：</p>
                    <p>如果員工於保單生效前，已經存在或出現病狀／病徵的傷患和疾病，不論是先天或後天出現、被確診與否，都會被視作「投保前已有病症」（Pre-existing Condition）。Bowtie 公司醫療保險計劃不保「投保前已有病症」。</p>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>

        {/* 理賠指引 */}
        <div className={style['claims-settlement-box']} style={showProductDetail(4)}>
          <div style={setFontSize(20, scale)} className={style['claims-settlement-top']}>
            <div style={setFontSize(24, scale)} className={style['claims-settlement-flow']}>
            {
              claimsSettlementFlow.map((el, index) => {
                return <div key={index} className={style['claims-settlement-flow-item']}>
                  {
                    index === 0 ? '' :
                    <div className={style['flow-next']}>
                      <img src={assetsUrl + 'images/icon_label_right.png'} alt='' />
                    </div>
                  }
                  {
                    <div className={style['flow-item']}>
                      <img src={assetsUrl + el.iconImg} alt='' />
                      <span>{el.name}</span>
                    </div>
                  }
                  </div>
              })
            }
            </div>
            <div className={style['claims-settlement-content']}>
              <p>
              保險的初衷是保障生活所需，市場上以投資產品為主，導致嚴重「保障缺口」，令到所需保障與可用資源的差額愈來愈大。
              </p>
              <br/>
              <p>
              我們以科技減低成本，提供真正合身的「純保障」保險產品，讓市民以更低廉的價錢得到更佳保障。保險的初衷是保障生活所需，市場上以投資產品為主，導致嚴重「保障缺口」，令到所需保障與可用資源的差額愈來愈大。
              </p>
              <br/>
              <p>
              我們以科技減低成本，提供真正合身的「純保障」保險產品，讓市民以更低廉的價錢得到更佳保障。
              </p>
            </div>

          </div>
          <div style={setFontSize(20, scale)} className={style['claims-settlement-bottom']}>
            <div style={setFontSize(26, scale)} className={style['claims-settlement-title']}>
              理賠須知
            </div>
            <div className={style['claims-settlement-line']}>
              <img src={assetsUrl + 'images/line_dotted_line.png'} alt='' />
            </div>
            <div className={style['claims-settlement-desc']}>
              <p>
              1.保險的初衷是保障生活所需，市場上以投資產品為主，導致嚴重「保障缺口」，令到所需保障與可用資源的差額愈來愈大。
              </p>
              <br/>
              <p>
              2.我們以科技減低成本，提供真正合身的「純保障」保險產品，讓市民以更低廉的價錢得到更佳保障。
              </p>
              <br/>
              <p>
              3.我們希望引領行業作出改變，變革傳統的銷售方式、簡化投保、索償程序與「魔鬼條款」。
              </p>
              <br/>
              <p>
              4.我們相信「剛好就是最好」。
              </p>
            </div>
          </div>
        </div>

        {/* 常見問題 */}
        <div className={style['fqa-box']} style={showProductDetail(5)}>
          {
            productDetail.fqaList.map((el, index) => {
              return <div key={index}>
                {
                  index === 0 ? '' : <div className={style['fqa-line']}>
                    <img src={assetsUrl + 'images/line_dotted_line.png'} alt='' />
                  </div>
                }
                <div style={setFontSize(26, scale)} className={style['fqa-q']}>
                  <img src={assetsUrl + 'images/icon_question_blue.png'} alt='' />
                  <span>{el.question}</span>
                </div>
                <div style={setFontSize(26, scale)} className={style['fqa-a']}>
                  <img src={assetsUrl + 'images/icon_answer_blue.png'} alt='' />
                  <span>{el.answer}</span>
                </div>
              </div>
            })
          }
        </div>
      </div>

    </div>
    <Footer />

  </div>
}
