import React, { useEffect } from 'react'
import { Footer} from '../../components'
import style from './About.module.scss'
import { useSelector } from 'react-redux'
import { companyProfile, teams1, teams2 } from '../home/mockup'
import { updateFontSize } from '../../utils'
export function About () {
  const scale = useSelector(state => state.font.scale)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    let element = document.getElementById('about-container')
    updateFontSize(element, scale)
  }, [scale])
  return <div id='about-container' className={style['about-container']}>
    <div id='main' className={style['main']}>
      <div className={style['header']}>
        <p className={style.subtitle}>關於慧擇集團</p>
        <p>慧擇集團於2006年創立，是國內知名互聯網保險產品與服務平臺，由中國慧擇保險經紀有限公司（「慧擇经纪」）運營，慧擇经纪持有中國銀保監會頒發的全國性保險經紀牌照及保險網銷資質，總部位於中國深圳。2020年慧擇集团於美國上市（股票代碼：HUIZ），同年在《中國互聯網保險中介服務平台Top10》排行榜中，「慧擇经纪」位居第四位，從而躋身中國保險科技領先行列。</p>
      </div>
      {/* 公司成就 */}
      <div className={style['company-achievement']}>
        <p className={style['title']}>慧擇15年專注互聯網保險</p>
        <div className={style['achievement-list']}>
          <div className={style['achievement-item']}>
            <div className={style['top']}>
              <span>5840</span>
              <span>萬</span>
            </div>
            <span>累計服務被保用戶數</span>
          </div>
          <div className={style['achievement-item']}>
            <div className={style['top']}>
              <span>1000</span>
              <span>+</span>
            </div>
            <span>提供保險產品數</span>
          </div>
          {/* <div className={style['achievement-item']}>
            <div className={style['top']}>
              <span>200</span>
              <span>萬</span>
            </div>
            <span>最高賠付額可達</span>
          </div> */}
        </div>

      </div>
      {/* 公司簡介 */}
      <div id='profile' className={style['company-profile']}>
        <p className={style.subtitle}>關於慧擇香港</p>
        <img style={{borderRadius: '8px'}} src={companyProfile.cover} alt=''/>
        <div className={style['profile-list']}>
          {
            companyProfile.profiles.map((el, index) => {
              return <div className={style['profile-item']} key={index}>
                <div className={style['step-box']}>
                  <span>{el.title}</span>
                  <p style={{whiteSpace: 'pre-wrap'}}>{el.desc}</p>
                </div>
              </div>
            })
          }
        </div>
      </div>
      {/* <div id='teams' className={style['teams-box']}>
        <p className={style.subtitle}>慧擇香港團隊</p>
        <div className={style['teams-list']} style={{justifyContent: 'center'}}>
          {
            teams1.map((el, index) => {
              return (
                <div className={style['teams-item']} key={index}>
                  <img className={style['member-cover']} src={el.cover} alt=''/>
                  <div className={style['member-info-box']}>
                    <p className={style['member-name']}>{el.name}</p>
                    <p className={style['member-position']}>{el.position}</p>
                    <p className={style['member-intro']}>{el.desc}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className={style['teams-list']} style={{marginTop: '0px'}}>
          {
            teams2.map((el, index) => {
              return (
                <div className={style['teams-item']} key={index}>
                  <img className={style['member-cover']} src={el.cover} alt=''/>
                  <div className={style['member-info-box']}>
                    <p className={style['member-name']}>{el.name}</p>
                    <p className={style['member-position']}>{el.position}</p>
                    <p className={style['member-intro']}>{el.desc}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div> */}
      <Footer />
    </div>
  </div>
}