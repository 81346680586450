import React from "react"
import { BrowserRouter} from "react-router-dom"
import { CacheSwitch } from 'react-router-cache-route'
// import { getStore } from './utils'
// import { fetchUserInfo } from './redux/user/slice'
// import { useDispatch } from 'react-redux'
// import { K_LOGIN_TOKEN } from './utils/constants'
import { Header } from './components'
import { FrontendAuth } from './FrontendAuth'
import { routes } from './router/router'
import { ContactUsOverlap } from './components'
import './App.css';
function App() {
  // const dispatch = useDispatch()
  // // 获取用户信息
  // useEffect(() => {
  //   const token = getStore(K_LOGIN_TOKEN)
  //   if (token) {
  //     //获取用户信息
  //     dispatch(fetchUserInfo())
  //   }
  // }, [dispatch])
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <ContactUsOverlap />
        <CacheSwitch>
          <FrontendAuth routerConfig={routes} />
        </CacheSwitch>
      </BrowserRouter>
    </div>
  );
}

export default App
