import React, { useEffect } from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import styles from './ReceiveSuccess.module.scss'
import { Footer} from '../../components'
import { assetsUrl } from '../../utils/constants'
import { useSelector } from 'react-redux'
import { setFontSize } from '../../utils'
export function ReceiveSuccess () {
  const scale = useSelector(state => state.font.scale)
  const h = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return <div className={styles['success-container']}>
    <div className={styles['top-header']}>
      {
        [1, 2, 3].map(idx => {
          return <div className={styles['header-list']}>
            <div className={styles['header-item']} style={setFontSize(18, scale)}>
              <div className={styles['receive-status']}>
                <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/>
              </div>
            </div>
            <div className={styles['progress-line']}></div>
          </div>
        })
      }
    </div>
    <div className={styles['main']}>
      <img src={assetsUrl + 'images/icon_rorm_result_success.png'} alt=''/>
      <p className={styles['title']} style={setFontSize(24, scale)}>感謝在【慧擇香港】投保免費「乘守護」乘客意外保障！</p>
      <p className={styles['sub-title']} style={setFontSize(18, scale)}>確認登記後，我們會於 2-3 個工作天內通知領取投保證書。</p>
      <p className={styles['sub-title']} style={{marginTop: '8px',...setFontSize(18, scale)}}>簽收保障證書，保障方可生效。</p>
      <Button style={setFontSize(20, scale)} onClick={() => h.replace('/')}>回到主頁</Button>
    </div>
    <Footer />
  </div>
}