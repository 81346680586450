import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { categorys, products } from './mockup'
import { rightArrow } from '../../assets'
import { Footer} from '../../components'
import styles from './ProductPromotion.module.scss'
export function ProductPromotion () {
  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [])
  const history = useHistory()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [listData, setListData] = useState(products)
  const categoryChange = (index) => {
    setCurrentIndex(index)
    setListData(index === 0 ? products : [products[index - 1]])
    // console.log(listData)
  }
  return (
    <div className={styles.container}>
      {/* <Header /> */}
      <div className={styles.main}>
        <div className={styles['nav-back']}>
          <span onClick={() => history.goBack()}>首頁</span>
          <img src={rightArrow} alt=''/>
          <span>產品推介</span>
        </div>
        <div className={styles['title-box']}>
          <p className={styles.title}>產品推介</p>
          <p className={styles.subtitle}>保險類別及簡介</p>
        </div>
        <div className={styles['category-box']}>
          {
            categorys.map((el, index) => {
              return <span className={currentIndex === index ? styles['category-active'] : {}} key={index} onClick={() => categoryChange(index)}>{el}</span>
            })
          }
        </div>
        <div className={styles['product-list']}>
          {
            listData.map((el, index) => {
              return (
                <div className={styles['product-item']} key={index}>
                  <img src={el.cover} alt='' />
                  <div className={styles['product-desc']}>
                      <span>{el.category}</span>
                      <p>{el.desc}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <Footer />
    </div>
    
  )
}