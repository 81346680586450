import React from 'react'
import { Route, Redirect, useLocation } from "react-router-dom"
import CacheRoute from 'react-router-cache-route'
import { K_LOGIN_TOKEN } from './utils/constants'
import { getStore } from './utils'
import { useDispatch } from 'react-redux'
import { setHeaderSelectIdx } from './redux/globalData/slice'
export function FrontendAuth (props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const { routerConfig } = props
    const { pathname } = location
    const isLogin = getStore(K_LOGIN_TOKEN)
    // 如果该路由不用进行权限校验，登录状态下登陆页除外
    // 因为登陆后，无法跳转到登陆页
    // 这部分代码，是为了在非登陆状态下，访问不需要权限校验的路由
    
    const targetRouterConfig = routerConfig.find(
      (item) => {
        const path = item.path.replace(/\s*/g,"")
        if (path.indexOf('/:') !== -1 && item.alias) {
          return pathname.indexOf(item.alias) !== -1
        }
        return path === pathname
      }
    )
    dispatch(setHeaderSelectIdx(targetRouterConfig && targetRouterConfig.headerIdx ? targetRouterConfig.headerIdx : -1) )
    if (targetRouterConfig && !targetRouterConfig.auth && !isLogin) {
      const { exact, path,component, cacheRoute } = targetRouterConfig
      if (cacheRoute) {
        return <CacheRoute exact={exact} path={path} component={component} />
      }
      return <Route exact={exact} path={path} component={component} />
    }
    if (isLogin) {
      // 如果是登陆状态，想要跳转到登陆，重定向到主页
      if (pathname === "/login") {
        return <Redirect to="/" />;
      } else {
        // 如果路由合法，就跳转到相应的路由
        if (targetRouterConfig) {
          const { exact, path, component, cacheRoute } = targetRouterConfig
          if (cacheRoute) {
            return <CacheRoute exact={exact} path={path} component={component} />
          }
          return (<Route exact={exact} path={path} component={component} />);
        } else {
          // 如果路由不合法，重定向到 404 页面
          return <Redirect to="/404" />;
        }
      }
    } else {
      // 非登陆状态下，当路由合法时且需要权限校验时，跳转到登陆页面，要求登陆
      if (targetRouterConfig && targetRouterConfig.auth) {
        return <Redirect to="/login" />;
      } else {
        // 非登陆状态下，路由不合法时，重定向至 404
        return <Redirect to="/404" />;
      }
    }
}