import React from 'react'
import styles from'./NotFound.module.scss'
import { useSelector } from 'react-redux'
import { setFontSize } from '../../utils'
export function NotFound () {
  const scale = useSelector(state => state.font.scale)
  return (
    <div className={styles['notfound-container']}>
      <p style={setFontSize(50, scale)} className={styles['title']}>找不到網頁</p>
      <p style={setFontSize(18, scale)} className={styles['sub-title']}>您要查找的頁面可能地址不正確或已被存檔。</p>
    </div>
  )
}
