import { assetsUrl } from '../../utils/constants'
export const channels = [
  {
    id: 0,
    icon: assetsUrl + 'images/icon_home_facebook.png'
  },
  {
    id: 1,
    icon: assetsUrl + 'images/icon_home_linkedin.png'
  },
  {
    id: 2,
    icon: assetsUrl + 'images/icon_home_twitter.png'
  },
  {
    id: 3,
    icon: assetsUrl + 'images/icon_home_wechat.png'
  }
]
export const recommendProductList = [
  {
    id: 2,
    icon: assetsUrl + 'images/icon_renshou.png',
    name: '人壽保障',
    desc: '人壽保險為您及您家人提供及時的經濟支援。您甚至可以為退休保障及傳承做好準備，早日達到心目中的財務目標。人壽保險大致分為幾大類。定期人壽保險和終身人壽保險會在投保人身故後提供一筆賠償，給予您的家人或受供養人財務保障，其中定期人壽保險設有一定保障期限，例如10年或20年。定期壽險的特點是保費較低、保額較高，讓您用最實惠價錢為家人提供最大保障。而終身人壽保險兼具保障及儲蓄功能，若投保人在世時取消保單可取回一定現金。另一種萬用人壽保險則擁有高度靈活性，同時擁有人壽保險及資產增值的優勢，助您傳承財富。'
  },
  {
    id: 1,
    icon: assetsUrl + 'images/icon_yiliao.png',
    name: '醫療保險',
    desc: '面對突如其來的醫療開支可令您大失預算，醫療保險不單提供全面的實報實銷醫療保障，包括住院丶手術丶藥物及其它相關醫療服務的費用外，也提供優質醫療網絡及專科支援，讓您安心配合您的預算及醫療需要。我們的醫療保險亦有住院現金保障計劃，就住院日數提供定額現金賠償，為您提供額外經濟支援，彌補患病或住院期間蒙受的收入損失，避免影響您和您家人的正常生活。'
  },
  {
    id: 0,
    icon: assetsUrl + 'images/icon_baoxian.png',
    name: '儲蓄保險',
    desc: '儲蓄保險讓您在未來日子裡同時享有累積財富及周全保障。您可將現金儲備用於退休後作為穩定收入來源的一部分，再結合人壽保障的防護盾，不但助您早日實現人生目標，更為您和您的摯愛家人提供安心保障或作傳承之用。'
  }
]
export const companyProfile = {
  // icon_about_banner_new
  cover: assetsUrl + 'images/icon_about_banner_simple.jpg',
  profiles: [
    {
      title: '慧擇香港保險經紀有限公司',
      desc: '慧擇香港保險經紀有限公司是「慧擇集團」全資子公司。「慧擇」為內地最早取得互聯網保險銷售資格的數字化平台之一，自2006年開始運營至今已累計服務超過5800萬被保客户，憑藉豐富經驗，先進科技和專業團隊，慧擇香港專注打造一個「以客戶利益為先」，以「幫你揀 + 助你賠」為服務特色的香港保險服務平台。'
    },
    {
      title: '價值觀',
      desc: '我們相信，保險為人而生，理應以人為本，每一款保險產品的呈現，每一次服務的提供，都應該真誠關注和回應人的真實需要。'
    },
    {
      title: '專業團隊',
      desc: '慧擇香港亦擁有一支平均超過十年以上專業保險及財富管理業經驗的精英團隊，包括曾於本港多間國際性保險公司工作的理財方案總監；擁有逾15年壽險與客戶服務工作經驗的客戶體驗總監，配合資深精算團隊竭誠為大家帶來優質產品和服務。針對客戶面對保單缺乏售後服務特別是孤兒單的困擾，我們提供的「保單診斷服務」可以協助您了解現有的保單是否仍切合所需保障及解答有關問題。'
    }
  ]
}
// [
//   {
//     id: 0,
//     step: '01',
//     service1: '高品質服務',
//     service2: '不花冤枉錢',
//     desc: '我們使用一流的服務為客戶挑選最合適的產品。一對一資深顧問，量身定制高性價比產品。'
//   },
//   {
//     id: 1,
//     step: '02',
//     service1: '高性價比',
//     service2: '保費透明',
//     desc: '精選最具性價比的口碑產品。依託多年海量數據，深度定制最貼合用戶需求的保險產品。'
//   },
//   {
//     id: 2,
//     step: '03',
//     service1: '7x24小時人工服務',
//     service2: '溫暖貼心',
//     desc: '您可以7天24小時隨時前往諮詢，我們將很樂意解决您可能遇到的任何投保問題。'
//   },
//   {
//     id: 3,
//     step: '04',
//     service1: '30天退款保證',
//     service2: '不滿意就退',
//     desc: '如果您對我們的服務或產品不滿意，我們將在前30天內支持無理由退款。您可以放心購買。'
//   },
//   {
//     id: 4,
//     step: '05',
//     service1: '以舊換新',
//     service2: '保單支持靈活轉換',
//     desc: '未來可免等待期、免健康告知即可轉換為指定產品，享受更新的保障。花更少的錢，獲更優保障。'
//   },
//   {
//     id: 5,
//     step: '06',
//     service1: '幫挑幫賠',
//     service2: '省力更省心',
//     desc: '一站式保險服務，我們用心傳遞保險善意，立志成為最值得信賴的互聯網保險服務平臺。'
//   },
// ]

export const teams1 = [
  {
    id: 0,
    cover: assetsUrl + 'images/icon_portrait_zwb_s.png',
    name: '趙文彬',
    position: '總經理',
    desc: '擁有超過20年保險業經驗。曾在多家知名保險公司及銀行擔任要職，曾任職匯豐銀行首席分銷官，涉足職責包括渠道開發，亞洲業務開發，產品管理和營銷等。'
  },
  {
    id: 1,
    cover: assetsUrl + 'images/icon_portrait_zyy_s.png',
    name: '曾穎豐',
    position: '理財方案總監',
    desc: '擁有逾12年壽險銷售、團隊管理及業務發展經驗，曾任職於大型保險公司、保險經紀公司及證券公司包括友邦香港、安達人壽、國泰君安證券等。'
  }
  // {
  //   id: 2,
  //   cover: assetsUrl + 'images/icon_head_portrait3.png',
  //   name: '劉維明',
  //   position: '首席產品總監'
  // },
]

export const teams2 = [
  {
    id: 4,
    cover: assetsUrl + 'images/icon_portrait_opz_s.png',
    name: '區沛彰',
    position: '客戶體驗總監',
    desc: '擁有逾15年壽險與客戶服務工作經驗，曾任職於多間國際保險公司包括友邦保險、中國人壽(海外) 、永明人壽等客戶服務管理要職。'
  },
  {
    id: 5,
    cover: assetsUrl + 'images/icon_portrait_pmh_s.png',
    name: '潘敏華',
    position: '高級運營經理',
    desc: '擁有逾20年壽險與行政管理經驗，此前曾任職於國際保險公司和多間保險經紀公司包括永明人壽、平盛資產管理、安盈財富等市場和行政管理要職。'
  }
]


export const praises = [
  {
    id: 0,
    avatar: assetsUrl + 'images/kehu_photo1.png',
    name: '張一凡',
    position: '香港欄目主播',
    desc: '各公司都推出了有自己特色的保險品種，價格也是顧客可承受的，推薦慧擇網，我是他們的老客戶了。去年騎行在絲綢之路上時，就是慧擇保險為我保駕護航。上面騎行類的專業旅遊保險，保障的內容都是我所剛剛好需要的！'
  },
  {
    id: 1,
    avatar: assetsUrl + 'images/kehu_photo2.png',
    name: '劉永',
    position: '環球旅行博主',
    desc: '每次出發前，都會在慧擇為自己和同伴買一份保險，我清楚地知道在自己感性玩樂之外，慧擇給了我一份理性安全保障。你也可以査詢慧擇網，選擇適合自己的保險，它的好處是可以線上支付和獲得電子版的保單，非常便捷。'
  },
  {
    id: 2,
    avatar: assetsUrl + 'images/kehu_photo3.png',
    name: '何娜娜',
    position: '著名編劇',
    desc: '以前一直這裡買，這次又買了兩份，購買醫療保險是辦理申根國家簽證的必備條件，比較了多家保險公司和保險網站，我依然選擇慧擇旅遊險。這家網站銷售的保險品種非常齊全，同類產品價格上也比其他家優惠很多。是出國旅遊探親人員的首選保險品種。'
  }
]

export const  news = [
  {
    id: 0,
    cover: assetsUrl + 'images/icon_meiti_cover1.jpeg',
    time: '21/05/2021',
    title: ['樂齡之友用戶體驗官，免費即享連環豐富禮遇'],
    titles: ['樂齡之友用戶體驗官', '免費即享連環豐富禮遇'],
    detail: '誠徵、好動、愛玩的你，追求生活品味的你，精打細算、發掘各種優惠的你，為了令我們的服務與優惠精益求精，我們現誠徵用戶體驗官多名，加入「慧擇樂齡之友會」，只要你年滿50歲，好動愛玩愛體驗，就可以免費體驗我們為大家精心安排，包括「品酒會」、「咖啡拉花班」、「糕點製作班]」、「舊照修復服務」及參與各類型專題講座等！你需要做的，只是盡情享受之後，跟我們分享體驗，讓我們於未來做得更好。'
  },
  {
    id: 1,
    cover: assetsUrl + 'images/icon_meiti_cover5.jpg',
    time: '20/05/2021',
    title: ['你的孤兒單沒有人跟進？究竟有幾多保障額？退保金額？如果想改受益人，要怎處理？需要申請賠償，應該要找誰？'],
    titles: ['你的孤兒單沒有人跟進？', '究竟有幾多保障額？退保金額？', '如果想改受益人，要怎處理？', '需要申請賠償，應該要找誰？'],
    detail: '假如你有以上問題，我們幫到你！我們特別為大家舉辦費用全免的「保單診斷工作坊」，由專業人士協助你解決各類型的保險問題及保單疑慮，參加者可攜帶個人保單、信件、月結單，我們會專業、耐心地為你重溫保單內容，教你如何閱讀保單文件。'
  }
]

/*
{
    id: 2,
    cover: assetsUrl + 'images/meiti_photo3.png',
    time: '19/05/2021',
    title: '榮膺2020領航中國“傑出保險仲介服...',
    detail: '第五届智慧金融國際論壇暨2020金融界“領航中國”年度評選頒獎盛典在北京隆…'
  }
*/

export const activities = [
  [
    {
      id: 1001,
      url: 'https://img1.baidu.com/it/u=1921096633,3188797021&fm=26&fmt=auto&gp=0.jpg',
      title: '免費專業諮詢 協助理賠免費專業諮詢 協助理賠免費專業諮詢 協助理賠'
    },
    {
      id: 1002,
      url: 'https://img0.baidu.com/it/u=1844318833,114428117&fm=26&fmt=auto&gp=0.jpg',
      title: '有得玩有禮物 免費創意摺紙工作坊'
    },
    {
      id: 1003,
      url: 'https://img1.baidu.com/it/u=1727993069,3103603587&fm=26&fmt=auto&gp=0.jpg',
      title: '慧擇樂齡學堂理財篇：善用儲備免費專業諮詢 協助理賠免費專業諮詢 協助理賠'
    }
  ],
  [
    {
      id: 1004,
      url: 'https://img2.baidu.com/it/u=1891763884,132527311&fm=26&fmt=auto&gp=0.jpg',
      title: '免費專業諮詢 協助理賠免費專業諮詢 協助理賠免費專業諮詢 協助理賠免費專業諮詢 協助理賠'
    },
    {
      id: 1005,
      url: 'https://img2.baidu.com/it/u=2312391198,3092325303&fm=26&fmt=auto&gp=0.jpg',
      title: '有得玩有禮物 免費創意摺紙工作坊'
    },
    {
      id: 1006,
      url: 'https://img2.baidu.com/it/u=263446416,640810511&fm=26&fmt=auto&gp=0.jpg',
      title: '慧擇樂齡學堂理財篇：善用儲備'
    }
  ],
  [
    {
      id: 1007,
      url: 'https://img1.baidu.com/it/u=3279884260,3623454484&fm=26&fmt=auto&gp=0.jpg',
      title: '免費專業諮詢 協助理賠'
    },
    {
      id: 1008,
      url: 'https://img0.baidu.com/it/u=2953766202,1208114073&fm=26&fmt=auto&gp=0.jpg',
      title: '有得玩有禮物 免費創意摺紙工作坊'
    },
    {
      id: 1009,
      url: 'https://img1.baidu.com/it/u=2018973988,3324714535&fm=26&fmt=auto&gp=0.jpg',
      title: '慧擇樂齡學堂理財篇：善用儲備'
    }
  ],
]

export const hotProducts = [
  {
    id: 1020,
    cover: 'image_product4.png',
    title: '「享未來」人壽保障',
    desc: '除人壽保障外，亦提供保證現金價值，以及非保證之保額增值紅利以及終期紅利，助您累積財富，為摯愛家人提供安穩的財務。'
  },
  {
    id: 1022,
    cover: 'image_product6.png',
    title: '真智惠保II人壽保障系列真智惠保II人壽保障系列',
    desc: '若您無法工作，可支取現金為您提供收入，維持生計若您無法工作，可支取現金。'
  },
  {
    id: 1021,
    cover: 'image_product5.png',
    title: '「惠未來」人壽保障',
    desc: '為您及摯愛家人提供安穩的財務保障。除人壽保障外，亦提供保證現金價值，以及非保證之保額增值紅利以及終期紅利，助您實踐人生。'
  },
  {
    id: 1022,
    cover: 'image_product6.png',
    title: '真智惠保II人壽保障系列真智惠保II人壽保障系列',
    desc: '若您無法工作，可支取現金為您提供收入，維持生計若您無法工作，可支取現金。'
  }
]

export const brandInsurance = [
  {
    id: 1120,
    cover: 'image_product7.png',
    title: '真智精選定期保險 / 真智定期保險',
    desc: '為您摯愛家人提供全面經濟保障。計劃極具彈性，讓您盡享靈活、保證續保、保證可轉換等優勢，迎合不同需要。非吸煙人士更享特更。'
  },
  {
    id: 1121,
    cover: 'image_product6.png',
    title: '真智惠保II人壽保障系列真智惠保II人壽保障系列',
    desc: '若您無法工作，可支取現金為您提供收入，維持生計若您無法工作，可支取現金。'
  },
  {
    id: 1122,
    cover: 'image_product5.png',
    title: '「惠未來」人壽保障',
    desc: '為您及摯愛家人提供安穩的財務保障。除人壽保障外，亦提供保證現金價值，以及非保證之保額增值紅利以及終期紅利，助您實踐人生。'
  }
]

export const ambassador = [
  {
    id: 1200,
    avatar: 'image_ambassador_Alvin.png',
    name: 'Alvin',
    intro: '超過20年客户服務經驗，貫切以禮待人，服務理念從心出發，贏取客户信任。'
  },
  {
    id: 1201,
    avatar: 'image_ambassador_Kewe.png',
    name: 'Kewe',
    intro: '擁有多年服務性行業工作經驗，以熱誠認真的態度服務廣大客户，帶给客户不一樣的服務體驗。'
  },
  {
    id: 1200,
    avatar: 'image_ambassador_Danny.png',
    name: 'Danny',
    intro: '於服務行業工作八年，曾任職外遊領隊，喜歡與客戶互動交流，並對新事物和不同文化持開放態度。'
  }
]

export const company = ['aig.png', 'axa.png', 'chinalife.png', 'china_taiping.png','chubb.png', 'cigna.png', 'ftlife.png', 'fwd_2.png', 'generali.png', 'sunlife.png', 'wellline.png', 'zurich.png']

export const insuranceCategory = [
  {
    id: 10,
    hover: false,
    icon: 'images/icon_home_medical_critical_illness.png',
    activeIcon: 'images/icon_home_medical_critical_illness_s.png',
    bgColor: '#00DDF9',
    selectBgColor: '#FFFFFF',
    title: '醫療及危疾',
    content: [
      {id: 10, name: '自願醫保'},
      {id: 13, name: '定期危疾'},
      {id: 14, name: '終身危疾'}
    ]
  },
  {
    id: 21,
    hover: false,
    icon: 'images/icon_home_savings.png',
    activeIcon: 'images/icon_home_savings_s.png',
    bgColor: '#04ABE7',
    selectBgColor: '#FFFFFF',
    title: '儲蓄',
    content: [
      {id: 21, name: '短期儲蓄'},
      {id: 22, name: '長期儲蓄'}
    ]
  },
  {
    id: 31,
    hover: false,
    icon: 'images/icon_home_life.png',
    activeIcon: 'images/icon_home_life_s.png',
    bgColor: '#0487C9',
    selectBgColor: '#FFFFFF',
    title: '人壽',
    content: [
      {id: 31, name: '定期人壽'},
      {id: 32, name: '終身人壽'}
    ]
  },
  {
    id: 41,
    hover: false,
    icon: 'images/icon_home_annuity.png',
    activeIcon: 'images/icon_home_annuity_s.png',
    bgColor: '#02639A',
    selectBgColor: '#FFFFFF',
    title: '年金',
    content: [
      {id: 41, name: '延期年金'},
      // {id: 42, name: '即期年金'}
    ]
  },
  {
    id: 52,
    hover: false,
    icon: 'images/icon_general_insurance_n.png',
    activeIcon: 'images/icon_general_insurance_s.png',
    bgColor: '#003B65',
    selectBgColor: '#FFFFFF',
    title: '一般保險',
    content: [
      {id: 52, name: '旅遊保險'},
      {id: 50, name: '家居保險'},
      {id: 51, name: '家傭保險'}
    ]
  },
  {
    id: 60,
    hover: false,
    icon: 'images/icon_other_insurance_n.png',
    activeIcon: 'images/icon_other_insurance_s.png',
    bgColor: '#011933',
    selectBgColor: '#FFFFFF',
    title: '其他',
    content: [
      {id: 60, name: '萬用壽險'},
      {id: 61, name: '意外保險'}
    ]
  }
]