/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { message, Spin, Checkbox, Radio } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import moment from 'moment'
import { Footer} from '../../components'
import './Payment.scss'
import { getStore } from '../../utils'
import { assetsUrl, checkoutPublicKey, K_ZURICH_TSP_CALC, K_ZURICH_SINGLE_TRAVEL_PLAN } from '../../utils/constants'
import { applyPay, createOrder, zurichInsuranceTrialCalculation } from '../../services/api'
import { onValidationChanged,  paymentMethodChanged } from './checkout'
export function Payment () {
  const history = useHistory()
  const { source } = useParams()
  const param = JSON.parse(decodeURIComponent(window.atob(source)))
  let { planType, productId, categoryId, cmntDate, expiryDate, insuredPlan, optionPlan1, optionPlan2} = param
  // const param = JSON.parse(window.decodeURIComponent(source))

  // 設置state字段
  const [orderNo, setOrderNo] = useState(false)
  const [orderAmount, setOrderAmount] = useState(false)
  const [orderBizNo, setOrderBizNo] = useState(false)
  const [token, setToken] = useState(false)
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)
  const [gradeGuaValue, setGradeGuaValue] = useState(3)
  const [travel_plan, setTravel_plan] = useState(false)
  const [applicant, setApplicant] = useState(false)
  const [traveler, setTraveler] = useState([])
  const [tsp_calc, setTsp_calc] = useState(false)
  const [formData, setFormData] = useState(false)
  const [pemium, setPemium] = useState(false)
  
  useEffect(() => {
    const travel_plan_s = JSON.parse(window.localStorage.getItem('k_zurich_single_travel_plan'))
    const tsp_calc_s = JSON.parse(window.localStorage.getItem('k_zurich_tsp_calc'))
    setTravel_plan(travel_plan_s)
    setApplicant(travel_plan_s.applicant)
    setTraveler(travel_plan_s.traveler)
    setTsp_calc(tsp_calc_s)
    setFormData(tsp_calc_s.formData)
    // setPemium(tsp_calc_s.pemium)
    // 表单初始化
    setTimeout(() => {
      configFrames()
    }, 100)
    // 请求获取保费
    if (!param.from) {
      calculatePremium(tsp_calc_s.formData)
    } else if (param.from === 'fail') {
      // onConfirmPayment()
      const repay_data_s = JSON.parse(window.localStorage.getItem('k_zurich_repay_data'))
      setOrderNo(repay_data_s.orderNo)
      setOrderAmount(repay_data_s.orderAmount)
      setOrderBizNo(repay_data_s.orderBizNo)
      setStep(2)
    }
  }, [])
  const [passed] = useState(false)
  const configFrames = () => {
    var payButton = document.getElementById("pay-button")
    var form = document.getElementById("payment-form")
    Frames.init({
      publicKey: checkoutPublicKey,
      localization: {
        cardNumberPlaceholder: "信用卡卡號",
        expiryMonthPlaceholder: "MM",
        expiryYearPlaceholder: "YY",
        cvvPlaceholder: "CVV",
      }
    })
    Frames.addEventHandler(
      Frames.Events.FRAME_VALIDATION_CHANGED,
      onValidationChanged
    )

    Frames.addEventHandler(
      Frames.Events.CARD_VALIDATION_CHANGED,
      () => {
        payButton.disabled = !Frames.isCardValid()
      }
    )

    Frames.addEventHandler(
      Frames.Events.CARD_TOKENIZATION_FAILED,
      (error) => {
        console.log("CARD_TOKENIZATION_FAILED: %o", error);
        setLoading(false)
        Frames.enableSubmitForm();
      }
    )

    Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, event => {
      setLoading(false)
      setToken(event.token)
      // token = event.token
      setStep(3)
      backToTop()
    })
  
    Frames.addEventHandler(
      Frames.Events.PAYMENT_METHOD_CHANGED,
      paymentMethodChanged
    )

    form && form.addEventListener("submit", (event) => {
      event.preventDefault()
      Frames.submitCard()
      setLoading(true)
    })
  }
  
  const calculatePremium = (formData) => {
    const singlePlan = JSON.parse(getStore(K_ZURICH_SINGLE_TRAVEL_PLAN))
    if (Number(formData.aldultNo) === 0 && Number(formData.childNo) === 0) {
      return
    }
    setLoading(true)
    const insuranceIssuePolicy = {
      system: {
        productCode: formData.productCode === 0 ? 'TSP' : 'TAP'
      },
      policy: {
        cmntDate: formData.departureDate ?  moment(formData.departureDate).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY'),
        planType: formData.planType === 0 ? 'I' : 'C',
        expiryDate: formData.returnDate ? moment(formData.returnDate).format('DD/MM/YYYY') : moment().add(7, 'days').format('DD/MM/YYYY')
      },
      insuredPerson: {
        repeat: getPolicyholderInfo(singlePlan.traveler)
      },
      // InsuredPersonADNum: Number(formData.aldultNo),
      // InsuredPersonCHNum: Number(formData.childNo)
    }
    zurichInsuranceTrialCalculation({
      insuranceProductChannel: 'zurich_travel_insurance_quote',
      applicationJsonString: JSON.stringify(insuranceIssuePolicy)
    }).then(res => {
      setLoading(false)
      if (res.data.code === 0) {
        if (res.data.data) {
          setPemium(state => {
            return res.data.data
          })
        }
      } else {
        message.error(res.data.message) 
      }
    })
  }
  const confirmNextPage = () => {
    setLoading(true)
    applyPay({
      sourceNo: orderNo,
      cardToken: token,
      payChannel: 'checkout'
    }).then(res => {
      setLoading(false)
      const repay_data_s = JSON.parse(window.localStorage.getItem('k_zurich_repay_data'))
      if (res.data.code === 0) {
        // 删除保单缓存
        window.localStorage.removeItem('k_zurich_single_travel_plan')
        window.localStorage.removeItem('k_zurich_tsp_calc')
        history.replace('/payment-result?type=success&number=' + orderBizNo + '&categoryId=' + (categoryId || repay_data_s.categoryId))
      } else {
        message.error(res.data.message)
        // if (!param.from) {
          window.localStorage.setItem('k_zurich_repay_data', JSON.stringify({
            orderNo,
            orderAmount,
            orderBizNo,
            categoryId: categoryId || repay_data_s.categoryId
          }))
        // }
        history.replace('/payment-result?type=fail&number=' + orderBizNo + '&code=' + res.data.code)
      }
    })
  }

  const onChange = (e) => {
    setGradeGuaValue(e.target.value)
  }

  const setbuttonStep = (step) => {
    onConfirmPayment()
  }

  const onConfirmPayment = () => {
    setLoading(true)
    const tspData = JSON.parse(getStore(K_ZURICH_TSP_CALC))
    const singlePlan = JSON.parse(getStore(K_ZURICH_SINGLE_TRAVEL_PLAN))
    const repay_data_s = JSON.parse(window.localStorage.getItem('k_zurich_repay_data'))
    const formData = singlePlan.applicant
    let planType2 = planType || repay_data_s.planType
    let expiryDate2 = expiryDate || repay_data_s.expiryDate
    let cmntDate2 = cmntDate || repay_data_s.cmntDate
    let protocol = formData.protocol.map(el => {
      if (el.select) {
        return el.desc
      }
      return ''
    }).filter(el => el.length > 0)

    const insuranceIssuePolicy = {
      system: {
        productCode: tspData.formData.productCode === 0 ? 'TSP' : 'TAP',
      },
      insured: {
        title: planType2 === 'I' ? (formData.gender === '先生' ? 'MR' : 'MRS') : '',
        surname: formData.surname,
        firstName: formData.name,
        fullName: formData.surname + ' ' + formData.name,
        sex: formData.gender === '先生' ? 'M' : 'F',
        idType: planType2 === 'I' ? 'I' : 'R',
        idNo: planType2 === 'I' ? (formData.idNo + formData.idLastNo) : formData.businessRegistNo,
        dob: planType2 === 'I' ? moment(formData.birthDate).format('DD/MM/YYYY') : '',
        emailAddr: formData.email,
        mobileTel: formData.mobile,
        companyName: formData.companyName,
      },
      policy: {
        cmntDate: moment(cmntDate2).format('DD/MM/YYYY'),
        planType: planType2,
        travelDestination1: formData.destId1,
        travelDestination2: formData.destId2,
        travelDestination3: formData.destId3,
        expiryDate: moment(expiryDate2).format('DD/MM/YYYY'),
      },
      insuredPerson: {
        repeat: getPolicyholderInfo(singlePlan.traveler)
      },
      protocol
    }

    createOrder({
      verifyCode: formData.verifyCode,
      productId: productId || repay_data_s.productId,
      insuranceProductChannel: 'zurich_travel_insurance_issue_policy',
      applicationJsonString: JSON.stringify(insuranceIssuePolicy)
    }).then(res => {
      setLoading(false)
      if (res.data.code === 0) {
        if (res.data.data && res.data.data.orderNo) {
          const data = res.data.data
          setOrderNo(data.orderNo)
          setOrderAmount(data.orderAmount)
          setOrderBizNo(data.orderBizNo)
          // message.error('投保成功') 
          setStep(2)
          backToTop()
        } else {
          message.error('未知錯誤') 
        }
      } else {
        message.error(res.data.message) 
      }
    }).catch(e => {
      setLoading(false)
    })
  }

  // 成人和儿童，報價摘要的旅客数量
  const setAdultNum = (adult) => {
    let num = 0
    if (traveler && traveler.length > 0) {
      traveler.forEach((item) => {
        if (item.type === '成人(本人)' || item.type === '成人') {
          if (adult) {
            num++
          }
        } else {
          if (!adult) {
            num++
          }
        }
      })
    }
    return num
  }

  const getPolicyholderInfo = (travelerFormData) => {
    const repay_data_s = JSON.parse(window.localStorage.getItem('k_zurich_repay_data'))
    let repeat = []
    const types = travelerFormData.filter(el => el.type === '成人' || el.type === '成人(本人)')
    travelerFormData.forEach((el, idx) => {
      const info = {
        itmNo: idx+1,
        insuredDob: moment(el.birthDate).format('DD/MM/YYYY'),
        name: el.surname + ' ' + el.name,
        surname: el.surname,
        firstname: el.name,
        insuredIdNo: el.idNo + el.idLastNo,
        insuredPlan: insuredPlan || repay_data_s.insuredPlan,
        optionPlan1: (el.type === '成人' || el.type === '成人(本人)') ? (optionPlan2 !== undefined ? optionPlan2 : repay_data_s.optionPlan2) : (types.length > 0 ? (optionPlan1 !== undefined ? optionPlan1 : repay_data_s.optionPlan1) : ''),
        relationship: el.type === '成人' ? 'AD' :  (el.type === '成人(本人)' ? 'SF' : 'CH'),
      }
      repeat.push(info)
    })
    return repeat
  }

  const backToTop = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }
  return (
    <div className='payment-container'>
      <div className='top-header'>
        <div className='header-item'>
          <div className='receive-status'>
            <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/>
          </div>
        </div>
        <div className='progress-line'></div>
        <div className='header-item'>
          <div className='receive-status'>
            <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/>
          </div>
        </div>
        <div className='progress-line'></div>
        <div className='header-item header-item-preview'>
        {/* {[`${styles['header-item']}`, `${styles['header-item-preview']}`].join(' ')} */}
          <div className='receive-status'>
            {/* <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/> */}
            <span>3</span>
          </div>
        </div>
        <div className='progress-line' style={{background: passed ? '#1E4994' : '#9D9D9D'}}></div>
        <div className='header-item'>
          <div className='receive-status' style={{background: passed ? '#1E4994' : '#9D9D9D'}}>
            {/* <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/> */}
            <span>4</span>
          </div>
        </div>
      </div>

      <div className='loading-box' style={{display: loading ? 'flex' : 'none'}}>
        <Spin />
      </div>
      <div className='form-body'>
        <div>
          <div className='form-body-content' style={{display: step === 2 ? 'block' : 'none', marginBottom: '88px'}}>
            <div className='payment-title'>
              <img className='payment-title-img' src={assetsUrl + 'images/icon_pay_lock.png'} alt="" />
              <div className='payment-title-text'>信用卡結帳</div>
              <div className='line'></div>
            </div>
            <div className='amount-payable'>
              <div className='amount-payable-insurance'>「易起行」旅遊保險（單次計畫）</div>
              <div className='amount-payable-price'>
                <span>應付總額 HKD </span>
                <span>{orderAmount}</span>
              </div>
            </div>
            <div className='payment-authorization'>
              <div className='payment-authorization-title'>付款授權書</div>
              <div className='payment-authorization-agreement-box'>
                <div className='agreement-box-title'>本人確認</div>
                <div className=''>
                  上述持卡人明白在點擊【確認及付款】後，持卡人的付款資料將會被鏈接到慧擇香港保險經紀有限公司（本公司）的商業合作夥伴Checkout的付款系統上進行網上付款，而持卡人的付款資料將安全第傳送至收款銀行。
                </div>
                <div className=''>
                  上述持卡人茲授權本公司從持卡人信用卡直接數去應繳總額，且同意如因該項付款交易而令持卡人信用卡出現透支，持卡人願意承擔全部責任。
                </div>
              <div className='agreement-box-decision'>如有任何爭議，本公司保留最終決定權。</div>
              </div>
              <div className='address-details-box'>
                <div className=''>付款予：慧擇香港保險經紀有限公司</div>
                <div className='address-details'>香港中環皇后大道中99號中環中心1911室</div>
              </div>
            </div>

            <form
              id="payment-form"
              method="POST"
              action="https://merchant.com/charge-card"
            >
              <div className='input-box'>
                <label htmlFor="card-number">信用卡卡號</label>
                <div className="input-container card-number">
                  <div className="icon-container">
                    <img id="icon-card-number" src={assetsUrl + 'images/card.svg'} alt="PAN" />
                  </div>
                  <div className="card-number-frame"></div>
                  <div className="icon-container payment-method">
                    <img id="logo-payment-method" alt='logo-payment-method'/>
                  </div>
                  <div className="icon-container">
                    <img id="icon-card-number-error" src={assetsUrl + 'images/error.svg'} alt='icon-card-number-error'/>
                  </div>
                </div>

                <div className="date-and-code">
                  <div>
                    <label htmlFor="expiry-date">信用卡到期日</label>
                    <div className="input-container expiry-date">
                      <div className="icon-container">
                        <img
                          id="icon-expiry-date"
                          src={assetsUrl + 'images/exp-date.svg'}
                          alt="Expiry date"
                        />
                      </div>
                      <div className="expiry-date-frame"></div>
                      <div className="icon-container">
                        <img
                          id="icon-expiry-date-error"
                          src={assetsUrl + 'images/error.svg'}
                          alt=''
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="cvv">卡背安全碼</label>
                    <div className="input-container cvv">
                      <div className="icon-container">
                        <img id="icon-cvv" src={assetsUrl + 'images/cvv.svg'} alt="CVV" />
                      </div>
                      <div className="cvv-frame"></div>
                      <div className="icon-container">
                        <img id="icon-cvv-error" src={assetsUrl + 'images/error.svg'} alt=''/>
                      </div>
                    </div>
                  </div>
                </div>
                <button id="pay-button" disabled="">
                  支付港幣 {orderAmount}
                </button>
              </div>
              <div>
                <span className="error-message error-message__card-number"></span>
                <span className="error-message error-message__expiry-date"></span>
                <span className="error-message error-message__cvv"></span>
              </div>
              <p className="success-payment-message"></p>
            </form>
          </div>
          
          <div className='form-body-content' style={{display: step === 3 ? 'block' : 'none', paddingTop: '56px', paddingBottom: '64px'}}>
            <div className='checkout-platform'>
              <div className='checkout-platform-title'>支付尚未完成</div>
              <div className='checkout-platform-shop-name'>請點擊“確認”按鈕完成支付</div>
              <br/>
              <div className='checkout-platform-price'>您將支付港幣{orderAmount}給慧擇香港保險經紀有限公司</div>
              {/* <div className=''>....</div> */}
              {/* <div className=''>..........</div> */}
            </div>
          </div>
          <div className='confirm-pay' style={{display: step === 3 ? 'block' : 'none'}}>
            {/* <button className='previous-step' onClick={()=> setStep(2)}>上一步</button> */}
            <button className='pay-button' onClick={confirmNextPage}>確認</button>
          </div>
          
        </div>
        {step === 1 &&
        <div>
          <div className='form-body-content'>
            <div className='form-body-content-tab'>
              <div className='payment-title'>
                <div className='payment-title-text'>報價摘要</div>
                <div className='line'></div>
              </div>
              <div className='payment-details'>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>保險計畫</div>
                  <div className='payment-details-content'>{formData.productCode === 1 ? '全年旅遊' : '單次旅遊'}</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>級別</div>
                  <div className='payment-details-content'>{insuredPlan === 'P1' ? '優遊計劃' : insuredPlan === 'P2' ? '精選計劃' : '隨心計劃'}</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>出發日期</div>
                  <div className='payment-details-content'>{moment(formData.departureDate).format('DD/MM/YYYY')}</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>回程日期</div>
                  <div className='payment-details-content'>{moment(formData.returnDate).format('DD/MM/YYYY')}</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>成人（{formData.productCode === 0 ? '18歲以上' : '18-70歲'}）</div>
                  {/* <div className='payment-details-content'>{formData.aldultNo}</div> */}
                  <div className='payment-details-content'>{setAdultNum(true)}</div>
                  
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>兒童（18歲以下）</div>
                  {/* <div className='payment-details-content'>{formData.childNo}</div> */}
                  <div className='payment-details-content'>{setAdultNum(false)}</div>
                </div>
                {formData.productCode === 0 && 
                  <div className='payment-details-item'>
                    <div className='payment-details-title'>旅遊日數</div>
                    
                    <div className='payment-details-content'>{(Number(moment(moment(formData.returnDate).format('YYYY-MM-DD') + ' 00:00:00').diff(moment(moment(formData.departureDate).format('YYYY-MM-DD') + ' 00:00:00'), 'day')) + 1) + ' 日'}</div>
                  </div>
                }
              </div>
            </div>
            <div className='form-body-content-tab'>

            {((setAdultNum(false) > 0 && setAdultNum(true) > 0)  || (insuredPlan === 'P1' && setAdultNum(true) > 0)) && 
            <div>
              <div className='payment-title payment-title-black'>
                <div className='payment-title-text'>額外保障</div>
                <div className='line'></div>
              </div>
              <div className='upgrade-guarantee'>
                {setAdultNum(false) > 0 && 
                  <div>
                    <div className='upgrade-guarantee-title'>1. 您需要為隨行兒童購買全額保障嗎？</div>
                    <Radio.Group onChange={onChange} value={optionPlan1 === 'OP1'?'yes':'no'} disabled>
                      <Radio value={'yes'}>
                        <span className='additional-before'>是</span>
                        {/* <span className='additional-premium'>（額外保費 113港元）</span> */}
                      </Radio>
                      <Radio value={'no'} style={{marginBottom: '34px'}}>
                        <span className='additional-before'>否</span>
                      </Radio>
                    </Radio.Group>
                  </div>
                }
                {insuredPlan === 'P1' && setAdultNum(true) > 0 &&
                <div>
                  <div className='upgrade-guarantee-title'>{setAdultNum(false) > 0 ? 2 : 1}. 您想升級個人意外及醫療費用保障嗎？（適用於所有18歲或以上之旅客）</div>
                  <Radio.Group onChange={onChange} value={optionPlan2||'OP1'} disabled>
                    <Radio value={'OP2'}>
                      <span className='additional-before'>是，每節額外1,000,000港元</span>
                      {/* <span className='additional-premium'>（額外保費 140港元）</span> */}
                    </Radio>
                    <Radio value={'OP3'}>
                      <span className='additional-before'>是，每節額外2,000,000港元</span>
                      {/* <span className='additional-premium'>（額外保費 200港元）</span> */}
                    </Radio>
                    <Radio value={'OP1'}>
                      <span className='additional-before'>否</span>
                    </Radio>
                  </Radio.Group>
                </div>
                }
              </div>
            </div>
            }
              <div className='payment-details premium-payable'>
                {/* <div className='payment-details-item width-100'>
                  <div className='payment-details-title'>基本計劃保費</div>
                  <div className='payment-details-content'>港幣 {formData.insuredPlan === 1 ? (pemium.p1 && pemium.p1.discountedPremium) : formData.insuredPlan === 2 ? (pemium.p2 && pemium.p2.discountedPremium) : (pemium.p3 && pemium.p3.discountedPremium)}</div>
                </div>
                <div className='payment-details-item width-100'>
                  <div className='payment-details-title'>額外保障</div>
                  <div className='payment-details-content'>港幣 0.00</div>
                </div> */}
                <div className='payment-details-item width-100'>
                  <div className='payment-details-title'>應繳保費</div>
                  <div className='payment-details-content'>港幣 {pemium && pemium[0] && pemium[0].discountedPremium}</div>
                  {/* <div className='payment-details-content'>港幣 {formData.insuredPlan === 1 ? (pemium.p1 && pemium.p1.grossPremium) : formData.insuredPlan === 2 ? (pemium.p2 && pemium.p2.grossPremium) : (pemium.p3 && pemium.p3.grossPremium)}</div> */}
                </div>
              </div>
              <div className='upgrade-guarantee-tips'>如果閣下所輸入的旅客資料與快速報價頁面的選擇不同，本頁所列保費可能與快速報價頁面所顯示的保費有所不同。</div>
            </div>
            <div className='form-body-content-tab'>
              <div className='payment-title'>
                <div className='payment-title-text'>申請人</div>
                <div className='line'></div>
              </div>
              <div className='payment-details'>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>稱謂</div>
                  <div className='payment-details-content'>{applicant.gender}</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>出生日期</div>
                  <div className='payment-details-content'>{moment(applicant.birthDate).format('DD/MM/YYYY')}</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>英文全名</div>
                  <div className='payment-details-content'>{applicant.surname + ' ' + applicant.name}</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>香港身份證號碼</div>
                  <div className='payment-details-content'>{applicant.idNo + applicant.idLastNo}</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>手提號碼</div>
                  <div className='payment-details-content'>{applicant.mobile}</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>電郵地址</div>
                  <div className='payment-details-content'>{applicant.email}</div>
                </div>
                <div className='payment-details-item width-100'>
                  <div className='payment-details-title'>申請人是否其中一位旅客</div>
                  <div className='payment-details-content'>{applicant.isPassenger}</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>{formData.productCode === 1 && '慣常'}旅遊目的地 1</div>
                  <div className='payment-details-content'>{applicant.destination1}</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>{formData.productCode === 1 && '慣常'}旅遊目的地 2</div>
                  <div className='payment-details-content'>{applicant.destination2}</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>{formData.productCode === 1 && '慣常'}旅遊目的地 3</div>
                  <div className='payment-details-content'>{applicant.destination3}</div>
                </div>
              </div>
            </div>
            {formData.productCode === 1 && 
            <div className='form-body-content-tab'>
              <div className='payment-title'>
                <div className='payment-title-text'>通訊地址</div>
                <div className='line'></div>
              </div>
              <div className='payment-details'>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>室</div>
                  <div className='payment-details-content'>2401</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>樓</div>
                  <div className='payment-details-content'>24</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>座</div>
                  <div className='payment-details-content'>22</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>大廈/屋邨</div>
                  <div className='payment-details-content'>DUOSHENG BUILDING</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>街道</div>
                  <div className='payment-details-content'>979 KING'S ROAD</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>地區</div>
                  <div className='payment-details-content'>Quarry Bay 鰂魚涌</div>
                </div>
                <div className='payment-details-item'>
                  <div className='payment-details-title'>區域</div>
                  <div className='payment-details-content'>香港</div>
                </div>
              </div>
            </div>}
            {traveler.map((item, index) => {
              return <div className='form-body-content-tab' key={index}>
                <div className='payment-title'>
                  <div className='payment-title-text'>旅客 {index+1}</div>
                  <div className='line'></div>
                </div>
                <div className='payment-details'>
                  <div className='payment-details-item'>
                    <div className='payment-details-title'>英文全名</div>
                    <div className='payment-details-content'>{item.surname + ' ' + item.name}</div>
                  </div>
                  <div className='payment-details-item'>
                    <div className='payment-details-title'>出生日期</div>
                    <div className='payment-details-content'>{moment(item.birthDate).format('DD/MM/YYYY')}</div>
                  </div>
                  <div className={`payment-details-item ${setAdultNum(true) <= 0?'width-100':''}`}>
                    <div className='payment-details-title'>香港身份證號碼</div>
                    <div className='payment-details-content'>{item.idNo+item.idLastNo}</div>
                  </div>
                  {setAdultNum(true) > 0 && 
                  <div className='payment-details-item'>
                    <div className='payment-details-title'>額外保障</div>
                    {(item.type === '成人' || item.type === '成人(本人)') ? 
                      <div className='payment-details-content'>{optionPlan2 === 'OP2' ? '港幣100萬個人意外及醫療費用' : optionPlan2 === 'OP3' ? '港幣200萬個人意外及醫療費用' : '沒選' }</div> :
                      <div className='payment-details-content'>{optionPlan1 === 'OP1' ? '已選' : '沒選'}</div>
                    }
                  </div>
                  }
                  <div className='payment-details-item'>
                    <div className='payment-details-title'>旅客類別</div>
                    <div className='payment-details-content'>{item.type}</div>
                  </div>
                </div>
              </div>
            })}
            <div className=''></div>
          </div>
          <div className='confirm-pay'>
            <button className='previous-step' onClick={()=> window.history.go(-1)}>返回</button>
            <button className='pay-button' onClick={setbuttonStep.bind(this, 2)}>確認及付款</button>
          </div>
        </div>
        }
      </div>

      <Footer />
    </div>
  )
}