import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  headerSelectIdx: -1
}
export const globalDataSlice = createSlice({
  name: "globalData",
  initialState,
  reducers: {
    setHeaderSelectIdx: (state, action) => {
      state.headerSelectIdx = action.payload
    }
  }
})

export const { setHeaderSelectIdx } = globalDataSlice.actions