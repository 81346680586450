import { assetsUrl } from '../../utils/constants'
export const medicalIllness = {
  top: {
    icon: assetsUrl + 'images/icon_home_medical_critical_illness_s.png',
    title: '醫療及危疾'
  },
  tags:['醫療保險提供住院丶手術丶藥物及其它相關醫療服務的費用，實報實銷，不僅保障未知的已有病症，合資格保費更可作稅務扣減。', '危疾保障一般針對三大危疾(癌症, 中風及心臟病)，及其他多種嚴重疾病。'],
  bottom: [
    {
      title: '自願醫保(VHIS)',
      sub_title: '*稅務扣減 (保單持有人最高可獲每年HK$8,000的免稅額)',
      list: ['均保證續保至100歲(具體內容請參照保險合約條款約定)', '保障未知的已有病症']
    },
    {
      title: '定期危疾',
      sub_title: '',
      list: ['主要提供嚴重疾病的保障。', '於保障期內，若受保人不幸患上符合保單定義的嚴重危疾，便可得到一筆過的賠償。', '常見的為每1年、5年及10年更新保費；受保人通常可保證續保至指定歲數。']
    },
    {
      title: '終身危疾',
      sub_title: '',
      list: ['主要提供嚴重疾病的保障。', '於保障期內，若受保人不幸患上符合保單定義的嚴重危疾，便可得到一筆過的賠償。', '常見的保費繳付期為5年至30年；受保人通常可保證續保至100歲或終身。(具體內容請參照保險合約條款約定)']
    }
  ]
}

export const life = {
  top: {
    icon: assetsUrl + 'images/icon_home_life_s.png',
    title: '人壽'
  },
  tags:['人壽保險主要分為定期人壽及終身人壽。兩者會在投保人身故後提供一筆過賠償，給予您的家人或受供養人財務保障。'],
  bottom: [
    {
      title: '定期人壽',
      sub_title: '',
      list: ['定期人壽保費通常較為便宜，而賠償倍數亦較高，若受保人於保障期內身故，受益人便可按條款得到一筆過的身故賠償。', '保費會按受保人年齡、繳費期數以及保額而釐定。']
    },
    {
      title: '終身人壽',
      sub_title: '',
      list: ['終身人壽保險有回報（包含保證及非保證回報）', '亦有人壽成分 (保障至終身)']
    }
  ]
}
export const generalInsurance = [
  {
    icon: assetsUrl + 'images/icon_travel_insurance.png',
    title: '旅遊保險',
    desc: '一般旅遊保險提供醫療費用、旅程延誤、租車自負額等。'
  },
  {
    icon: assetsUrl + 'images/icon_house_insurance.png',
    title: '家居保險',
    desc: '一般家居保險提供財物保險保障、公眾責任保障、臨時住宿保障等。'
  },
  {
    icon: assetsUrl + 'images/icon_domesticworkers.png',
    title: '家傭保險',
    desc: '一般家傭保險提供外科手術及住院費用、門診費用、個人意外死亡或傷殘賠償等。'
  }
]
export const other = {
  top: {
    icon: assetsUrl + 'images/icon_other_insurance_s.png',
    title: '其他'
  },
  tags:[],
  bottom: [
    {
      title: '萬用壽險',
      sub_title: '',
      list: ['可自訂投資選項的人壽保險計劃，針對高資產客戶人群。', '可提供資本增值的長線投資產品同時包含有投資及保險成分，兼顧投資及遺產策劃目標。']
    },
    {
      title: '意外保險',
      sub_title: '',
      list: ['一般個人意外保險保障意外死亡或永久傷殘等。', '提供意外醫療費用。']
    }
  ]
}