import React, { useEffect } from 'react'
import { useHistory} from 'react-router-dom'
import { Button } from 'antd'
import { Footer} from '../../components'
import styles from './GiftInsurance.module.scss'
import { assetsUrl } from '../../utils/constants'
import { advantage } from './mockup'
import { useSelector } from 'react-redux'
import { setFontSize } from '../../utils'
const features = ['● 努力地在擠逼的巴士或火車上保持平衡；', '● 在週末愉快地駕駛私家車與家人到郊外忙裏偷閒進行戶外活動；', '● 在在午夜工作後帶著疲倦的身軀回家，歇力地避免在小巴中入睡；', '● 善用「兩元乘車優惠」與您相識多年的摯友一起遊歷香港']
export function GiftInsurance () {
  const h = useHistory()
  const scale = useSelector(state => state.font.scale)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return <div className={styles['gift-insurance']}>
    <div className={styles['top-header']}>
      <img src={assetsUrl + 'images/icon_fpa_insurance_banner.png'}alt='' />
      <div className={styles['advantage-box']}>
        <div className={styles['advantage-content']}>
          <div className={styles['advantage-list']}>
            {
              advantage.map(el => {
                return <div key={el} className={styles['advantage-item']} >
                  <img src={assetsUrl + 'images/icon_giftInsurance_advantage.png'} alt='' />
                  <span style={setFontSize(24, scale)}>{el}</span>
                </div>
              })
            }
          </div>
          <div className={styles['hint-box']} style={setFontSize(24, scale)}>
            <div className={styles['hint-item']}>
              <p style={{fontWeight: 'bold'}}>活動時間：</p>
              <p style={{flex: '1'}}>即日至2021年10月15日</p>
            </div>
            <div className={styles['hint-item']}>
              <p style={{fontWeight: 'bold'}}>重要提示：</p>
              <p style={{flex: '1'}}>受保成員必須為香港居民、並持有效香港身份證及擁有香港地址。受保年齡需為18至80歲。<a href={assetsUrl + 'document/保障小冊子.pdf'} target="view_window">按此</a> 連結至保障小冊子。</p>
            </div>
          </div>
        </div>  
      </div>
    </div>
    <div className={styles['table-container']}>
      <div className={styles['table-box']}>
        <div className={styles['table-header']}>
          <div className={styles['left-title']}>
            <span style={setFontSize(40, scale)}>保障</span>
          </div>
          <div className={styles['right-title']}>
            <span style={setFontSize(24, scale)}>保障內容 (HK$)</span>
          </div>
        </div>
        <div className={styles['region-limit-box']} style={setFontSize(20, scale)}>
          <div className={styles['region-limit-left']} style={{width: '42%', height: '58px'}}>
            <span>地域限制</span>
          </div>
          <div className={styles['region-limit-right']} style={{width: '58%', height: '58px'}}>
            <span>香港</span>
          </div>
        </div>
        <div className={styles['age-box']} style={setFontSize(24, scale)}>
          <div className={styles['age-left']} style={{width: '42%', height: '63px'}}>
            <span>年齡</span>
          </div>
          <div className={styles['age-right']} style={{width: '58%', height: '63px'}}></div>
        </div>
        <div className={styles['renewal-age-box']} style={setFontSize(20, scale)}>
          <div className={styles['renewal-left']} style={{width: '42%'}}>
            <span>受保/續保年齡</span>
          </div>
          <div className={styles['renewal-right']} style={{width: '58%'}}>
            <div style={{width: '100%', height: '57px'}}>
              <span>受保年齡：18-80歲</span>
            </div>
            <div style={{width: '100%', height: '57px'}}>
              <span>續保年齡：直至80歲</span>
            </div>
          </div>
        </div>
        <div className={styles['age-box']} style={setFontSize(24, scale)}>
          <div className={styles['age-left']} style={{width: '42%', height: '63px'}}>
            <span>保障期</span>
          </div>
          <div className={styles['age-right']} style={{width: '58%', height: '63px'}}></div>
        </div>
        <div className={styles['region-limit-box']} style={setFontSize(20, scale)}>
          <div className={styles['region-limit-left']} style={{width: '42%', height: '58px'}}>
            <span>保障期</span>
          </div>
          <div className={styles['region-limit-right']} style={{width: '58%', height: '58px', borderTopColor: '#D3D3D3'}}>
            <span style={{fontWeight: 'bold'}}>1年</span>
          </div>
        </div>
        <div className={styles['age-box']} style={setFontSize(24, scale)}>
          <div className={styles['age-left']} style={{width: '42%', height: '63px'}}>
            <span>個人意外 (a)</span>
          </div>
          <div className={styles['age-right']} style={{width: '58%', height: '63px'}}></div>
        </div>
        <div className={styles['accidental-box']} style={setFontSize(20, scale)}>
          <div className={styles['left-box']}>
            <span>意外死亡</span>
          </div>
          <div className={styles['right-box']}>
            <div className={styles['guaranteed-amount-box']}>
              <span>HK$3,000,000</span>
              <span>HK$1,000,000</span>
            </div>
            <div className={styles['guaranteed-amount-box']} style={{fontWeight: '400'}}>
              <span>18-59歲</span>
              <span>60至80歲</span>
            </div>
          </div>
        </div>
        <div className={styles['age-box']} style={setFontSize(24, scale)}>
          <div className={styles['age-left']} style={{width: '42%', height: '63px'}}>
            <span>個人意外 (b)</span>
          </div>
          <div className={styles['age-right']} style={{width: '58%', height: '63px'}}></div>
        </div>
        <div className={styles['region-limit-box']} style={setFontSize(20, scale)}>
          <div className={styles['region-limit-left']} style={{width: '42%', height: '58px'}}>
            <span>永久傷殘</span>
          </div>
          <div className={styles['region-limit-right']} style={{width: '58%', height: '58px', borderTopColor: '#D3D3D3'}}>
            <span style={{fontWeight: 'bold'}}>HK$100,000</span>
          </div>
        </div>
        <div className={styles['age-box']} style={setFontSize(24, scale)}>
          <div className={[`${styles['age-left']}`, `${styles['age-left-special']}`].join(' ')} style={{width: '42%'}}>
            <span style={{marginBottom: '16px'}}>每日住院現金保障</span>
          </div>
          <div className={styles['age-right']} style={{width: '58%'}}>
            <span>每日HK$750</span>
            <span>每次意外最長15日</span>
          </div>
        </div>
        <div className={styles['region-limit-box']} style={setFontSize(20, scale)}>
          <div className={styles['region-limit-left']} style={{width: '42%', height: '58px'}}>
            <span>保費</span>
          </div>
          <div className={styles['region-limit-right']} style={{width: '58%', height: '58px', borderTopColor: '#D3D3D3', borderBottomColor: '#F2A915'}}>
            <span style={{fontWeight: 'bold', color: '#1E4994'}}>免費</span>
          </div>
        </div>
      </div>
      <p className={styles['insurance-desc']} style={setFontSize(20, scale)}>為生活在我們鍾愛城市中的市民和家庭支柱而設，「乘守護」保障您在每天乘坐交通工具途中可能發生的意外事故。當您作為付費乘客乘坐公共交通工具或以乘客身份乘坐私家車時，我們可以讓您和您的摯愛更加安心。</p>
      <div className={styles['insurance-features']} style={setFontSize(20, scale)}>
        <div className={styles['left-box']}>
          <p className={styles['insurance-title']} style={setFontSize(24, scale)}>專為香港乘客而設的保險計劃</p>
          <p style={{margin: '16px 0px'}}>在香港，繁忙的交通及市中心高昂的住屋費用，迫使香港人每天花很多時間在交通工具上。</p>
          <p style={{marginBottom: '4px'}}>作為一個城市人，無論您是：</p>
          <div className={styles['features-list']}>
            {
              features.map(el => {
                return <p style={{margin: '4px 0px'}} key={el}>{el}</p>
              })
            }
          </div>
          <p style={{marginTop: '12px'}}>如果您在乘車途中發生不幸的事故，請讓我們來保障您和您的摯愛。</p>
        </div>
        <div className={styles['right-box']}>
          <p className={styles['insurance-title']} style={setFontSize(24, scale)}>個人意外</p>
          <p style={{marginTop: '16px'}}>若您作為受保人在保險期內遭遇意外1而蒙受損傷，您可享：</p>
          <p style={{marginTop: '8px'}}>● 高達<strong>3,000,000</strong>港元意外死亡保障；或<strong>100,000</strong>港元的永久傷殘保障</p>
          <p style={{marginTop: '32px', ...setFontSize(24, scale)}} className={styles['insurance-title']}>每日住院現金保障</p>
          <p style={{marginTop: '16px'}}>若您作為受保人在保險期內因意外1而損傷及於香港住院，您可享：</p>
          <p style={{marginTop: '8px'}}>● <strong>750</strong>港元每日住院現金保障，每次意外最長<strong>15</strong>日</p>
        </div>
      </div>
      <p className={styles['insurance-intro']} style={setFontSize(24, scale)}>「乘守護」乘客意外保障由蘇黎世保險有限公司承保，慧擇香港為「乘守護」乘客意外保障之保單持有人，登記者為此保單之成員。</p>
      <p className={styles['special-instructions']} style={setFontSize(20, scale)}>意外是指任何受保人以付款乘客身份（並非操作員、機師或機員）乘坐、登上或離開任何持牌可於香港營運的公共交通工具或受保人以乘客身份乘坐、登上或離開任何持牌可於香港行走的私家車所發生的不可預見或預料並導致受保人蒙受損傷之突發事件。為免存疑，如意外發生時，如受保人已透過完成出境手續離開香港，意外會被視為不在香港發生。</p>
    </div>
    <div className={styles['btn-group']}>
      <Button style={setFontSize(24, scale)} onClick={() => {
        var path = {
          pathname:'/contact',
          query: {
            scroll: true
          }
        }
        h.push(path)
      }}>諮詢方案專家</Button>
      <Button style={setFontSize(24, scale)} onClick={() => {
        h.push('/receive-gift-insurance')
      }}>下一步</Button>
    </div>
    {/* <div className={styles['important-note-box']}>
      <div className={styles['important-note-content']} style={setFontSize(20, scale)}>
        <div className={styles['important-note']}>重要提示：</div>
        <div className={styles['important-note']}>1. 登記「乘守護」乘客意外保障，同時成為慧擇香港會員。</div>
        <div className={styles['important-note']}>2. 受保成員必需是香港居民、並持有香港身份證（或香港出世紙）及擁有香港住址。</div>
        <div className={styles['important-note']}>3. 於保障生效日時，受保年齡需為 18 至 80 歲。</div>
        <div className={styles['important-note']}>4. 於發記此保障計劃前，您應閱讀 <a>保險證書之條款及細則</a>，<a>保障聲明</a>及 <a>保障小冊子</a></div>
        <div className={styles['important-note']}>5. 以上資料僅供參考之用，並不構成保險合約的一部分。</div>
      </div>
    </div> */}
    <Footer />
  </div>
}