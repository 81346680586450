import React, { useEffect, useState } from 'react'
import { Form, Input, Select, DatePicker, Button, message, Upload, Spin } from 'antd'
import { useHistory ,useLocation } from 'react-router-dom'
import { selectArrow } from '../../assets'
import style from './InsuranceClaimsService.module.scss'
import './select.css'
import { assetsUrl } from '../../utils/constants'
import { getInsuranceCategory, submitCustomerClaimsService, uploadCustomerClaimsServiceFiles, getCustomerClaimsServiceContactTime } from '../../services/api'
import { isLegalEmail, isLegalHKPhone } from '../../utils'
import { setFontSize } from '../../utils'
import { useSelector } from 'react-redux'
import { tabInfo } from './mockup'
import locale from 'antd/es/date-picker/locale/zh_CN'
import moment from 'moment';

// 調用後台並回調
const requireFrontApi = (require, callback) => {
  require.then(res => {
    if (res.data.code === 0) {
      callback(res.data.data)
    } else {
      console.error(res.data.message)
    }
  }).catch(e => {
    console.error(e)
  });
}
export function InsuranceClaimsService () {
  const location = useLocation()
  const history = useHistory()
  const scale = useSelector(state => state.font.scale)
  const initialValues = {
    surname: '',
    name: '',
    gender: undefined,
    phone: '',
    email: '',
    insuranceCategoryId: undefined,
    insuranceCompanyName: '',
    policyNo: '',
    contactDate: '',
    contactTime: undefined,
    policyDocuments: '',
    requirement: ''
  }
  const [fileList, setFileList] = useState([])
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [formData, setFormData] = useState({ ...initialValues })
  const [tabIndex, setTabIndex] = useState(0)
  const [insuranceCategoryList, setInsuranceCategoryList] = useState([])
  const [contactTimeList, setContactTimeList] = useState([])
  const [inputCompleted, setInputCompleted] = useState(false)
  const [loading, setLoading] = useState(false)
  const maxUploadCount = 10

  const onTabChange = (idx) => {
    if (tabIndex === idx) {
      return
    }
    setTabIndex(idx)
    let fields = []
    if (formData.surname.length === 0) {
      // fields.push('surname')
    }
    if (fields.length > 0) {
      // form.resetFields(fields)
    }
  }
  const canSubmit = () => {
    if (formData.name.length > 0 &&
        formData.surname.length > 0 &&
        formData.gender &&
        formData.phone.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  // 文件上传
  const upLoadprops = {
    beforeUpload: file => {
      const isLt10M = file.size / 1024 / 1024 <= 10;
      if (!isLt10M) {
        message.warning('檔案太大，請上載少於 10MB 的檔案。')
        return
      }
      setLoading(true)
      const formData = new FormData()
      formData.append('file', file)
      uploadCustomerClaimsServiceFiles(formData).then(res => {
        setLoading(false)
        if (res.data.code === 0) {
          setFileList([...fileList, res.data.data])
        } else {
          message.error(res.data.message)
        }
      })
      return false
    },
    accept: 'image/jpg,image/png,image/jpeg,.pdf',
    showUploadList: false
  }

  // 獲取保險產品詳情
  const getInsuranceCategoryList = () => {
    requireFrontApi(
      getInsuranceCategory({level: 1}),
      (data) => {
        setInsuranceCategoryList(JSON.parse(JSON.stringify(data)))
      });
  }
  // 獲取理賠接客服的聯絡時間
  const getContactTimeList = () => {
    requireFrontApi(
      getCustomerClaimsServiceContactTime(),
      (data) => {
        const list = []
        const keys = Object.keys(data)
        for (let key of keys) {
          list.push({
            key: key,
            value: data[key]
          })
        }
        setContactTimeList(list)
      });
  }

  const submit = () => {
    let param = {
      type: tabInfo[tabIndex].type,
      surname: formData.surname,
      name: formData.name,
      sex: formData.gender === '先生' ? 1 : 0,
      mobile: formData.phone,
      mobileArea: 'HK',
      email: formData.email,
      insuranceCategoryId: formData.insuranceCategoryId,
      insuranceCompanyName: formData.insuranceCompanyName,
      policyNo: formData.policyNo,
      contactDate: formData.contactDate,
      contactTime: formData.contactTime,
      policyDocuments: fileList.length === 0 ? '' : JSON.stringify(fileList),
      requirement: formData.requirement
    }

    switch(tabInfo[tabIndex].type) {
      case 'CLAIMS' :
        param.policyDocuments = '';
        param.requirement = '';
        break;
      case 'CHANGE' :
        param.policyDocuments = '';
        break;
      default:
        break;
    }

    submitCustomerClaimsService(param).then(res => {
      if (res.data.code === 0) {
        setSubmitSuccess(true)
        form.resetFields()
        setFormData({...initialValues})
      } else {
        setSubmitSuccess(false)
        message.error(res.data.message)
      }
    })
  }
  const onFinish = (values) => {
    if (!isLegalHKPhone(formData.phone)) {
      message.error('请填寫正确的手機號碼')
    } else if (formData.email.length > 0 && !isLegalEmail(formData.email)) {
      message.error('请填寫正确的電郵地址')
    } else {
      submit()
    }
  }
  useEffect(() => {
    getInsuranceCategoryList();
    getContactTimeList();

  }, [])
  message.config({
    maxCount: 1
  });
  const [form] = Form.useForm()
  return <div className={style['program-made']}>
    <img className={style['bg-img']} src={assetsUrl + 'images/banner_claims_customer_service.png'}  alt='理賠及客服'/>
    <div className={style['bg-mask']} style={{display: location.pathname === '/' ? 'block' : 'none'}}></div>
    <div className={style['made-box']}  onClick={e => {
      if (location.pathname === '/') {
        history.push('/claims-customer-service')
      }
    }}>
      <div className={style['form-container']}  onClick={e => e.stopPropagation()} style={{height: submitSuccess ? '672px': ''}}>
        <div style={{display: submitSuccess ? 'flex' : 'none'}} className={style['success-content']}>
          <img src={assetsUrl + 'images/icon_pay_result_success.png'} alt="" />
          <p className={style['title']}>謝謝。我們已經收到您的{tabInfo[tabIndex].title}的請求。</p>
          <p className={style['desc']}>我們的顧問會儘快與您聯絡，為您服務。</p>
          <Button onClick={() => {
            if (location.pathname === '/claims-customer-service') {
              history.replace('/')
            } else {
              setSubmitSuccess(false)
            }
          }}>{location.pathname === '/claims-customer-service' ? '回到首頁' : '确定'}</Button>
        </div>

        <div className={style['form-content']} style={{display: !submitSuccess ? 'block' : 'none'}}>
            <div className={style['tabs-box']}>
              {
                tabInfo.map((el, index) => {
                  return <div key={index} className={[`${style['tabs-item']}`, tabIndex === index ? `${style['tabs-item-active']}` : ''].join(' ')} onClick={e => onTabChange(index)}>
                    <span style={setFontSize(26, scale)}>{el.title}</span>
                  </div>
                })
              }
            </div>
            <Form
              form={form}
              initialValues={initialValues}
              onFieldsChange={() => setInputCompleted(false)}
              onFinish={onFinish}>
              <Form.Item style={{ marginTop: '24px', marginBottom: 0}}>
                <Input.Group compact>
                  <Form.Item
                    name='surname'
                    rules={[{ required: true, message: '请填寫您的姓' }]}
                    style={{width: '17%', marginRight: '1.5%'}}>
                      <Input size={'large'} maxLength={25} placeholder="姓(必填)" onChange={ e => {
                        setFormData(state => {
                          return {
                            ...state,
                            surname: e.target.value
                          }
                        })
                    }}/>
                  </Form.Item>
                  <Form.Item
                    name='name'
                    rules={[{ required: true, message: '请填寫您的名' }]}
                    style={{width: '31%', marginRight: '1.5%'}}>
                      <Input size={'large'} maxLength={25} placeholder="名(必填)" onChange={ e => {
                        setFormData(state => {
                          return {
                            ...state,
                            name: e.target.value
                          }
                        })
                    }}/>
                  </Form.Item>
                  <Form.Item
                    name='gender'
                    style={{width: '49%'}}
                    rules={[{ required: true, message: '请選擇您的性別' }]}>
                      <Select size={'large'} placeholder="性別(必填)" suffixIcon={<img src={selectArrow} alt='' style={{height: '8px'}}/>} onChange={value => {
                        setFormData(state => {
                          return {
                            ...state,
                            gender: value
                          }
                        })
                      }}>
                      <Select.Option value="先生">先生</Select.Option>
                      <Select.Option value="女士">女士</Select.Option>
                    </Select>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item style={{ marginBottom: '0' }}>
                <Input.Group compact>
                  <Form.Item
                    style={{width: '17%', marginRight: '1.5%'}}>
                    <div className={style['mobile-code']}>
                      <span>+852</span>
                    </div>
                  </Form.Item>
                  <Form.Item
                    style={{width: '31%', marginRight: '1.5%'}}
                    name="phone"
                    rules={[
                      {validator: (rule, value) =>{
                        if(value.length === 0) {
                          return Promise.reject('请填寫您的手機號碼');
                        } else if(inputCompleted && value.length < 8) {
                          return Promise.reject('请填寫有效的的手機號碼');
                        } else {
                          return Promise.resolve();
                        }
                      }}
                    ]}>
                    <Input size={'large'} maxLength={8} placeholder="手機號碼(必填)" onChange={ e => {
                      const value = e.target.value.replace(/\D/g, '')
                      form.setFieldsValue({phone: value})
                      setFormData(state => {
                        return {
                          ...state,
                          phone: value
                        }
                      })
                    }}/>
                  </Form.Item>
                  <Form.Item
                    name='email'
                    rules={[
                      { validator: (rule, value) => {
                        if (inputCompleted && formData.email.length > 0 && !isLegalEmail(formData.email)) {
                          return Promise.reject('请填寫有效的電郵地址');
                        } else {
                          return Promise.resolve();
                        }
                      }}
                    ]}
                    style={{width: '49%', marginBottom: '0'}}>
                      <Input size={'large'} maxLength={250} placeholder="電郵地址" onChange={ e => {
                        setFormData(state => {
                          return {
                            ...state,
                            email: e.target.value
                          }
                        })
                      }}/>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item style={{ marginTop: '0px', marginBottom: '0'}}>
                <Input.Group compact>
                  <Form.Item
                    name='insuranceCategoryId'
                    style={{width: '49.5%', marginRight: '1.5%'}}>
                      <Select style={{width: '100%'}}  size={'large'} placeholder="保單類別" suffixIcon={<img src={selectArrow} alt='' style={{height: '8px'}}/>} onChange={value => {
                        setFormData(state => {
                          return {
                            ...state,
                            insuranceCategoryId: value
                          }
                        })
                      }}>
                      {
                        insuranceCategoryList.map((el, index) => {
                          return <Select.Option value={el.id} key={el.id}>{el.name}</Select.Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="insuranceCompanyName"
                    style={{ width: '49%'}}>
                    <Input size={'large'} maxLength={100} placeholder="保險公司"  onChange={ e => {
                      setFormData(state => {
                        return {
                          ...state,
                          insuranceCompanyName: e.target.value
                        }
                      })
                    }}/>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                style={{ marginTop: '0px', marginBottom: '0'}}>
                <Input.Group compact>
                  <Form.Item
                    name="policyNo"
                    style={{ width: '49.5%'}}>
                    <Input size={'large'} maxLength={100} placeholder="保單編號"  onChange={ e => {
                      setFormData(state => {
                        return {
                          ...state,
                          policyNo: e.target.value
                        }
                      })
                    }}/>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                style={{ marginTop: '0px', marginBottom: '0'}}>
                <Input.Group compact>
                  <Form.Item
                    name='contactDate'
                    style={{width: '49.5%', marginRight: '1.5%'}}>
                      <DatePicker style={{ width: '100%' }} size={'large'} placeholder="聯絡日期" format='DD/MM/YYYY' showToday={false} locale={locale}
                      disabledDate={current => {
                        return current <= moment().endOf('day') ||
                               current > moment().add(7, 'days') ||
                               current.format('E') === 6 + '' ||
                               current.format('E') === 7 + ''
                      }}
                      onChange={value => {
                        setFormData(state => {
                          return {
                            ...state,
                            contactDate: value
                          }
                        })
                      }} />
                  </Form.Item>
                  <Form.Item
                    name='contactTime'
                    style={{width: '49%'}}>
                      <Select size={'large'} placeholder="聯絡時間" suffixIcon={<img src={selectArrow} alt='' style={{height: '8px'}}/>} onChange={value => {
                        setFormData(state => {
                          return {
                            ...state,
                            contactTime: value
                          }
                        })
                      }}>
                      {
                        contactTimeList.map((el, index) => {
                          return <Select.Option key={index} value={el.key}>{el.value}</Select.Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                style={{ marginTop: '0px', marginBottom: '0', height: '101px'}}>
                <Form.Item
                  name="requirement"
                  style={{ width: tabInfo[tabIndex].reqWidth, display: tabInfo[tabIndex].reqIsShow ? 'block' : 'none'}}>
                  <Input.TextArea style={{height: '101px'}} maxLength={1000} placeholder= {tabInfo[tabIndex].reqPlaceholder} onChange={ e => {
                    setFormData(state => {
                      return {
                        ...state,
                        requirement: e.target.value
                      }
                    })
                  }}/>
                </Form.Item>
              </Form.Item>
              <Form.Item
                style={{ marginTop: '25px', marginBottom: '0', display: tabInfo[tabIndex].canUploadFile ? 'flex' : 'none'}}>
                <Spin spinning={loading} size="large" style={{position: 'static'}}>
                {
                  fileList.length === 0 ? <Upload {...upLoadprops}>
                    <div className={style['upload-box']}>
                      <div>
                        <img className={style['bg-img']} src={assetsUrl + 'images/icon_add_file.png'}  alt='上載保單文件(如有)'/>
                      </div>
                      <div>
                        <span>上載</span>
                        <span>保單文件(如有)</span>
                      </div>
                    </div>
                    </Upload> :
                    <div className={style['file-list']}>
                    {
                      fileList.map((el, idx) => {
                        return <div key={el.path} className={style['file-item']} onClick={() => {
                          const files = fileList
                          files.splice(idx, 1)
                          setFileList([...files])
                        }}>
                          <img className={style['file-icon']} src={assetsUrl + 'images/icon_file_blue.png'} alt="" />
                          <span className={style['upload-file-name']}>{el.filename}</span>
                          <img className={style['file-close']} src={assetsUrl + 'images/icon_customer_service_delete_file.png'} alt="" />
                        </div>
                      })
                    }
                    <div className={style['file-item']} style={{display: fileList.length < maxUploadCount ? 'flex' : 'none'}}>
                      <Upload {...upLoadprops}>
                        <img className={style['file-add']} src={assetsUrl + 'images/button_add_blue.png'} alt=''/>
                      </Upload>
                    </div>
                  </div>
                }
                </Spin>
              </Form.Item>
              <div className={style['protocol-box']}>
                本人提交此表格表示本人已細閱並同意慧擇香港的<a href="/privacy-policy" target="_blank">私隱政策</a>、<a href="/terms-of-use" target="_blank">使用條款</a>及<a href="/personal-information" target="_blank">個人資料收集聲明</a>。我們的專家會盡快聯絡閣下。
              </div>
              <Form.Item>
                <div className={style['submit-btn']} >
                  <Button type="primary" style={canSubmit() ? {opacity: '1.0', pointerEvents: 'auto'} : {opacity: '0.5', pointerEvents: 'none'}} onClick={() => {
                setInputCompleted(true)
                form.submit()
              }}>提交</Button>
                </div>
              </Form.Item>
            </Form>
          </div>
      </div>
    </div>
  </div>
}