import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Footer} from '../../components'
import { assetsUrl } from '../../utils/constants'
import './PaymentResult.scss'

export function PaymentResult () {
  const history = useHistory()
  const search = window.location.search.substring(1)
  const params = {}
  search.split('&').forEach((item) => {
    let itemArr = item.split('=')
    params[itemArr[0]] = itemArr[1]
  })
  
  useEffect(() => {
    if (params.type === 'success') {
      window.localStorage.removeItem('k_zurich_repay_data')
    }
  }, [])

  const tobuyAnotherPolicy = () => {
    history.replace('/compare-product/' + params.categoryId)
  }
  const reBuy = () => {
    // window.history.go(-1)
    const jsonStr = window.btoa(JSON.stringify({ from: 'fail'}))
    history.replace(`/payment/${jsonStr}`)
  }
  
  return <div className='payment-result'>
    <div className='top-header'>
      <div className='header-item'>
        <div className='receive-status'>
          <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/>
        </div>
      </div>
      <div className='progress-line'></div>
      <div className='header-item'>
        <div className='receive-status'>
          <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/>
        </div>
      </div>
      <div className='progress-line'></div>
      <div className='header-item header-item-preview'>
        <div className='receive-status'>
          <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/>
        </div>
      </div>
      <div className='progress-line'></div>
      <div className='header-item'>
        <div className='receive-status'>
          {/* <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/> */}
          <span>4</span>
        </div>
      </div>
    </div>
    <div className='payment-result-body'>
      {params.type === 'success' ?
      <div className='payment-result-body-content'>
        <div className='payment-result-icon'>
          <img src={assetsUrl + 'images/icon_pay_result_success.png'} alt="" />
        </div>
        <div className='payment-result-thanks'>多謝閣下向我們投保，祝您旅途愉快！</div>
        <div className='payment-result-number'>您的投保編號：{params.number}</div>
        <div className='payment-result-phone'>客戶服務熱線：(+852) 2789 9923</div>
        <div className='payment-result-tips'>
          <div className=''>閣下的【易起行】旅遊保險計劃之確認短訊將發送，而保單亦將以電郵發送至閣下。</div>
          {/* <div className=''>● 請列印及保存保單證明書及保單之條款及細則。</div> */}
          {/* <div className=''>● 祝您旅途愉快！</div> */}
        </div>
        <div className='tobuy-another-policy' onClick={tobuyAnotherPolicy}>購買另一張保單</div>
      </div>: 
      <div className='payment-result-body-content'>
        <div className='payment-result-icon'>
          <img src={assetsUrl + 'images/icon_pay_result_Fail.png'} alt="" />
        </div>
        <div className='pay-fail'>抱歉，網上付款失敗[{params.code}]</div>
        <div className='tobuy-another-policy' onClick={reBuy}>請重試</div>
      </div>
      }
    </div>

    <Footer />
  </div>
}