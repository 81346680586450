import React from "react"
import style from './ContactUsOverlap.module.scss'
import { assetsUrl } from '../../utils/constants'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setHeaderSelectIdx } from '../../redux/globalData/slice'
import { navTItles } from '../header/mockup'
export function ContactUsOverlap () {
  const contactUsKey = '聯絡我們';
  const hisistory = useHistory();
  const dispatch = useDispatch();
  const contactUsElement = navTItles.filter(item => item.title === contactUsKey)[0];
  return (
    <div className={style['overlap-contanier']}>
      <div className={style['overlap-content']} role="button" onClick={() => {
      hisistory.push(contactUsElement.path);
      dispatch(setHeaderSelectIdx(contactUsElement.id));
    }}>
        <img src={assetsUrl + 'images/icon_contact us.png'} alt='' />
      </div>
    </div>
  )
}