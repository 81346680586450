import React, { useState, useEffect} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment';
import { Form, Input, DatePicker, Select, Button, message, Spin, Radio } from 'antd'
import { InputVerifyCode ,Footer } from '../../components'
import { useDebounce } from '../../utils/hook'
import { assetsUrl, K_ZURICH_SINGLE_TRAVEL_PLAN } from '../../utils/constants'
import styles from'./SingleTravelPlan.module.scss'
import scrollIntoView from 'scroll-into-view-if-needed'
import { isLegalEmail, isLegalHKID, generateRangeNumber, setStore, getStore, removeItem } from '../../utils'
import { getTravelDestination } from '../../services/api'
import locale from 'antd/es/date-picker/locale/zh_CN'
export function SingleTravelPlan () { 
  const history = useHistory()
  const [form] = Form.useForm()
  const { plan } = useParams()
  const param = JSON.parse(decodeURIComponent(window.atob(plan)))
  const { categoryId, productId, productCode, cmntDate, planType, expiryDate, insuredPlan, optionPlan1, optionPlan2, travelerCount} = param
  const initTravelerValues = () => {
    return {
      type: '',
      types: ['成人' ,'隨行兒童 / 兒童'],
      birthDate: '',
      age: '',
      surname: '',
      name: '',
      idNo: '',
      idLastNo: ''
    }
  }
  const initApplicantValues = () => {

  }
  const initFormData = () => {
    let initialValues = {
      idNo: '',
      idLastNo: '',
      gender: '先生',
      surname: '',
      name: '',
      companyName: '',
      businessRegistNo: '',
      birthDate: '',
      mobile: '',
      email: '',
      isPassenger: '',
      destination1: '',
      destId1: '',
      destination2: '',
      destId2: '',
      destination3: '',
      destId3: '',
      verifyCode: '',
      protocol: [
        {
          desc: '本人已閱讀並同意所有有關此旅遊保險之 聲明、保單之條款及細則 及慧擇香港之 收集個人資料聲明 及使用條款，並同意接受其約束。',
          select: null
        },
        {
          desc: '本人／我們不同意 慧擇香港使用或向第三方提供本人／我們的個人資料作 慧擇香港之收集個人資料聲明 中所列之市場推廣用途。',
          select: null
        }
      ]
    }
    const data = getStore(K_ZURICH_SINGLE_TRAVEL_PLAN)
    if (data) {
      const fd = JSON.parse(data)
      const travelerData = fd.traveler
      let initTravelerValue = {}
      travelerData.forEach((el, idx) => {
        const values = {
          ['type-'+idx]: el.type, 
          ['birthDate-'+idx]: moment(el.birthDate), 
          ['name-'+idx]: el.name, 
          ['surname-'+idx]: el.surname,
          ['idNo-'+idx]: el.idNo, 
          ['idLastNo-'+idx]: el.idLastNo
        }
        initTravelerValue =  {...initTravelerValue, ...values}
      })
      initialValues = {...fd.applicant, ...initTravelerValue}
      initialValues.birthDate = moment(fd.applicant.birthDate) 
    }
    return initialValues
  } 
  const initTravelerFormData = () => {
    let initValues = []
    const data = getStore(K_ZURICH_SINGLE_TRAVEL_PLAN)
    if (data) {
      const fd = JSON.parse(data)
      const travelerData = fd.traveler
      initValues = travelerData.map((el, idx) => {
        el.birthDate = moment(el.birthDate)
        return {...el}
      })
    } else {
      initValues = generateRangeNumber(1, Number(travelerCount)).map(() => {
        return initTravelerValues()
      })
    }
    return initValues
  }
  const [formData, setFormData] = useState({...initFormData()})
  const [travelerFormData, setTravelerFormData] = useState([...initTravelerFormData()])
  // const [declaration, setDeclaration] = useState()
  const [openPhoneTips, setPhoneTips] = useState(false)
  const [openEmailTips, setEmailTips] = useState(false)
  const [openDestinationTips, setDestinationTips] = useState(false)
  const [validatePhoneFailed, setPhoneFailed] = useState(false)
  const [validateEmailFailed, setEmailFailed] = useState(false)
  const [validateDestFailed, setDestFailed] = useState(false)
  const [passed, setPassed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isVisibleDest1, setVisibleDest1] = useState(false)
  const [isVisibleDest2, setVisibleDest2] = useState(false)
  const [isVisibleDest3, setVisibleDest3] = useState(false)
  const [inputCompleted, setInputCompleted] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('beforeunload', onBeforeunload)
    // setFormData({...initFormData()})
    // setTravelerFormData([...initTravelerFormData()])
  }, [])
  const onFinish = () => {
    if (!formData.protocol[0].select) {
      const node = document.getElementById('user-declaration')
      scrollIntoView(node, { behavior: 'smooth'})
      return
    }
  
    if (formData.verifyCode.length === 0) {
      message.warning('請輸入有效的驗證碼')
      const node = document.getElementById('idno-label')
      scrollIntoView(node, { behavior: 'smooth'})
      return
    }

    if (formData.isPassenger === '否' && (formData.idNo+formData.idLastNo).length >= 8) {
      const pass = travelerFormData.every(el => (el.idNo+el.idLastNo) !== (formData.idNo+formData.idLastNo))
      if (!pass) {
        message.warning('請輸入有效及不可重覆的香港身份證號碼')
        const node = document.getElementById('idno-label')
        scrollIntoView(node, { behavior: 'smooth'})
        return
      }
    }

    const ids = travelerFormData.map(el => el.idNo+el.idLastNo)
    console.log(ids)
    const idsSet = new Set(ids)
    if (idsSet.size !== ids.length) {
      message.warning('請輸入有效及不可重覆的香港身份證號碼')
      const node = document.getElementById('idno-label')
      scrollIntoView(node, { behavior: 'smooth'})
      return
    }
  
    setStore(K_ZURICH_SINGLE_TRAVEL_PLAN, JSON.stringify({
      applicant: formData,
      traveler: travelerFormData
    }))
  
    const param = JSON.stringify({
      planType,
      categoryId,
      productId,
      cmntDate, 
      expiryDate, 
      insuredPlan, 
      optionPlan1, 
      optionPlan2
    })
    const jsonStr = window.btoa(param) 
    history.push(`/payment/${jsonStr}`)
  }
  const deleteTraveler = (idx) => {
    const travelers = travelerFormData
    form.setFieldsValue({
      ['type-'+idx]: idx + 1 < travelers.length ? travelers[idx + 1].type : undefined, 
      ['birthDate-'+idx]: idx + 1 < travelers.length ? travelers[idx + 1].birthDate : '', 
      ['name-'+idx]: idx + 1 < travelers.length ? travelers[idx + 1].name :  '', 
      ['surname-'+idx]: idx + 1 < travelers.length ? travelers[idx + 1].surname :  '',
      ['idNo-'+idx]: idx + 1 < travelers.length ? travelers[idx + 1].idNo :  '', 
      ['idLastNo-'+idx]: idx + 1 < travelers.length ? travelers[idx + 1].idLastNo :  ''
    })
    travelers.splice(idx, 1)
    setTravelerFormData([...travelers])
  }
  const addTraveler = (idx) => {
    form.setFieldsValue({
      ['type-'+idx]: undefined, 
      ['birthDate-'+idx]: '', 
      ['name-'+idx]: '', 
      ['surname-'+idx]: '',
      ['idNo-'+idx]: '', 
      ['idLastNo-'+idx]: ''
    })
    const travelers = travelerFormData
    travelers.push(initTravelerValues())
    setTravelerFormData([...travelers])
  }
  const convertApplicantToTraveler = (converted) => {
    const travelers = travelerFormData
    let traveler = travelers[0]
    if (converted) {
      traveler.types.unshift('成人(本人)')
      traveler.type = '成人(本人)'
      traveler.birthDate = formData.birthDate
      traveler.name = formData.name
      traveler.surname = formData.surname
      traveler.idNo = formData.idNo
      traveler.idLastNo = formData.idLastNo
    } else {
      traveler.types = ['成人' ,'隨行兒童 / 兒童']
      traveler.type = undefined
      traveler.birthDate = ''
      traveler.name = ''
      traveler.surname = ''
      traveler.idNo = ''
      traveler.idLastNo = ''
    }
    setTravelerFormData([...travelers])
    form.setFieldsValue({
      'type-0': traveler.type, 
      'birthDate-0': traveler.birthDate, 
      'name-0': traveler.name, 
      'surname-0': traveler.surname,
      'idNo-0': traveler.idNo, 
      'idLastNo-0': traveler.idLastNo
    })
  }
  const dismissDestPop = () => {
    setVisibleDest1(false)
    setVisibleDest2(false)
    setVisibleDest3(false)
  }
  const onBeforeunload = () => {
    // removeItem(K_ZURICH_SINGLE_TRAVEL_PLAN)
  }
  return (
    <div className={styles['insured-container']} onClick={e => {
      dismissDestPop()
    }}>
      <div className={styles['top-header']}>
        <div className={styles['header-item']}>
          <div className={styles['receive-status']}>
            <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/>
          </div>
        </div>
        <div className={styles['progress-line']}></div>
        <div className={styles['header-item']}>
          <div className={styles['receive-status']}>
            <span>2</span>
          </div>
        </div>
        <div className={styles['progress-line']} style={{background: passed ? '#1E4994' : '#9D9D9D'}}></div>
        <div className={[`${styles['header-item']}`, `${styles['header-item-preview']}`].join(' ')}>
          <div className={styles['receive-status']} style={{background: passed ? '#1E4994' : '#9D9D9D'}}>
            {/* <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/> */}
            <span>3</span>
          </div>
        </div>
        <div className={styles['progress-line']} style={{background: passed ? '#1E4994' : '#9D9D9D'}}></div>
        <div className={styles['header-item']}>
          <div className={styles['receive-status']} style={{background: passed ? '#1E4994' : '#9D9D9D'}}>
            {/* <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/> */}
            <span>4</span>
          </div>
        </div>
      </div>
      <div className={styles['loading-box']} style={{display: loading ? 'flex' : 'none'}}>
        <Spin />
      </div>
      <div className={styles['form-container']}>
        <p className={styles['form-demand']}>所有欄目均必須填寫及請以英文輸入。</p>
        <div className={styles['title-box']}>
          <span>申請人</span>
          <div></div>
        </div>
        <Form
        form={form}
        initialValues={initFormData()}
        onFinishFailed={({ errorFields }) => {
          if (errorFields.length > 0) {
            const isSuccess = errorFields.every(field => !(field.name.length > 0 && (field.name[0] === 'mobile' || field.name[0] === 'email')))
            setPhoneFailed(!isSuccess)
            setEmailFailed(!isSuccess)
            const pass = errorFields.every(field => !(field.name.length > 0 && (field.name[0] === 'destination1')))
            setDestFailed(!pass)
          }
          const node = document.getElementById('idno-label')
          scrollIntoView(node, { behavior: 'smooth'})
        }}
        onFieldsChange={() => {
          setInputCompleted(false)
        }}
        onFinish={onFinish}>
          <div className={styles['label-box']} style={{display: planType === 'I' ? 'flex' : 'none'}}>
            <div className={styles['label-desc']}>
              <span>稱謂</span>
            </div>
            <div className={styles['label-desc']}>
              <span>出生日期</span>
            </div>
          </div>
          <Form.Item style={{marginBottom: '0', display: planType === 'I' ? 'block' : 'none'}}>
            <Input.Group compact>
              <Form.Item 
              name='gender'
              rules={[{ required: true, message: '請選擇稱謂' }]}
              style={{width: '47%', marginRight: '6%'}}>
                <Select disabled={passed} placeholder='請選擇稱謂' suffixIcon={<img src={assetsUrl + 'images/icon_receive_form_dropdown.png'} alt='' style={{width: '40px', height: '40px'}}/>}>
                  <Select.Option value="先生">先生</Select.Option>
                  <Select.Option value="女士">女士</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item 
                name='birthDate'
                rules={[
                  { validator: (value) => {
                    if (planType === 'C') {
                      return Promise.resolve()
                    }
                    if (!formData.birthDate) {
                      return Promise.reject('請輸入出生日期')
                    } else if (moment(formData.birthDate.format('YYYY-MM-DD')) > moment(cmntDate).subtract(18, "years"))  {
                      return Promise.reject('申請人的年齡必須為18歲或以上')
                    } else {
                      return Promise.resolve()
                    }
                  }}
                ]}
                style={{width: '47%'}}>
                  <DatePicker placeholder='出生日期' format='DD/MM/YYYY' showToday={false} locale={locale} disabledDate={current => current && current > moment().endOf('day')} onChange={(date) => {
                    if (formData.isPassenger === '是') {
                      const travelers = travelerFormData
                      travelers[0].birthDate = date
                      setTravelerFormData([...travelers])
                      form.validateFields(['type-0'])
                      form.validateFields(['birthDate-0'])
                      form.setFieldsValue({'birthDate-0': date})
                    }
                    setFormData(state => {
                      return{
                        ...state,
                        birthDate: date
                      }
                    })
                  }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div className={styles['label-box']} style={{display: planType === 'C' ? 'flex' : 'none'}}>
            <div className={styles['label-desc']}>
              <span>公司/機構名稱</span>
            </div>
            <div className={styles['label-desc']}>
              <span className={styles['label-normal']}>商業登記號碼</span>
            </div>
          </div>
          <Form.Item style={{marginBottom: '0', display: planType === 'C' ? 'block' : 'none'}}>
            <Input.Group compact>
              <Form.Item 
              name='companyName'
              rules={[{ required: planType === 'C', message: '請輸入公司/機構名稱' }]}
              style={{width: '47%', marginRight: '6%'}}>
                <Input onChange={e => {
                  setFormData(state => {
                    return{
                      ...state,
                      companyName: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
              <Form.Item 
                name='businessRegistNo'
                style={{width: '47%'}}>
                <Input onChange={e => {
                  setFormData(state => {
                    return{
                      ...state,
                      businessRegistNo: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div className={styles['label-box']} style={{marginTop: '8px', display: planType === 'I' ? 'flex' : 'none'}}>
            <div className={styles['label-desc']}>
              <span>英文全名</span>
            </div>
            <div className={styles['label-desc']}>
              <span>香港身份證號碼</span>
              <p className={styles['symbol-label']} style={{right: '126px'}}>(</p>
              <p className={styles['symbol-label']}>)</p>
            </div>
          </div>
          <Form.Item style={{marginBottom: '0', display: planType === 'I' ? 'block' : 'none'}}>
            <Input.Group compact>
              <Form.Item 
              name='name'
              rules={[
                { validator: (value) => {
                  if (planType === 'C') {
                    return Promise.resolve()
                  }
                  if(formData.name.length === 0) {
                    return Promise.reject('請輸入與身份證相符的英文全名')
                  } else if (inputCompleted) {
                    return formData.name.length === 0 || formData.surname.length === 0  ? Promise.reject('請輸入與身份證相符的英文全名') : Promise.resolve()
                  } else {
                    return Promise.resolve()
                  }
                }}
              ]}
              style={{width: '26%', marginRight: '0.7%'}}>
                <Input disabled={passed} placeholder='名：與身份證相符' onChange={e => {
                  let value = e.target.value.replace(/[0-9]/ig,"");
                  value =  value.replace(/[^\w\.\s\/]/ig,'')
                  form.setFieldsValue({name: value})
                  setFormData(state => {
                    return{
                      ...state,
                      name: value
                    }
                  })
                  if (formData.isPassenger === '是') {
                    const travelers = travelerFormData
                    travelers[0].name = value
                    setTravelerFormData([...travelers])
                    form.validateFields(['name-0'])
                    form.setFieldsValue({'name-0': value})
                  }
                }} onPaste={e => {
                  let value = e.target.value.replace(/[0-9]/ig,"");
                  value =  value.replace(/[^\w\.\s\/]/ig,'')
                  form.setFieldsValue({name: value})
                  setFormData(state => {
                    return{
                      ...state,
                      name: value
                    }
                  })
                  if (formData.isPassenger === '是') {
                    const travelers = travelerFormData
                    travelers[0].name = value
                    setTravelerFormData([...travelers])
                    form.validateFields(['name-0'])
                    form.setFieldsValue({'name-0': value})
                  }
                }}/>
              </Form.Item>
              <Form.Item 
              name='surname'
              style={{width: '20.3%', marginRight: '6%'}}>
                <Input disabled={passed} placeholder='姓：與身份證相符' onChange={e => {
                  let value = e.target.value.replace(/[0-9]/ig,"");
                  value =  value.replace(/[^\w\.\s\/]/ig,'')
                  form.setFieldsValue({surname: value})
                  setFormData(state => {
                    return{
                      ...state,
                      surname: value
                    }
                  })
                  form.validateFields(['name'])
                  if (formData.isPassenger === '是') {
                    const travelers = travelerFormData
                    travelers[0].surname = value
                    setTravelerFormData([...travelers])
                    form.validateFields(['surname-0'])
                    form.setFieldsValue({'surname-0': value})
                  }
                }} onPaste={e => {
                  let value = e.target.value.replace(/[0-9]/ig,"");
                  value =  value.replace(/[^\w\.\s\/]/ig,'')
                  form.setFieldsValue({surname: value})
                  setFormData(state => {
                    return{
                      ...state,
                      surname: value
                    }
                  })
                  form.validateFields(['name'])
                  if (formData.isPassenger === '是') {
                    const travelers = travelerFormData
                    travelers[0].surname = value
                    setTravelerFormData([...travelers])
                    form.validateFields(['surname-0'])
                    form.setFieldsValue({'surname-0': value})
                  }
                }}/>
               </Form.Item>
               <Form.Item 
                name='idNo'
                rules={[
                  { validator: (value) => {
                    if (planType === 'C') {
                      return Promise.resolve()
                    }
                    if (formData.idLastNo.length === 0 && formData.idNo.length === 0) {
                      return Promise.reject('請輸入香港身份證號碼')
                    } else if ((formData.idNo.length >= 7 && formData.idLastNo.length > 0) || inputCompleted){
                      // if (formData.isPassenger === '否' && (formData.idNo+formData.idLastNo).length >= 8) {
                      //   const pass = travelerFormData.every(el => (el.idNo+el.idLastNo) !== (formData.idNo+formData.idLastNo))
                      //   if (!pass) {
                      //     return Promise.reject('請輸入有效及不可重覆的香港身份證號碼')
                      //   }
                      // }
                      return !isLegalHKID(formData.idNo + '(' + formData.idLastNo + ')') ? Promise.reject('不是有效的證件號碼，請核對後重新確認') : Promise.resolve()
                    } else {
                      return Promise.resolve()
                    }
                }}
              ]}
              style={{width: '32%', marginRight: '3.6%'}}>
                <Input disabled={passed} maxLength={8} placeholder="香港身份證號碼" onChange={e => {
                  let value = e.target.value.replace(/[^\w]/g,"");
                  if (value.length > 8) {
                    value = value.slice(0, 8)
                  }
                  form.setFieldsValue({idNo: value})
                  setFormData(state => {
                    return {
                      ...state,
                      idNo: value
                    }
                  })
                  if (formData.isPassenger === '是') {
                    const travelers = travelerFormData
                    travelers[0].idNo = value
                    setTravelerFormData([...travelers])
                    form.validateFields(['idNo-0'])
                    form.setFieldsValue({'idNo-0': value})
                  }
                }} onPaste={e => {
                  let value = e.target.value.replace(/[^\w]/g,"");
                  if (value.length > 8) {
                    value = value.slice(0, 8)
                  }
                  form.setFieldsValue({idNo: value})
                  setFormData(state => {
                    return {
                      ...state,
                      idNo: value
                    }
                  })
                  if (formData.isPassenger === '是') {
                    const travelers = travelerFormData
                    travelers[0].idNo = value
                    setTravelerFormData([...travelers])
                    form.validateFields(['idNo-0'])
                    form.setFieldsValue({'idNo-0': value})
                  }
                }}/>
              </Form.Item>
              <Form.Item 
              name='idLastNo'
              style={{width: '8.8%'}}>
                <Input disabled={passed} maxLength={1} onChange={e => {
                  let value = e.target.value.replace(/[^\w]/g,"");
                  if (value.length > 1) {
                    value = value.slice(0, 1)
                  }
                  form.setFieldsValue({idLastNo: value})
                  setFormData(state => {
                    return {
                      ...state,
                      idLastNo: value
                    }
                  })
                  form.validateFields(['idNo'])
                  if (formData.isPassenger === '是') {
                    const travelers = travelerFormData
                    travelers[0].idLastNo = value
                    setTravelerFormData([...travelers])
                    form.validateFields(['idLastNo-0'])
                    form.setFieldsValue({'idLastNo-0': value})
                  }
                }} onPaste={e => {
                  let value = e.target.value.replace(/[^\w]/g,"");
                  if (value.length > 1) {
                    value = value.slice(0, 1)
                  }
                  form.setFieldsValue({idLastNo: value})
                  setFormData(state => {
                    return {
                      ...state,
                      idLastNo: value
                    }
                  })
                  form.validateFields(['idNo'])
                  if (formData.isPassenger === '是') {
                    const travelers = travelerFormData
                    travelers[0].idLastNo = value
                    setTravelerFormData([...travelers])
                    form.validateFields(['idLastNo-0'])
                    form.setFieldsValue({'idLastNo-0': value})
                  }
                }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div id='idno-label' className={styles['label-box']} style={{marginTop: '8px'}}>
            <div className={styles['label-desc']}>
              <span>手提號碼</span>
              <img src={assetsUrl + 'images/icon_receive_form_tips.png'} alt='' onClick={() => setPhoneTips(!openPhoneTips)}/>
              <div className={[`${styles['input-tips']}`, openPhoneTips ? `${styles['input-tips-active']}` : ''].join(' ')} style={{top: formData.mobile.length === 0 && validatePhoneFailed ? '131px' : '105px'}}>
                <span>用以發送保單確認短訊</span>
              </div>
            </div>
            <div className={styles['label-desc']}>
              <span>電郵地址</span>
              <img src={assetsUrl + 'images/icon_receive_form_tips.png'} alt='' onClick={() => setEmailTips(!openEmailTips)}/>
              <div className={[`${styles['input-tips']}`, openEmailTips ? `${styles['input-tips-active']}` : ''].join(' ')} style={{width: '272px', top: formData.email.length === 0 && validateEmailFailed ? '131px' : '105px'}}>
                <span>保單將透過電郵發送至閣下</span>
              </div>
            </div>
          </div>
          <Form.Item style={{marginBottom: '0'}}>
            <Input.Group compact>
              <InputVerifyCode form={form} initMobile={formData.mobile} initVerifyCode={formData.verifyCode} inputCompleted={inputCompleted} validatPhoneFailed={() => {
                setPhoneFailed(true)
              }} onChange={evt => {
                setFormData(state => {
                  return {
                    ...state,
                    mobile: evt.mobile,
                    verifyCode: evt.verifyCode,
                  }
                })
              }}/>
              <Form.Item 
              name='email'
              rules={[
                { validator: () => {
                  if (formData.email.length === 0) {
                    return Promise.reject('请輸入有效的電郵地址')
                  } else if (inputCompleted) {
                    return !isLegalEmail(formData.email) ?  Promise.reject('請輸入有效的電郵地址') : Promise.resolve()
                  }
                  return Promise.resolve()
                }}
              ]}
              style={{width: '47%'}}>
                <Input disabled={passed} maxLength={254} placeholder='e.g.xxx@abc.com' onChange={e => {
                  if (e.target.value.length === 0) {
                    setEmailFailed(true)
                  }
                  setFormData(state => {
                    return {
                      ...state,
                      email: e.target.value
                    }
                  })
                }} onPaste={e => {
                  if (e.target.value.length === 0) {
                    setEmailFailed(true)
                  }
                  setFormData(state => {
                    return {
                      ...state,
                      email: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div className={styles['label-box']} style={{marginTop: openPhoneTips || openEmailTips ? '64px' : '8px', marginBottom: '8px', display: planType === 'I' ? 'flex' : 'none'}}>
            <div className={styles['label-desc']}>
              <span>申請人是否其中一位旅客</span>
            </div>
          </div>
          <Form.Item name='isPassenger' rules={[{required: planType === 'I', message: '請選擇'}]} style={{display: planType === 'I' ? 'block' : 'none'}}>
            <Radio.Group onChange={e => {
              convertApplicantToTraveler('是' === e.target.value)
              setFormData(state => {
                return {
                  ...state,
                  isPassenger: e.target.value
                }
              })
            }}>
              <Radio value='是'>是</Radio>
              <Radio value='否'>否</Radio>
            </Radio.Group>
          </Form.Item>
          <div className={styles['label-box']} style={{marginTop: planType === 'C' ? ( openPhoneTips || openEmailTips ? '64px' : '8px') : '8px'}}>
            <div className={styles['label-desc']}>
              <span>旅遊目的地 1</span>
              <img src={assetsUrl + 'images/icon_receive_form_tips.png'} alt='' onClick={() => setDestinationTips(!openDestinationTips)}/>
              <div className={[`${styles['input-tips']}`, openDestinationTips ? `${styles['input-tips-active']}` : ''].join(' ')} style={{top: formData.destination1.length === 0 && validateDestFailed ? '131px' : '105px', width: '457px'}}>
                <span>如旅遊目的地不在列表內,請聯絡我們的客戶服務</span>
              </div>
              <Destination isVisible={isVisibleDest1} keyword={formData.destination1} onSelected={el => {
                setVisibleDest1(false)
                setDestFailed(false)
                setFormData(state => {
                  return{
                    ...state,
                    destId1: el.placeId,
                    destination1: el.place
                  }
                })
                form.setFieldsValue({destination1: el.place})
                form.validateFields(['destination1', 'destination2','destination3'])
              }}/>
            </div>
            <div className={styles['label-desc']}>
              <span className={styles['label-normal']}>旅遊目的地 2</span>
              <Destination isVisible={isVisibleDest2} keyword={formData.destination2} onSelected={el => {
                setVisibleDest2(false)
                setFormData(state => {
                  return{
                    ...state,
                    destId2: el.placeId,
                    destination2: el.place
                  }
                })
                form.setFieldsValue({destination2: el.place})
                form.validateFields(['destination1', 'destination2','destination3'])
              }}/>
            </div>
          </div>
          <Form.Item style={{marginBottom: '0'}}>
            <Input.Group compact >
              <Form.Item 
              name='destination1' 
              style={{width: '47%', marginRight: '6%'}} 
              rules={[
                // {required: true, message: '請輸入有效的旅遊目的地'},
                {validator: () => {
                  if (inputCompleted && (formData.destination1.length === 0 || formData.destId1.length === 0)) {
                    return Promise.reject('請輸入有效的旅遊目的地')
                  } else if (formData.destId1.length > 0 && (formData.destId1 === formData.destId2 || formData.destId1 === formData.destId3)) {
                    return Promise.reject('旅遊目的地不能重复')
                  } else {
                    return Promise.resolve()
                  }
                }}
              ]}>
                <Input placeholder='旅遊目的地' autoComplete='off' prefix={<div className={styles['icon-input-search']}></div>} onChange={e => {
                  setVisibleDest1(true)
                  let value = e.target.value
                  setDestFailed(value.length === 0)
                  setFormData(state => {
                    return{
                      ...state,
                      destId1: '',
                      destination1: value
                    }
                  })
                  form.validateFields(['destination1', 'destination2','destination3'])
                }}/>
              </Form.Item>
              <Form.Item 
              name='destination2' 
              style={{width: '47%'}}
              rules={[
                {validator: () => {
                  if (formData.destId2.length > 0 && (formData.destId2 === formData.destId1 || formData.destId2 === formData.destId3)) {
                    return Promise.reject('旅遊目的地不能重复')
                  } else if (inputCompleted && formData.destination2.length > 0 && formData.destId2.length === 0) {
                    return Promise.reject('請輸入有效的旅遊目的地')
                  } else {
                    return Promise.resolve()
                  }
                }}
              ]}>
                <Input placeholder='旅遊目的地' autoComplete='off' prefix={<div className={styles['icon-input-search']}></div>} onChange={e => {
                  setVisibleDest2(true)
                  setFormData(state => {
                    return{
                      ...state,
                      destId2: '',
                      destination2: e.target.value
                    }
                  })
                  form.validateFields(['destination1', 'destination2','destination3'])
                }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div id='label-destination' className={styles['label-box']} style={{marginTop: openDestinationTips ? '64px' : '8px'}}>
            <div className={styles['label-desc']}>
              <span className={styles['label-normal']}>旅遊目的地 3</span>
              <Destination isVisible={isVisibleDest3} keyword={formData.destination3} onSelected={el => {
                setVisibleDest3(false)
                setFormData(state => {
                  return{
                    ...state,
                    destId3: el.placeId,
                    destination3: el.place
                  }
                })
                form.setFieldsValue({destination3: el.place})
                form.validateFields(['destination1', 'destination2','destination3'])
              }}/>
            </div>
          </div>
          <Form.Item 
          name='destination3' 
          style={{width: '47%'}}
          rules={[
            // {required: true, message: '請輸入有效的旅遊目的地'},
            {validator: () => {
              if (formData.destId3.length > 0 && (formData.destId3 === formData.destId1 || formData.destId2 === formData.destId3)) {
                return Promise.reject('旅遊目的地不能重复')
              } else if (inputCompleted && formData.destination3.length > 0 && formData.destId3.length === 0) {
                return Promise.reject('請輸入有效的旅遊目的地')
              } else {
                return Promise.resolve()
              }
            }}
          ]}>
            <Input placeholder='旅遊目的地' autoComplete='off' prefix={<div className={styles['icon-input-search']}></div>} onChange={e => {
              setVisibleDest3(true)
              setFormData(state => {
                return{
                  ...state,
                  destId3: '',
                  destination3: e.target.value
                }
              })
              form.validateFields(['destination1', 'destination2','destination3'])
            }}/>
          </Form.Item>
          {/* 单个Form管理 */}
          <Form.Item className={styles['traveler-list']}>
            {
              travelerFormData.map((el, idx) => {
                return <Form.Item className={styles['traveler-item']} key={'traveler-item-'+idx}>
                  <div className={styles['title-box']}>
                    <span>旅客 {idx+1}</span>
                    <div></div>
                  </div>
                  <Form.Item>
                    <div className={styles['label-box']}>
                      <div className={styles['label-desc']}>
                        <span>旅客類別</span>
                      </div>
                      <div className={styles['label-desc']}>
                        <span>出生日期</span>
                      </div>
                    </div>
                    <Form.Item>
                      <Input.Group compact>
                        <Form.Item 
                        name={'type-' + idx}
                        rules={[
                          { validator: (value) => {
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            if (!traveler.type) {
                              return Promise.reject('請選擇旅客類別')
                            } else {
                              if (traveler.birthDate) {
                                // const date = moment(traveler.birthDate.format('YYYY-MM-DD'))
                                const date = moment(traveler.birthDate.format('YYYY-MM-DD'))
                                // console.log(moment(cmntDate).subtract(17, "years").format('YYYY-MM-DD'))
                                if (traveler.type === '成人' || traveler.type === '成人(本人)') {
                                  return date > moment(cmntDate).subtract(18, "years") ? Promise.reject('請選擇有效的旅客類別') : Promise.resolve()
                                }
                                return date <= moment(cmntDate).subtract(18, "years") ? Promise.reject('請選擇有效的旅客類別') : Promise.resolve()
                              }
                              return Promise.resolve()
                            }
                          }}
                        ]}
                        style={{width: '47%', marginRight: '6%'}}>
                          <Select disabled={formData.isPassenger === '是' && idx === 0} placeholder='請選擇旅客類別' suffixIcon={<img src={assetsUrl + 'images/icon_receive_form_dropdown.png'} alt='' style={{width: '40px', height: '40px', display: formData.isPassenger === '是' && idx === 0 ? 'none' : 'block'}}/>} onChange={value => {
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            traveler.type = value
                            setTravelerFormData([...travelers])
                            if (traveler.birthDate) {
                              form.validateFields(['birthDate-'+idx])
                            }
                          }}>
                            {
                              travelerFormData[idx].types.map(item => {
                                return <Select.Option value={item} key={item}>{item}</Select.Option>
                              })
                            }
                          </Select>
                        </Form.Item>
                        <Form.Item 
                          name={'birthDate-'+idx}
                          rules={[
                            { validator: (value) => {
                              const travelers = travelerFormData
                              const traveler = travelers[idx]
                              if (!traveler.birthDate) {
                                return Promise.reject('請輸入出生日期')
                              } else {
                                const date = moment(traveler.birthDate.format('YYYY-MM-DD'))
                                if (traveler.type &&  traveler.type.length > 0) {
                                  if (traveler.type === '成人' || traveler.type === '成人(本人)') {
                                    return date > moment(cmntDate).subtract(18, "years") ? Promise.reject('旅客的年齡必須為18歲或以上') : Promise.resolve()
                                  }
                                  return date <= moment(cmntDate).subtract(18, "years") ? Promise.reject('旅客的的年齡必須為17歲或以下') : Promise.resolve()
                                }
                                return Promise.resolve()
                                // return date > moment(cmntDate).subtract(18, "years") ? Promise.reject('申請人之年齡必須為18歲或以上') : Promise.resolve()
                              } 
                            }}
                          ]}
                          style={{width: '47%'}}>
                          <DatePicker disabled={formData.isPassenger === '是' && idx === 0} placeholder='出生日期' format='DD/MM/YYYY' showToday={false} locale={locale} disabledDate={current => current && current > moment().endOf('day')} onChange={(date) => {
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            traveler.birthDate = date
                            setTravelerFormData([...travelers])
                            if (traveler.type) {
                              form.validateFields(['type-'+idx])
                            }
                          }}/>
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                    <div className={styles['label-box']}>
                      <div className={styles['label-desc']}>
                        <span>英文全名</span>
                      </div>
                      <div className={styles['label-desc']}>
                        <span>香港身份證號碼</span>
                        <p className={styles['symbol-label']} style={{right: '116px'}}>(</p>
                        <p className={styles['symbol-label']}>)</p>
                      </div>
                    </div>
                    <Form.Item style={{marginBottom: '0'}}>
                      <Input.Group compact>
                        <Form.Item 
                        name={'name-'+idx}
                        rules={[
                          { validator: (value) => {
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            if(traveler.name.length === 0) {
                              return Promise.reject('請輸入旅客的英文全名')
                            }  else if (inputCompleted) {
                              return traveler.name.length === 0 || traveler.surname.length === 0  ? Promise.reject('請輸入旅客的英文全名') : Promise.resolve()
                            } else {
                              return Promise.resolve()
                            }
                          }}
                        ]}
                        style={{width: '26%', marginRight: '0.7%'}}>
                          <Input disabled={formData.isPassenger === '是' && idx === 0} placeholder='名：與身份證相符' onChange={e => {
                            let value = e.target.value.replace(/[0-9]/ig,"");
                            value =  value.replace(/[^\w\.\s\/]/ig,'')
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            form.setFieldsValue({['name-'+idx]: value})
                            traveler.name = value
                            setTravelerFormData([...travelers])
                          }} onPaste={e => {
                            let value = e.target.value.replace(/[0-9]/ig,"");
                            value =  value.replace(/[^\w\.\s\/]/ig,'')
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            form.setFieldsValue({['name-'+idx]: value})
                            traveler.name = value
                            setTravelerFormData([...travelers])
                          }}/>
                        </Form.Item>
                        <Form.Item 
                        name={'surname-'+idx}
                        style={{width: '20.3%', marginRight: '6%'}}>
                          <Input disabled={formData.isPassenger === '是' && idx === 0} placeholder='姓：與身份證相符' onChange={e => {
                            let value = e.target.value.replace(/[0-9]/ig,"");
                            value =  value.replace(/[^\w\.\s\/]/ig,'')
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            form.setFieldsValue({['surname-'+idx]: value})
                            traveler.surname = value
                            setTravelerFormData([...travelers])
                            form.validateFields([`name-${idx}`])
                          }} onPaste={e => {
                            let value = e.target.value.replace(/[0-9]/ig,"");
                            value =  value.replace(/[^\w\.\s\/]/ig,'')
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            form.setFieldsValue({['surname-'+idx]: value})
                            traveler.surname = value
                            setTravelerFormData([...travelers])
                            form.validateFields([`name-${idx}`])
                          }}/>
                        </Form.Item>
                        <Form.Item 
                          name={'idNo-'+idx}
                          rules={[
                            { validator: (value) => {
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            if (traveler.idLastNo.length === 0 && traveler.idNo.length === 0) {
                              return Promise.reject('請輸入旅客的香港身份證號碼')
                            }  else if ((traveler.idNo.length >= 7 && traveler.idLastNo.length > 0) || inputCompleted) {
                              // if (formData.isPassenger === '否' && (formData.idNo+formData.idLastNo).length >= 8 && (traveler.idNo+traveler.idLastNo) === (formData.idNo+formData.idLastNo)) {
                              //   return Promise.reject('請輸入有效及不可重覆的香港身份證號碼')
                              // } else {
                              //   return !isLegalHKID(formData.idNo + '(' + formData.idLastNo + ')') ? Promise.reject('不是有效的證件號碼，請核對後重新確認') : Promise.resolve()
                              // }
                              return !isLegalHKID(traveler.idNo + '(' + traveler.idLastNo + ')') ? Promise.reject('不是有效的證件號碼，請核對後重新確認') : Promise.resolve()
                            } else {
                              return Promise.resolve()
                            }
                          }}
                        ]}
                        style={{width: '32%', marginRight: '3.6%'}}>
                          <Input disabled={formData.isPassenger === '是' && idx === 0} maxLength={8} placeholder="香港身份證號碼" onChange={e => {
                            let value = e.target.value.replace(/[^\w]/g,"");
                            if (value.length > 8) {
                              value = value.slice(0, 8)
                            }
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            traveler.idNo = value
                            form.setFieldsValue({['idNo-'+idx]: value})
                            setTravelerFormData([...travelers])
                          }} onPaste={e => {
                            let value = e.target.value.replace(/[^\w]/g,"");
                            if (value.length > 8) {
                              value = value.slice(0, 8)
                            }
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            traveler.idNo = value
                            form.setFieldsValue({['idNo-'+idx]: value})
                            setTravelerFormData([...travelers])
                          }}/>
                        </Form.Item>
                        <Form.Item 
                          name={'idLastNo-'+idx}
                          style={{width: '8.8%'}}>
                          <Input disabled={formData.isPassenger === '是' && idx === 0} maxLength={1} onChange={e => {
                            let value = e.target.value.replace(/[^\w]/g,"");
                            if (value.length > 1) {
                              value = value.slice(0, 1)
                            }
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            traveler.idLastNo = value
                            form.setFieldsValue({['idLastNo-'+idx]: value})
                            setTravelerFormData([...travelers])
                            form.validateFields([`idNo-${idx}`])
                          }} onPaste={e => {
                            let value = e.target.value.replace(/[^\w]/g,"");
                            if (value.length > 1) {
                              value = value.slice(0, 1)
                            }
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            traveler.idLastNo = value
                            form.setFieldsValue({['idLastNo-'+idx]: value})
                            setTravelerFormData([...travelers])
                            form.validateFields([`idNo-${idx}`])
                          }}/>
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Form.Item>
                  <div className={styles['delete-traveler']} style={{display: (formData.isPassenger === '是' && idx === 0) || travelerFormData.length === 1  ? 'none' : 'block'}}>
                    <span onClick={() => {
                      deleteTraveler(idx)
                    }}>
                      <img src={assetsUrl + 'images/icon_form_delete_traveler..png'} alt=''/>
                    </span>
                  </div>
                </Form.Item >
              })
            }
          </Form.Item>
        </Form>
        <div className={styles['add-traveler']} onClick={() => {
          addTraveler(travelerFormData.length)
        }}>
          <img src={assetsUrl + 'images/icon_form_add_traveler.png'} alt=''/>
          <span>新增旅客</span>
        </div>
        <div id='user-declaration' className={styles['user-declaration']}>
          {
            formData.protocol.map((el, idx) => {
              return <div key={el.desc} className={styles['declaration-item']}>
                <div className={styles['top-box']} onClick={() => {
                  let tempDeclaration = formData.protocol
                  let item = tempDeclaration[idx]
                  if (item.select === null) {
                    item.select = true
                  } else {
                    item.select = !item.select
                  }
                  setFormData(state => {
                    return{
                      ...state,
                      protocol: [...tempDeclaration]
                    }
                  })
                }}>
                  <img src={assetsUrl + 'images/icon_receive_form_checkbox_' + (el.select ? 's' : 'n') + '.png'} alt=''/>
                  {
                    idx === 0 ? <div className={styles['declaration-info']}>本人已閱讀並同意所有有關此旅遊保險之 <a href={assetsUrl + 'document/聲明.pdf'}  rel="noreferrer" target="_blank" onClick={e => e.stopPropagation()}>聲明</a>、<a href={assetsUrl + 'document/保單之條款及細則(保單條款）.pdf'} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>保單之條款及細則</a>及慧擇香港之<a href='/personal-information' target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>收集個人資料聲明</a>及<a href="/terms-of-use" target="_blank" onClick={e => e.stopPropagation()}>使用條款</a>，並同意接受其約束。</div> : 
                    <div className={styles['declaration-info']}>本人／我們不同意 慧擇香港使用或向第三方提供本人／我們的個人資料作 慧擇香港之<a href='/personal-information' target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>收集個人資料聲明</a> 中所列之市場推廣用途。</div>
                  }
                </div>
                <span className={styles['err-msg']} style={{display: el.select === false && idx === 0 ? 'block' : 'none'}}>遞交申請表前，請勾選方格以茲確認</span>
            </div>
            })
          }
          <p style={{marginTop: '-10px', marginBottom: '8px'}}>繼續投保即表示您同意以下聲明：</p>
          <p>慧擇香港保險經紀有限公司(“慧擇香港”)及/或介紹人將藉收取保險人支付的報酬，作為其所提供服務的酬金。若閣下同意進行是項保險交易，將構成閣下同意慧擇香港收取有關報酬。此外，提交此請求即表示你同意慧擇香港將代表你進行受監管的活動，同時你亦接受慧擇香港的使用條款 。</p>
        </div>
      </div>
      <div className={styles['next-btn']}>
        <Button 
        style={{border: '1px solid #447EDA', color: '#447EDA', background: 'transparent', marginRight: '32px'}} 
        onClick={() => {
          history.goBack()
        }}>返回</Button>
        <Button onClick={() => {
          dismissDestPop()
          setInputCompleted(true)
          const tempDeclaration = formData.protocol
          const declItem = tempDeclaration[0]
          if (declItem.select === null) {
            declItem.select = false
            setFormData(state => {
              return{
                ...state,
                protocol: [...tempDeclaration]
              }
            })
          }
          form.submit()
        }}>下一頁</Button>
      </div>
      <Footer />
    </div>
  )
}

const Destination = ({isVisible, keyword, onSelected}) => {
  const debounceDestination = useDebounce(keyword, 200)
  const [list, setList] = useState([])
  useEffect(() => {
    getTravelDestination({
      keyword: debounceDestination,
      channel: 'ZURICH'
    }).then(res => {
      console.log(res)
      if (res.data.code === 0) {
        setList(res.data.data)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceDestination])

  const highlightKeyword = (content) => {
    const values = content.split(keyword)
    const innerHTML = values.join('<span>' + keyword + '</span>')
    return innerHTML
  }

  return <div style={{display: isVisible && list.length > 0 && debounceDestination.length > 0 ? 'flex' : 'none'}} className={styles['destination-list']}>
    {
      list.map(el => {
        return <div id='destination-item' className={styles['destination-item']} key={el.place} dangerouslySetInnerHTML={{__html: highlightKeyword(el.place)}}  onClick={e => {
          e.stopPropagation()
          onSelected(el)
        }}></div>
      })
    }
  </div>
}
