import React from 'react'
import { Tooltip } from 'antd'
import {assetsUrl} from '../../utils/constants'
import style from './SectionCompare.module.scss'
export function SectionCompare ({compareProduct, comparing, compareAction, deleteAction, resetAction}) {
  return <div style={{display: compareProduct.length > 0 ? 'flex' : 'none'}} className={style['compareing-product']}>
    <div className={style['product-list']}>
      {
        [1, 2, 3, 4].map((el, idx) => {
          return <div className={style['product-item']} key={idx}>
            {
              compareProduct.length > idx ?
              <div className={style['select-item']}>
                <div className={style['product-info']}>
                  {
                    compareProduct[idx].brandPicUrl ? <img className={style['product-cover']} src={compareProduct[idx].brandPicUrl} alt='' /> : null
                  }
                  <Tooltip>
                    <p>{compareProduct[idx].productName}</p>
                  </Tooltip>
                </div>
                <img className={style['btn-close']} src={assetsUrl + 'images/icon_delete_compare_product.png'} alt='' onClick={e => {
                  deleteAction(idx)
                }} />
              </div> : <div className={style['normal-item']}>
                <img src={assetsUrl + 'images/icon_add_compare_product.png'} alt='' />
              </div>
            }
          </div>
        })
      }
    </div>
    <div className={style['now-compare']} >
      <div className={style['compare-btn']} style={{opacity: compareProduct.length > 1 ? '1.0' : '0.7'}} onClick={e =>{
          if (compareProduct.length > 1) {
            compareAction()
          }
        }}>
        <span>立即比較</span>
        <img className={[comparing ? `${style['compare-animate']}` : `${style['compare-normal']}`]} src={assetsUrl + 'images/icon_compare_loading.png'} alt =''/>
      </div>
      <span className={style['reset-btn']} onClick={e => {
        resetAction()
      }}>重設</span>
    </div>
  </div>
}