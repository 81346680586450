export const fstLevelCategoryList = [
  {id: 1, name: '醫療及危疾', cId: 10},
  {id: 2, name: '儲蓄', cId: 21},
  {id: 3, name: '人壽', cId: 31},
  {id: 4, name: '年金', cId: 41},
  {id: 5, name: '一般保險', cId: 52},
  {id: 6, name: '其他', cId: 60},
]
export const secLevelCategoryList = [
  {
    pId: fstLevelCategoryList[0].id,
    pName: fstLevelCategoryList[0].name,
    id: 10,
    name: '自願醫保',
    icon: 'images/icon_home_medical_critical_illness_s.png',
    subTitle: '*稅務扣減 (保單持有人最高可獲每年HK$8,000的免稅額)',
    desc: ['均保證續保至100歲(具體內容請參照保險合約條款約定)', '保障未知的已有病症']
  },
  {
    pId: fstLevelCategoryList[0].id,
    pName: fstLevelCategoryList[0].name,
    id: 13,
    name: '定期危疾',
    icon: 'images/icon_home_medical_critical_illness_s.png',
    subTitle: '',
    desc: ['主要提供嚴重疾病的保障。', '於保障期內，若受保人不幸患上符合保單定義的嚴重危疾，便可得到一筆過的賠償。', '常見的為每1年、5年及10年更新保費；受保人通常可保證續保至指定歲數。']
  },
  {
    pId: fstLevelCategoryList[0].id,
    pName: fstLevelCategoryList[0].name,
    id: 14,
    name: '終身危疾',
    icon: 'images/icon_home_medical_critical_illness_s.png',
    subTitle: '',
    desc: ['主要提供嚴重疾病的保障。', '於保障期內，若受保人不幸患上符合保單定義的嚴重危疾，便可得到一筆過的賠償。', '常見的保費繳付期為5年至30年；受保人通常可保證續保至100歲或終身。(具體內容請參照保險合約條款約定)']
  },
  {
    pId: fstLevelCategoryList[1].id,
    pName: fstLevelCategoryList[1].name,
    id: 21,
    name: '短期儲蓄',
    icon: 'images/icon_home_savings_s.png',
    subTitle: '',
    desc: ['短期儲蓄保險的保單年期較短，幾年便期滿。（具體期限請見保險合約條款約定）', '固定的保證回報，而回本期較短。（具體期限請見保險合約條款約定）']
  },
  {
    pId: fstLevelCategoryList[1].id,
    pName: fstLevelCategoryList[1].name,
    id: 22,
    name: '長期儲蓄',
    icon: 'images/icon_home_savings_s.png',
    subTitle: '',
    desc: ['長期儲蓄保險有回報（包含保證及非保證回報）。', '人壽保障至終身。']
  },
  {
    pId: fstLevelCategoryList[2].id,
    pName: fstLevelCategoryList[2].name,
    id: 31,
    name: '定期人壽',
    icon: 'images/icon_home_life_s.png',
    subTitle: '',
    desc: ['定期人壽保費通常較為便宜，而賠償倍數亦較高，若受保人於保障期內身故，受益人便可按條款得到一筆過的身故賠償。', '保費會按受保人年齡、繳費期數以及保額而釐定。']
  },
  {
    pId: fstLevelCategoryList[2].id,
    pName: fstLevelCategoryList[2].name,
    id: 32,
    name: '終身人壽',
    icon: 'images/icon_home_life_s.png',
    subTitle: '',
    desc: ['終身人壽保險有回報（包含保證及非保證回報）', '亦有人壽成分 (保障至終身)']
  },
  {
    pId: fstLevelCategoryList[3].id,
    pName: fstLevelCategoryList[3].name,
    id: 41,
    name: '延期年金',
    icon: 'images/icon_home_annuity_s.png',
    subTitle: '*稅務扣減 (保單持有人最高可獲每年HK$60,000的免稅額)',
    desc: ['延期是指一筆過或保費繳付期屆滿後，在指定入息期開始提取年金。']
  },
  // {
  //   pId: fstLevelCategoryList[3].id,
  //   pName: fstLevelCategoryList[3].name,
  //   id: 42,
  //   name: '即期年金',
  //   icon: 'images/icon_home_annuity_s.png',
  //   subTitle: '',
  //   desc: ['即期是指一筆過繳保費後，隨即領取年金。']
  // },
  {
    pId: fstLevelCategoryList[4].id,
    pName: fstLevelCategoryList[4].name,
    id: 52,
    name: '旅遊保險',
    icon: 'images/icon_general_insurance_s.png',
    subTitle: '',
    desc: ['一般旅遊保險提供醫療費用、旅程延誤、租車自負額等。']
  },
  {
    pId: fstLevelCategoryList[4].id,
    pName: fstLevelCategoryList[4].name,
    id: 50,
    name: '家居保險',
    icon: 'images/icon_general_insurance_s.png',
    subTitle: '',
    desc: ['一般家居保險提供財物保險保障、公眾責任保障、臨時住宿保障等。']
  },
  {
    pId: fstLevelCategoryList[4].id,
    pName: fstLevelCategoryList[4].name,
    id: 51,
    name: '家傭保險',
    icon: 'images/icon_general_insurance_s.png',
    subTitle: '',
    desc: ['一般家傭保險提供外科手術及住院費用、門診費用、個人意外死亡或傷殘賠償等。']
  },
  {
    pId: fstLevelCategoryList[5].id,
    pName: fstLevelCategoryList[5].name,
    id: 60,
    name: '萬用壽險',
    icon: 'images/icon_other_insurance_s.png',
    subTitle: '',
    desc: ['可自訂投資選項的人壽保險計劃，針對高資產客戶人群。', '可提供資本增值的長線投資產品同時包含有投資及保險成分，兼顧投資及遺產策劃目標。']
  },
  {
    pId: fstLevelCategoryList[5].id,
    pName: fstLevelCategoryList[5].name,
    id: 61,
    name: '意外保險',
    icon: 'images/icon_other_insurance_s.png',
    subTitle: '',
    desc: ['一般個人意外保險保障意外死亡或永久傷殘等。', '提供意外醫療費用。']
  },
]
export const travelAttrList = [
  {
    attrId: 1,
    attrName: '計劃',
    valueSelect: [
      {
        selected: true,
        alias: 'single',
        desc: '單次計劃',
      },
      {
        selected: false,
        alias: 'annual',
        desc: '全年計劃',
      }
    ]
  }
]