import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Carousel as Swiper, Spin, Tooltip } from 'antd'
import { Footer, Carousel, HotProductItem, ProductItem, InsuranceCustomization} from '../../components'
import style from './Home.module.scss'
// <<<<<<< HEAD
// import {channels, recommendProductList, teams, praises, hotProducts, brandInsurance, ambassador, company, insuranceCategory} from './mockup'
// import { fetchNewsList } from '../../redux/news/slice'
// =======
import {recommendProductList, teams1, praises, hotProducts, brandInsurance, ambassador, company, insuranceCategory} from './mockup'
// import { fetchNewsList } from '../../redux/news/slice'
import { arrow } from '../../assets/index'
import { splitArray } from '../../utils/index'
import { getActivityListActionCreator } from '../../redux/activityList/activityListAction'
import { useSelector, useDispatch } from 'react-redux'
import { assetsUrl } from '../../utils/constants'
import { setFontSize } from '../../utils/index'
import { getNewsList } from '../../services/api'
// import PropTypes from 'prop-types'
export function Home () {
  const history = useHistory()
  const dispatch = useDispatch()
  const scale = useSelector(state => state.font.scale)
  const [activeDotIdx, setActiveDotIdx] = useState(0)
  const [category, setCategory] = useState(insuranceCategory)
  let swiperRef = null
  const loading = useSelector(state => state.activityList.loading)
  const activityData = useSelector(state => state.activityList.data)
  const activityList = activityData ? splitArray(activityData, 4) : []
  // const newsState = useSelector(state => state.newsList)
  const [newsLoading, setNewsLoading] = useState(false)
  const [newsData, setNewsData] = useState(null)
  const fetchNewsList = () => {
    setNewsLoading(true)
    getNewsList({
      pageNum: 1,
      pageSize: 4
    }).then(res => {
      setNewsLoading(false)
      if (res.data.code === 0) {
        if (res.data.data) {
          setNewsData(res.data.data)
        }
      } else {
        setNewsData(null)
        // message.error(res.data.message)
      }
    }).catch(e => {
      setNewsLoading(false)
    })
  }
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  useEffect(() => {
    if (activityList.length === 0) {
      dispatch(getActivityListActionCreator({ pageNum: 1, pageSize: 20}))
    }

    fetchNewsList()
    // if (!newsState.data) {
    //   dispatch(fetchNewsList({ pageNum: 1, pageSize: 4}))
    // }

  }, [activityList.length, dispatch])
  return (
    <div className={style['home-container']}>
      {/* <Header /> */}
      <Carousel />
      <div className={style.main}>
        {/* 保险分类 */}
        <div className={style['insurance-category']}>
          {
            category.map((el, idx) =>{
              return <div className={style['category-item']} style={{background: el.hover ? el.selectBgColor : el.bgColor}} key={el.title} onClick={e => {
                history.push(`/compare-product/${el.id}`)
                const c = category
                c[idx].hover = false
                setCategory(state => {
                  return [...c]
                })
              }} onMouseEnter={e => {
                const c = category
                c[idx].hover = true
                setCategory(state => {
                  return [...c]
                })
              }} onMouseLeave={e => {
                const c = category
                c[idx].hover = false
                setCategory(state => {
                  return [...c]
                })
              }}>
                <p className={style['insurance-title']} style={setFontSize(24, scale)}>{el.title}</p>
                <img src={assetsUrl + (el.hover ? el.activeIcon : el.icon)} alt=''/>
                <div className={style['insurance-desc']} style={setFontSize(18, scale)}>
                  {
                    el.content.map((item, index) => {
                      return <p key={index} onClick={e => {
                        e.stopPropagation()
                        history.push(`/compare-product/${item.id}`)
                        const c = category
                        c[idx].hover = false
                        setCategory(state => {
                          return [...c]
                        })
                      }}>{item.name}</p>
                    })
                  }
                </div>
              </div>
            })
          }
        </div>
        {/* 熱門產品 */}
        <div id='news' className={style['latest-news']} style={{display: 'none' ,background: 'transparent'}}>
          <div className={style['hot-activity-title']} style={{marginTop: '39px', ...setFontSize(40, scale)}}>
            <p>熱門</p>
            <p>產品</p>
          </div>
          {/* {
            newsLoading === false ? ( (newsData && newsData.records.length > 0) ? <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              
            </div> : <div></div>) : <div className={style['loading-box']}><Spin style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '441px'}} /></div>
          } */}
          <div className={style['news-list']}>
            {
              hotProducts.map((el,idx) => {
                return (
                  <div className={style['news-item']} key={el.title+idx} onClick={() => {
                    // if (el.id) {
                    //   history.push(`/news-detail/${el.id}`)
                    // }
                  }}>
                    <img className={style['news-cover']} src={assetsUrl+'images/'+el.cover} alt=''/>
                    {/* <p className={style['news-time']}>{el.time}</p> */}
                    <Tooltip title={el.title}>
                      <p className={style['news-title']} style={setFontSize(24, scale)}>{el.title}</p>
                    </Tooltip>
                    <p className={style['news-detail']} style={setFontSize(18, scale)}>{el.desc}</p>
                    <span className={style['more-box']} style={setFontSize(20, scale)}>了解更多</span>
                  </div>
                )
              })
            }
          </div>
          <div className={style['understand-more']} style={{...setFontSize(20, scale), marginTop: '60px', marginBottom: '0px'}} onClick={e => {
            history.push('/news-list')
          }}>
            <span>了解更多</span>
            <img src={assetsUrl + 'images/icon_learn_more.png'} alt='了解更多'/>
          </div>
        </div>
        {/* 熱門活動 */}
        {loading === false ?
          <div className={style['hot-activity']}>
            <div className={style['hot-activity-title']} style={setFontSize(40, scale)}>
              <p>精彩</p>
              <p>活動</p>
            </div>
            {
              <Swiper ref={ref => swiperRef = ref} className={style['swiper-box']} dots={false} beforeChange={(from, to) => {
                setActiveDotIdx(to)
              }}>
                {
                  activityList.map((el, index) => {
                    return <div className={style['swiper-item']} key={index}>
                      {
                        el.map((item, idx) => {
                          return <div className={style['swiper-item-content']} key={idx} onClick={() => {
                            if (item.id) {
                              history.push(`/activity-detail/${item.id}/home`)
                            }
                          }}>
                            <img className={style['content-cover']} src={item.coverImageUrl} alt="" />
                            <Tooltip title={item.title}>
                              <div className={style['title-box']} style={setFontSize(24, scale)}>
                                <span className={style['content-title']}>{item.title}</span>
                              </div>
                            </Tooltip>
                            <div className={style['bottom-box']}>
                              <img src={assetsUrl + 'images/icon_hot_activity_arrow.png'} alt="hot_activity_arrow" />
                            </div>
                          </div>
                        })
                      }
                    </div>
                  })
                }
              </Swiper>
            }
            <div className={style['page-dot']} style={{display: activityList.length > 1 ? 'flex' : 'none'}} >
              {
                activityList.length > 0 ? activityList.map((el, i) => {
                  return <div className={style['dot-item']} key={i}>
                    <img src={assetsUrl + (activeDotIdx ===  i ? 'images/icon_dot_active.png' : 'images/icon_dot_normal.png')} alt='dot' onClick={e => {
                      if (swiperRef) {
                        swiperRef.goTo(i)
                      }
                    }}/>
                  </div>
                }) : <div style={{display: 'none'}}></div>
              }
            </div>
            <div className={style['understand-more']} style={setFontSize(20, scale)}>
              <span onClick={() => history.push('/activities')}>了解更多</span>
              <img src={assetsUrl + 'images/icon_learn_more.png'} alt='了解更多'/>
            </div>
            <div className={style['page-control']} style={{display: activityList.length > 1 ? 'flex' : 'none'}}>
              <img src={assetsUrl + 'images/icon_left_control_small.png'} alt='' onClick={() => {
                if (swiperRef) {
                  swiperRef.prev()
                }
              }}/>
              <img src={assetsUrl + 'images/icon_right_control_small.png'} alt='' onClick={() => {
                if (swiperRef) {
                  swiperRef.next()
                }
              }}/>
            </div>
          </div> : <div className={style['loading-box']}> <Spin style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '390px'}}/></div>
        }
        {/*最新資訊 */}
        <div id='news' className={style['latest-news']} style={{marginBottom: '-50px'}}>
          <div className={style['hot-activity-title']} style={{marginTop: '39px', ...setFontSize(40, scale)}}>
            <p>最新</p>
            <p>資訊</p>
          </div>
          {
            newsLoading === false ? ( (newsData && newsData.records.length > 0) ? <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div className={style['news-list']}>
                {
                  newsData.records.map((el) => {
                    return (
                      <div className={style['news-item']} key={el.title} onClick={() => {
                        if (el.id) {
                          history.push(`/news-detail/${el.id}`)
                        }
                      }}>
                        <img className={style['news-cover']} src={el.coverImageUrl} alt=''/>
                        {/* <p className={style['news-time']}>{el.time}</p> */}
                        <Tooltip title={el.title}>
                          <p className={style['news-title']} style={setFontSize(24, scale)}>{el.title}</p>
                        </Tooltip>
                        <p className={style['news-detail']} style={setFontSize(18, scale)}>{el.detailContentText}</p>
                        <span className={style['more-box']} style={setFontSize(20, scale)}>了解更多</span>
                      </div>
                    )
                  })
                }
              </div>
            </div> : <div></div>) : <div className={style['loading-box']}><Spin style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '441px'}} /></div>
          }
          <div className={style['understand-more']} style={{...setFontSize(20, scale), marginTop: '60px', marginBottom: '127px'}} onClick={e => {
            history.push('/news-list')
          }}>
            <span>了解更多</span>
            <img src={assetsUrl + 'images/icon_learn_more.png'} alt='了解更多'/>
          </div>
        </div>
        {/* 品牌保險 */}
        <div className={style['hot-product']} style={{marginTop: '60px', display: 'none'}}>
          <p className={style.subtitle} style={setFontSize(40, scale)}>品牌保險</p>
          <div className={style['product-list']}>
            {
              brandInsurance.map((el, idx) => {
                return <ProductItem className={style['product-item']} {...el} scale={scale} key={idx}/>
              })
            }
          </div>
          <span className={style['understand-more']} style={setFontSize(20, scale)}>了解更多</span>
        </div>
        {/* 保險比较  */}
        <div className={style['insurance-comparison']}>
          <div className={style['image-box']}>
            <img src='https://temp-cdn.huixinguanli.cn/static/media/images/icon_insurance_comparison.png' alt='' />
          </div>
          <div className={style['comparison-box']}>
            <p className={style['comparison-title']}>保險比较 </p>
            <p className={style['comparison-desc']}>比較市場上各種保險的保費與性價比！</p>
            <span className={style['comparison-btn']} onClick={e => {
              history.push('/compare-insurance')
            }}>比較產品</span>
          </div>
        </div>
        {/* 個人化保險方案定制  */}
        <InsuranceCustomization scale={scale} />
        {/* 慧擇大使 */}
        {/* <div className={style['ambassador-box']}>
          <div className={style['common-title']} style={setFontSize(40, scale)}>
            <div className={style['common-box']}>
              <p >慧擇大使</p>
              <img src={assetsUrl + 'images/icon_home_underline.png'} alt=''/>
            </div>
          </div>
          <div className={style['ambassador-list']}>
            {
              ambassador.map((el, idx) => {
                return <div className={style['ambassador-item']} key={idx}>
                  <img src={assetsUrl + 'images/' + el.avatar}  alt=''/>
                  <p className={style['name']} style={setFontSize(26, scale)}>{el.name}</p>
                  <p className={style['intro']} style={setFontSize(20, scale)}>{el.intro}</p>
                </div>
              })
            }
          </div>
        </div> */}
        {/* 保单托管 */}
        {/* <PolicyEscrow /> */}
        {/* 產品推介 */}
        <div id='recommend' className={style['product-promotion']}>
          {/* <p className={style.title}>產品推介</p> */}
          <p className={style.subtitle}>我們的產品</p>
          <div className={style['recommend-product']}>
            {
              recommendProductList.map((el, index) => {
                return <div className={style['recommend-product-item']} key={index}>
                  <div className={style['img-box']}>
                    <img src={el.icon} alt="" />
                  </div>
                  <p className={style['product-name']}>{el.name}</p>
                  <p className={style['product-desc']}>{el.desc}</p>
                </div>
              })
            }
          </div>
          <div className={style['apply-customized-box']} onClick={() => history.push('/product-promotion')}>
            <span>了解更多</span>
            <img src={arrow}  alt=''/>
          </div>
        </div>
        {/* { 我们的团队} */}
        <div style={{display: 'none'}} id='teams' className={style['teams-box']}>
          {/* <p className={style.title}>瞭解我們</p> */}
          <p className={style.subtitle}>慧擇香港團隊</p>
          <div className={style['teams-list']}>
            {
              teams1.map((el) => {
                return (
                  <div className={style['teams-item']} key={el.name}>
                    <img className={style['member-cover']} src={el.cover} alt=''/>
                    {/* <ShareBox /> */}
                    <p className={style['member-name']}>{el.name}</p>
                    <p className={style['member-position']}>{el.position}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
        {/* 客戶好評推薦 */}
        <div style={{display: 'none'}} id='praise' className={style['customer-praise']}>
          {/* <p className={style.title}>客戶評價</p> */}
          <p className={style.subtitle}>客戶好評推薦</p>
          <div className={style['customer-list']}>
            {
              praises.map((el) => {
                return (
                  <div className={style['customer-item']} key={el.name}>
                    <div className={style['customer-info']}>
                      <img src={el.avatar} alt=''/>
                      <div className={style['customer-intro']}>
                        <p className={style['customer-name']}>{el.name}</p>
                        <p className={style['customer-position']}>{el.position}</p>
                      </div>
                    </div>
                    <p className={style['customer-evaluation']}>{el.desc}</p>
                  </div>
                )
              })
            }
          </div>
          <div className={style['apply-customized-box']}>
            <span>全部評價</span>
            <img src={arrow}  alt=''/>
          </div>
        </div>
        {/* 合作保險公司 */}
        <div className={style['partner-company']}>
          <div className={style['common-title']} style={setFontSize(40, scale)}>
            <div className={style['common-box']}>
              <p >合作保險公司</p>
              <img src={assetsUrl + 'images/icon_home_underline_l.png'} alt=''/>
            </div>
          </div>
          <div className={style['company-list']}>
            {
              company.map((el) => {
                return <div className={style['company-item']} key={el}>
                  <img src={assetsUrl + 'images/icon_partner_company_logo_' + el} alt='' />
                </div>
              })
            }
          </div>
        </div>
      </div>
      <Footer isHome={true}/>
    </div>
  )
}