import React from "react";
import { moreArrow } from '../../assets/index'
import styles from "./ProductItem.module.scss";
import { assetsUrl } from '../../utils/constants'
import { setFontSize } from '../../utils'
export function ProductItem ({cover, title, desc, scale}) {
  return <div className={styles['product-item']}>
    <img className={styles['cover']} src={assetsUrl + 'images/' + cover} alt=''/>
    <p className={styles['title']} style={setFontSize(26, scale)}>{title}</p>
    <p className={styles['desc']} style={setFontSize(20, scale)}>{desc}</p>
    <div className={styles['bottom']}>
      <p style={setFontSize(16, scale)}>了解更多  </p>
      <img src={moreArrow} alt=''/>
    </div>
  </div>
}
