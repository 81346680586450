export const tableHeader = [
  {
    with: '31%',
    icon: '',
    title: '每名受保人每次旅程 \n 之最高賠償額（港元）'
  },
  {
    with: '23%',
    icon: 'icon_zurich_form_icon_plan3',
    title: '優遊計劃'
  },
  {
    with: '23%',
    icon: 'icon_zurich_form_icon_plan2',
    title: '精選計劃'
  },
  {
    with: '23%',
    icon: 'icon_zurich_form_icon_plan1',
    title: '隨心計劃'
  }
]
export const planLevel = ['已選計劃級別', '每名成人每次受保旅程之最高賠償額（港元）', '醫療費用', '緊急醫療運送','個人意外', '旅程延誤津貼', '取消旅程']
export const excellentPlan1 = [
  [
    {
      base: '',
      actual: '優遊計劃'
    }
  ],
  [
    {
      base: '',
      actual: '18至75歲'
    },
    {
      base: '',
      actual: '76歲或以上'
    },
    {
      base: '',
      actual: '17歲或以下'
    }
  ],

  [
    {
      base: 1500000,
      actual: '1,500,000'
    },
    {
      base: 1500000,
      actual: '1,500,000'
    },
    {
      base: 750000,
      actual: '1,500,000'
    }
  ],
  [
    {
      base: '',
      actual: '實際費用'
    },
    {
      base: '',
      actual: '實際費用'
    },
    {
      base: '',
      actual: '實際費用'
    }
  ],

  [
    {
      base: 1500000,
      actual: '1,500,000'
    },
    {
      base: 1500000,
      actual: '1,500,000'
    },
    {
      base: 750000,
      actual: '1,500,000'
    }
  ],
  [
    {
      base: 2000,
      actual: '2,000'
    },
    {
      base: 2000,
      actual: '2,000'
    },
    {
      base: 1000,
      actual: '2,000'
    }
  ],
  [
    {
      base: 40000,
      actual: '40,000'
    },
    {
      base: 40000,
      actual: '40,000'
    },
    {
      base: 20000,
      actual: '40,000'
    }
  ]
]
export const excellentPlan2 = [
  [
    {
      base: '',
      actual: '優遊計劃'
    }
  ],
  [
    {
      base: '',
      actual: '18至75歲'
    },
    {
      base: '',
      actual: '76歲或以上'
    },
    {
      base: '',
      actual: '17歲或以下'
    }
  ],

  [
    {
      base: 1000000,
      actual: '1,000,000'
    },
    {
      base: 500000,
      actual: '500,000'
    },
    {
      base: 500000,
      actual: '1,000,000'
    }
  ],
  [
    {
      base: '',
      actual: '實際費用'
    },
    {
      base: '',
      actual: '實際費用'
    },
    {
      base: '',
      actual: '實際費用'
    }
  ],

  [
    {
      base: 1000000,
      actual: '1,000,000'
    },
    {
      base: 400000,
      actual: '400,000'
    },
    {
      base: 500000,
      actual: '1,000,000'
    }
  ],
  [
    {
      base: 1000,
      actual: '1,000'
    },
    {
      base: 1000,
      actual: '1,000'
    },
    {
      base: 500,
      actual: '1,000'
    }
  ],
  [
    {
      base: 20000,
      actual: '20,000'
    },
    {
      base: 20000,
      actual: '20,000'
    },
    {
      base: 10000,
      actual: '20,000'
    }
  ]
]
export const excellentPlan3 = [
  [
    {
      base: '',
      actual: '優遊計劃'
    }
  ],
  [
    {
      base: '',
      actual: '18至75歲'
    },
    {
      base: '',
      actual: '76歲或以上'
    },
    {
      base: '',
      actual: '17歲或以下'
    }
  ],

  [
    {
      base: 500000,
      actual: '500,000'
    },
    {
      base: 250000,
      actual: '250,000'
    },
    {
      base: 250000,
      actual: '500,000'
    }
  ],
  [
    {
      base: '',
      actual: '實際費用'
    },
    {
      base: '',
      actual: '實際費用'
    },
    {
      base: '',
      actual: '實際費用'
    }
  ],

  [
    {
      base: 500000,
      actual: '500,000'
    },
    {
      base: 200000,
      actual: '200,000'
    },
    {
      base: 250000,
      actual: '500,000'
    }
  ],
  [
    {
      base: 300,
      actual: '300'
    },
    {
      base: 300,
      actual: '300'
    },
    {
      base: 150,
      actual: '300'
    }
  ],
  [
    {
      base: 5000,
      actual: '5,000'
    },
    {
      base: 5000,
      actual: '5,000'
    },
    {
      base: 2500,
      actual: '5,000'
    }
  ]
]
export const childProtection = {
  title: '1. 您需要為隨行兒童購買全額保障嗎？',
  content: [
    {
      desc: '是',
      directions: ' (額外保費 133.00港元)',
      optionPlan: 'OP1'
    },
    {
      desc: '否',
      directions: ' (額外保費 133.00港元)',
      optionPlan: ''
    }
  ]
}
export const aldultProtection = 
{
  title: '您想升級個人意外及醫療費用保障嗎？（適用於所有18歲或以上之旅客）',
  content: [
    {
      desc: '是，每節額外1,000,000港元',
      directions: ' (額外保費 140.00港元)',
      optionPlan: 'OP2'
    },
    {
      desc: '是，每節額外2,000,000港元',
      directions: ' (額外保費 200.00港元)',
      optionPlan: 'OP2'
    },
    {
      desc: '否',
      directions: '',
      optionPlan: ''
    },
  ]
}

export const prompt = ['重要提示：', '申請人及旅客必須為香港公民或居民。', '若受保旅程並非由香港出發，該行程必須經香港安排及付款。', '於投保此保險計劃前，您應閱讀 (a) 承保範圍 及 (b) 保單條款。', '以上資料僅供參考之用，並不構成保險合約的一部分。', '世界衛生組織於2020年3月11日宣布新型冠狀病毒為全球大流行，有關當日或之後簽發之保單，我們將視新型冠狀病毒為「已知事項及狀況」，保單內任何因新型冠狀病毒引致的旅程阻礙損失將不受保障。', '一概保障必須在本申請獲保險公司接納並繳交應付保費後始可生效。']