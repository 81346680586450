import {
  Home,
  ActivityList,
  ActivityDetail,
  NewsList,
  NewsDetail,
  About,
  Contact,
  CompareInsurance,
  ProductDetail,
  PrivacyPolicy,
  PersonalInformation,
  GiftInsurance,
  ReceiveGiftInsurance,
  ReceiveSuccess,
  TermOfUse,
  ProductPromotion,
  InsurancePolicy,
  CompareProduct,
  CustomerSupport,
  Consultant,
  Login,
  TravelPlusProcess,
  SingleTravelPlan,
  AnnualTravelPlan,
  Payment,
  PaymentResult,
  ExclusiveCustom,
  NotFound,
  ClaimsCustomerService
} from '../pages'

export const  routes = [
  { path: "/", name: "App", component: Home, exact: true, auth: false,  cacheRoute: false},
  { path: "/activities", name: "Activities", component: ActivityList, exact: false, auth: false,  cacheRoute: true, headerIdx: 1005  },
  { path: "/activity-detail/:id", name: "ActivityDetail", alias: 'activity-detail/', component: ActivityDetail, exact: false, auth: false,  cacheRoute: false, headerIdx: 1005  },
  { path: "/news-list", name: "NewsList", component: NewsList, exact: false, auth: false,  cacheRoute: true, headerIdx: 1006  },
  { path: "/news-detail/:id", name: "NewsDetail", alias: 'news-detail/', component: NewsDetail, exact: false, auth: false,  cacheRoute: false, headerIdx: 1006 },
  { path: "/product-promotion", name: "ProductPromotion", component: ProductPromotion, exact: false, auth: false,  cacheRoute: false },
  // { path: "/insurance-policy", name: "InsurancePolicy", component: InsurancePolicy, exact: false, auth: true,  cacheRoute: false, headerIdx: 1008  },
  { path: "/privacy-policy", name: "PrivacyPolicy", component: PrivacyPolicy, exact: false, auth: false,  cacheRoute: false },
  { path: "/personal-information", name: "PersonalInformation", component: PersonalInformation, exact: false, auth: false,  cacheRoute: false },
  { path: "/compare-insurance", name: "CompareInsurance", component: CompareInsurance, exact: false, auth: false,  cacheRoute: false },
  { path: "/compare-product/:id", name: "CompareProduct", alias: 'compare-product/', component: CompareProduct, exact: false, auth: false,  cacheRoute: false, headerIdx: 1007 },
  { path: "/product-detail/:id", name: "ProductDetail", alias: 'product-detail/', component: ProductDetail, exact: false, auth: false,  cacheRoute: false, headerIdx: 1007 },
  { path: "/customer-support", name: "CustomerSupport", component: CustomerSupport, exact: false, auth: false,  cacheRoute: false, headerIdx: 1003  },
  { path: "/about", name: "About", component: About, exact: false, auth: false,  cacheRoute: false, headerIdx: 1001  },
  { path: "/contact", name: "Contact", component: Contact, exact: false, auth: false,  cacheRoute: false, headerIdx: 1004  },
  { path: "/consultant", name: "Consultant", component: Consultant, exact: false, auth: false,  cacheRoute: false },
  { path: "/gift-insurance", name: "GiftInsurance", component: GiftInsurance, exact: false, auth: false,  cacheRoute: false },
  { path: "/receive-gift-insurance", name: "ReceiveGiftInsurance", component: ReceiveGiftInsurance, exact: false, auth: false,  cacheRoute: false },
  { path: "/receive-success", name: "ReceiveSuccess", component: ReceiveSuccess, exact: false, auth: false,  cacheRoute: false },
  { path: "/login", name: "Login", component: Login, exact: false, auth: false,  cacheRoute: false },
  { path: "/terms-of-use", name: "TermOfUse", component: TermOfUse, exact: false, auth: false,  cacheRoute: false },
  { path: "/travelPlusProcess", name: "TravelPlusProcess", component: TravelPlusProcess, exact: false, auth: false,  cacheRoute: false },
  // { path: "/travelPlusProcess/:id", name: "TravelPlusProcess", alias: 'travelPlusProcess/', component: TravelPlusProcess, exact: false, auth: false,  cacheRoute: false },
  { path: "/singleTravelPlan/:plan", name: "SingleTravelPlan", alias: 'singleTravelPlan/', component: SingleTravelPlan, exact: false, auth: false,  cacheRoute: false },
  { path: "/annualTravelPlan/:plan", name: "AnnualTravelPlan", alias: 'annualTravelPlan/', component: AnnualTravelPlan, exact: false, auth: false,  cacheRoute: false },
  { path: "/payment/:source", name: "Payment", alias: 'payment/', component: Payment, exact: true, auth: false,  cacheRoute: false },
  { path: "/payment-result", name: "PaymentResult", component: PaymentResult, exact: false, auth: false,  cacheRoute: false },
  { path: "/exclusive-custom", name: "ExclusiveCustom", component: ExclusiveCustom, exact: false, auth: false,  cacheRoute: false, headerIdx: 1008 },
  { path: "/claims-customer-service", name: "ClaimsCustomerService", component: ClaimsCustomerService, exact: false, auth: false,  cacheRoute: false, headerIdx: 1003 },
  { path: "/404", name: "NotFound", component: NotFound, exact: false, auth: false,  cacheRoute: false }
]