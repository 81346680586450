import React, { useEffect, useState } from 'react'
import { Footer} from '../../components'
// import { useLocation } from 'react-router-dom'
import { Form, Select, Button, message, Spin, Input } from 'antd'
import style from './CompareInsurance.module.scss'
import { submitCustomerReserve } from '../../services/api'
import { selectArrow } from '../../assets'
import { isLegalEmail, isLegalHKCommunication} from '../../utils'
// Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
// import 'swiper/swiper.scss';
// import  SwiperCore, { Autoplay } from 'swiper/core';
import { assetsUrl } from '../../utils/constants'
import { medicalIllness, life, other, generalInsurance } from './mockup'
import { useSelector } from 'react-redux'
import { setFontSize } from '../../utils'
// SwiperCore.use([Autoplay]);
const tabs = [
  {
    id: 3001,
    icon: 'images/icon_home_medical_critical_illness_black.png',
    activeIcon: 'images/icon_home_medical_critical_illness_s.png',
    title: '醫療及危疾',
    href: 'medical-illness-box'
  },
  {
    id: 3002,
    icon: 'images/icon_home_savings_black.png',
    activeIcon: 'images/icon_home_savings_s.png',
    title: '儲蓄',
    href: 'savings-box'
  },
  {
    id: 3003,
    icon: 'images/icon_home_life_black.png',
    activeIcon: 'images/icon_home_life_s.png',
    title: '人壽',
    href: 'life-box'
  },
  {
    id: 3004,
    icon: 'images/icon_home_annuity_black.png',
    activeIcon: 'images/icon_home_annuity_s.png',
    title: '年金',
    href: 'annuity-box'
  },
  {
    id: 3005,
    icon: 'images/icon_general_insurance_blcak.png',
    activeIcon: 'images/icon_home_general_insurance_s.png',
    title: '一般保險',
    href: 'general-insurance-box'
  },
  {
    id: 3006,
    icon: 'images/icon_other_insurance_black.png',
    activeIcon: 'images/icon_other_insurance_s.png',
    title: '其他',
    href: 'other-insurance-box'
  }
]
let tops = []
export function CompareInsurance () {
  // const { query } = useLocation()
  const scale = useSelector(state => state.font.scale)
  const tabHeight = 114
  const [tabIndex, setTabIndex] = useState(-1)
  const [loading, setLoading] = useState(false)
  const initialValues = {
    surname: '',
    name: '',
    gender: undefined,
    areaCode: '+852',
    phone: '',
    email: '',
    idealContactTime: '',
    applyinfo: ''
  }
  const switchTab = (id, idx) => {
    const item = document.getElementById(id)
    if (item) {
      const react = item.getBoundingClientRect()
      if (idx === 5) {
        if (react.top <= (122 + tabHeight) && react.top > -(react.height - 122 - tabHeight)) {
          if (tabIndex !== idx) {
            setTabIndex(idx)
          }
          return true
        } else {
          setTabIndex(-1)
        }
      } else {
        if (react.top <= (122 + tabHeight) && react.top > -(react.height - 122 - tabHeight)) {
          if (tabIndex !== idx) {
            setTabIndex(idx)
          }
          return true
        }
      }
    }
    return false
  }
  window.onscroll = () => {
    if(switchTab('medical-illness-box', 0)){
      return
    }
    if (switchTab('savings-box', 1)){
      return
    }
    if (switchTab('life-box', 2)){
      return
    }
    if (switchTab('annuity-box', 3)){
      return
    }
    if (switchTab('general-insurance-box', 4)){
      return
    }
    if (switchTab('other-insurance-box', 5)){
      return
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    tabs.forEach((el, idx) => {
      const item = document.getElementById(el.href)
      const react = item.getBoundingClientRect()
      let gap = 40
      if (idx === 0) {
        gap = 0
      } else if (idx === 1) {
        gap = 10
      } else if (idx === 2) {
        gap = 20
      }
      tops.push(react.top - 122 - 114 + gap)
    })
  }, [])
  return <div className={style['compare-container']}>
    <div className={style['banner-box']}>
      <p style={setFontSize(50, scale)} className={style['title']}>產品介紹</p>
      {/* <p style={setFontSize(20, scale)} className={style['desc']}>保險種類五花八門.....</p> */}
    </div>
    <div id='tab-box' className={style['tab-box']}>
      {
        tabs.map((el, idx) => {
          return <div style={setFontSize(24, scale)} className={[`${style['tab-item']}`, tabIndex === idx ? `${style['tab-item-active']}` : ''].join(' ')}  key={el.title} onClick={e => {
            if (tabIndex === idx) {
              return
            }
            const item = document.getElementById(el.href)
            window.scrollTo({
              'top': item.offsetTop - 114 - 122,
              'behavior': 'auto'
            })
            // document.documentElement.scrollTo(0, tops[idx])
            // setTabIndex(idx)
          }}>
            <img src={assetsUrl + (tabIndex === idx ? el.activeIcon : el.icon)} alt=''/>
            <p style={{color: tabIndex === idx ? '#447EDA' : '#333'}}>{el.title}</p>
          </div>
        })
      }
    </div>
    <div className={style['title-box']} style={setFontSize(40, scale)}>
      <p>不同類型的保險計劃產品 </p>
      <p>讓您及家人得到最全面的保障</p>
    </div>
    {/* 醫療及危疾 */}
    <div id='medical-illness-box' className={style['medical-illness-box']}>
      <Category data={ medicalIllness } scale={scale}/>
    </div>
    {/* 儲蓄 */}
    <div id='savings-box' className={style['savings-box']}>
      <div className={style['main']}>
        <div className={style['top-box']}>
          <img src={assetsUrl + 'images/icon_home_savings_s.png'} alt=''/>
          <p style={setFontSize(40, scale)}>儲蓄</p>
        </div>
        <div className={style['content']}>
          <div className={style['tag-box']}>
            {
              ['儲蓄保險主要提供：1.身故賠償保障; 2.保證及預期儲蓄回報。', '各壽險產品的定位不盡相同，有些保障較重，有些強調財富增值。'].map(el => {
                return <div className={style['tag-item']} key={el}>
                  <span></span>
                  <p style={setFontSize(20, scale)}>{el}</p>
                </div>
              })
            }
          </div>
          <div className={style['bottom-box']}>
            <div className={style['bottom-item']}>
              <p className={style['title']} style={setFontSize(26, scale)}>短期儲蓄</p>
              <div className={style['desc-box']}>
                <span></span>
                <p style={setFontSize(20, scale)}>{'短期儲蓄保險的保單年期較短，幾年便期滿。\n (具體期限請見保險合約條款約定)'}</p>
              </div>
              <div className={style['desc-box']}>
                <span></span>
                <p style={setFontSize(20, scale)}>{'固定的保證回報，而回本期較短。\n (具體期限請見保險合約條款約定)'}</p>
              </div>
            </div>
            <div className={style['bottom-item']}>
              <p className={style['title']} style={setFontSize(26, scale)}>終身儲蓄</p>
              <div className={style['desc-box']}>
                <span></span>
                <p style={setFontSize(20, scale)}>終身儲蓄保險有回報(包含保證及非保證回報)。</p>
              </div>
              <div className={style['desc-box']}>
                <span></span>
                <p style={setFontSize(20, scale)}>人壽保障至終身。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* 人壽 */}
    <div id='life-box' className={style['medical-illness-box']}>
      <Category data={ life } scale={scale}/>
    </div>
    {/* 年金 */}
    <div id='annuity-box' className={style['savings-box']}>
      <div className={style['main']}>
        <div className={style['top-box']}>
          <img src={assetsUrl + 'images/icon_home_annuity_s.png'} alt=''/>
          <p style={setFontSize(40, scale)}>年金</p>
        </div>
        <div className={style['content']}>
          <div className={style['tag-box']}>
            {
              ['讓您在將來退休時，提供豐盛而安穩的收入來源的一部分。', '年金可分為即期年金和延期年金。'].map(el => {
                return <div className={style['tag-item']} key={el}>
                  <span></span>
                  <p style={setFontSize(20, scale)}>{el}</p>
                </div>
              })
            }
          </div>
          <div className={style['bottom-box']}>
            <div className={style['bottom-item']}>
              <p className={style['title']} style={setFontSize(26, scale)}>延期年金</p>
              <p className={style['sub-title']} style={setFontSize(20, scale)}>*稅務扣減 (保單持有人最高可獲每年HK$60,000的免稅額)</p>
              <div className={style['desc-box']}>
                <span></span>
                <p style={setFontSize(20, scale)}>延期是指一筆過或保費繳付期屆滿後，在指定入息期開始提取年金。</p>
              </div>
            </div>
            <div className={style['bottom-item']} style={{justifyContent: 'center'}}>
              <p className={style['title']} style={{marginTop: '0', ...setFontSize(26, scale)}}>即期年金</p>
              <div className={style['desc-box']}>
                <span></span>
                <p style={setFontSize(20, scale)}>即期是指一筆過繳保費後，隨即領取年金。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* 一般保險 */}
    <div id='general-insurance-box' className={style['general-insurance-box']}>
      <div className={style['general-insurance-bg']}></div>
      <div className={style['main']}>
        <p className={style['title']} style={setFontSize(40, scale)}>一般保險</p>
        <div className={style['insurance-list']}>
          {
            generalInsurance.map((el) => {
              return <div className={style['insurance-item']} key={el.title}>
                <img src={el.icon} alt='' />
                <p style={setFontSize(26, scale)} className={style['name']}>{el.title}</p>
                <p style={setFontSize(20, scale)} className={style['desc']}>{el.desc}</p>
              </div>
            })
          }
        </div>
      </div>
    </div>
    {/* 其他 */}
    <div id='other-insurance-box' className={style['other-insurance-box']}>
      <div className={style['medical-illness-box']}>
        <Category data={ other } scale={scale}/>
      </div>
    </div>
    <Spin spinning={loading} style={{maxHeight: 'none'}}>
        <div className={style['contact-now']}>
          <div className={style['bg-box']}>
            <div className={style['content']}>
              <p className={style['title']} style={setFontSize(58, scale)}>聯絡我們</p>
              <p className={style['sub-title']} style={setFontSize(40, scale)}>{'專業團隊，以客為先 \n「幫你揀‧助你賠」'}</p>
            </div>
          </div>
          <div className={style['form-container']}>
            <div className={style['tabs-box']} style={setFontSize(26, scale)}>
              <p>約見 </p>
              <p>方案專家</p>
            </div>
            <Form
            initialValues={initialValues}
            scrollToFirstError={true}
            onFinish={(values) => {
              console.log('onFinish：', values)
              if (!isLegalHKCommunication(values.phone)) {
                message.error('请填寫正确的手機號碼')
              } else if ( values.email.length > 0 && !isLegalEmail(values.email)) {
                message.error('请填寫正确的電郵地址')
              } else {
                setLoading(true)
                submitCustomerReserve({
                  surname: values.surname,
                  name: values.name,
                  sex: values.gender === '女士'  ? 0 : 1,
                  type: 0,
                  mobile: '+852' + values.phone,
                  email: values.email,
                  contactTime: values.idealContactTime,
                  requirement: values.applyinfo				
                }).then(res => {
                  setLoading(false)
                  if (res.data.code === 0) {
                    message.success('提交成功')
                  } else {
                    message.error(res.data.message)
                  }
                }).catch(e => {
                  setLoading(false)
                  message.error(e.message)
                })
              }
            }}>
              <Form.Item style={{ marginTop: '24px', marginBottom: 0}}>
                <Input.Group compact>
                  <Form.Item 
                    name="surname"
                    style={{ width: '34.5%', marginRight: '2.5%'}}
                    rules={[{ required: true, message: '*请填寫您的姓' }]}
                  >
                    <Input size={'large'} maxLength={25} placeholder="姓(必填)"/>
                  </Form.Item>
                  <Form.Item
                    style={{ width: '63%' }}
                    name="name"
                    rules={[{ required: true, message: '*请填寫您的名' }]}>
                    <Input size={'large'} maxLength={25} placeholder="名(必填)"/>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item name="gender" 
                rules={[{ required: true, message: '*请选择您的性別' }]}>
                <Select size={'large'} placeholder="性別(必填)" suffixIcon={<img src={selectArrow} alt=''style={{height: '8px'}}/>} >
                  <Select.Option value="先生">先生</Select.Option>
                  <Select.Option value="女士">女士</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item style={{ marginBottom: '0' }}>
                <Input.Group compact>
                  <Form.Item 
                    name="areaCode"
                    style={{ width: '34.5%', marginRight: '2.5%'}}
                  >
                    <Select size={'large'} suffixIcon={<img src={selectArrow} alt='' style={{height: '8px'}}/>}>
                      <Select.Option value="+852">+852</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ width: '63%' }}
                    name="phone"
                    rules={[{ required: true, message: '*请填寫您的手機號碼' }]}>
                    <Input size={'large'} type='number' maxLength={25} placeholder="手機號碼(必填)"/>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item 
                style={{marginTop: '0px', marginBottom: '0' }}>
                  <Input.Group compact>
                    <Form.Item 
                      name="email" 
                      style={{ width: '100%'}}
                      >
                      <Input size={'large'} maxLength={254} placeholder="電郵地址"/>
                    </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item 
                style={{ marginTop: '0px', marginBottom: '0'}}>
                  <Input.Group compact>
                    <Form.Item 
                      name="idealContactTime" 
                      style={{ width: '100%'}}
                      >
                      <Input size={'large'} maxLength={60} placeholder="理想聯絡時間"/>
                    </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item 
                name="applyinfo" 
                style={{ marginTop: '0px' }}>
                <Input.TextArea maxLength={500} placeholder="預約信息"  onChange={ e => {
                  
                }}/>
              </Form.Item>
              <div className={style['protocol-box']}>
                本人提交此表格表示本人已細閱並同意慧擇香港的<a href="/privacy-policy" target="_blank">私隱政策</a>、<a href="/terms-of-use" target="_blank">使用條款</a>及<a href="/personal-information" target="_blank">個人資料收集聲明</a>。我們的專家會盡快聯絡閣下。
              </div>
              <Form.Item style={{marginTop: '24px'}}>
                <div className={style['submit-btn']} >
                  <Button style={setFontSize(18, scale)} type="primary" htmlType="submit">提交</Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    {/* <div className={style['main--']}>
      <div className={style['select-compare']}>
        <span className={style['title']}>保險比較</span>
        <div className={style['form-container']}>
          <span className={style['form-label']}>我想比較</span>
          <Form style={{width: '100%'}}
            onFinish={onFinish}
          >
            <Form.Item
              name="type"
              rules={[{ required: true, message: '請選擇你想比較的保險分類' }]}
            >
              <Select size={'large'} placeholder='請選擇你想比較的保險分類' suffixIcon={<img src={selectArrow} alt=''/>} onChange={value => {
                console.log(value)
              }}>
                {
                  category.map((el, idx) => {
                    return <Select.Option value={idx} key={idx}>{el.name}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item style={{ marginTop: '32px',textAlign: 'center'}}>
              <Button style={btnStyle} htmlType="submit">立即比較</Button>
            </Form.Item>
          </Form>
        </div>
        <div className={style['annuity-insurers']}>
          <Swiper
            style={{width: '100%', height: '100%'}}
            initialSlide={0}
            autoplay={{
              delay: 1000,
              disableOnInteraction: false
            }}
            loop={true}
            spaceBetween={16}
            slidesPerView={5}
          >
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
            <SwiperSlide><div className={style['swiper-slide-item']}></div></SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div> */}
    <Footer />
  </div>
}

export function Category({data, scale}){
  return <div className={style['category-container']}>
    <div className={style['top-box']} style={{marginBottom: data.tags.length > 0 ? '63px' : '32px'}}>
      <img src={data.top.icon} alt=''/>
      <p style={setFontSize(40, scale)}>{data.top.title}</p>
    </div>
    <div className={style['tag-box']}>
      {
        data.tags.map(el => {
          return <div className={style['tag-item']} key={el}>
            <span style={{display: data.tags.length > 1 ? 'inline-block' : 'none'}}></span>
            <p style={setFontSize(20, scale)}>{el}</p>
          </div>
        })
      }
    </div>
    <div className={style['bottom-box']}>
      {
        data.bottom.map((el, idx) => {
          return <ListContent key={el.title} title={el.title} subTitle={el.sub_title} list={el.list} scale={scale}/>
        })
      }
    </div>
  </div>
}

export function ListContent({title, subTitle='', list=[], scale}){
  return <div className={style['list-container']}>
    <div className={style['list-title']}>
      <p style={{marginBottom: subTitle.length > 0 ? '0' : '24px', ...setFontSize(26, scale)}}>{title}</p>
      <p style={{display: subTitle.length > 0 ? 'block' : 'none', ...setFontSize(20, scale)}}>{subTitle}</p>
    </div>
    <div className={style['list-box']}>
    {
      list.map((el) => {
        return <div className={style['list-item']} key={el}>
          <span></span>
          <p style={setFontSize(20, scale)}>{el}</p>
        </div>
      })
    }
    </div>
  </div>
}