/* global Frames */
import { assetsUrl } from '../../utils/constants'
const generateLogos = () =>{
  let logos = {};
  logos["card-number"] = {
    src: "card",
    alt: "card number logo",
  };
  logos["expiry-date"] = {
    src: "exp-date",
    alt: "expiry date logo",
  };
  logos["cvv"] = {
    src: "cvv",
    alt: "cvv logo",
  };
  return logos;
}

var errors = {};
errors["card-number"] = "Please enter a valid card number";
errors["expiry-date"] = "Please enter a valid expiry date";
errors["cvv"] = "Please enter a valid cvv code";

export const onValidationChanged = (event) =>{
  var e = event.element;
  if (event.isValid || event.isEmpty) {
    if (e === "card-number" && !event.isEmpty) {
      showPaymentMethodIcon();
    }
    setDefaultIcon(e);
    clearErrorIcon(e);
    clearErrorMessage(e);
  } else {
    if (e === "card-number") {
      clearPaymentMethodIcon();
    }
    setDefaultErrorIcon(e);
    setErrorIcon(e);
    setErrorMessage(e);
  }
}

export const  clearErrorMessage = (el) => {
  var selector = ".error-message__" + el;
  var message = document.querySelector(selector);
  message.textContent = "";
}

export const  clearErrorIcon = (el) => {
  var logo = document.getElementById("icon-" + el + "-error");
  logo.style.removeProperty("display");
}

export const  showPaymentMethodIcon = (parent, pm) => {
  if (parent) parent.classList.add("show");
  var logo = document.getElementById("logo-payment-method");
  if (pm) {
    var name = pm.toLowerCase();
    logo.setAttribute("src", assetsUrl + "images/" + name + ".svg");
    logo.setAttribute("alt", pm || "payment method");
  }
  logo.style.removeProperty("display");
}

export const  clearPaymentMethodIcon = (parent) => {
  if (parent) parent.classList.remove("show");

  var logo = document.getElementById("logo-payment-method");
  logo.style.setProperty("display", "none");
}

export const setErrorMessage = (el) =>{
  var selector = ".error-message__" + el;
  var message = document.querySelector(selector);
  message.textContent = errors[el]
}

export const setDefaultIcon = (el) => {
  var selector = "icon-" + el;
  var logo = document.getElementById(selector);
  logo.setAttribute("src", assetsUrl + "images/" + generateLogos()[el].src + ".svg");
  logo.setAttribute("alt", generateLogos()[el].alt);
}

export const setDefaultErrorIcon = (el)  => {
  var selector = "icon-" + el;
  var logo = document.getElementById(selector);
  logo.setAttribute("src", assetsUrl +  "images/" + generateLogos()[el].src + "-error.svg");
  logo.setAttribute("alt", generateLogos()[el].alt);
}

export const setErrorIcon =(el) => {
  var logo = document.getElementById("icon-" + el + "-error");
  logo.style.setProperty("display", "block");
}

export const  paymentMethodChanged = (event) => {
  var pm = event.paymentMethod;
  let container = document.querySelector(".icon-container.payment-method");

  if (!pm) {
    clearPaymentMethodIcon(container);
  } else {
    clearErrorIcon("card-number");
    showPaymentMethodIcon(container, pm);
  }
}
