export const productTitle = [
  {
    id: 0,
    name: '保障詳情',
    active: true,
  },
  {
    id: 1,
    name: '產品解讀',
    active: true,
  },
  {
    id: 2,
    name: '典型案例',
    active: true,
  },
  {
    id: 3,
    name: '投保須知',
    active: false,
  },
  {
    id: 4,
    name: '理賠指引',
    active: false,
  },
  {
    id: 5,
    name: '常見問題',
    active: true,
  }
]
export const claimsSettlementFlow = [
  {
    id: 1001,
    iconImg: 'images/icon_communication_requirements.png',
    name: '1.報案'
  },
  {
    id: 1002,
    iconImg: 'images/icon_develop_a_proposa.png',
    name: '2.提交理賠資料'
  },
  {
    id: 1003,
    iconImg: 'images/icon_assist_to_insure.png',
    name: '3.理賠資料審核'
  },
  {
    id: 1004,
    iconImg: 'images/icon_continuous_service.png',
    name: '4.領取賠款'
  },
]
