import http from './http'
import { K_LOGIN_TOKEN } from '../utils/constants'
import { getStore } from '../utils/index'
const getActivityList = (data) => {
  return http.post('activity/list', data)
}
const getActivityDetail = (data) => {
  return http.post('activity/detail', data)
}
const getActivityCategoryList = () => {
  return http.post('activity/categoryList', {})
}

const getNewsList = (data) => {
  return http.post('news/list', data)
}

const getNewsDetail = (data) => {
  return http.post('news/detail', data)
}

const getBannerList = () => {
  return http.post('promo/list', {})
}

const submitCustomerReserve = (data) => {
  return http.post('customerReserve/submit', data)
}

// 注册，发送验证码
const sendRegisterCode = (data) => {
  return http.post('user/sendRegisterCode', data)
}

// 手机号注册
const registerByMobile = (data) => {
  return http.post('user/registerByMobile', data)
}

// 手机号登录
const loginByMobile = (data) => {
  return http.post('user/login', data)
}

const thirdPartyLogin = (data) => {
  return http.post(data.type === 'google' ? '/user/loginWithGoogle' : '/user/loginWithFacebook', data.param)
}

const forgetPassword = (data) => {
  return http.post('user/sendForgetPassCode', data)
}

const getUserInfo  = () => {
  const token = getStore(K_LOGIN_TOKEN)
  return http.post('user/detail', {token})
}

const getInsuranceCategory  = (data) => {
  return http.post('common/insuranceCategoryList', data)
}

const getInsuranceBrandList  = () => {
  return http.post('common/insuranceBrandList', {})
}

const searchInsuranceProduct  = (data) => {
  return http.post('insurance/filter', data)
}

const searchInsuranceProductList  = (data) => {
  return http.post('search', data)
}

const uploadInsurancePolicy  = (data) => {
  const token = getStore(K_LOGIN_TOKEN)
  return http.post('insurancePolicyCustody/upload', data, { 'Content-Type': 'multipart/form-data', token})
}

const compareInsurance = (data) => {
  return http.post('insurance/compare', data)
}

const getInsuranceAttrList = (data) => {
  return http.post('insurance/filtrate/attrList', data)
}
// 发送提交保单资料验证码
const sendInsuredVerifyCode = (data) => {
  return http.post('insurance/sendApplicationVerifyCode', data)
}

//提交保单
const submitGiftInsurancePolicy = (data) => {
  return http.post('insurance/submitInsuranceApplicationForm', data)
}

// 生成保险订单
const createOrder = (data) => {
  return http.post('order/create', data)
}

// 支付保费
const applyPay = (data) => {
  return http.post('pay/applyPay', data)
}

// 保险试算
const zurichInsuranceTrialCalculation = (data) => {
  return http.post('insurance/quote', data)
}

// 旅行地区列表
const getTravelDestination = (data) => {
  return http.post('common/travelDestination', data)
}

// 地址
const queryAddress = (data) => {
  return http.post('', data)
}

// 保險產品詳情
const getInsuranceProductDetail = (data) => {
  return http.post('insurance/productDetail', data)
}

//发送專屬定制手机验证码
const sendInsuranceCustomerVerifyCode = (data) => {
  return http.post('customerSolution/sendCustomerSolutionVerifyCode', data)
}

// 提交专属定制
const submitInsuranceCustomer = (data) => {
  return http.post('customerSolution/submit', data)
}

// 提交理賠及客服
const submitCustomerClaimsService = (data) => {
  return http.post('customerClaimsService/submit', data)
}

// 上傳理賠及客服保單
const uploadCustomerClaimsServiceFiles = (data) => {
  return http.post('customerClaimsService/upload', data)
}

// 獲取理賠及客服保單的聯絡時間
const getCustomerClaimsServiceContactTime = (data) => {
  return http.post('customerClaimsService/contactTime', data)
}

export {
  getActivityList,
  getActivityCategoryList,
  getActivityDetail,
  getNewsList,
  getNewsDetail,
  getBannerList,
  submitCustomerReserve,
  sendRegisterCode,
  registerByMobile,
  loginByMobile,
  thirdPartyLogin,
  forgetPassword,
  getUserInfo,
  getInsuranceCategory,
  uploadInsurancePolicy,
  getInsuranceBrandList,
  searchInsuranceProduct,
  searchInsuranceProductList,
  compareInsurance,
  getInsuranceAttrList,
  sendInsuredVerifyCode,
  submitGiftInsurancePolicy,
  createOrder,
  applyPay,
  zurichInsuranceTrialCalculation,
  getTravelDestination,
  queryAddress,
  getInsuranceProductDetail,
  sendInsuranceCustomerVerifyCode,
  submitInsuranceCustomer,
  submitCustomerClaimsService,
  uploadCustomerClaimsServiceFiles,
  getCustomerClaimsServiceContactTime,
}
