import React, { useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'
import {Spin, message, Empty} from 'antd'
import { Footer} from '../../components'
import {useParams } from 'react-router-dom'
import styles from'./ActivityDetail.module.scss'
import { assetsUrl } from '../../utils/constants'
import { fetchActivityDetail } from '../../redux/activityDetail/slice'
import { useSelector, useDispatch } from 'react-redux'
import { setFontSize } from '../../utils'
export function ActivityDetail () {
  message.config({
    maxCount: 1
  })
  const scale = useSelector(state => state.font.scale)
  const { id } = useParams()
  const [bgCover, setBgcover] = useState(assetsUrl+'images/icon_news_detail_bg_normal.png')
  const [bgCoverStyle, setBgcoverStyle] = useState({
    top: '14px',
    left: '14px',
    width: '938px',
    height: '618px'
  })
  const loading = useSelector(state => state.activityDetail.loading)
  const activityDetail = useSelector(state => state.activityDetail.data)
  const errMsg = useSelector((state) => state.activityDetail.errMsg)
  console.log(errMsg)
  if (errMsg) {
    message.error(errMsg) 
  }
  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(fetchActivityDetail(id))
  }, [dispatch, id])
  return (
    <div id='activity-detail-container' className={styles['activity-detail-container']}>
      {
        loading === false ? ( activityDetail ?
          <div className={styles['main']}>
            <div className={styles['detail-content']}>
              <div className={styles['activity-cover-box']}>
                <LazyLoad height={650} once>
                  <img className={styles['activity-cover']} src={activityDetail.detailImageUrl} alt='' />
                </LazyLoad>
              </div>
              <p className={styles['activity-title']} style={setFontSize(26, scale)}>{activityDetail.title}</p>
              <div className={styles['detail-desc']} dangerouslySetInnerHTML={{__html: activityDetail.detailContent}}></div>
            </div>
            <div className={styles['activity-bottom']}>
              <div className={styles['bottom-bg']}></div>
              <div className={styles['bottom-content']}>
                <img className={styles['bg-cover']} style={bgCoverStyle} src={bgCover} alt=''/>
                <div className={styles['activity-info']} onMouseEnter={e => {
                    setBgcover(assetsUrl+'images/icon_news_detail_bg_shadow.png')
                    setBgcoverStyle({
                      top: '0px',
                      left: '0px',
                      width: '968px',
                      height: '646px'
                    })
                  }} onMouseLeave={e => {
                    setBgcoverStyle({
                      top: '14px',
                      left: '14px',
                      width: '938px',
                      height: '618px'
                    })
                    setBgcover(assetsUrl+'images/icon_news_detail_bg_normal.png')
                  }}>
                  <img className={styles['activity-cover']} src={activityDetail.coverImageUrl} alt='' />
                  <p className={styles['title']} style={setFontSize(40, scale)}>{activityDetail.title}</p>
                  <p className={styles['activity-date']} style={setFontSize(20, scale)}>{activityDetail.activityDate}</p>
                  <div className={styles['info-box']}>
                    <img src={assetsUrl + 'images/icon_active_detail_clock.png'} alt=''/>
                    <p style={setFontSize(20, scale)}>{activityDetail.activityTime}</p>
                  </div>
                  <div className={styles['info-box']}>
                    <img src={assetsUrl + 'images/icon_active_detail_location.png'} alt=''/>
                    <p style={setFontSize(20, scale)}>{activityDetail.address}</p>
                  </div>
                  <div className={styles['info-box']} >
                    <img src={assetsUrl + 'images/icon_active_detail_bag.png'} alt=''/>
                    <p className={styles['highlight-quota']} style={setFontSize(20, scale)}>{activityDetail.priceNm}</p>
                  </div>
                  <div className={styles['info-box']} style={{display: activityDetail.limitCountNm ? 'flex' : 'none', ...setFontSize(20, scale)}}>
                    <img src={assetsUrl + 'images/icon_active_detail_people.png'} alt=''/>
                    <div className={styles['limit-people']}>
                      <p style={setFontSize(26, scale)}>{activityDetail.limitCountNm}</p>
                      <span style={setFontSize(20, scale)}>位</span>
                    </div>
                  </div>
                  <a href={activityDetail.enterUrl} target="view_window" style={{pointerEvents: activityDetail.enterUrl ? 'auto' : 'none', ...setFontSize(20, scale)}} className={styles['sign-up-btn']} >立即報名</a>
                </div> 
              </div>
            </div>
          </div> : <Empty style={{height: '45vh', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}} description={false} />) : <div className={styles['loading-box']}> <Spin /></div>
      }
      <Footer />
    </div>
  )
}