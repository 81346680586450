import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { rightArrow, selectArrow } from '../../assets'
import { Form, Input, Select, Button, message } from 'antd'
import { Footer} from '../../components'
import style from './Consultant.module.scss'
import { isLegalEmail } from '../../utils'
import { submitCustomerReserve } from '../../services/api'
export function Consultant () {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const history = useHistory()
  const initialValues = {
    name: '',
    areaCode: '+852',
    phone: '',
    email: '',
    contactTime: 'ALL',
    needs: ''
  }
  const btnStyle = {
    width: '148px',
    height: '46px',
    background: '#F2A915',
    lineHeight: '14px',
    fontSize: '14px',
    fontFamily: 'SourceHanSansCN-Medium',
    fontWeight: '500',
    color: '#FFFFFF',
    borderColor: 'transparent'
  }
  const [opacity, setOpacity] = useState(1.0)
  const onFinish = (values) => {
    console.log('Success:', values);
    if (values.email.length > 0) {
      if (!isLegalEmail(values.email)) {
        message.error('请填寫您的正確的電郵地址')
        return
      }
    }
    submitCustomerReserve({
      username: values.name,
      mobile: values.areaCode + values.phone,
      email: values.email,
      contactTime: values.contactTime,
      requirement: values.needs				
    }).then(res => {
      if (res.data.code === 0) {
        setOpacity(0.5)
        message.success('表格提交成功')
      } else {
        message.error(res.data.message)
      }
    }).catch(e => {
      message.error(e.message)
    })
  }; 
  return <div className={style['consultant-container']}>
    {/* <Header /> */}
    <div className={style['nav-bar']}>
      <div className={style['nav-back']}>
        <span onClick={() => history.goBack()}>聯繫我們</span>
        <img src={rightArrow } alt=''/>
        <span>約見顧問</span>
      </div>
    </div>
    <div className={style['main']}>
      <p className={style['title']}>約見顧問</p>
      <div className={style['form-container']}>
        <Form
          initialValues={initialValues}
          onFinish={onFinish}>
          <div className={style['input-label']}>
            <div className={style['label-box']}>
              <p>姓名</p>
              <span>*</span>
            </div>
            <div className={style['label-box']}>
              <p>電話號碼</p>
              <span>*</span>
            </div>
          </div>
          <Form.Item style={{ marginBottom: '0' }}>
            <Input.Group compact>
              <Form.Item
                name="name"
                rules={[{ required: true, message: '请填寫您的姓名' }]}
                style={{ width: '49.4%', marginRight: '1.3%'}}
              >
                <Input disabled={opacity === 0.5} size={'large'} placeholder='名字' />
              </Form.Item>
              <Form.Item
                name="areaCode"
                style={{ width: '15.3%', marginRight: '1.1%'}}
              >
                <Select disabled={opacity === 0.5} size={'large'} suffixIcon={<img src={selectArrow} alt=''/>} onChange={value => {}}>
                  <Select.Option value="+852">+852</Select.Option>
                  <Select.Option value="+86">+86</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name='phone'
                rules={[{ required: true, message: '请填寫您的電話號碼' }]}
                style={{ width: '32.9%'}}
              >
                <Input disabled={opacity === 0.5} size={'large'} type='number' placeholder='電話號碼' />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div className={style['input-label']}>
            <div className={style['label-box']}>
              <p>電郵地址</p>
              <span style={{display: 'none'}}></span>
            </div>
            <div className={style['label-box']}>
              <p>理想聯絡時間</p>
              <span style={{display: 'none'}}></span>
            </div>
          </div>
          <Form.Item style={{ marginBottom: '0' }}>
            <Input.Group compact >
              <Form.Item
                name="email"
                style={{ width: '49.4%', marginRight: '1.3%', marginBottom: '0'}}
              >
                <Input disabled={opacity === 0.5} size='large' placeholder='電郵地址（非必須填寫）' />
              </Form.Item>
              <Form.Item
                name="contactTime"
                style={{ width: '49.3%', marginBottom: '0'}}
              >
                <Select disabled={opacity === 0.5} size={'large'} suffixIcon={<img src={selectArrow} alt=''  style={{height: '8px'}}/>} onChange={value => {}}>
                  <Select.Option value="ALL">任何時間</Select.Option>
                  <Select.Option value="MORNING">早上</Select.Option>
                  <Select.Option value="NOON">中午</Select.Option>
                  <Select.Option value="AFTERNOON">下午</Select.Option>
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div className={style['working-time']}>
            <p>辦公時間：星期一至五，9：00-18：00</p>
          </div>
          <div className={style['needs-box']}>
            <p>我想談談....</p>
          </div>
          <Form.Item
            name="needs"
          >
            <Input.TextArea disabled={opacity === 0.5} showCount maxLength={500} placeholder='在這裡輸入您的需求（非必須填寫）' />
          </Form.Item>
          <div className={style['desc-title']}>
            <span>*</span>
            <p>電郵地址</p>
          </div>
          <div className={style['desc-content']}>
            提交此表格即表示本人同意慧擇香港使用我以上的個人資料以進行有關慧擇香港的保險產品的直接促銷，否則慧擇香港不可使用上述資料作任何推廣及促銷用途。本人亦已細閱及同意有關<Link className={style['intro-title']} to="/privacy-policy">私隱政策聲明</Link>及<Link className={style['intro-title']} style={{pointerEvents: 'none'}}>收集個人資料聲明</Link>
          </div>
          <Form.Item style={{ marginTop: '66px',marginBottom: 0, textAlign: 'center'}}>
            <Button disabled={opacity === 0.5} style={{...btnStyle, opacity: opacity}} htmlType="submit">{opacity === 1 ? '即刻獲取免費諮詢' : '表格提交成功'}</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
    <Footer />
  </div>
}