import React, {useEffect} from 'react'
import styles from './PrivacyPolicy.module.scss'
import { useSelector } from 'react-redux'
import { setFontSize } from '../../utils'
export function PrivacyPolicy () {
  const scale = useSelector(state => state.font.scale)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.main} style={setFontSize(14, scale)}>
        <h1 style={setFontSize(28, scale)}>私隱政策</h1>
        <p style={{marginTop: '30px'}}>感謝您對我們產品服務的使用和信任！為更好的提供服務，<span style={{fontWeight: '700'}}>Huize Hong Kong Insurance Broker Limited</span>（慧擇香港保險經紀有限公司）（以下簡稱“<strong>慧擇香港</strong>”、“<strong>我們</strong>”或“<strong>本公司</strong>”）將按照合法、合理、安全、保密的原則，依據資訊安全和保險相關法律法規要求，妥善收集、保存、使用和共用您的個人資料。</p>
        <p style={{marginTop: '30px'}}>在您投保、使用我們的平台服務或參加我們的活動前，我們強烈建議您務必仔細閱讀本私隱政策（以下簡稱“<strong>本政策</strong>”）。您使用本公司的平台和服務將被視為同意接受本政策及其所有修訂並受其約束。</p>
        <p style={{marginTop: '30px'}}>本政策適用於慧擇香港運營的網站huize.com.hk及移動應用程式 （以下簡稱“<strong>平台</strong>”）所有產品和服務。</p>
        <p style={{marginTop: '30px'}}>最後修訂及生效日期：2021年8月27日</p>
        <p style={{marginTop: '30px'}}>本政策將幫助您瞭解以下內容：</p>
        <ul>
            <li><strong>1、我們收集您的哪些個人資料；</strong></li>
            <li><strong>2、我們如何使用您的個人資料；</strong></li>
            <li><strong>3、我們如何共用、轉讓、公開披露您的個人資料；</strong></li>
            <li><strong>4、我們如何保存和保護您的個人資料；</strong></li>
            <li><strong>5、您的權利；</strong></li>
            <li><strong>6、本政策如何更新；</strong></li>
            <li><strong>7、如何聯繫我們。</strong></li>
        </ul>
        <p style={{marginTop: '30px'}}><strong>1、我們收集您的哪些個人資料</strong></p>
        <p style={{marginTop: '20px'}}>您毋須提供任何個人資料以瀏覽我們的平台。當您投保、使用我們平台的服務或參加我們的活動，則我們會收集您的個人資料使我們能夠為您提供服務。如您拒絕提交相關資料，本公司將不能向您提供特定服務。根據我們需要為您提供的不同服務，我們會收集的個人資料包括 （但不限於）：</p>
        <ul>
          <li style={{marginLeft: '15px'}}>•	暱稱，性別，聯絡資料 （如電話號碼、電郵地址），會員賬戶識別號和登入密碼等；</li>
          <li style={{marginLeft: '15px'}}>•	投保必要資料，例如身份證件資訊（包括姓名，證件號碼，生日等），家庭資料，居住地資料，婚姻狀況，工作資料，受益人資料等；</li>
          <li style={{marginLeft: '15px'}}>•	不能辨別個人身份的資料，例如使用行為、設備資訊，用戶偏好、線上活動數據；</li>
          <li style={{marginLeft: '15px'}}>•	與慧擇香港或慧擇香港員工的溝通記錄。</li>
        </ul>
        <p style={{marginTop: '20px'}}>同時，任何由您傳送或登載於我們平台任何部份的問題、意見、建議或資料，除個人資料外，均視作以非保密及非專有資料的形式自願提供予本公司。我們有權自行將該等資料在其他地方使用、複製、披露、傳送、轉讓予協力廠商、刊載及/或登載。</p>
        <p style={{marginTop: '20px'}}><strong>如您投保、使用我們的平台的服務或參加我們的活動，我們將會收集您提交的個人資料，更詳細的約定請參見我們的個人資料收集聲明。網址： </strong></p>
        <a style={{textDecoration: 'underline'}} href='/personal-information' target="_blank"><strong>https://www.huize.com.hk/personal-information 。</strong></a>
        <p style={{marginTop: '30px'}}><strong>2、我們如何使用您的個人資料</strong></p>
        <p style={{marginTop: '20px'}}>我們收集個人資料，為了包括但不限於以下目的：</p>
        <ul>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>處理、評估和確定客戶對產品及／或服務的申請或要求；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>執行、維持、管理和運作向客戶提供的產品及／或服務；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>確認客戶身份並識別客戶；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>確認所收集資訊的準確性；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>加深瞭解客戶的財務狀況、評估客戶申請、評估慧擇香港所承擔的風險並審核提交給慧擇香港的索償申請；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>與客戶提出、針對客戶提出或在其他方面涉及客戶的、與任何產品及／或服務相關的任何索賠有關的任何目的，其中包括但不限於提出該等索賠、就其進行辯護、分析、調查、處理、評估、確定和應對；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>根據客戶在慧擇香港持有的帳戶的條款和條件提供投資管理服務、交易和顧問服務、託管服務和其他服務；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>履行與產品及／或服務相關的任何職責和活動，包括但不限於市場推廣、審計、報告、研究、分析、再保險以及一般服務和維持網上及其他服務（例如臉書）；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>為客戶研究及／或設計產品及／或服務，宣傳、改進和改善產品及／或服務的提供；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>開展核對程式；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>根據對慧擇香港或慧擇集團任何成員（無論在香港境內還是境外）有約束力或對其適用的任何法律、法規、規章、守則、指引或指南的規定進行披露，包括但不限於向當地或外國的監管機構、政府機構、諸如保險公司聯會或協會等公認行業組織（無論在香港境內還是境外）、信貸資料服務機構或審計機構進行披露；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>由於慧擇香港或慧擇集團任何成員在相關當地或外國監管機構、政府機構、或公認行業組織（無論在香港境內還是境外）所在司法管轄區的或涉及該等司法管轄區的財務、商業、業務或其他利益或活動而由慧擇香港或慧擇集團任何成員承擔或施加給其的、與該等當地或外國監管機構、政府機構、或公認行業組織之間的任何合同、其他承諾或安排； </li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>用於經營目的（每項均包括行為分析以及對與慧擇集團之間總體關係的評估，其中包括為遵守關於在慧擇集團內部共用資料和資訊的任何義務、要求、政策、程式、措施或安排而使用該等資料，及／或根據慧擇集團內任何有關遵守制裁或防止或發現洗錢、為恐怖分子提供資金或其他非法活動的計劃而對資料和資訊進行的其他使用），無論是針對資料當事人還是其他人的；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>行使慧擇香港在向客戶提供產品及／或服務方面可能享有的任何權利；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>確定應向客戶支付或客戶應付的任何債務金額，向客戶或向為客戶債務提供任何擔保或承諾的任何人收取和追討任何應收金額；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>使慧擇香港或慧擇集團任何成員的權利或業務的實際或擬議受讓人、承讓人、參與人或次級參與人能對該等轉讓、參與或次級參與擬涉及的交易進行評估；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>慧擇香港提供的任何特定服務或產品中具體規定的目的；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>與上述相關的任何目的（包括尋求專業意見），或根據慧擇香港或慧擇集團任何成員的一般政策進行的、與保險、職業退休計劃、強積金計劃、投資基金、財富管理服務以及慧擇香港或慧擇集團任何成員不時向客戶提供的通知、通告或其他條款和條件中所述的其他金融產品和服務相關的任何其他目的。</li>
        </ul>
        <p style={{marginTop: '30px'}}><strong>電子支付資訊</strong></p>
        <p style={{marginTop: '20px'}}>我們採用符合支付卡產業認證（PCI認證）及支付卡產業資料安全標準（PCI-DSS標準）的協力廠商支付服務供應商以促進管道上的電子交易。當您在我們的平台或與我們系統連結的有關服務進行付款時，支付資訊均由該協力廠商服務商處理及儲存。我們則接收支付結果及有限識別資訊（如僅包含部分信用卡數字的識別資訊）和交易識別號，以符合PCI-DSS合規性要求，並配合相關的規則和法規，以進行交易驗證。除了上述情況外，我們不會收集或存儲任何其它信用卡資訊。</p>
        
        <p style={{marginTop: '30px'}}><strong>Cookies ，日誌檔及行爲分析</strong></p>
        <p style={{marginTop: '20px'}}>我們會在平台中使用cookies 和日誌檔來追蹤您的設備（如設備的瀏覽器資訊，IP位址）和您在平台上的體驗和使用行爲（如瀏覽的頁面網址，瀏覽時間等）。</p>
        <p style={{marginTop: '20px'}}>我們會使用協力廠商的行爲分析服務（例如Google Analytics）去追蹤和分析您在平台中的活動。這些分析服務收集到的資料有可能與其他協力廠商（例如Google）服務共用。</p>
        <p style={{marginTop: '20px'}}>我們、我們的合作夥伴及服務供應商有可能共同使用本公司的cookies及協力廠商cookies （如Google Analytics cookies），以基於您過往在平台中的使用行爲記錄而優化平台和提供合適的資訊。</p>
        <p style={{marginTop: '20px'}}>我們一般會使用自動收集資訊和數據來評估平台的受眾規模、各種資訊的受歡迎程度、用戶變動及活動參與人數等。</p>

        <p style={{marginTop: '30px'}}><strong>協力廠商供應商</strong></p>
        <p style={{marginTop: '20px'}}>如果您選擇透過我們的服務與協力廠商簽訂合約，則您提供給協力廠商的數據將被轉移給該協力廠商，並由協力廠商根據該協力廠商供應商的私隱政策進行管控。因此，您應該直接與他們聯絡以獲取有關其私隱政策的其他資訊，並在簽訂合約之前閱讀所有政策、條款及條件等。對於協力廠商供應商如何使用您的個人資料，我們概不負責。</p>


        <p style={{marginTop: '30px'}}><strong>連接其他網站的連結</strong></p>
        <p style={{marginTop: '20px'}}>我們平台可能會包含連接其他網站或應用程式的連結以提供更好的服務。當您進入這些網站或應用程式和使用其提供的服務時，您的個人資料和/或數據可能會在這些網站或應用程式中被收集。這些網站或應用程式並非由我們運營，故本政策不適用於這些網站或應用程式，不會對您顯露於其他網站或應用程式的個人資料數據而提供保障。本公司強烈建議您應參考該等網站或應用程式的私隱政策。為免存疑，我們不會就您使用該等其他網站或應用程式承擔任何責任或義務。</p>

        <p style={{marginTop: '30px'}}><strong>3、我們如何共用、轉讓、公開披露您的個人資料</strong></p>
        <p style={{marginTop: '20px'}}>在以下情形下，我們有可能需要披露個人資料：</p>
        <ul>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>符合上述收集資料目的之一般工作；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>代表您處理所購買的合作夥伴的產品及服務（例如代表您投保，更新保單，索償等）時，以保密方式向合作夥伴披露必須的資料；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>遵守任何對我們有約束力的法律或監管機構的適用規則、法律條例；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>基於法律目的（包括但不限於配合執法和解決糾紛）；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>如本公司在任何合併、融資、收購、破產、解散、交易或訴訟談判過程中出售、轉讓、剝離或披露其全部或部分業務或資產給另一家公司，則本公司可能會向協力廠商</li>
        </ul>
        <p style={{marginTop: '20px'}}>我們有可能會公開共用非個人資料，例如包括但不限於與交易量、用戶流量、產品績效有關之匿名數據和匯總數據，並有可能會與我們的合作夥伴共用，包括但不限於現有及潛在商業合作夥伴，承辦商，學界等。</p>

        <p style={{marginTop: '30px'}}><strong>4、我們如何保存和保護您的個人資料</strong></p>
        <p style={{marginTop: '30px'}}>我們會採取一切實際可行方法確保個人資料的安全，以及避免個人資料失去或在未經授權或意外的情況下被取用、盜用、修改、刪除、披露或作其他用途。這包括在實物具體上、技術上及程式上的適當安全措施，確保個人資料存放於安全的位置，只有經授權人士才能接觸有關資料。</p>
        <p style={{marginTop: '30px'}}>我們遵循公認的行業標準保障您提交的個人資料和數據。然而，沒有一種百份之一百安全的互聯網傳輸或電子儲存方法。因此，儘管本公司致力於保護您的個人資料和數據免受未經授權的接觸，我們不能保證數據儲存或傳遞絕對安全，亦不能保證沒有未經授權的第三者入侵電腦，竊取資料，或擅自取閱資料。</p>
        <p style={{marginTop: '30px'}}>您必須將您的會員識別碼，密碼或任何作為安全程式的一部分的資訊視為賬戶保密資訊。您不得將其透露給任何協力廠商。如果您將您的手機號碼用於帳戶登錄，您應確保在您的手機號碼被更改或交還流動電訊營運商時，在平台中及時更新您手機號碼。您須負責保護您的帳戶保密資訊。我們對此不承擔任何責任。</p>
        <p style={{marginTop: '30px'}}>您在使用我們產品及服務期間，我們將持續爲您保存您的個人資料。如果您註銷帳號或主動要求刪除上述資料，我們將依據條例及適用的法律法規進行處理。我們也會依據適用的法律法規對不再為使用目的而需要的個人資料進行刪除處理。</p>
        
        <p style={{marginTop: '30px'}}><strong>5、您的權利</strong></p>
        <ul>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>訪問、查詢您在我們平台上留存的個人資料，法律法規規定的例外情況除外。</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>您有權對錯誤或不完整的資訊作出更正或更新，您可以通過我們提供的聯繫方式隨時與我們聯繫，提出更正申請。為保障安全，我們將在您行使更正權前對您的身份進行驗證。</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>刪除您的個人資料或註銷您的賬戶。如未成功投保，您可以向我們提出刪除個人資料的請求，或申請註銷此前註冊的賬戶。您可以通過我們提供的聯繫方式反饋隨時與我們聯繫。</li>
        </ul>
        <p style={{marginTop: '20px'}}>如欲獲取您的個人資料，或對此私隱政策有任何疑問或意見，請聯絡我們的客戶服務部。根據《個人資料（私隱）條例》的規定，本公司有權對查閱或更正個人資料的要求收取合理的費用。</p>
        
        <p style={{marginTop: '30px'}}><strong>6、本政策如何更新</strong></p>
        <p style={{marginTop: '20px'}}>為了反映我們的服務、業務或適用法律的變更，我們可能需要不時修改或更新本私隱政策。我們保留定期修改本私隱政策的權利，恕不另行通知。在對本私隱政策進行任何更改或修訂後，您繼續訪問或使用服務將被視為構成您對修訂後的私隱政策條款的同意。我們將始終將「最後修訂」日期作為私隱政策的一部分。您可以定期查看此頁面，以隨時瞭解我們的最新私隱政策。</p>

        <p style={{marginTop: '30px'}}><strong>7、如何聯繫我們</strong></p>
        <p style={{marginTop: '20px'}}>如您對本政策有任何疑問、意見或建議，可通過以下方式聯繫我們：</p>
        <p style={{marginTop: '10px'}}>電話：<a style={{color: '#000'}}  target="_self" href='tel:+85227899923'>(+852) 2789 9923</a></p>
        <p style={{marginTop: '10px'}}>郵箱：<a style={{color: '#000', textDecoration: 'underline'}} target="_self" href='mailto:admin@huize.com.hk'>admin@huize.com.hk</a></p>
        <p style={{marginTop: '10px'}}>辦事處地址：香港鰂魚涌英皇道979號太古坊多盛大廈24樓2401室慧擇香港保險經紀有限公司一般情況下，我們將在<strong>十五日內</strong>回復</p>
        <p style={{marginTop: '30px'}}>本私隱政策以英文及中文編寫。中英文版本如有歧義，應以中文版本為準。</p>
      </div>
    </div>
  )
}