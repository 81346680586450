// https://connect.facebook.net/en_US/sdk.js
//
export const K_LOGIN_TOKEN = 'k_user_login_token'
export const K_ZURICH_TSP_CALC = 'k_zurich_tsp_calc'
export const K_ZURICH_TAP_CALC = 'k_zurich_tap_calc'
export const K_ZURICH_SINGLE_TRAVEL_PLAN = 'k_zurich_single_travel_plan'
export const K_ZURICH_ANNUAL_TRAVEL_PLAN = 'k_zurich_annual_travel_plan'
export const assetsUrl =  "https://temp-cdn.huixinguanli.cn/static/media/"
export const gapi = 'https://apis.google.com/js/api:client.js'
export const gmapapi = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCqI0IcK8xNwW6jIg6G6NFd-p351ynLOI8'
export const fbapi = 'https://connect.facebook.net/zh_CN/sdk.js#xfbml=1&version=v11.0'
export const google_client_id = '544111296444-pa28mgb1au6i60boc1rpb8fr63brc12c.apps.googleusercontent.com'
export const google_api_key = 'AIzaSyAPe4S5TGyQhddR71TKo21KGPRJhdhGEuQ'
export const checkoutPublicKey = 'pk_c54225b6-e3f7-4d3a-8d5e-c6ea8836b8d6' // pk_test_f1ddc0c3-eac5-46bf-9e19-0c31ee813ad3
