import React, { useEffect, useState, useCallback } from 'react'
import { Footer } from '../../components'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Input, Select, DatePicker, Button, Spin, message } from 'antd'
import styles from './TravelPlusProcess.module.scss'
import { zurichInsuranceTrialCalculation } from '../../services/api'
import { generateRangeNumber, setStore, getStore, removeItem, dealNumber, parseUrlParam } from '../../utils/index'
import { assetsUrl, K_ZURICH_TSP_CALC, K_ZURICH_SINGLE_TRAVEL_PLAN } from '../../utils/constants'
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN'
import scrollIntoView from 'scroll-into-view-if-needed'
import { tableHeader, planLevel, excellentPlan1, excellentPlan2, excellentPlan3, aldultProtection, childProtection, prompt } from './mockup'
export function TravelPlusProcess () {
  const initValues = () => {
    return {
      isOneWayTrip: false,
      productCode: 0,
      planType: 0,
      aldultNo: 0,
      childNo: 0,
      departureDate: moment(),
      returnDate: moment().add(7, 'days'),
      insuredPlan: -1,
      selectChildPlan: false,
      optionChildPlanIndex: 1,
      optionAldultPlanIndex: 2
    }
  }
  const initFormData = () => {
    let initialValues = initValues()
    const data = getStore(K_ZURICH_TSP_CALC)
    if (data) {
      const fd = JSON.parse(data)
      fd.formData.departureDate =  moment(fd.formData.departureDate)
      fd.formData.returnDate = moment(fd.formData.returnDate)
      initialValues = {...fd.formData}
    }
    return initialValues
  }
  const initPemiumValues = () => {
    return {
      p1: {
        grossPremium: "0.00", 
        discountedPremium: "0.00", 
        planName: "P1"
      },
      p2: {
        grossPremium: "0.00", 
        discountedPremium: "0.00", 
        planName: "P2"
      },
      p3: {
        grossPremium: "0.00", 
        discountedPremium: "0.00", 
        planName: "P3"
      },
    }
  }
  const initPemium = () => {
    let value = initPemiumValues()
    const data = getStore(K_ZURICH_TSP_CALC)
    if (data) {
      const fd = JSON.parse(data)
      value = { ...fd.pemium }
    }
    return value
  }
  const history = useHistory()
  const { search, pathname } = useLocation()
  const { categoryId, productId } = parseUrlParam(search)
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({...initValues()})
  const [loading, setLoading] = useState(false)
  const [calcFailed, setCalcFailed] = useState(false)
  const [pemium, setPemium] = useState({...initPemiumValues()})
  const [inputCompleted, setInputCompleted] = useState(false)
  const [excellentPlan, setExcellentPlan] = useState(excellentPlan1)
  const [featuredPlan, setFeaturedPlan] = useState(excellentPlan2)
  const [wishPlan, setWishPlan] = useState(excellentPlan3)
  const calculatePremium = () => {
    if (Number(formData.aldultNo) === 0 && Number(formData.childNo) === 0) {
      return
    }
    setLoading(true)
    const insuranceIssuePolicy = {
      system: {
        productCode: formData.productCode === 0 ? 'TSP' : 'TAP'
      },
      policy: {
        cmntDate: formData.departureDate ?  formData.departureDate.format('DD/MM/YYYY') : moment().format('DD/MM/YYYY'),
        planType: formData.planType === 0 ? 'I' : 'C',
        expiryDate: formData.returnDate ? formData.returnDate.format('DD/MM/YYYY') : moment().add(7, 'days').format('DD/MM/YYYY')
      },
      InsuredPersonADNum: Number(formData.aldultNo),
      InsuredPersonCHNum: Number(formData.childNo)
    }
    zurichInsuranceTrialCalculation({
      insuranceProductChannel: 'zurich_travel_insurance_quote',
      applicationJsonString: JSON.stringify(insuranceIssuePolicy)
    }).then(res => {
      setLoading(false)
      if (res.data.code === 0) {
        if (res.data.data) {
          setCalcFailed(false)
          setPemium(state => {
            return{
              ...state,
              p1:res.data.data.find(el => el.planName === 'P1'),
              p2:res.data.data.find(el => el.planName === 'P2'),
              p3:res.data.data.find(el => el.planName === 'P3'),
            }
          })
        } else {
          setCalcFailed(true)
        }
      } else {
        setCalcFailed(true)
        message.error(res.data.message) 
      }
    })
  }
  useEffect(() => {
    removeItem(K_ZURICH_SINGLE_TRAVEL_PLAN)
    window.scrollTo(0, 0)
    window.addEventListener('beforeunload', onBeforeunload);
    console.log(initFormData())
    setFormData({...initFormData()})
    setPemium({...initPemium()})
  }, [])

  useEffect(() => {
    const plans= formData.insuredPlan === 1 ? excellentPlan : (formData.insuredPlan === 2 ? featuredPlan : wishPlan)
    plans.forEach((el, i) => {
      if (i === 2 || i === 4 || i === 5|| i === 6) {
        el.forEach((e, j) => {
          if (formData.insuredPlan === 1 && (i === 2 || i === 4) && j !== 2) {
            e.actual = dealNumber(formData.optionAldultPlanIndex === 0 ? e.base+1000000 : (formData.optionAldultPlanIndex === 1 ? e.base+2000000 : e.base)) 
          } else if ((formData.insuredPlan === 1 || formData.insuredPlan === 2 || formData.insuredPlan === 3) && j === 2) {
            e.actual = dealNumber( Number(formData.aldultNo) === 0 ? e.base*2 : (formData.selectChildPlan ?  e.base*2 : e.base))
          }
        })
      }
    })
    formData.insuredPlan === 1 ? setExcellentPlan([...plans]) : (formData.insuredPlan === 2 ? setFeaturedPlan([...plans]) : setWishPlan([...plans]))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.insuredPlan, formData.optionAldultPlanIndex, formData.selectChildPlan, formData.aldultNo])
  const onFinish = () => {
    if (Number(formData.aldultNo) === 0 && Number(formData.childNo) === 0) {
      const node = document.getElementById('traveler-num')
      scrollIntoView(node, { behavior: 'smooth'})
      return
    }

    if (formData.insuredPlan === -1) {
      message.warning('請選擇計劃。')
      const node = document.getElementById('table-header')
      scrollIntoView(node, { behavior: 'smooth'})
      return
    }

    if (Number(formData.aldultNo) + Number(formData.childNo) > 50) {
      message.warning('於同一張保單內，旅客人數不可超過50位')
      const node = document.getElementById('traveler-num')
      scrollIntoView(node, { behavior: 'smooth'})
      return
    }

    if (calcFailed) {
      message.warning('保費試算失敗，請重新選擇')
      const node = document.getElementById('traveler-num')
      scrollIntoView(node, { behavior: 'smooth'})
      return
    }

    setStore(K_ZURICH_TSP_CALC, JSON.stringify({
      formData, 
      pemium
    }))
    let param = {
      categoryId,
      productId,
      productCode: formData.productCode === 0 ? 'TSP' : 'TAP',
      cmntDate: formData.departureDate ?  formData.departureDate.format('YYYY/MM/DD') : moment().format('YYYY/MM/DD'),
      planType: formData.planType === 0 ? 'I' : 'C',
      expiryDate: formData.returnDate ? formData.returnDate.format('YYYY/MM/DD') : moment().add(7, 'days').format('YYYY/MM/DD'),
      insuredPlan: formData.isOneWayTrip ? 'P2' : (formData.insuredPlan === 1 ? 'P1' : (formData.insuredPlan === 2 ? 'P2' : 'P3')),
      optionPlan1: (Number(formData.aldultNo) > 0 && Number(formData.childNo) > 0) ? (formData.selectChildPlan ? 'OP1' : '') : '',
      optionPlan2: (formData.insuredPlan === 1 && Number(formData.aldultNo) > 0) ? (formData.optionAldultPlanIndex === 0 ? 'OP2' : (formData.optionAldultPlanIndex === 1 ? 'OP3' : '')) : '',
      travelerCount: Number(formData.childNo) + Number(formData.aldultNo)
    }
    const jsonStr = window.btoa(JSON.stringify(param)) 
    const path = formData.productCode === 0 ? 'singleTravelPlan' : 'annualTravelPlan'
    history.push(`/${path}/${jsonStr}`)
  }
  
  const onBeforeunload = () => {
    console.log(pathname)
    // removeItem(K_ZURICH_TSP_CALC)
  }
  return <div className={styles['travel-process']}>
    <div className={styles['loading-box']} style={{display: loading ? 'flex' : 'none'}}>
      <Spin />
    </div>
    <img className={styles['instranue-banner']} src={assetsUrl + 'images/icon_zurich_instranue_banner_new.png'} alt='zurich_instranue_banner'/>
    <div className={styles['form-conteiner']}>
      <div className={styles['top-box']}>
        {/* <div className={styles['purchase-reason']} style={{display: formData.productCode === 0 ? 'flex' : 'none'}} onClick={() => {
          setFormData(state => {
            return{
              ...state,
              insuredPlan: -1,
              isOneWayTrip: !state.isOneWayTrip
            }
          })
        }}>
          <img src={assetsUrl + `images/icon_receive_form_checkbox_${formData.isOneWayTrip ? 's' : 'n'}.png`} alt='zurich_instranue_banner'/>
          <span>我因移居海外需要購買單次的單程旅遊保障 (最長至90日) 。</span>
        </div>
        <div className={styles['insurance-plan']}>
          <span style={{marginRight: '40px'}}>保險計畫：</span>
          {
            // '全年旅遊'
            ['單次旅遊'].map((el, idx) => {
              return <div key={el} className={[`${styles['plan-item']}`, idx === formData.productCode ? `${styles['plan-item-select']}` : ''].join(' ')} style={{display: formData.isOneWayTrip && idx === 1 ? 'none' : 'flex'}} onClick={e => {
                let data = idx === 0 ? moment().add(7, 'days') : moment().add(364, 'days')
                setFormData(state => {
                  return{
                    ...state,
                    isOneWayTrip: idx === 0 ? state.isOneWayTrip : false,
                    productCode: idx,
                    returnDate: data
                  }
                })
                // form.setFieldsValue({returnDate: data})
              }}>
                <span>{el}</span>
                <img src={assetsUrl + 'images/icon_zurich_form_selected.png'} alt='icon_zurich_form_selected'/>
              </div>
            })
          }
        </div>
        <div className={styles['applicant-type']}>
          <span style={{marginRight: '40px'}}>申請人類別：</span>
          {
            // '公司/機構'
            ['個人'].map((el, idx) => {
              return <div key={el} className={styles['applicant-item']} style={{display: idx === 1 && formData.isOneWayTrip ? 'none' : 'flex'}} onClick={e => {
                setFormData(state => {
                  return{
                    ...state,
                    planType: idx
                  }
                })
              }}>
                <img src={assetsUrl+`images/icon_zurich_form_radio_${idx === formData.planType ? 's' : 'n'}.png`} alt='icon_zurich_form_selected'/>
                <span>{el}</span>
              </div>
            })
          }
        </div> */}
        <div className={styles['title-box']} style={{marginTop: '0px'}}>
          <span>旅客人數</span>
          <div></div>
        </div>
      </div>
      <Form
      form={form}
      initialValues={{...initFormData()}}
      onFieldsChange={() => {
        setInputCompleted(false)
      }}
      onFinish={onFinish}
      onFinishFailed={() => {
        const node = document.getElementById('departure-date')
        scrollIntoView(node, { behavior: 'smooth'})
      }}
      >
        <div id='traveler-num' className={styles['label-box']}>
          <div className={styles['label-desc']}>
            <span>成人（{formData.productCode === 0 ? '18歲以上' : '18-70歲'}）</span>
          </div>
          <div className={styles['label-desc']}>
            <span>兒童（18歲以下）</span>
          </div>
        </div>
        <Form.Item>
          <Input.Group compact>
            <Form.Item 
            name='aldultNo'
            style={{width: '47%', marginRight: '6%'}}
            rules={[
              { validator: (value) => {
                if ( Number(formData.aldultNo) === 0 && Number(formData.childNo) === 0) {
                  return Promise.reject('請選擇最少一位受保人')
                } else {
                  if (!inputCompleted) {
                    calculatePremium()
                  }
                  return Promise.resolve()
                }
              }}
            ]}>
              <Select suffixIcon={<img src={assetsUrl + 'images/icon_receive_form_dropdown.png'} alt='' style={{width: '40px', height: '40px'}}/>} onChange={value => {
                setFormData(state => {
                  return {
                    ...state,
                    aldultNo: value
                  }
                })
                form.validateFields(['childNo'])
              }}>
                {
                  generateRangeNumber(0, 50).map(el => {
                    return <Select.Option key={el.value} value={el.value}>{el.value}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item 
            name='childNo'
            style={{width: '47%'}}
            rules={[
              { validator: (value) => {
                if ( Number(formData.aldultNo) === 0 && Number(formData.childNo) === 0) {
                  return Promise.reject('請選擇最少一位受保人')
                } else {
                  if (!inputCompleted) {
                    calculatePremium()
                  }
                  return Promise.resolve()
                }
              }}
            ]}>
              <Select  suffixIcon={<img src={assetsUrl + 'images/icon_receive_form_dropdown.png'} alt='' style={{width: '40px', height: '40px'}}/>} onChange={value => {
                setFormData(state => {
                  return {
                    ...state,
                    childNo: value
                  }
                })
                if (Number(value) === 0) {
                  setFormData(state => {
                    return {
                      ...state,
                      selectChildPlan: false
                    }
                  })
                }
                form.validateFields(['aldultNo'])
              }}>
                {
                  generateRangeNumber(0, 50).map(el => {
                    return <Select.Option key={el.value} value={el.value}>{el.value}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <div id='departure-date' className={styles['label-box']}>
          <div className={styles['label-desc']}>
            <span className={styles['label-select']}>{formData.productCode === 0 ? '出發日期' : '保單生效日期'}</span>
          </div>
          <div className={styles['label-desc']}>
            <span className={styles['label-select']}>{formData.productCode === 0 ? '回程日期' : '保單有效日期'}</span>
          </div>
        </div>
        <Form.Item>
          <Input.Group compact>
            <Form.Item
            name='departureDate'
            style={{width: '47%', marginRight: '6%'}}
            rules={[
              { validator: (value) => {
                if (!formData.departureDate) {
                  return Promise.reject('請選擇'+ (formData.productCode === 0 ? '出發日期' : '保單生效日期'))
                } else {
                  console.log(formData.departureDate.diff(moment(), 'day'))
                  if (formData.productCode === 0) { //单次计划
                    if (formData.departureDate.diff(moment(), 'day') > 363) {
                      return Promise.reject('出發日期必須為今天起計365 天之內')
                    } else if (moment(moment(formData.returnDate).format('YYYY-MM-DD') + ' 00:00:00').isBefore(moment(moment(formData.departureDate).format('YYYY-MM-DD') + ' 00:00:00'))) {
                      return Promise.reject('出發日期不能大於回程日期')
                    } else if (Number(moment(moment(formData.returnDate).format('YYYY-MM-DD') + ' 00:00:00').diff(moment(moment(formData.departureDate).format('YYYY-MM-DD') + ' 00:00:00'), 'day')) >= (formData.isOneWayTrip ? 90 : 180)) {
                      return Promise.reject(`回程日期必須為出發日期後${formData.isOneWayTrip ? 90 : 180}天內`)
                    }
                    if (!inputCompleted) {
                      calculatePremium()
                    }
                    return Promise.resolve()
                  } else {
                    if ((formData.returnDate <  formData.departureDate) || formData.departureDate.diff(moment(), 'day') > 89) {
                      return Promise.reject('保單生效日期必須為今天起計 90 天之內。')
                    }
                    if (!inputCompleted) {
                      calculatePremium()
                    }
                    return Promise.resolve()
                  }
                }
              }}
            ]}>
              <DatePicker placeholder='出發日期' format='DD/MM/YYYY' showToday={false} locale={locale} disabledDate={current => current && current < moment().subtract(1, 'days')} onChange={(date) => {
                let targetDate = { ...date }
                form.setFieldsValue({returnDate: moment(targetDate).add(7, 'days')})
                setFormData(state => {
                  return {
                    ...state,
                    departureDate: date,
                    returnDate: moment(targetDate).add(7, 'days')
                  }
                })
                // form.validateFields(['returnDate'])
              }}/>
            </Form.Item>
            <Form.Item
            name='returnDate'
            style={{width: '47%'}}
            rules={[
              { validator: (value) => {
                if (!formData.returnDate) {
                  return Promise.reject('請選擇'+ (formData.productCode === 0 ? '回程日期' : '保單有效日期'))
                } else {
                  // console.log(formData.departureDate)
                  // console.log(formData.returnDate.diff(formData.departureDate, 'day'))
                  if (formData.productCode === 0) { //单次计划 
                    if (moment(moment(formData.returnDate).format('YYYY-MM-DD') + ' 00:00:00').isBefore(moment(moment(formData.departureDate).format('YYYY-MM-DD') + ' 00:00:00'))) {
                      return Promise.reject('回程日期不能小於出發日期')
                    } else if (Number(moment(moment(formData.returnDate).format('YYYY-MM-DD') + ' 00:00:00').diff(moment(moment(formData.departureDate).format('YYYY-MM-DD') + ' 00:00:00'), 'day')) >= (formData.isOneWayTrip ? 90 : 180)) {
                      return Promise.reject(`回程日期必須為出發日期後${formData.isOneWayTrip ? 90 : 180}天內`)
                    }
                    if (!inputCompleted) {
                      calculatePremium()
                    }
                    return Promise.resolve()
                  }
                  if (!inputCompleted) {
                    calculatePremium()
                  }
                  return Promise.resolve()
                }
              }}
            ]}>
              <DatePicker disabled={formData.productCode === 1} placeholder='回程日期' format='DD/MM/YYYY' showToday={false} locale={locale} disabledDate={current => current && current < moment().subtract(1, 'days')} onChange={(date) => {
                setFormData(state => {
                  return {
                    ...state,
                    returnDate: date
                  }
                })
                form.validateFields(['departureDate'])
              }}/>
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Form>
      <p className={styles['traveler-intro']}>每一位成人可免費攜同一位隨行兒童，如有任何額外之隨行兒童將會根據保費表收取保費；隨行兒童之保障為成人的50%。</p>
      <div className={styles['table-container']}>
        <div className={[`${styles['table-header']}`, formData.isOneWayTrip ? `${styles['table-header-trip']}` : ''].join(' ')}>
          {
            tableHeader.map((el, idx) => {
              return <div id='table-header'  key={el.title} className={[`${styles['header-item']}`, idx === formData.insuredPlan ? `${styles['header-item-select']}` : ''].join(' ')} style={{width: el.with}} onClick={e => {
                setFormData(state => {
                  return {
                    ...state,
                    insuredPlan: idx
                  }
                })
                // const plans= idx === 1 ? excellentPlan : (idx === 2 ? featuredPlan : wishPlan)

              }}>
                {/* <img style={{display: idx === 0 ? 'none' : 'block'}} src={el.icon.length > 0 ? (assetsUrl + 'images/' + el.icon + '.png') : ''}  alt=''/> */}
                <span>{el.title}</span>
                <Button style={{display: idx === 0 || idx === formData.insuredPlan ? 'none' : 'block'}}>選擇</Button>
              </div>
            })
          }
        </div>
        <div className={styles['table-content']}>
          <div className={[`${styles['table-item']}`, formData.isOneWayTrip ? `${styles['table-item-trip']}` : '' ].join(' ')} style={{borderTop: 'none'}}>
            <div className={styles['premium-box']}>
              <span>總保費（不包括額外保障）</span>
            </div>
            <div className={styles['premium-box']}>
              <span style={{marginBottom: '8px'}}>保費原價: ${pemium.p1.grossPremium}</span>
              <span>應繳保費: ${pemium.p1.discountedPremium}</span>
            </div>
            <div className={styles['premium-box']}>
              <span style={{marginBottom: '8px'}}>保費原價: ${pemium.p2.grossPremium}</span>
              <span>應繳保費: ${pemium.p2.discountedPremium}</span>
            </div>
            <div className={styles['premium-box']}>
              <span style={{marginBottom: '8px'}}>保費原價: ${pemium.p3.grossPremium}</span>
              <span>應繳保費: ${pemium.p3.discountedPremium}</span>
            </div>
          </div>
          <div className={styles['table-item']}>
            <div className={styles['premium-box']}>
              <span>主要保障（ <a href={assetsUrl + 'document/承保範圍.pdf'} target="_blank" rel="noreferrer">更多保障</a>）</span>
            </div>
          </div>
          <div className={[`${styles['table-item']}`, formData.isOneWayTrip ? `${styles['table-item-trip']}` : '' ].join(' ')}>
            <div className={styles['premium-box']}>
              <span>醫療費用</span>
            </div>
            <div className={styles['premium-box']}>
              <span>$1,500,000</span>
            </div>
            <div className={styles['premium-box']}>
              <span>$1,000,000</span>
            </div>
            <div className={styles['premium-box']}>
              <span>$500,000</span>
            </div>
          </div>
          <div className={[`${styles['table-item']}`, formData.isOneWayTrip ? `${styles['table-item-trip']}` : '' ].join(' ')}>
            <div className={styles['premium-box']}>
              <span>緊急醫療運送</span>
            </div> 
            <div className={styles['premium-box']}>
              <span>實際費用</span>
            </div>
            <div className={styles['premium-box']}>
              <span>實際費用</span>
            </div>
            <div className={styles['premium-box']}>
              <span>實際費用</span>
            </div>
          </div>
          <div className={[`${styles['table-item']}`, formData.isOneWayTrip ? `${styles['table-item-trip']}` : '' ].join(' ')}>
            <div className={styles['premium-box']}>
              <span>個人意外</span>
            </div>
            <div className={styles['premium-box']}>
              <span>$1,500,000</span>
            </div>
            <div className={styles['premium-box']}>
              <span>$1,000,000</span>
            </div>
            <div className={styles['premium-box']}>
              <span>$500,000</span>
            </div>
          </div>
          <div className={[`${styles['table-item']}`, formData.isOneWayTrip ? `${styles['table-item-trip']}` : '' ].join(' ')}>
            <div className={styles['premium-box']}>
              <span>旅程延誤津貼</span>
            </div>
            <div className={styles['premium-box']}>
              <span>✔（沒有受保條件限制）</span>
            </div>
            <div className={styles['premium-box']}>
              <span>✔（有受保條件限制）</span>
            </div>
            <div className={styles['premium-box']}>
              <span>✔（有受保條件限制）</span>
            </div>
          </div>
          <div className={[`${styles['table-item']}`, formData.isOneWayTrip ? `${styles['table-item-trip']}` : '' ].join(' ')}>
            <div className={styles['premium-box']}>
              <span>旅程阻礙保障</span>
            </div>
            <div className={styles['premium-box']}>
              <span>✔（沒有受保條件限制）</span>
            </div>
            <div className={styles['premium-box']}>
              <span>✔（有受保條件限制）</span>
            </div>
            <div className={styles['premium-box']}>
              <span>✔（有受保條件限制）</span>
            </div>
          </div>
        </div>
        <div style={{display: formData.insuredPlan > 0 ? 'flex' : 'none'}} className={styles['featured-plan']}>
          <ul className={styles['left-plan-title']}>
            {
              planLevel.map(el => {
                return <li key={el}><span>{el}</span></li>
              })
            }
          </ul>
          <ul className={styles['right-plan-content']}>
            {
              (formData.insuredPlan === 1 ? excellentPlan : (formData.insuredPlan === 2 ? featuredPlan : wishPlan)).map((el, idx) => {
                return <li key={'li-item-'+idx}>
                  {
                    el.map((p, i) => <span key={p+'-'+i}>{(idx === 2 || idx === 4 || idx === 5 || idx === 6 ? '$' : '') + p.actual}</span>)
                  }
                </li>
              })
            }
          </ul>
        </div>
        <div style={{display: formData.insuredPlan > 0 && (Number(formData.aldultNo) > 0) && formData.planType === 0 ? 'flex' : 'none'}} className={styles['extra-protection']}>
          <div className={styles['title-box']} style={{marginTop: '48px'}}>
            <span style={{color: '#333'}}>額外保障</span>
            <div></div>
          </div>
          <div style={{display: Number(formData.aldultNo) > 0 &&  Number(formData.childNo) > 0 ? 'flex' : 'none'}} className={styles['protection-list']}>
            <div className={styles['protection-item']}>
              <p style={{color: '#333'}}>{childProtection.title}</p>
              {
                childProtection.content.map((item, idx) => {
                  return <div key={item.desc} className={styles['protection-intro']} onClick={e => {
                    
                    setFormData(state => {
                      return {
                        ...state,
                        optionChildPlanIndex: idx,
                        selectChildPlan: idx === 0 ? true : false
                      }
                    })
                  }}>
                    <img src={assetsUrl + `images/icon_zurich_form_radio_${formData.optionChildPlanIndex === idx  ? 's' : 'n'}.png`} alt={item.desc}/>
                    <span className={styles['protection-desc']}>{item.desc}</span>
                    {/* <span style={{display: item.directions.length > 0 ? 'block' : 'none', color: '#FF0000'}}>(額外保費 {(formData.productCode === 0 ? 113.0 : 868.00) * formData.childNo}港元)</span> */}
                  </div>
                })
              }
            </div>
          </div>

          <div style={{display: Number(formData.aldultNo) > 0 && formData.insuredPlan === 1 ? 'flex' : 'none'}} className={styles['protection-list']}>
            <div className={styles['protection-item']}>
              <p style={{color: '#333'}}>{(Number(formData.childNo) > 0 ? '2. ' : '1. ') + aldultProtection.title}</p>
              {
                aldultProtection.content.map((item, idx) => {
                  return <div key={item.desc} className={styles['protection-intro']}  onClick={e => {
                    setFormData(state => {
                      return {
                        ...state,
                        optionAldultPlanIndex: idx
                      }
                    })
                  }}>
                    <img src={assetsUrl + `images/icon_zurich_form_radio_${formData.optionAldultPlanIndex === idx ? 's' : 'n'}.png`} alt={item.desc}/>
                    <span className={styles['protection-desc']}>{item.desc}</span>
                    {/* <span style={{display: item.directions.length > 0 ? 'block' : 'none', color: '#FF0000'}}> (額外保費 {(idx === 0 ? (formData.productCode === 0 ? 140.00 : 980.00) : (formData.productCode === 0 ? 200.00 : 1480.00)) * formData.aldultNo}港元)</span> */}
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div className={styles['prompt-box']}>
        {
          prompt.map((el, idx) => {
            return <div className={styles['prompt-item']} key={el}>
              <span style={{marginRight: '6px', display: idx === 0 ? 'none' : 'block'}}>{idx + '.'}</span>
              {
                idx === 0 ? <span className={styles['prompt-title']}>{el}</span> : 
                idx === 3 ? <span>於投保此保險計劃前，您應閱讀 (a) <a href={assetsUrl + 'document/承保範圍.pdf'} target="_blank" rel="noreferrer">承保範圍</a> 及 (b) <a href={assetsUrl + 'document/保單之條款及細則(保單條款）.pdf'} target="_blank" rel="noreferrer">保單條款</a>。</span> : <span className={[idx === 0 ? `${styles['prompt-title']}`: ''].join(' ')}>{el}</span>
              }
            </div>
          })
        }
      </div>
    </div>
    <div className={styles['submit-btn']}><Button onClick={e => {
      setInputCompleted(true)
      form.submit()
    }}>下一頁</Button></div>
    <Footer />
  </div>
}

