import React, {useEffect, useState, useCallback, useImperativeHandle } from "react";
import { Spin, message, Empty, Pagination, ConfigProvider} from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import style from "./Search.module.scss";
import { assetsUrl } from '../../utils/constants'
import { setFontSize } from '../../utils/index'
import { useSelector } from 'react-redux'
import { fstLevelCategoryList, secLevelCategoryList, travelAttrList } from './mockup'
import { InsuranceProduct, SectionCompare, ProductComparisonModal } from '../../components'
import { getInsuranceBrandList, searchInsuranceProductList } from '../../services/api'

let searchParam = {
  pageNum: 1,
  pageSize: 10,
  categoryPid: -1,
  insuranceCategoryId: -1,
  insuranceBrandIds: [],
  travelPlans: [],
  sortValue: '',
  inputValue: '',
}

export function Search ({closeHeaderSearch, openSearch, onRef }) {
  message.config({
    maxCount: 1
  })
  let modalRef = React.createRef()
  const [keyword, setKeyword] = useState(null)
  const [sortIndex, setSortIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [compareProduct, setCompareProduct] = useState([])
  const [productList, setProductList] = useState(null)
  const [comparing, setComparing] = useState(false)
  const [productTotal, setProductTotal] = useState(0)
  const [insuranceCategoryId, setInsuranceCategoryId] = useState(searchParam.insuranceCategoryId)
  const [fstLevelCategoryId, setFstLevelCategoryId] = useState(searchParam.categoryPid)
  const [secLevelCategory, setSecLevelCategory] = useState({
    pId: '',
    pName: '',
    id: '',
    name: '',
    icon: '',
    subTitle: '',
    desc: []
  })
  const [secLevelCategoryFilterList, setSecLevelCategoryFilterList] = useState([])
  const [travelAttrFilterList, setTravelAttrFilterList] = useState([])
  const [isShowTravelInfo, setIsShowTravelInfo] = useState(false)
  const [brandList, setBrandList] = useState([])

  const scale = useSelector(state => state.font.scale)
  // 獲取公司品牌
  const fetchInsuranceBrandList = () => {
    getInsuranceBrandList().then(res => {
      if (res.data.code === 0) {
        let list = res.data.data ? JSON.parse(JSON.stringify(res.data.data)) : [];
        list.forEach(item => {
          item.selected = false;
        });
        setBrandList(list)
      } else {
        console.error(res.data.message)
      }
    }).catch(e => {
      console.error(e)
    });
  }
  // 獲取旅遊險相關數據
  const fetchTravelAttrList = () => {
    setTravelAttrFilterList(JSON.parse(JSON.stringify(travelAttrList)));
  }
  // 設定旅遊信息
  const initSearchTravelInfo = (attrName, selectList) => {
    let list = [];
    selectList.forEach((item, index) => {
      if (item.selected) {
        list.push(item.alias);
      }
    })

    if (attrName === '計劃') {
      searchParam.travelPlans = list
    } else {
      // null
    }
  }

  const setCategoryInfo = (secLevelCategoryId) => {
    if (secLevelCategoryId === -1) {
      return;
    }
    let findCategoryId = Number(secLevelCategoryId);
    let findCategory = null;

    // 终止for循环，使用break
    for (let idx=0; idx < secLevelCategoryList.length; idx++) {
      let item = secLevelCategoryList[idx];
      if (item.id === findCategoryId) {
        // 顯示用
        setSecLevelCategory(item);
        setFstLevelCategoryId(item.pId);
        findCategory = item;
        break;
      }
    }

    // 檢索用
    setSecLevelCategoryFilterList(
      secLevelCategoryList.filter(item => {
        return item.pId === findCategory.pId;
    }))

    // 關聯旅遊險的項目
    const isTravel = '旅遊保險' === findCategory.name;
    setIsShowTravelInfo(isTravel)
    if (isTravel) {
      const travelKey = '計劃'
      const list = travelAttrFilterList.filter(item => item.attrName === travelKey)[0]
      initSearchTravelInfo(travelKey, list.valueSelect);
      search()
    } else {
      searchParam.travelPlans = []
    }
  }

  useEffect(() => {
    searchParam.insuranceCategoryId = insuranceCategoryId
    setCategoryInfo(insuranceCategoryId);
    search();
    // // 顯示用
    // if (insuranceCategoryId !== secLevelCategory.id) {
    //   let findCategoryId = Number(insuranceCategoryId)
    //   // 终止for循环，使用break
    //   for (let idx=0; idx < secLevelCategoryList.length; idx++) {
    //     let item = secLevelCategoryList[idx];
    //     if (item.id === findCategoryId) {
    //       // 顯示用
    //       setSecLevelCategory(preState => {
    //         return {...preState, ...item}
    //       });
    //       break;
    //     }
    //   }
    // }
  }, [insuranceCategoryId])


  const [priceState, setPriceState] = useState({
    hover: false,
    isAscent: false
  })
  const [salesState, setSalesState] = useState({
    hover: false,
    isAscent: false
  })
  const [timeState, setTimeState] = useState({
    hover: false,
    isAscent: false
  })
  const search = (newPageNum) => {
    // 首頁初期會被調用
    if (!openSearch) {
      return;
    }
    if (!newPageNum) {
      searchParam.pageNum = 1
    } else {
      searchParam.pageNum = newPageNum
    }
    let currentParam = JSON.parse(JSON.stringify(searchParam));
    // 保險類別
    currentParam.categoryPid =
      currentParam.categoryPid === -1 ? '' : currentParam.categoryPid;
    // 險種
    currentParam.insuranceCategoryId =
      currentParam.insuranceCategoryId === -1 ? '' : currentParam.insuranceCategoryId;
    // 關鍵字
    currentParam.key = currentParam.inputValue;
    console.log('search:' + JSON.stringify(currentParam));
    document.getElementById('search-content-bg').scrollTop = 0

    setLoading(true)
    searchInsuranceProductList({...currentParam}).then(res => {
    // searchInsuranceProduct({...searchParam}).then(res => {
      setLoading(false)
      // 搜索增加保险比较功能
      if (res.data.code === 0) {
        if (res.data.data) {
          // 總數設定
          setProductTotal(res.data.data.total)
          if (res.data.data.records.length > 0) {
            const products = res.data.data.records
            products.forEach(el => {
              el.selected = false
              if (compareProduct.length > 0) {
                // const cpItem = compareProduct[0]

                compareProduct.forEach(item => {
                  if (item.productId === el.productId) {
                    el.selected = true
                    return
                  }
                })
              }
            })
            setProductList(products)
          } else {
            setProductList([])
          }
        } else {
          setProductList(null)
          setProductTotal(0)
        }
        setTotalPages(res.data.data.total)
      } else {
        message.error(res.data.message)
      }
    }).catch(e => {
      setLoading(false)
    })
  }
  const reset =  useCallback(() => {
    searchParam.inputValue = ''
    searchParam.pageNum = 1
    searchParam.pageSize = 10
    searchParam.categoryPid = -1;
    searchParam.insuranceCategoryId = -1;
    searchParam.insuranceBrandIds = []
    searchParam.travelPlans = []
    searchParam.sortValue = ''
    setFstLevelCategoryId(searchParam.categoryPid)
    setSecLevelCategory({
      pId: '',
      pName: '',
      id: '',
      name: '',
      icon: '',
      subTitle: '',
      desc: []
    })
    setIsShowTravelInfo(false)
    setInsuranceCategoryId(searchParam.insuranceCategoryId);
    setBrandList( preState => {
      const list = [...preState]
      list.forEach(item => {
        item.selected = false
      })
      return list;
    })
    setTravelAttrFilterList(JSON.parse(JSON.stringify(travelAttrList)));
    setSortIndex(0)
    // setPriceState(state => {
    //   return{
    //     ...state,
    //     hover: false,
    //     isAscent: false
    //   }
    // })
    // setSalesState(state => {
    //   return{
    //     ...state,
    //     hover: false,
    //     isAscent: false
    //   }
    // })
    // setTimeState(state => {
    //   return{
    //     ...state,
    //     hover: false,
    //     isAscent: false
    //   }
    // })
  }, [])

  const resetSort = (idx) => {
    if (idx === 1) {
      setPriceState(state => {
        return{
          ...state,
          hover: false,
          isAscent: false
        }
      })
    } else if (idx === 2) {
      setSalesState(state => {
        return{
          ...state,
          hover: false,
          isAscent: false
        }
      })
    }  else if (idx === 3) {
      setTimeState(state => {
        return{
          ...state,
          hover: false,
          isAscent: false
        }
      })
    }
  }
  useEffect(() => {
    fetchInsuranceBrandList()
    fetchTravelAttrList()
  }, [])

  useEffect(() => {
    if (openSearch === false) {
      setProductList(null)
    }
  }, [openSearch, reset])

  const updateSortValue = (idx, isAscent) => {
    let sValue = ''
    if (idx === 1) {
      sValue = isAscent ? 'min_premium-asc' : 'min_premium-desc'
    } else if (idx === 2) {
      sValue = isAscent ? 'sales-asc' : 'sales-desc'
    } else if (idx === 3) {
      sValue = isAscent ? 'sale_start_time-asc' : 'sale_start_time-desc'
    }
    searchParam.sortValue = sValue
    search()
  }
  const updataKeyword = (key) => {
    // reset()
    searchParam.inputValue = key
    setKeyword(key)
  }
  useImperativeHandle(onRef, () => {
    return{
      search,
      updataKeyword,
      reset
    }
	})
  const updateProduct = (ids) => {
    let ps = productList
    ids.forEach(el => {
      ps.forEach(item => {
        if (item.productId === el) {
          item.selected = false
          return
        }
      })
    })
    setProductList(state => {
      return [...ps]
    })
  }
  const add = (el, idx) => {
    const ps = productList //产品列表
    // const cp = compareProduct //需要比较的产品列表
    // if (cp && cp.length > 0) {
    //   let item = ps[idx]
    //   let cItem = cp[0]
    //   if (cItem.insuranceCategoryId !== item.insuranceCategoryId ) {
    //     message.warning('比較的產品險種不一致，請重新選擇')
    //     return
    //   }
    // }
    ps[idx].selected = true
    setProductList(ps)
    setCompareProduct(state => {
      return [...state, el]
    })
  }
  const closeSearch = () => {
    openSearch = false
    closeHeaderSearch()
  }
  const compare = () => {
    modalRef.current.compare((loading) => {
      setComparing(loading)
    })
  }
  const resetComparison = () => {
    const ids = compareProduct.map(el => {
      return el.productId
    })
    updateProduct(ids)
    setCompareProduct(state => {
      return []
    })
  }
  const deleteProduct = (idx) => {
    updateProduct([compareProduct[idx].productId])
    let cp = compareProduct
    cp.splice(idx, 1)
    setCompareProduct(state => {
      return [...cp]
    })
  }
  const update = (idx) => {
    updateProduct([compareProduct[idx].productId])
    let cp = compareProduct
    cp.splice(idx, 1)
    setCompareProduct(state => {
      return [...cp]
    })
  }
  return <div id='search-content-bg' className={style['search-content-bg']} style={{display: productList ? 'flex' : 'none'}} onClick={(e) => {
      e.stopPropagation()
    }}>
    <div className={style['search-content']} style={{paddingBottom: compareProduct.length > 0 ? '242px' : '60px'}}>
      <div className={style['filter-title']}>
        <p>篩選</p>
        <img src={assetsUrl + 'images/icon_right_fliter.png'} alt=''/>
      </div>
      <div className={style['filter-box']} style={setFontSize(16, scale)}>
        {/* 保險類別 */}
        <div className={style['currency-box']}>
          <p>保險類別</p>
          <div className={style['currency-list']}>
            {
              fstLevelCategoryList.map((item, idx) => {
                return <div className={style['currency-menu-box']} key={item.id} onClick={e => {
                  searchParam.categoryPid = item.id
                  setFstLevelCategoryId(item.id)
                  setInsuranceCategoryId(-1)
                  // 檢索用
                  setSecLevelCategoryFilterList(
                    secLevelCategoryList.filter(rec => {
                      return rec.pId === item.id;
                  }))
                  search()
                }}>
                  <span className={[`${style['currency-menu']}`, item.id === fstLevelCategoryId ? `${style['currency-menu-active']}` : `${style['currency-menu-normal']}`].join(' ')}>{item.name}</span>
                </div>
              })
            }
          </div>
        </div>
        {/* 險種 */}
        <div className={style['currency-box']} style={{display: fstLevelCategoryId === -1 ? 'none' : ''}}>
          <p>險種</p>
          <div className={style['currency-list']}>
            {
              secLevelCategoryFilterList.map((item, idx) => {
                return <div className={style['currency-menu-box']} key={item.id} onClick={e => {
                  setInsuranceCategoryId(item.id);
                }}>
                  <span className={[`${style['currency-menu']}`, item.id === Number(insuranceCategoryId) ? `${style['currency-menu-active']}` : `${style['currency-menu-normal']}`].join(' ')}>{item.name}</span>
                </div>
              })
            }
          </div>
        </div>
        {/* 保險公司 */}
        <div className={style['select-insurance-company']}>
          <div className={style['company-box']}>
            <p>保險公司</p>
            <div className={style['species-list']}>
              {
                brandList.map((el, idx) => {
                  return  <div className={style['species-menu-box']} key={idx} onClick={e => {
                    const company = [...brandList]
                    company[idx].selected = !company[idx].selected
                    setBrandList(company)
                    searchParam.insuranceBrandIds = []
                    // 遍历数组开始搜索
                    company.forEach((item, index) => {
                      if (item.selected) {
                        searchParam.insuranceBrandIds.push(item.id)
                      }
                    })
                    search()
                  }} >
                    <img src={assetsUrl + 'images/icon_check_company_' + (el.selected ? 's' : 'n') + '.png'} style={{display: true ? 'none' : 'inline-block'}} alt='brandList'/>
                    <span className={[`${style['species-menu']}`, el.selected ? `${style['species-menu-active']}` : ''].join(' ')}>{el.name}</span>
                  </div>
                })
              }
            </div>
            <span style={setFontSize(14, scale)}className={style['more-btn']} onClick={() => {
              const company = [...brandList]
              company.forEach((el) => {
                el.selected = false
              })
              setBrandList(company)
              searchParam.insuranceBrandIds = []
              search()
            }}>清空</span>
          </div>
        </div>

        {/* 動態旅遊篩選項 */}
        {
          travelAttrFilterList.map((el, idx) => {
            return <div className={style['currency-box']} key={el.attrName} style={{display: isShowTravelInfo ? 'flex' : 'none'}}>
              <p>{el.attrName}</p>
              <div className={style['currency-list']}>
                {
                  el.valueSelect.map((p, i) => {
                    return <div className={style['currency-menu-box']} key={p.desc} onClick={e => {
                      const alist = [...travelAttrFilterList];
                      alist[idx].valueSelect[i].selected = !p.selected
                      setTravelAttrFilterList(alist);

                      // 重新設定檢索信息
                      initSearchTravelInfo(el.attrName, alist[idx].valueSelect);
                    }}>
                      <span className={[`${style['currency-menu']}`, p.selected ? `${style['currency-menu-active']}` : `${style['currency-menu-normal']}`].join(' ')}>{p.desc}</span>
                    </div>
                  })
                }
              </div>
            </div>
          })
        }
      </div>
      {/* 隱藏排序 */}
      <div className={style['sort-box']}>
        <div className={style['top-line']}></div>
        <div className={style['bottom-line']}></div>
        <div className={[`${style['default-sort']}`, sortIndex === 0 ? `${style['default-sort-selected']}` : ''].join(' ')} onClick={e => {
          if (sortIndex === 0) {
            return
          }
          setSortIndex(preState => {
            if (preState !== 0) {
              resetSort(preState)
            }
            return 0
          })
          updateSortValue(0, false)
        }}>
          <span>默认排序</span>
        </div>
        <div className={[`${style['default-sort']}`, sortIndex === 1 ? `${style['default-sort-selected']}` : ''].join(' ')} onClick={e => {
          setSortIndex(preState => {
            if (preState !== 1) {
              resetSort(preState)
            }
            return 1
          })
          setPriceState(state => {
            updateSortValue(1, !state.isAscent)
            return {
              ...state,
              isAscent: !state.isAscent
            }
          })
        }} onMouseEnter={e => {
          setPriceState(state => {
            return {
              ...state,
              hover: true
            }
          })
        }} onMouseLeave={e => {
          setPriceState(state => {
            return {
              ...state,
              hover: false
            }
          })
        }}>
          <span>价格</span>
          <img src={assetsUrl + (sortIndex === 1 ? (priceState.isAscent ? 'images/icon_sort_select_ascend.png' :'images/icon_sort_select_descend.png') : (priceState.hover ? 'images/icon_sorting_selected.png' : 'images/icon_sort_normal.png'))} alt=''/>
        </div>
        <div className={[`${style['default-sort']}`, sortIndex === 2 ? `${style['default-sort-selected']}` : ''].join(' ')} onClick={e => {
          setSortIndex(preState => {
            if (preState !== 2) {
              resetSort(preState)
            }
            return 2
          })
          setSalesState(state => {
            updateSortValue(2, !state.isAscent)
            return {
              ...state,
              isAscent: !state.isAscent
            }
          })
        }} onMouseEnter={e => {
          setSalesState(state => {
            return {
              ...state,
              hover: true
            }
          })
        }} onMouseLeave={e => {
          setSalesState(state => {
            return {
              ...state,
              hover: false
            }
          })
        }}>
          <span>销量</span>
          <img src={assetsUrl + (sortIndex === 2 ? (salesState.isAscent ? 'images/icon_sort_select_ascend.png' :'images/icon_sort_select_descend.png') : (salesState.hover ? 'images/icon_sorting_selected.png' : 'images/icon_sort_normal.png'))} alt=''/>
        </div>
        <div className={[`${style['default-sort']}`, sortIndex === 3 ? `${style['default-sort-selected']}` : ''].join(' ')} onClick={e => {
          setSortIndex(preState => {
            if (preState !== 3) {
              resetSort(preState)
            }
            return 3
          })
          setTimeState(state => {
            updateSortValue(3, !state.isAscent)
            return {
              ...state,
              isAscent: !state.isAscent
            }
          })
        }} onMouseEnter={e => {
          setTimeState(state => {
            return {
              ...state,
              hover: true
            }
          })
        }} onMouseLeave={e => {
          setTimeState(state => {
            return {
              ...state,
              hover: false
            }
          })
        }}>
          <span>上架时间</span>
          <img src={assetsUrl + (sortIndex === 3 ? (timeState.isAscent ? 'images/icon_sort_select_ascend.png' :'images/icon_sort_select_descend.png') : (timeState.hover ? 'images/icon_sorting_selected.png' : 'images/icon_sort_normal.png'))} alt=''/>
        </div>
      </div>

      {/* 隱藏搜索 */}
      <p className={style['search-result-title']} style={{display: 'none'}}>您搜索的“{keyword}”相关结果如下：</p>

      {
          insuranceCategoryId === -1 ?
            <div style={setFontSize(20, scale)} className={style['filter-result-no-category']}>
              <span>篩選結果：共</span>
              <span> {productTotal} </span>
              <span>款產品</span>
            </div> :
            <div className={style['category-box']}>
              <div style={setFontSize(20, scale)} className={style['category-content']}>
                <div className={style['filter-result']}>
                  <span>篩選結果：共</span>
                  <span> {productTotal} </span>
                  <span>款產品</span>
                </div>
                <div className={style['category-title']}>
                  {
                    secLevelCategory.icon === '' ? '' : <img src={assetsUrl + secLevelCategory.icon} alt=''/>
                  }
                  <span style={setFontSize(26, scale)}>{secLevelCategory.pName}</span>
                </div>
                <div className={style['category-item-title']}>
                  <span style={setFontSize(40, scale)}>{secLevelCategory.name}</span>
                </div>
                <div className={style['category-item-sub-title']}>
                  <span>{secLevelCategory.subTitle}</span>
                </div>
                {
                  secLevelCategory.desc.map(el => {
                    return <div className={style['category-item-info']} key={el}>
                              <span></span>
                              <span>{el}</span>
                           </div>
                  })
                }
              </div>
            </div>
      }

      <Spin spinning={loading} style={{width: '900px', height: '30vh', position: 'static'}}>
        {
          productList ? (productList.length > 0 ?
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div className={style['search-result']}>
            {
              productList.map((el, idx) => {
                return <InsuranceProduct firstComparedId={compareProduct.length > 0 ? compareProduct[0].insuranceCategoryId + '' : ''} product={el} productLength={compareProduct.length} index={idx} key={el.productId} addCompareAction={add} closeSearchAction={closeSearch}/>
              })
            }
          </div>
          <ConfigProvider locale={zh_CN}>
            <Pagination style={{display: totalPages > 10 ? 'block' : 'none', marginTop: '40px'}} current={searchParam.pageNum} total={totalPages} onChange={(page, pSize) => {
              searchParam.pageNum = page
              searchParam.pageSize = pSize
              search(page)
            }}/>
          </ConfigProvider>
          </div> : <Empty description='暫未搜索到相關保險產品'  style={{height: '45vh', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}} />) : <div></div>
        }
      </Spin>
    </div>
    <SectionCompare compareProduct={compareProduct} comparing={comparing} compareAction={compare} resetAction={resetComparison} deleteAction={deleteProduct}/>
    <ProductComparisonModal onRef={modalRef} compareProduct={compareProduct} categoryName={'一个文案'} updateAction={update}/>
  </div>
}