import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Input, Select, Button, Modal, message, Spin } from 'antd'
import { Footer} from '../../components'
import styles from './ReceiveGiftInsurance.module.scss'
import { sendInsuredVerifyCode, submitGiftInsurancePolicy } from '../../services/api'
import { assetsUrl } from '../../utils/constants'
import { useMountedRef } from '../../utils/hook'
import moment from 'moment';
// import locale from 'antd/es/date-picker/locale/zh_CN';
import { userDeclaration } from './mockup'
import scrollIntoView from 'scroll-into-view-if-needed'
import { isLegalEmail, isLegalHKPhone, isLegalHKID, generateRangeNumber } from '../../utils'
// locale.lang.placeholder='DD/MM/YYYY'
message.config({
  maxCount: 1
})
let nums = 180
var interval = null
export function ReceiveGiftInsurance () {
  const initialValues = {
    idNo: '',
    idLastNo: '',
    gender: undefined,
    surname: '',
    name: '',
    birthDateYear: undefined,
    birthDateMonth: undefined,
    birthDateDay: undefined,
    phoneNumber: '',
    email: '',
    relation: '本人',
    verifyCode: '',
    protocol: []
  }
  const h = useHistory()
  const mountedRef = useMountedRef()
  const [form] = Form.useForm()
  const [modalForm] = Form.useForm()
  const [formData, setFormData] = useState({ ...initialValues })
  const [declaration, setDeclaration] = useState(userDeclaration)
  const [openPhoneTips, setPhoneTips] = useState(false)
  const [openEmailTips, setEmailTips] = useState(false)
  const [validatePhoneFailed, setPhoneFailed] = useState(false)
  const [validateEmailFailed, setEmailFailed] = useState(false)
  const [visibleModal, setModalVisible] = useState(false)
  const [passed, setPassed] = useState(false)
  const [inputComplete, setInputComplete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [countdown, setCountdown] = useState({
    desc: '180s',
    disabled: false
  })
  const [effectiveYears, setEffectiveYears] = useState(generateRangeNumber(moment().subtract(80, "years").format('YYYY'), moment().subtract(18, "years").format('YYYY')))
  const [effectiveMonths, setEffectiveMonths] = useState(generateRangeNumber(1, 12))
  const [effectiveDays, setEffectiveDays] = useState(generateRangeNumber(1, 31))
  useEffect(() => {
    window.scrollTo(0, 0)
    // startDate = moment().subtract(80, "years"); //当前时间的前1年时间1941-08-23
    // endDate = moment().subtract(28, "days"); //当前时间的前10天时间2021-07-26
    // endDate = moment().subtract(18, "years"); //当前时间的前10天时间2021-07-26
  }, [])
  const prefixSelector = (
    <Form.Item style={{width: '95px', height: '61px', margin: 0, background: '#D3D3D3'}}>
      <span className={styles['phone-prefix']}>+852</span>
    </Form.Item>
  )
  const onFinish = (values) => {
    if (!/^(?!(\s+$))/.test(formData.name) || !/^(?!(\s+$))/.test(formData.surname)) {
      const node = document.getElementById('contact-label')
      scrollIntoView(node, { behavior: 'smooth'})
      message.warning('请輸入有效的英文全名')
      return
    }
    if (!isLegalEmail(values.email)) {
      const node = document.getElementById('contact-label')
      scrollIntoView(node, { behavior: 'smooth'})
      message.warning('請輸入有效的電郵地址')
      return
    }
    if (formData.verifyCode.length === 0) {
      const node = document.getElementById('contact-label')
      scrollIntoView(node, { behavior: 'smooth'})
      message.warning('請輸入驗證碼')
      return
    }
    if (!declaration[0].select || !declaration[declaration.length - 1].select) {
      const node = document.getElementById('user-declaration')
      scrollIntoView(node, { behavior: 'smooth'})
      return
    }
    setPassed(true)
    setFormData(state => {
      return {
        ...state,
        gender: values.gender
      }
    })
    window.scrollTo(0, 0)
  }
  const destroyInterval = () => {
    clearInterval(interval)
    setCountdown(state => {
      return {
        ...state,
        desc: '180s',
        disabled: false
      }
    })
    nums = 180
  }
  const startCountdown = () => {
    sendVerifyCode()
    setCountdown(state => {
      return {
        ...state,
        desc: nums + 's',
        disabled: true
      }
    })
    interval = setInterval(() => {
      if (mountedRef.current) {
        nums--
        if(nums > 0){
          setCountdown(state => {
            return {
              ...state,
              desc: nums + 's',
            }
          })
        }else{
          destroyInterval()
        }
      } else {
        clearInterval(interval)
      }
    }, 1000)
  }
  const sendVerifyCode = () => {
    sendInsuredVerifyCode({
      mobile: formData.phoneNumber,
      mobileArea: 'HK'
    }).then(res => {
      if (res.data.code === 0) {
        message.success('驗證碼發送成功')
      } else {
        message.error(res.data.message)
        destroyInterval()
      }
    })
  }
  const submit = () => {
    setLoading(true)
    let protocol = declaration.map(el => {
      if (el.select) {
        return el.desc
      }
      return ''
    }).filter(el => el.length > 0)
    submitGiftInsurancePolicy({
      idCardNumber: formData.idNo + '(' + formData.idLastNo + ')',
      idCardName: formData.surname + ' ' + formData.name,
      sex: formData.gender === '先生' ? 1 : 0,
      birthday: moment(formData.birthDateYear + '-' + formData.birthDateMonth + '-' + formData.birthDateDay).format('YYYY-MM-DD'),
      mobileArea: 'HK',
      verifyCode: formData.verifyCode,
      mobile: '+852' + formData.phoneNumber,
      email: formData.email,
      insuredRelationType: 0,
      insuranceCode: 0,
      protocol
    }).then(res => {
      setLoading(false)
      if (res.data.code === 0) {
        if (interval) {
          destroyInterval()
        }
        h.replace('/receive-success')
      } else {
        message.error(res.data.message)
      }
    }).catch(e => {
      setLoading(false)
    })
  }
  const validateAction = () => {
    let tempDeclaration = declaration
    tempDeclaration.forEach(el => {
      if (el.select === null) {
        el.select = false
      }
    })
    setDeclaration([...tempDeclaration])
    if (formData.surname !== null && formData.surname.length === 0) {
      setFormData(state => {
        return{
          ...state,
          surname: null
        }
      })
    }
    if (formData.birthDateMonth === undefined) {
      setFormData(state => {
        return{
          ...state,
          birthDateMonth: null
        }
      })
    }
    if (formData.birthDateYear === undefined) {
      setFormData(state => {
        return{
          ...state,
          birthDateYear: null
        }
      })
    }
    setInputComplete(true)
    form.submit()
  }
  return <div className={styles['receive-insurance']}>
    <div className={styles['top-header']}>
      <div className={styles['header-item']}>
        <div className={styles['receive-status']}>
          <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/>
          {/* <span>即時報價</span> */}
        </div>
      </div>
      <div className={styles['progress-line']}></div>
      <div className={styles['header-item']}>
        <div className={styles['receive-status']}>
          {
            passed ? <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/> : <span>2</span>
          }
        </div>
      </div>
      <div className={styles['progress-line']} style={{background: passed ? '#1E4994' : '#9D9D9D'}}></div>
      <div className={styles['header-item']}>
        <div className={styles['receive-status']} style={{background: passed ? '#1E4994' : '#9D9D9D'}}>
          {/* <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/> */}
          <span>3</span>
        </div>
      </div>
    </div>
    <div className={styles['loading-box']} style={{display: loading ? 'flex' : 'none'}}>
      <Spin />
    </div>
    <div className={styles['form-container']}>
      <div className={styles['company-profile']}>
        {/* <img src={assetsUrl + 'images/icon_logo_zurich.png'} alt=''/> */}
        <div className={styles['profile-box']}>
          <p className={styles['title']}>「乘守護」乘客意外保障</p>
          <p className={styles['tag']}>保單資料</p>
          <p className={styles['desc']}>免費保障：一年</p>
        </div>
      </div>
      <div className={styles['title-box']}>
        <span>投保人</span>
        <div></div>
      </div>
      <Form
      form={form}
      initialValues={initialValues}
      onFinishFailed={({ errorFields }) => {
        if (errorFields.length > 0) {
          const isSuccess = errorFields.every(field => !(field.name.length > 0 && (field.name[0] === 'phoneNumber' || field.name[0] === 'email')))
          setPhoneFailed(!isSuccess)
          setEmailFailed(!isSuccess)
        }
        const node = document.getElementById('idno-label')
        scrollIntoView(node, { behavior: 'smooth'})
      }}
      onFinish={onFinish}
      >
        <div className={styles['label-box']}>
          <div className={styles['label-desc']}>
            <span>香港身份證號碼</span>
            <p className={styles['symbol-label']} style={{right: '126px'}}>(</p>
            <p className={styles['symbol-label']}>)</p>
          </div>
          <div className={styles['label-desc']}>
            <span>稱謂</span>
          </div>
        </div>
        <Form.Item style={{marginBottom: '0'}}>
          <Input.Group compact>
            <Form.Item
            name='idNo'
            rules={[
              { validator: (value) => {
                if (formData.idLastNo.length === 0 && formData.idNo.length === 0) {
                  return Promise.reject('请輸入有效的香港身份證號碼')
                } else if ((formData.idNo.length >= 7 && formData.idLastNo.length > 0) || inputComplete) {
                  return !isLegalHKID(formData.idNo + '(' + formData.idLastNo + ')') ? Promise.reject('不是有效的證件號碼，請核對後重新確認') : Promise.resolve()
                } else {
                  return Promise.resolve()
                }
              }}
            ]}
            style={{width: '32%', marginRight: '3.6%'}}>
              <Input disabled={passed} maxLength={8} placeholder="香港身份證號碼" onChange={e => {
                let value = e.target.value.replace(/[^\w]/g,"");
                if (value.length > 8) {
                  value = value.slice(0, 8)
                }
                form.setFieldsValue({idNo: value})
                setInputComplete(false)
                setFormData(state => {
                  return {
                    ...state,
                    idNo: value
                  }
                })
              }} onPaste={e => {
                let value = e.target.value.replace(/[^\w]/g,"");
                if (value.length > 8) {
                  value = value.slice(0, 8)
                }
                form.setFieldsValue({idNo: value})
                setInputComplete(false)
                setFormData(state => {
                  return {
                    ...state,
                    idNo: value
                  }
                })
              }}/>
            </Form.Item>
            <Form.Item
            name='idLastNo'
            style={{width: '8.8%', marginRight: '8.6%'}}>
              <Input disabled={passed} maxLength={1} onChange={e => {
                let value = e.target.value.replace(/[^\w]/g,"");
                if (value.length > 1) {
                  value = value.slice(0, 1)
                }
                form.setFieldsValue({idLastNo: value})
                setInputComplete(false)
                setFormData(state => {
                  return {
                    ...state,
                    idLastNo: value
                  }
                })
                form.validateFields(['idNo'])
              }} onPaste={e => {
                let value = e.target.value.replace(/[^\w]/g,"");
                if (value.length > 1) {
                  value = value.slice(0, 1)
                }
                form.setFieldsValue({idLastNo: value})
                setInputComplete(false)
                setFormData(state => {
                  return {
                    ...state,
                    idLastNo: value
                  }
                })
                form.validateFields(['idNo'])
              }}/>
            </Form.Item>
            <Form.Item
            name='gender'
            rules={[{ required: true, message: '請選擇稱謂' }]}
            style={{width: '47%'}}>
              <Select disabled={passed} placeholder='請選擇稱謂' suffixIcon={<img src={assetsUrl + 'images/icon_receive_form_dropdown.png'} alt='' style={{width: '40px', height: '40px'}}/>}>
                <Select.Option value="先生">先生</Select.Option>
                <Select.Option value="女士">女士</Select.Option>
              </Select>
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <div id='idno-label' className={styles['label-box']} style={{marginTop: '8px'}}>
          <div className={styles['label-desc']}>
            <span>英文全名</span>
          </div>
          <div className={styles['label-desc']}>
            <span>出生日期</span>
          </div>
        </div>
        <Form.Item style={{marginBottom: '0'}}>
          <Input.Group compact>
            <Form.Item
            name='name'
            rules={[
              { validator: (value) => {
                console.log(/^(?!(\s+$))/.test(formData.name))
                if(formData.name.length === 0 || formData.surname === null) {
                  return Promise.reject('请輸入有效的英文全名')
                }
                // else if (formData.name.length > 0 && /^(?!(\s+$))/.test(formData.name)) {
                //   return Promise.reject('请輸入有效的英文全名')
                // }
                else {
                  return Promise.resolve()
                }
              }}
            ]}
            style={{width: '26%', marginRight: '0.7%'}}>
              <Input disabled={passed} placeholder='名：與身份證相符' onChange={e => {
                let value = e.target.value.replace(/[0-9]/ig,"");
                value =  value.replace(/[^\w\.\s\/]/ig,'')
                form.setFieldsValue({name: value})
                setFormData(state => {
                  return{
                    ...state,
                    name: value
                  }
                })
              }} onPaste={e => {
                let value = e.target.value.replace(/[0-9]/ig,"");
                value =  value.replace(/[^\w\.\s\/]/ig,'')
                form.setFieldsValue({name: value})
                setFormData(state => {
                  return{
                    ...state,
                    name: value
                  }
                })
              }}/>
            </Form.Item>
            <Form.Item
            name='surname'
            style={{width: '20.3%', marginRight: '6%'}}>
              <Input disabled={passed} placeholder='姓：與身份證相符' onChange={e => {
                let value = e.target.value.replace(/[0-9]/ig,"");
                value =  value.replace(/[^\w\.\s\/]/ig,'')
                form.setFieldsValue({surname: value})
                setFormData(state => {
                  return{
                    ...state,
                    surname: value
                  }
                })
                form.validateFields(['name'])
              }} onPaste={e => {
                let value = e.target.value.replace(/[0-9]/ig,"");
                value =  value.replace(/[^\w\.\s\/]/ig,'')
                form.setFieldsValue({surname: value})
                setFormData(state => {
                  return{
                    ...state,
                    surname: value
                  }
                })
                form.validateFields(['name'])
              }}/>
            </Form.Item>
            <Form.Item
            name='birthDateDay'
            rules={[
              { validator: (value) => {
                if ( formData.birthDateDay === undefined || formData.birthDateYear === null ||  formData.birthDateMonth === null) {
                  return Promise.reject('請輸入有效的日期')
                } else {
                  return Promise.resolve()
                }
              }}
            ]}
            style={{width: '14.6%', marginRight: '0.7%'}}>
              <Select disabled={passed} placeholder='日' suffixIcon={<img src={assetsUrl + 'images/icon_receive_form_dropdown.png'} alt='' style={{width: '40px', height: '40px'}}/>} onChange={value => {
                setFormData(state => {
                  return{
                    ...state,
                    birthDateDay: value
                  }
                })
                // 月存在
                if (formData.birthDateMonth && Number(formData.birthDateMonth) > 0) {
                  if (Number(formData.birthDateMonth) > Number(moment().format('MM'))) {
                    const years = effectiveYears
                    years[0].disabled = true
                    years[effectiveYears.length - 1].disabled = true
                    setEffectiveYears(years)
                  } else if (Number(formData.birthDateMonth) === Number(moment().format('MM'))) {
                    if (Number(value) > Number(moment().format('DD'))) {
                      const years = effectiveYears
                      years[0].disabled = true
                      years[effectiveYears.length - 1].disabled = true
                      setEffectiveYears(years)
                    } else {
                      setEffectiveYears(generateRangeNumber(moment().subtract(80, "years").format('YYYY'), moment().subtract(18, "years").format('YYYY')))
                    }
                  }
                } else {
                  setEffectiveYears(generateRangeNumber(moment().subtract(80, "years").format('YYYY'), moment().subtract(18, "years").format('YYYY')))
                }
                // 年存在
                if (formData.birthDateYear && Number(formData.birthDateYear) > 0) {
                  if (Number(formData.birthDateYear) === Number(moment().subtract(18, "years").format('YYYY')) || Number(formData.birthDateYear) === Number(moment().subtract(80, "years").format('YYYY'))) {
                    // setEffectiveMonths(generateRangeNumber(1, Number(value) > moment().format('DD') ? (Number(moment().format('MM')) - 1 )  : moment().format('MM')))
                    const months = effectiveMonths
                    months.forEach(el => {
                      // const days = Number(moment(el.value, "MM").daysInMonth())
                      if (Number(value) > moment().format('DD')) {
                        el.disabled =  Number(el.value) >= moment().format('MM')
                      } else {
                        el.disabled =  Number(el.value) > moment().format('MM')
                      }
                    })
                    setEffectiveMonths(months)
                  } else {
                    const months = effectiveMonths
                    months.forEach(el => {
                      const days = Number(moment(el.value, "MM").daysInMonth())
                      el.disabled = days < Number(value)
                    })
                    setEffectiveMonths(months)
                  }
                } else {
                  const months = effectiveMonths
                  months.forEach(el => {
                    const days = Number(moment(el.value, "MM").daysInMonth())
                    el.disabled = days < Number(value)
                  })
                  setEffectiveMonths(months)
                }
                form.validateFields(['birthDateDay'])
              }}>
                {
                  effectiveDays.map(el => {
                    return <Select.Option disabled={el.disabled} key={el.value} value={el.value}>{el.value}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
            name='birthDateMonth'
            style={{width: '14.6%', marginRight: '0.7%'}}>
              <Select disabled={passed} placeholder='月' suffixIcon={<img src={assetsUrl + 'images/icon_receive_form_dropdown.png'} alt='' style={{width: '40px', height: '40px'}}/>} onChange={value => {
                setFormData(state => {
                  return{
                    ...state,
                    birthDateMonth: value
                  }
                })
                if (Number(value) > Number(moment().format('MM'))) {
                  const years = effectiveYears
                  years[0].disabled = true
                  years[effectiveYears.length - 1].disabled = true
                  setEffectiveYears(years)
                } else if (Number(value) === Number(moment().format('MM'))) {
                  if ((formData.birthDateYear && Number(formData.birthDateYear) > 0) && (Number(formData.birthDateYear) === Number(moment().subtract(18, "years").format('YYYY')) || Number(formData.birthDateYear) === Number(moment().subtract(80, "years").format('YYYY')))) {
                    setEffectiveDays(generateRangeNumber(1, Number(moment().format('DD'))))
                  } else {
                    setEffectiveDays(generateRangeNumber(1, moment(value, "MM").daysInMonth()))
                  }
                  setEffectiveYears(generateRangeNumber(moment().subtract(80, "years").format('YYYY'), moment().subtract(18, "years").format('YYYY')))
                } else {
                  setEffectiveYears(generateRangeNumber(moment().subtract(80, "years").format('YYYY'), moment().subtract(18, "years").format('YYYY')))
                  setEffectiveDays(generateRangeNumber(1, moment(value, "MM").daysInMonth()))
                }
                form.validateFields(['birthDateDay'])
              }}>
                {
                  effectiveMonths.map(el => {
                    return <Select.Option disabled={el.disabled} key={el.value} value={el.value}>{el.value}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
            name='birthDateYear'
            style={{width: '16.4%'}}>
              <Select disabled={passed} placeholder='年' suffixIcon={<img src={assetsUrl + 'images/icon_receive_form_dropdown.png'} alt='' style={{width: '40px', height: '40px'}}/>} onChange={value => {
                setFormData(state => {
                  return{
                    ...state,
                    birthDateYear: value
                  }
                })
                if (value === Number(moment().subtract(18, "years").format('YYYY')) || value === Number(moment().subtract(80, "years").format('YYYY'))) {
                  const maxMonth = moment().format('MM')
                  const months = effectiveMonths
                  months.forEach(el => {
                    if (formData.birthDateDay && Number(formData.birthDateDay) > 0 ) {
                      if (Number(formData.birthDateDay) > Number(moment().format('DD'))){
                        el.disabled = Number(el.value) >= Number(maxMonth)
                      } else if (Number(formData.birthDateDay) === Number(moment().format('DD'))) {
                        el.disabled = Number(el.value) > Number(maxMonth)
                      }
                    } else {
                      el.disabled = Number(el.value) > Number(maxMonth)
                    }
                  })
                  setEffectiveMonths(months)

                  const maxDay = moment().format('DD')
                  const days = effectiveDays
                  days.forEach(el => {
                    if (formData.birthDateMonth && Number(formData.birthDateMonth) > 0 ) {
                      if (Number(formData.birthDateMonth) > Number(moment().format('MM'))){
                        el.disabled = Number(el.value) >= Number(maxDay)
                      } else if (Number(formData.birthDateMonth) === Number(moment().format('MM'))) {
                        el.disabled = Number(el.value) > Number(maxDay)
                      }
                    } else {
                      el.disabled = Number(el.value) > Number(maxDay)
                    }
                  })
                  setEffectiveDays(days)
                } else {

                  if (formData.birthDateMonth && Number(formData.birthDateMonth) > 0 ) {
                    setEffectiveDays(generateRangeNumber(1, Number(moment(formData.birthDateMonth, "MM").daysInMonth())))
                  } else {
                    setEffectiveDays(generateRangeNumber(1, 31))
                  }

                  if (formData.birthDateDay && Number(formData.birthDateDay) > 0 ) {
                    const months = effectiveMonths
                    months.forEach(el => {
                      const days = Number(moment(el.value, "MM").daysInMonth())
                      el.disabled = days < Number(formData.birthDateDay)
                    })
                    setEffectiveMonths(months)
                  } else {
                    setEffectiveMonths(generateRangeNumber(1, 12))
                  }
                }
                form.validateFields(['birthDateDay'])
              }}>
                {
                  effectiveYears.map(el => {
                    return <Select.Option disabled={el.disabled} key={el.value} value={el.value}>{el.value}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <div id='contact-label' className={styles['label-box']} style={{marginTop: '8px'}}>
          <div className={styles['label-desc']}>
            <span>手提號碼</span>
            <img src={assetsUrl + 'images/icon_receive_form_tips.png'} alt='' onClick={() => setPhoneTips(!openPhoneTips)}/>
            <div className={[`${styles['input-tips']}`, openPhoneTips ? `${styles['input-tips-active']}` : ''].join(' ')} style={{top: formData.phoneNumber.length === 0 && validatePhoneFailed ? '131px' : '105px'}}>
              <span>用以發送保單確認短訊</span>
            </div>
            <div className={[`${styles['send-code-btn']}`, countdown.disabled ? `${styles['send-code-disabled']}` : ''].join(' ')} style={{display: formData.phoneNumber.length >= 8 && !passed ? 'block' : 'none'}} onClick={() => {
              if (!isLegalHKPhone(formData.phoneNumber)) {
                message.warning('請輸入有效的手機號碼')
                return
              }
              setModalVisible(true)
              if (!countdown.disabled) {
                startCountdown()
              }
            }}>{!countdown.disabled ? '發送驗證碼驗證' : (countdown.desc + '後重新獲取')}</div>
          </div>
          <div className={styles['label-desc']}>
            <span>電郵地址</span>
            <img src={assetsUrl + 'images/icon_receive_form_tips.png'} alt='' onClick={() => setEmailTips(!openEmailTips)}/>
            <div className={[`${styles['input-tips']}`, openEmailTips ? `${styles['input-tips-active']}` : ''].join(' ')} style={{width: '272px', top: formData.email.length === 0 && validateEmailFailed ? '131px' : '105px'}}>
              <span>保單將透過電郵發送至閣下</span>
            </div>
          </div>
        </div>
        <Form.Item style={{marginBottom: '0'}}>
          <Input.Group compact>
            <Form.Item
            name='phoneNumber'
            rules={[
              { required: true, message: '請輸入有效的手提號碼'},
              { validator: (value) => {
                if(formData.phoneNumber.length >= 8 && !isLegalHKPhone(formData.phoneNumber)) {
                  return Promise.reject('請輸入有效的手提號碼');
                } else {
                  return Promise.resolve();
                }
              }}
            ]}
            style={{width: '47%', marginRight: '6%'}}>
              <Input disabled={passed}  maxLength={8} addonBefore={prefixSelector} placeholder='請輸入您的手提號碼' onChange={e => {
                if (e.target.value.length === 0) {
                  setPhoneFailed(true)
                }
                if (!/^[0-9]*$/.test(e.target.value)) {
                  const value = e.target.value.slice(0, e.target.value.length - 1)
                  form.setFieldsValue({phoneNumber: value})
                  return
                }
                setFormData(state => {
                  return {
                    ...state,
                    phoneNumber: e.target.value
                  }
                })
              }} onPaste={e => {
                if (e.target.value.length === 0) {
                  setPhoneFailed(true)
                }
                if (!/^[0-9]*$/.test(e.target.value)) {
                  const value = e.target.value.slice(0, e.target.value.length - 1)
                  form.setFieldsValue({phoneNumber: value})
                  return
                }
                setFormData(state => {
                  return {
                    ...state,
                    phoneNumber: e.target.value
                  }
                })
              }}/>
            </Form.Item>
            <Form.Item
            name='email'
            rules={[{ required: true, message: '请輸入有效的電郵地址' }]}
            style={{width: '47%'}}>
              <Input disabled={passed} maxLength={254} placeholder='e.g.xxx@abc.com' onChange={e => {
                if (e.target.value.length === 0) {
                  setEmailFailed(true)
                }
                setFormData(state => {
                  return {
                    ...state,
                    email: e.target.value
                  }
                })
              }} onPaste={e => {
                if (e.target.value.length === 0) {
                  setEmailFailed(true)
                }
                setFormData(state => {
                  return {
                    ...state,
                    email: e.target.value
                  }
                })
              }}/>
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <div className={styles['title-box']} style={{marginTop: openPhoneTips || openEmailTips ? '106px' : '48px'}}>
          <span>受保人</span>
          <div></div>
        </div>
        <div className={styles['label-box']} style={{marginTop: '24px'}}>
          <div className={styles['label-desc']}>
            <span>與投保人的關係</span>
          </div>
        </div>
        <div className={styles['relation-box']} style={{width: '47%'}}>
          <span>本人</span>
          {/* <img  src={assetsUrl + 'images/icon_receive_form_dropdown.png'} alt='' style={{width: '40px', height: '40px'}}/> */}
        </div>
        <div id='user-declaration' className={styles['user-declaration']}>
          {
            declaration.map((el, idx) => {
              return <div key={el.desc} className={styles['declaration-item']}>
                <div className={styles['top-box']} onClick={() => {
                  if (passed) {
                    return
                  }
                  let tempDeclaration = declaration
                  let item = tempDeclaration[idx]
                  if (item.select === null) {
                    item.select = true
                  } else {
                    item.select = !item.select
                  }
                  setDeclaration([...tempDeclaration])
                }}>
                  <img src={assetsUrl + 'images/icon_receive_form_checkbox_' + (el.select ? 's' : 'n') + '.png'} alt=''/>
                  {
                    idx === 0 ? <div className={styles['declaration-info']}>登記代表本人希望得到免費保障，享受慧擇香港服務及成爲慧擇香港會員, 並已閱讀及同意  <a href={assetsUrl + 'document/保障條款及細則.pdf'} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>保險證書之條款及細則</a>， 及 <a href={assetsUrl + 'document/保障聲明.pdf'} target="_blank" onClick={e => e.stopPropagation()} rel="noreferrer">保障聲明。</a></div> :
                    <div className={styles['declaration-info']}>
                      <div>本人同意在本申請表中收集本人的個人資料將用於處理和評估我的免費保障申請，該保障由慧擇香港和/或蘇黎世保險有限公司（“保險商”）提供 ，我的個人資料也會用於 <a href='/personal-information' target="_blank" onClick={e => e.stopPropagation()}>個人資料收集聲明 </a>（“聲明”）中所述的其他目的。若我未能提供此類資料會導致慧擇香港和/或保險商無法提供該保障。本人明白本人的個人資料將根據該 <a href='/personal-information' target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>聲明</a> 轉交包括保險商在內的其他人士作上述用途。</div>
                      <div>本人確認已閱讀、理解並同意該 <a href='/personal-information' target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>聲明</a> ，並確認本人同意該 <a href='/personal-information' target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>聲明</a> 第 6 段（個人資料在直接促銷中的使用）。請注意，直接營銷可能包括提供特別折扣、優惠券或禮品。</div>
                    </div>
                  }
                </div>
                <span className={styles['err-msg']} style={{display: el.select === false ? 'block' : 'none'}}>遞交申請表前，請勾選方格以茲確認</span>
            </div>
            })
          }
        </div>
      </Form>
    </div>
    <div className={styles['next-btn']}>
      <Button
      style={{display: passed ? 'block' : 'none', border: '1px solid #447EDA', color: '#447EDA', background: 'transparent', marginRight: '32px'}}
      onClick={() => {
        window.scrollTo(0, 0)
        setPassed(false)
      }}>返回上一步</Button>
      <Button onClick={() => {
        if (passed) {
          window.scrollTo(0, 0)
          submit()
        } else {
          validateAction()
        }
      }}>{passed ? '確認提交' : '立即登記' }</Button>
    </div>
    <Footer />
    <Modal
      destroyOnClose
      closable={false}
      style={{ top: 347 }}
      visible={visibleModal}
      width={600}
      footer={null}
    >
    <div className={styles['modal-content']}>
      <div className={styles['close-btn']}>
        <img src={assetsUrl + 'images/icon_modal_cancel.png'} alt='' onClick={() => {
          modalForm.resetFields()
          setModalVisible(false)
        }}/>
      </div>
      <p>已發送驗證碼到{formData.phoneNumber}，驗證碼5分鐘內有效，請及時填寫。</p>
      <Form
      form={modalForm}
      initialValues={{verifyCode: ''}}
      style={{width: '100%', padding: '32px', position: 'relative'}}
      onFinish={ values => {
        setFormData(state => {
          return {
            ...state,
            verifyCode: values.verifyCode
          }
        })
        setModalVisible(false)
      }}>
        <span className={styles['countdown-tip']}>{countdown.desc}</span>
        <Form.Item
        name='verifyCode'
        rules={[{ required: true, message: '请輸入簡訊驗證碼' }]}
        style={{width: '100%'}}>
          <Input maxLength={6} placeholder='簡訊驗證碼' onChange={e => {
            if (!/^[0-9]*$/.test(e.target.value)) {
              const value = e.target.value.slice(0, e.target.value.length - 1)
              modalForm.setFieldsValue({verifyCode: value})
              return
            }
          }}/>
        </Form.Item>
        <Form.Item style={{width: '100%', marginTop: '8px'}}>
          <div className={styles['footer-box']}>
            <Button onClick={() => {
              modalForm.resetFields()
              setModalVisible(false)
            }}>取消</Button>
            <Button  htmlType='submit'>確定</Button>
          </div>
        </Form.Item>
      </Form>
    </div>
    </Modal>
  </div>
}