import React, {useEffect} from 'react'
import styles from './TermOfUse.module.scss'
import { useSelector } from 'react-redux'
import { setFontSize } from '../../utils'
export function TermOfUse () {
  const scale = useSelector(state => state.font.scale)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.main} style={setFontSize(14, scale)}>
        <h1 style={setFontSize(28, scale)}>使用條款及細則</h1>
        <p style={{marginTop: '10px'}}>最後修訂及生效日期﹕2021年9月30日 </p>
        <p style={{marginTop: '20px', fontWeight: 'bold'}}><span style={{marginRight: '10px'}}>I.</span>定義</p>
        <ul>
          <li style={{margin: '10px 0 10px 20px'}}>「Huize Hong Kong」、「慧擇香港」、「本公司」、「我們」或「我們的」代表Huize Hong Kong Insurance Broker Limited（慧擇香港保險經紀有限公司；地址：香港鰂魚涌英凰道979號太古坊多盛大廈24樓2401室；電話：（852）2789-9800；；Email：【admin@huize.com.hk】）</li>
          <li style={{margin: '10px 0 10px 20px'}}>「平台」代表我們營運的數碼管道，包括 huize.com.hk (「網站」) 及/或我們推出的移動應用程式 (「應用程式」)</li>
          <li style={{margin: '10px 0 10px 20px'}}>「您」、「您的」、「您們」或「用戶」代表接觸、投保及使用我們平台的人士及/或實體，或參加慧擇香港的活動的人士及/或實體</li>
          <li style={{margin: '10px 0 10px 20px'}}>「香港」代表中華人民共和國香港特別行政區</li>
          <li style={{margin: '10px 0 10px 20px'}}>「本條款」代表本使用條款及細則</li>
        </ul>

        <p style={{marginTop: '40px', fontWeight: 'bold'}}><span style={{marginRight: '10px'}}>II.</span>遵守條款</p>
        <p style={{marginTop: '30px'}}>本條款是您與我們之間關於您使用我們平台和服務所訂立的協議。使用本公司平台和服務的任何用戶會被視為接受並同意遵守本條款。本條款是我們的《私隱政策》及《個人資料收集聲明》的基礎服務協議，我們的《私隱政策》及《個人資料收集聲明》是本條款不可分割的組成部分，與本條款構成統一整體。</p>
        <p style={{marginTop: '30px'}}>本公司保留權利在沒有預先通知的情況下，隨時修改本條款。一旦在平台上發佈，修訂後的本條款將適用於所有用戶。本公司建議用戶應定期瀏覽此頁面，查看最新版本。您繼續使用本公司的平台和服務將被視為接受本條款的所有修訂並受其約束。</p>
        <p style={{marginTop: '30px'}}>用戶也接受並同意，當使用本平台時，除了受本條款所約束，用戶將同時受本公司使用的相關平台或服務供應商之條款和細則所約束。</p>
        <p style={{marginTop: '30px'}}>用戶進一步接受並同意，您應就使用平台遵守所有適用的法律、法規、條例和規章。用戶瞭解到互聯網之全球性質並瞭解和同意須遵守其司法管轄區以外適用的法律、 法規、 條例或規章。您確保所提供的的個人資料及其他資訊的真實性和完整性。</p>
        <p style={{marginTop: '30px'}}>我們是一間獨立的保險經紀公司，並已向香港保險業監管局註冊，保險經紀牌照號碼：FB1661。</p>



        <p style={{marginTop: '30px'}}>當您</p>
        <ul>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>使用和訪問本公司平台，或</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>聯繫我們表示同意，或</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>支付保費後，</li>
        </ul>
        <p style={{marginTop: '0'}}>即代表您同意委託我們作爲您的代表，並根據所適用的監管指引和法律法規，爲您的保險合約與保險公司作出協商。</p>
        <p style={{marginTop: '30px'}}>爲免疑問，本使用條款適用於慧擇香港和用戶之間的關係。當用戶向慧擇香港尋求任何形式的服務，給予慧擇香港任何形式的指示時，用戶均同意上述的委託。</p>
        <p style={{marginTop: '30px'}}>作為我們對您的承諾，我們將</p>
        <ul>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>誠實公正地行事</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>為您提供清晰的文件和信息</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>為您提供信息，协助您就購買保險做出明智的決定</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>不會誤導您</li>
        </ul>
        <p style={{marginTop: '30px', fontWeight: 'bold'}}><span style={{marginRight: '10px'}}>III.</span>地域限制</p>
        <p style={{marginTop: '30px'}}>本平台中的資訊、產品及服務只限於香港居民。本平台展示的保險產品亦只容許香港居民選購。</p>
        <p style={{marginTop: '30px'}}>本平台的所有資料及服務並不適用於香港以外的其他地區。本平台之任何部份不應被詮釋成向香港以外地區作出任何產品/服務之要約或招攬。</p>
        <p style={{marginTop: '30px'}}>除非有特別聲明，我們有可能拒絕向非香港居民提供服務，且不會對由此所引致的任何索償及/或損失負任何責任。</p>

        <p style={{marginTop: '40px', fontWeight: 'bold'}}><span style={{marginRight: '10px'}}>IV.</span>服務可用性及資訊準確性</p>
        <p style={{marginTop: '30px'}}>本平台的資訊來源自與我們有合作關係的保險公司。我們會將保險公司的保險產品資料進行整理分析。但當我們無法從保險公司直接取得這些資料時，我們會盡力以其他不同合法途徑搜集保險產品的資料，以提供更多的參考資訊。我們致力確保平台內容的準確性及完整性，但我們無法保證我們的平台能完全滿足您的需要。</p>
        <p style={{marginTop: '30px'}}>本平台的資訊有可能並非該相應產品的資訊、條款、細則、豁免或實際保障的最終完整描述，保險公司針對每個產品可能有其他條款或資訊更新。當保險公司收到您對某個保險產品的投保指示後，保險公司會以電子或書面發送最終的產品說明和條款。如保險公司的最終產品說明和條款與本條款產品與我們提供的資訊有分歧，應以保險公司的特定產品條款爲準，我們不會就此承擔任何責任。</p>
        <p style={{marginTop: '30px'}}>由於我們無法控制的技術因素，我們無法向您保證平台服務在任何時候均不會被中斷或出錯，或所有問題會得到補救，或伺服器不會有任何病毒或錯誤。我們可能會隨時在未作出通知的情況下暫時或中止平台服務以進行維修保養或其他工作。</p>
        <p style={{marginTop: '30px'}}>您需自行承擔連接及使用本平台之風險，我們不會保證使用本平台來下載的任何材料不會令任何財物受損，或減少或杜絕互聯網的其他固有風險，包括但不限於：數據遺失、電腦病毒傳染、間諜軟件、惡意破壞軟件、木馬及電腦蠕蟲。同時我們不會承擔任何由未授權協力廠商對平台內容作出更改而造成之損失或破壞之責任。</p>
        <p style={{marginTop: '30px'}}>假如您在上載或傳送任何內容或資料至我們的平台期間遺失該等資料，我們概不負責。</p>
        <p style={{marginTop: '30px'}}>我們在適用法律允許的最大範圍內排除任何保證，承諾或聲明 (明示或默示) (包括但不限於我們平台以及內容或材料的全部或部份內容或材料的準確性，可用性或完整性，平台或內容或材料的任何部份適用於或可用於任何司法管轄權)。</p>


        <p style={{marginTop: '40px', fontWeight: 'bold'}}><span style={{marginRight: '10px'}}>V.</span>知識產權</p>
        <p style={{marginTop: '30px'}}>我們是本平台知識產權權利人，除非特別聲明，本平台軟件的一切著作權、商標權、專利權、商業秘密等知識產權，以及與平台及軟體相關的所有資訊內容（包括圖像及文字安排、數據儲存權利等）均受相關法律法規及國際條約保護。</p>
        <p style={{marginTop: '30px'}}>平台及刊載在平台上的資訊均為本公司的財產。任何人不得使用平台上任何資訊、圖像、標誌、名稱及商標，任何未經授權的相關使用將會構成侵權。任何人在未經我們書面批准下，不得採用、發佈、分發、複製、摘錄或再用平台上的任何部份內容，或把內容上載至其他地點或公開發佈。</p>
        <p style={{marginTop: '30px'}}>假如您參與我們平台上的任何討論或提供評論，即代表您確認任何您所作的言論及建議均屬我們所有，我們可利用您的意見而無需支付您及任何其他人士，但您仍然需就您所作的言論及建議招致的侵犯別人權利或違法結果（“侵權和違法行為”）承擔所有法律責任，而我們絕對不會承擔該等法律責任，同時您需就您的侵權和違法行為招致我們的所有損失向我們承擔賠償責任。</p>
        <p style={{marginTop: '30px'}}>我們可有權進一步研發您在我們的平台上提出的意念或其他相似的構思。您同時保證使用有關資料不會及將不會侵犯任何協力廠商的權利。</p>

        <p style={{marginTop: '40px', fontWeight: 'bold'}}><span style={{marginRight: '10px'}}>VI.</span>保險產品資訊</p>
        <p style={{marginTop: '30px'}}>本平台比較香港的保險產品，並在需要的時候對產品進行排列。本平台根據公開的方法（包括用戶的選擇、假設及數學運算）來進行排列。用戶應同時仔細閱讀有關的排列方法，以瞭解排列的參考價值及限制。</p>
        <p style={{marginTop: '30px'}}>本平台的保險產品篩選和排列並沒有考慮您的個人目的、財務狀況及需要。此資料僅供一般性的教育及參考用途，並不構成任何購買保險產品的建議，絕不代表我們提議、暗示、代言、承認、推薦任何一間保險公司或其產品和服務，包括您個人使用的產品或服務的質素或適合性。購買任何保險產品前，您應與持牌保險顧問諮詢專業、適合自己的保險方案。</p>
        <p style={{marginTop: '30px'}}>本網站所提供的信息並非產品或服務的內容和條款的全面描述。您希望購買的每個產品或服務的相關條款將由產品或服務供應商以書面或電子方式提供。這些供應商的條款應優先於與我們關於該產品或服務的描述。</p>
        <p style={{marginTop: '30px'}}>本平台不會就資料來源的準確性、完整性及適時性所引致的任何索償及/ 或損失負上任何責任。</p>

        <p style={{marginTop: '40px', fontWeight: 'bold'}}><span style={{marginRight: '10px'}}>VII.</span>協力廠商連結</p>
        <p style={{marginTop: '30px'}}>除非特別標明，本平台不會持有或控制任何保險公司或於我們的平台上列出的保險公司之產品或服務。本平台亦不會持有、控制、或營運這些保險公司的網站或應用程式。</p>
        <p style={{marginTop: '30px'}}>我們可能會提供其他網站或應用程式的連結以作參考，但我們將無法控制該等網站或應用程式。在您點擊這些連結時，您將會離開我們的平台。我們並未認可或推薦或為這些協力廠商連結之內容及可用性負責，同時不會為您因使用該等網站或應用程式而蒙受的任何損失或破壞而承擔責任。這些網站或應用程式或會有自己的使用條款及細則與私隱政策。在使用這些網站或應用程式前，您應先行查閱其使用條款及細則。假如您決定瀏覽該等連結，您自行承擔其風險。</p>
        <p style={{marginTop: '30px'}}>我們的平台可能會含有由不屬於我們公司或與我們無關人士提供之特別優惠、折扣或推廣的內容或連結。除非特別標明，我們並無贊助、推薦或認可任何協力廠商優惠或這些優惠中涉及的協力廠商資料。</p>

        <p style={{marginTop: '40px', fontWeight: 'bold'}}><span style={{marginRight: '10px'}}>VIII.</span>網上交易</p>
        <p style={{marginTop: '30px'}}>用戶在平台上提交信息並不構成我們或保險公司接受用戶的請求或確認用戶的要約，但我們會在符合法律法規要求的範圍內記錄您提交的信息，以及您的請求是被接受還是被拒絕。</p>
        <p style={{marginTop: '30px'}}>用戶在平台上的投保過程完結均以保險公司接納投保爲準。即使用戶在平台上完成了交易所需步驟及傳送資訊，仍僅屬於投保指示，並不代表投保被保險公司接納或保單生效。</p>
        <p style={{marginTop: '30px'}}>投保時，您必須全面地和忠誠地提供投保所需的所有資料，及申報您已知或應該知道的相關事宜，而這些事宜與保險公司是否願意接受您的保單申請是相關連的。</p>
        <p style={{marginTop: '30px'}}>投保最終是否被接納乃保險公司之決定，本公司並不參與其中的決策，也無法對結果進行控制和保證。</p>
        <p style={{marginTop: '30px'}}>當保險公司就投保指示作出回應後，在法律容許的情況下，用戶會收到電子或書面通知。用戶同意只會透過電子方式收到通訊，除非您提出明確要求或法律有明確要求書面通知。請注意，不管用戶是否收到書面通知，相關合同仍然生效。</p>
        <p style={{marginTop: '30px'}}>假若由於機械、軟件、電腦、電子通訊或電子故障，或其他供應商或系統之遺漏或錯失等我們雙方均無法控制之原因而令我們無法收到您的投保指示或完成交易支付。我們不會為透過我們的平台傳送或提交電子指示而直接或間接造成的任何損失或破壞，或因任何原因致令我們無法接受電子指示而負責。</p>
        <p style={{marginTop: '30px'}}>我們可能在您作出付款後保留您向我們提供的信用卡有限識別資訊（如僅包含部分信用卡數字的識別資訊）和交易識別號，以符合支付卡產業資料安全標準（PCI-DSS）合規性要求，並配合相關的規則和法規，以進行交易驗證。</p>
        <p style={{marginTop: '30px'}}>在透過我們平台提出服務請求時，您同意本公司將您必須的個人資料披露給對應的保險公司以處理您的服務請求。服務請求包括但不限於投保申請，更新保單，索償，糾紛處理等。</p>
        <p style={{marginTop: '30px'}}>我們可能在任何時間基於任何原因而拒絕處理交易。在法律容許的情況下，我們無需因此而向您或任何協力廠商承擔責任。</p>

        <p style={{marginTop: '40px', fontWeight: 'bold'}}><span style={{marginRight: '10px'}}>IX.</span>退款政策</p>
        <p style={{marginTop: '30px'}}>您在我們平台購買的保險產品都是與保險公司簽訂的合同，一旦你簽訂了這些合同，您必須遵守該合同。如果你在任何時間取消，保費的退還完全取決於保單文件内的退款政策。一些保險公司可能會按比例退款，但也有可能不獲退款，另外有些產品會提供冷靜期處理。請細心閲讀保險公司的退款政策。</p>

        <p style={{marginTop: '40px', fontWeight: 'bold'}}><span style={{marginRight: '10px'}}>X.</span>限制使用</p>
        <p style={{marginTop: '30px'}}>您不應以將會或可能會對我們平台及服務造成任何干擾或損害的方式使用我們的平台及服務。您知悉並同意您需就所有由您的電腦發出的電子指示負責。</p>
        <p style={{marginTop: '30px'}}>在您使用我們的平台時，您不可：</p>
        <ul>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>將我們的平台用作商業用途，或用作任何除成為我們平台合法用戶外的其他用途；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>採取任何可能阻礙、干擾或損害平台正常運作的行動；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>採取任何包括利用任何數據累積工具、機械軟件、蜘蛛軟件或其他自動裝置或程式以編譯、發佈、摘錄、處理、監察或複製平台上之任何頁面的行動，包括任何資料、數據、圖像或內容；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>利用任何自動程式以結集或綜合我們平台內及可由我們平台取得之資料、內容或數據，包括可透過平台取得的任何協力廠商資料、內容或數據；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>採取任何會異常地增加我們的平台網絡結構負擔或流量的行動；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>採取任何涉及我們平台的反編譯、逆向工程、反匯編、企圖索取來源碼或其他與我們平台或其他軟件相關的程式或公式的行動；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>在未取得我們的書面批准前抄襲、複製、更改、修改或公開披露我們平台的任何部份或內容。</li>
        </ul>
        <p style={{marginTop: '20px'}}>如干擾或進入平台以意圖阻礙、破壞或停止我們的平台或服務或取得其他商業利益為嚴重行為，我們將採取一切所需行動以保護我們的平台免受破壞，並保護我們的知識產權。</p>
        <p style={{marginTop: '20px'}}>您知悉損害賠償可能不是您侵犯我們權利的充分補償，我們有權申請取得禁令，特定履行命令或其他法庭命令以沒收侵權之文件或物品，並就任何可能或實際侵權取得其他法定或公平的救濟並無需發佈擔保或提供特殊損害賠償的證明。</p>
        <p style={{marginTop: '20px'}}>除非獲得我們的授權，否則您只可利用我們的平台：</p>
        <ul>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>為您自己或家人獲取保險報價；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>為您自己、家人或朋友向我們索取有關保險價格的資料；</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>或為您或家人(需先徵得對方同意) 進行與保險相關的交易或查詢。</li>
        </ul>
        <p style={{marginTop: '20px'}}>除上述指定用途您不得利用我們的平台來獲取報價或向我們索取其他有關我們的保險價格的資料或進行其他與保險相關的交易或查詢。</p>
        <p style={{marginTop: '20px'}}>您不可在我們的平台上插入超連結。您不可以任何方式使用我們的名稱及標誌，包括廣告宣傳或與任何本平台的資料相關的宣傳活動。</p>
        <p style={{marginTop: '20px'}}>您同時同意賠償我們就任何因您違反本使用條款而造成的任何責任或引致的任何損失或開支。</p>

        <p style={{marginTop: '40px', fontWeight: 'bold'}}><span style={{marginRight: '10px'}}>XI.</span>您需要承擔的責任</p>
        <p style={{marginTop: '20px'}}>當使用本平台時，您必須同意及承擔以下責任：</p>
        <ul>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>您不會違法地使用我們的平台，並只作個人用途，非商業用途。</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>您不會使用任何軟件，器材或儀器去（或嘗試去）幹預我們平台的運作。</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>您理解並同意，我們的平台可能會受到多種因素影響，包括但不限於用戶原因、網絡服務質量、社會環境、他人非法利用用戶資料、他人進行現實中的騷擾等、受到惡意程式侵襲如病毒及木馬程式等，這將威脅您的終端設備資訊和數據安全，影響您正常使用本平台。您應加強資訊安全及個人資料保護意識。</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>您有責任保障您在本平台的帳戶安全（用戶名稱、密碼、或任何個人資料），不向任何人士披露您的帳戶資料，以及限制其他人使用您的電腦，從而防止未授權人士登入您的帳戶。您須負責保護您的帳戶保密資訊。我們對此產生的後果或損失不承擔任何法律責任。</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>您需承擔及全權負責您的任何帳戶活動。您同意在有理由相信密碼外洩或被未經授權或非法使用時立即通知我們。</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>您不得製作、發佈、使用、轉播用於竊取他人個人資料、財產或賬號的惡意程式。您不可在未獲得授權下登入其他人士賬戶。如有觸犯可能涉及刑事檢控。</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>您有責任保證資料準確性，並且時刻更新個人資料 。如出現因爲您所提供的資料出錯而導致我們提供的服務或內容出現差錯，我們不會付上任何法律責任。</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>您有責任檢查及確保於我們的平台上列出的保險公司之產品或服務的適用性、實用性、質素、充足性及供應情況，切合您的個人用途。如有需要，在作出任何購買決定前，您應尋求專業意見。</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>•</span>在任何情況下，您不應輕信借款、索要密碼或其他涉及財產的網絡資訊。涉及財產操作的，請一定先核實對方身份，並請經常留意有關防範詐騙犯罪的提示。</li>
        </ul>

        <p style={{marginTop: '40px', fontWeight: 'bold'}}><span style={{marginRight: '10px'}}>XII.</span>個人資料及私隱政策</p>
        <p style={{marginTop: '30px'}}>爲了可以根據您的所需而提供合適的服務，我們有可能收集一些個人資料。您提供您的個人資料以供我們按照收集時所說明的使用時，則表示您已經閱讀和理解我們的私隱政策和個人資料收集聲明，並同意我們收集您的個人資料並將其用於我們的私隱政策和個人資料收集聲明所敘的所有目的及相關用途。我們私隱政策及個人資料收集聲明將作為本條款的組成部分，對於我們會如何收集、使用、存儲、共用和保護您的個人資料及您享有何種權利，您還可以閱讀我們的私隱政策及個人資料收集聲明予以進一步瞭解。我們將遵守香港的個人資料（私隱）條例執行其任務。</p>

        <p style={{marginTop: '40px', fontWeight: 'bold'}}><span style={{marginRight: '10px'}}>XIII.</span>法律與賠償</p>
        <p style={{marginTop: '30px'}}>本條款受香港特別行政區法律所約束，並按香港法律詮釋。閣下同意香港法院具司法管轄權處理因使用本平台而引發的任何爭議。</p>
        <p style={{marginTop: '30px'}}>任何未同意受本條款約束之協力廠商人士在任何司法制度下均無行使以上本條款之任何權利。</p>
        <p style={{marginTop: '30px'}}>假如您違反了本條款 (包括但不限於由偽造保單授權同意引起之情況)，您同意向我們賠償因違反本條款而引致或可能引致的任何及一切費用及開支。您理解並同意，因您違反本條款導致或產生協力廠商主張的任何索賠、要求或損失，您應當獨立承擔責任；如我們因此遭受損失的，您也應當一並賠償。</p>
        <p style={{marginTop: '30px'}}>假如您違反了本條款而我們並未即時對您採取行動，不能構成我們放棄任何權利，我們仍然有權就有關違反或任何其後的違反執行我們的權利或進行追討。</p>
        <p style={{marginTop: '30px'}}>如我們發現或收到他人舉報或投訴用戶違反本條款約定的，我們將保留以下權利:</p>
        <ul>
        <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>1.</span>終止、暫停或中斷您對我們平台的使用，亦可在無需預先通知或提供任何原因的情況底下隨時取消您的帳戶。</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>2.</span>根據我們合理的判斷，隨時刪除、拒絕刊登被認為不適當的用戶內容，但我們沒有責任去檢查、監察用戶內容。</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>3.</span>根據我們合理的判斷，決定什麼用戶內容違反本條款，並將有關不適當的內容刪除。</li>
          <li style={{marginLeft: '15px', display: 'flex', flexDirection: 'row'}}><span style={{marginRight: '5px'}}>4.</span>如果我們未有立刻根據本條款行使或履行我們的權利，我們將繼續有權在任何時候行使或履行這些權利。</li>
        </ul>
        <p style={{marginTop: '30px'}}>如果本條款無法執行、無效、有空缺、不適用或在任何司法管轄區無效，我們可以有效、可執行、（目的）相近的規定去代替原有條款，而其餘的條款及細則將仍具十足效力及約束力。</p>
        <p style={{marginTop: '30px'}}>本條款為最終決定，構成您與我們之間就使用本平台的完整協議，比與使用本平台服務的所有之前的口頭或書面協議擁有更大的效力，假如本條款與上述的口頭或書面協議有不一致的地方，以本條款為准。</p>

        <p style={{marginTop: '40px', fontWeight: 'bold'}}><span style={{marginRight: '10px'}}>XIV.</span>免責聲明</p>
        <p style={{marginTop: '30px'}}>當您使用本平台和我們的服務時，我們即認定您已經閱讀、理解並同意本條款。</p>
        <p style={{marginTop: '30px'}}>我們不會為您與保險公司的任何互動或交易﹙包括其建議、意見、或合同所引致的損失﹚負上任何法律負責。</p>
        <p style={{marginTop: '30px'}}>您同意在任何情況下，本公司、其執行官、雇員、代表、股東、絕不需因任何跟本平台直接或間接引起或造成的任何損失、 索償或損害賠償（包括但不限於直接、 間接、 偶然、 特殊、 懲罰性或相應的損害賠償，業務或利潤損失）負任何責任。我們 (包括我們所有僱員、高級職員及董事) 不會承擔任何直接或間接的經濟損失或任何收入、數據、利潤、合約、使用權、機會、業務或預期節省的損失；因使用我們的平台而蒙受或引起或相關的良好品格或聲譽或任何特別、偶然發生、後果性的損失或破壞 (即使我們知悉有關損失)；或未能遵守或違反本條款之責任。</p>
      </div>
    </div>
  )
}
