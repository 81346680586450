/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Form, Input, Select, Button, message, Spin } from 'antd'
import { Footer} from '../../components'
import style from './Contact.module.scss'
// import { Loader } from "@googlemaps/js-api-loader"
import { assetsUrl } from '../../utils/constants'
import { selectArrow } from '../../assets'
import { isLegalEmail, isLegalHKPhone} from '../../utils'
import { submitCustomerReserve } from '../../services/api'
import { useSelector } from 'react-redux'
import { setFontSize } from '../../utils'
import scrollIntoView from 'scroll-into-view-if-needed'
export function Contact () {
  const { query } = useLocation()
  const scale = useSelector(state => state.font.scale)
  useEffect(() => {
    if (query && query.scroll) {
      const node = document.getElementById('contact-us')
      scrollIntoView(node, { behavior: 'smooth'})
    } else {
      window.scrollTo(0, 0)
    }
    // initGoogleMap()
  }, [query])
  const [tabIndex, setTabIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const initialValues = {
    surname: '',
    name: '',
    gender: undefined,
    areaCode: '+852',
    phone: '',
    email: '',
    idealContactTime: '',
    applyinfo: ''
  }
  const [formData, setFormData] = useState({ ...initialValues, })
  const [form] = Form.useForm()
  // const initGoogleMap = () => {
  //   const loader = new Loader({
  //     apiKey: google_api_key,
  //     version: "weekly",
  //     libraries: ['places']
  //   });
  //   loader.load().then(() => {
  //     // var map = new google.maps.Map(document.getElementById("map"), {
  //     //   center: { lat: 22.287195347386586, lng: 114.21177506446838 },
  //     //   zoom: 18,
  //     //   panControl: false,
  //     //   mapTypeControl: false,
  //     //   fullscreenControl: false
  //     // })
  //     //  "lat": 22.287195347386586,
  //     // "lng": 114.21177506446838
  //     //{ lat: 22.287383845149684, lng: 114.21177524528241 }
  //     //22.28727479944417, 114.21179377546162
  //     // const marker = new google.maps.Marker({
  //     //   position: { lat: 22.28727479944417, lng: 114.21179377546162 },
  //     //   map: map,
  //     // })
  //     // const infowindow = new google.maps.InfoWindow({
  //     //   content: "<p>Marker Location:" + marker.getPosition() + "</p>",
  //     // });
  //     // google.maps.event.addListener(marker, "click", () => {
  //     //   console.log('mouseenter')
  //     //   infowindow.open(map, marker);
  //     // })
  //     // var marker = new google.maps.Marker({
  //     //   map,
  //     //   draggable: true,
  //     //   animation: google.maps.Animation.DROP,
  //     //   position: { lat: 22.287195347386586, lng: 114.21177506446838 },
  //     // });
  //     // map.addListener("click", (mapsMouseEvent) => {
  //     //   // Close the current InfoWindow.
  //     //   console.log(JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2))
  //     //   // infoWindow.close();
  //     //   // // Create a new InfoWindow.
  //     //   // infoWindow = new google.maps.InfoWindow({
  //     //   //   position: mapsMouseEvent.latLng,
  //     //   // });
  //     //   // infoWindow.setContent(
  //     //   //   JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
  //     //   // );
  //     //   // infoWindow.open(map);
  //     // });
  //     // marker.addListener("mousemove", () => {

  //     //   // if (marker.getAnimation() !== null) {
  //     //   //   marker.setAnimation(null);
  //     //   // } else {
  //     //   //   marker.setAnimation(google.maps.Animation.BOUNCE);
  //     //   // }
  //     // })
  //   });
  // }
  const onTabChange = (idx) => {
    if (tabIndex === idx) {
      return
    }
    setTabIndex(idx)
    let fields = []
    if (formData.surname.length === 0) {
      fields.push('surname')
    }
    if (formData.name.length === 0) {
      fields.push('name')
    }
    if (!formData.gender) {
      fields.push('gender')
    }
    if (formData.phone.length === 0) {
      fields.push('phone')
    }
    if (formData.email.length === 0) {
      fields.push('email')
    }
    if (fields.length > 0) {
      form.resetFields(fields)
    }
  }
  return <div className={style['contact-container']}>
    {/* <Header /> */}
    <img className={style['banner']} src={assetsUrl + 'images/icon_contact_banner.png'} alt=''/>
    {/* <div id="map" style={{height: '320px'}}></div> */}
    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d391565.2558546159!2d116.11727013234623!3d39.9390731255796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35f05296e7142cb9%3A0xb9625620af0fa98a!2z5Lit5Zu95YyX5Lqs5biC!5e0!3m2!1szh-CN!2sjp!4v1499172397317" ></iframe> */}
    <iframe src='https://www.google.com/maps?q=中環中心&hl=zh-CN&output=embed' style={{height: '320px'}} title='聯繫我們'></iframe>
    <div className={style['main']}>
      <p className={style['banner-title']} style={setFontSize(50, scale)}>聯絡我們</p>
      <div className={style['contact-way']}>
        <div className={style['mail-box']} style={{marginRight: '325px'}}>
          <img src={assetsUrl + 'images/icon_contact_email.png'} alt=''/>
          <p style={setFontSize(40, scale)}>電郵地址</p>
          <a style={setFontSize(26, scale)} target="_self" href='mailto:admin@huize.com.hk'>admin@huize.com.hk</a>
        </div>
        <div className={style['mail-box']} style={{marginRight: '0px'}}>
          <img src={assetsUrl + 'images/icon_contact_phone.png'} alt=''/>
          <p style={setFontSize(40, scale)}>客戶服務熱線</p>
          <a style={setFontSize(26, scale)} target="_self" href='tel:+85227899923'>852-2789 9923</a>
        </div>
      </div>
      <Spin id='contact-us'  spinning={loading} style={{maxHeight: 'none'}}>
        <div className={style['contact-now']}>
          <div className={style['bg-box']}>
            <div className={style['content']}>
              <p className={style['title']} style={setFontSize(58, scale)}>聯絡我們</p>
              <p className={style['sub-title']} style={setFontSize(40, scale)}>{'專業團隊，以客為先 \n「幫你揀‧助你賠」'}</p>
            </div>
          </div>
          <div className={style['form-container']}>
            <div className={style['tabs-box']}>
              <div className={[`${style['tabs-item']}`, tabIndex === 0 ? `${style['tabs-item-active']}` : ''].join(' ')} onClick={e => onTabChange(0)}>
                <span style={setFontSize(26, scale)}>約見方案專家</span>
              </div>
              <div className={[`${style['tabs-item']}`, tabIndex === 1 ? `${style['tabs-item-active']}` : ''].join(' ')} onClick={e => onTabChange(1)}>
                <span style={setFontSize(26, scale)}>一般查詢</span>
              </div>
            </div>
            <Form
            form={form}
            initialValues={initialValues}
            scrollToFirstError={true}
            onFinish={(values) => {
              console.log('onFinish：', values)
              if (!isLegalHKPhone(values.phone)) {
                message.error('请填寫正确的手機號碼')
              } else if (values.email.length > 0 && !isLegalEmail(values.email)) {
                message.error('请填寫正确的電郵地址')
              } else {
                setLoading(true)
                submitCustomerReserve({
                  surname: values.surname,
                  name: values.name,
                  sex: values.gender === '女士'  ? 0 : 1,
                  type: tabIndex,
                  mobile: '+852' + values.phone,
                  email: values.email,
                  contactTime: tabIndex === 0 ? values.idealContactTime : '',
                  requirement: values.applyinfo
                }).then(res => {
                  setLoading(false)
                  if (res.data.code === 0) {
                    message.success('提交成功')
                  } else {
                    message.error(res.data.message)
                  }
                }).catch(e => {
                  setLoading(false)
                  message.error(e.message)
                })
              }
            }}>
              {/* <div className={style['input-label']}>
                <div className={style['label-box']} style={{ width: '34.5%', marginRight: '2.5%'}}>
                  <p>姓</p>
                  <span>*</span>
                </div>
                <div className={style['label-box']} style={{ width: '63%' }}>
                  <p>名</p>
                  <span>*</span>
                </div>
              </div> */}
              <Form.Item style={{ marginTop: '24px', marginBottom: 0}}>
                <Input.Group compact>
                  <Form.Item
                    name="surname"
                    style={{ width: '34.5%', marginRight: '2.5%'}}
                    rules={[{ required: true, message: '*请填寫您的姓' }]}
                  >
                    <Input size={'large'} maxLength={25} placeholder="姓(必填)"  onChange={ e => {
                      setFormData(state => {
                        return {
                          ...state,
                          surname: e.target.value
                        }
                      })
                    }}/>
                  </Form.Item>
                  <Form.Item
                    style={{ width: '63%' }}
                    name="name"
                    rules={[{ required: true, message: '*请填寫您的名' }]}>
                    <Input size={'large'} maxLength={25} placeholder="名(必填)"  onChange={ e => {
                      setFormData(state => {
                        return {
                          ...state,
                          name: e.target.value
                        }
                      })
                    }}/>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                name="gender"
                rules={[{ required: true, message: '*请选择您的性別' }]}>
                <Select size={'large'} placeholder="性別(必填)" suffixIcon={<img src={selectArrow} alt=''style={{height: '8px'}}/>} onChange={value => {
                  console.log(value)
                  setFormData(state => {
                    return {
                      ...state,
                      gender: value
                    }
                  })
                }}>
                  <Select.Option value="先生">先生</Select.Option>
                  <Select.Option value="女士">女士</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item style={{ marginBottom: '0' }}>
                <Input.Group compact>
                  <Form.Item
                    name="areaCode"
                    style={{ width: '34.5%', marginRight: '2.5%'}}
                  >
                    <Select size={'large'} suffixIcon={<img src={selectArrow} alt='' style={{height: '8px'}}/>}>
                      <Select.Option value="+852">+852</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ width: '63%' }}
                    name="phone"
                    rules={[{ required: true, message: '*请填寫您的手機號碼' }]}>
                    <Input size={'large'} type='number' maxLength={25} placeholder="手機號碼(必填)"  onChange={ e => {
                      setFormData(state => {
                        return {
                          ...state,
                          phone: e.target.value
                        }
                      })
                    }}/>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                style={{marginTop: '0px', marginBottom: '0' }}>
                  <Input.Group compact>
                    <Form.Item
                      name="email"
                      style={{ width: '100%'}}>
                      <Input size={'large'} maxLength={254} placeholder="電郵地址"  onChange={ e => {
                        setFormData(state => {
                          return {
                            ...state,
                            email: e.target.value
                          }
                        })
                      }}/>
                    </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                style={{ marginTop: '0px', marginBottom: '0', display: tabIndex === 0 ? 'flex' : 'none'}}>
                  <Input.Group compact>
                    <Form.Item
                      name="idealContactTime"
                      style={{ width: '100%'}}
                      >
                      <Input size={'large'} maxLength={60} placeholder="理想聯絡時間"/>
                    </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                name="applyinfo"
                style={{ marginTop: '0px' }}>
                <Input.TextArea style={{height: tabIndex === 0 ? '70px' : '140px' }} maxLength={500} placeholder= {tabIndex === 0 ? '預約信息' : '查詢內容'}/>
              </Form.Item>
              <div className={style['protocol-box']}>
                本人提交此表格表示本人已細閱並同意慧擇香港的<a href="/privacy-policy" target="_blank">私隱政策</a>、<a href="/terms-of-use" target="_blank">使用條款</a>及<a href="/personal-information" target="_blank">個人資料收集聲明</a>。我們的專家會盡快聯絡閣下。
              </div>
              <Form.Item
              style={{marginTop: '24px'}}
              >
                <div className={style['submit-btn']} >
                  <Button style={setFontSize(18, scale)} type="primary" htmlType="submit">提交</Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    </div>
    <Footer />
  </div>
}