import React from 'react'
import { Tooltip } from 'antd'
import style from './InsuranceProduct.module.scss'
import { assetsUrl } from '../../utils/constants'
import { useHistory } from 'react-router-dom'
export function InsuranceProduct ({ firstComparedId = '' ,product, index, productLength, addCompareAction, closeSearchAction}) {
  // console.log('firstComparedId: ' + firstComparedId)
  // console.log(product.productName + '的insuranceCategoryId: ' + product.insuranceCategoryId)
  const history = useHistory()
  const coverageStyle = () => {
    let width = '23.5%'
    if (product.coverageList.length === 1) {
      width = '100%'
    } else if (product.coverageList.length === 2) {
      width = '49%'
    } else if (product.coverageList.length === 3) {
      width = '32%'
    }
    return width
  }
  return <div className={style['product-item']}>
    <div className={style['top']}>
      <p className={style['name']}>{product.brandCompanyName}</p>
      <div className={style['compare-box']} style={{opacity: (firstComparedId.length > 0 && firstComparedId !== product.insuranceCategoryId + '') || product.selected || productLength === 4 ? '0.5' : '1.0'}} onClick={e => {
        e.stopPropagation()
        // 分类不同的商品不能一起比较
        if (firstComparedId.length > 0 && firstComparedId !== product.insuranceCategoryId + '') {
          return
        }
        if (product.selected) {
          return
        }
        if (productLength === 4) {
          return
        }
        addCompareAction(product, index)
      }}>
        <img src={ assetsUrl + (product.selected ? 'images/icon_compare_check.png' : 'images/icon_compare_uncheck.png') } alt=''/>
        <p style={{color: (firstComparedId.length > 0 && firstComparedId !== product.insuranceCategoryId) || product.selected || productLength === 4 ? '#447EDA' : '#333333'}}>加入比較</p>
      </div>
    </div>
    <div className={style['product-info']}>
      <div className={style['product-detail']}>
        {
          product.brandPicUrl ? <img src={product.brandPicUrl} alt=''/> : ''
        }
        <Tooltip title={product.productName}>
          <p>{product.productName}</p>
        </Tooltip>
      </div>
      <p className={style['product-price']}>{product.minPremiumString}</p>
    </div>
    <div className={style['tag-box']}>
      {
        product.tags.map((el, idx) => {
          return <span key={idx}>{el}</span>
        })
      }
    </div>
    <div className={style['bottom']}>
      {
        (product.coverageList && product.coverageList.length > 0) ? <div className={style['insured-amount']}>
          {
            product.coverageList.map((el, idx) => {
              return <div className={style['insured-box']} key={idx} style={{width: coverageStyle()}}>
                <span>{el.coverageDesc}</span>
                <span>{el.coverageContent}</span>
              </div>
            })
          }
        </div> : <div></div>
      }
      <div className={style['check-detail']} onClick={
          (e) => {
            // history.push(`/product-detail/${product.productId}`)
            // closeSearchAction()
            e.stopPropagation();
          }
        }>
        <span>
          <a href={window.location.origin + '/product-detail/' + product.productId} target="view_window">詳細訊息</a>
        </span>
      </div>
    </div>
  </div>
}