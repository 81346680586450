import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import style from './Header.module.scss'
import { menus, navTItles } from './mockup'
import { Search } from '../../components'
import { useHistory, useLocation } from 'react-router-dom'
// import { logo } from '../../assets/index'
import { useSelector, useDispatch } from 'react-redux'
import { assetsUrl } from '../../utils/constants'
import { setFontSize } from '../../utils/index'
import { setFontScale } from '../../redux/fontScale/slice'
import { setHeaderSelectIdx } from '../../redux/globalData/slice'
export function Header () {
  message.config({
    maxCount: 1
  })
  const dispatch = useDispatch()
  let searchRef = React.createRef();
  const hisistory = useHistory()
  const location = useLocation()
  const user = useSelector(state => state.user.data)
  const scale = useSelector(state => state.font.scale)
  const selectIdx = useSelector(state => state.globalData.headerSelectIdx)
  const [inputState, setInputState] = useState({
    value: '',
    focus: false
  })
  const [fontIndex, setFontIndex] = useState(1)
  const [openSearch, setOpenSearch] = useState(false)
  const closeHeaderSearch = () => {
    setOpenSearch(false)
    document.getElementById('input-box').focus()
    document.body.style.overflow = 'inherit'
    searchRef.current.reset()
  }
  useEffect(() => {
    if (!openSearch) {
      setInputState(state => {
        return {
          ...state,
          value: '',
          focus: false
        }
      })
      setOpenSearch(false)
    }
    // console.log(selectIdx)
    // dispatch(setHeaderSelectIdx(openSearch ? 1009 : -1))
  }, [openSearch])
  return (
    <div className={style['header-contanier']} style={setFontSize(18, scale)}>
      <div className={style['app-header']} onClick={e => {
        if (openSearch) {
          setOpenSearch(false)
          document.body.style.overflow = 'inherit'
        }
      }}>
        <div className={style['top-header']}>
          <div className={style['top-content']}>
            <div className={style['font-box']} >
              <div className={style['font-small']} style={{opacity: fontIndex === 0 ? '1.0' : '0.5'}} onClick={e => {
                setFontIndex(0)
                dispatch(setFontScale(0.8))
              }}>
                <p>A</p>
                <span style={{display: fontIndex === 0 ? 'inline-block' : 'none'}}></span>
              </div>
              <div className={style['font-default']} style={{opacity: fontIndex === 1 ? '1.0' : '0.5'}} onClick={e => {
                setFontIndex(1)
                dispatch(setFontScale(1))
              }}>
                <p>A</p>
                <span style={{display: fontIndex === 1 ? 'inline-block' : 'none'}}></span>
              </div>
              <div className={style['font-big']} style={{opacity: fontIndex === 2 ? '1.0' : '0.5'}} onClick={e => {
                setFontIndex(2)
                dispatch(setFontScale(1.3))
              }}>
                <p>A</p>
                <span style={{display: fontIndex === 2 ? 'inline-block' : 'none'}}></span>
              </div>
            </div>
            <div className={style['contact-box']}>
              {
                navTItles.map(el => {
                  return <span key={el.title} className={[`${style['customer-support']}`, selectIdx === el.id && !openSearch ? `${style['customer-support-select']}` : ''].join(' ')} onClick={() => {
                    if (el.path.length === 0) {
                      return
                    }
                    if (el.path !== location.pathname) {
                      hisistory.replace(el.path)
                      dispatch(setHeaderSelectIdx(el.id))
                    }
                  }}>{el.title}</span>
                })
              }
              {/* <span className={style['login-btn']} onClick={() => {
                if (!user) {
                  if ('/login' !== location.pathname) {
                    hisistory.replace('/login')
                  }
                }
                dispatch(setHeaderSelectIdx(-1))
              }}>{user ? '个人中心' : '登錄'}</span> */}
            </div>
          </div>
        </div>
        <div className={style['nav-bar']} onClick={e => {
        if (openSearch) {
          setOpenSearch(false)
          document.body.style.overflow = 'inherit'
        }
      }}>
          <div className={style['nav-bar-container']}>
            <div className={style['logo-box']} onClick={() => {
              hisistory.replace('/')
              dispatch(setHeaderSelectIdx(-1))
            }}>
              <img src={assetsUrl + 'images/logo.png'} alt='' />
            </div>
            <div className={style['action-box']}>
              <div className={style['menu-list']} >
                {
                  menus.map(el => {
                    return <div  className={[`${style['menu-item']}`, selectIdx === el.id && !openSearch ? `${style['menu-item-select']}` : ''].join(' ')} key={el.title} style={setFontSize(20, scale)} onClick={() => {
                      if (el.path.length === 0) {
                        return
                      }
                      if (el.path !== location.pathname) {
                        hisistory.replace(el.path)
                        dispatch(setHeaderSelectIdx(el.id))
                      }
                      // else if (location.pathname === '/insurance-policy') {
                      //   window.location.reload()
                      // }
                      // hisistory.replace(el.path)
                      // window.location.reload()
                    }}>
                      <span>{el.title}</span>
                    </div>
                  })
                }
              </div>
              <div className={style['contact-us-overlap-zone']}>
                <div className={[`${style['search-box']}`, openSearch ? `${style['search-box-select']}` : ''].join(' ')}  onClick={() => {
                  setOpenSearch(!openSearch)
                  document.getElementById('input-box').focus()
                  document.body.style.overflow = !openSearch ? 'hidden' : 'inherit'
                  if (!openSearch) {
                    searchRef.current.reset()
                  }
                }}>
                  <img alt='' />
                  {/* <img src={assetsUrl + 'images/icon_home_search.png'} alt='' /> */}
                  <span>搜索保險產品</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={[`${style['search-nav']}`, openSearch ? `${style['search-nav-animate']}` : ''].join(' ')} >
        <div className={style['input-box']} style={{borderColor: inputState.focus ? '#1E4994' : (inputState.value.length > 0 ? '#333333' : '#D3D3D3')}}>
          <img src={assetsUrl + 'images/icon_search_gloable.png'} alt='' style={{display: inputState.value.length > 0 ? 'none' : 'block'}} />
          <input id='input-box' value={inputState.value} maxLength="20" placeholder='搜索保險產品' onChange={e => {
            setInputState(state => {
              return {
                ...state,
                value: e.target.value
              }
            })
          }} onFocus={e => {
            setInputState(state => {
              return {
                ...state,
                focus: true
              }
            })
          }} onBlur={e => {
            setInputState(state => {
              return {
                ...state,
                focus: false
              }
            })
          }} onKeyPress={e => {
            if (e.code === 'Enter') {
              e.preventDefault()
              // if (inputState.value.length === 0) {
              //   message.warning('請輸入需要搜索的保險產品名稱')
              //   return
              // }
              searchRef.current.updataKeyword(inputState.value)
              searchRef.current.search()
            }
          }}/>
        </div>
        <span style={setFontSize(16, scale)} onClick={e => {
          // if (inputState.value.length === 0) {
          //   message.warning('請輸入需要搜索的保險產品名稱')
          //   return
          // }
          searchRef.current.updataKeyword(inputState.value)
          searchRef.current.search()
        }}>搜索</span>
      </div>
      <div className={style['search-container']} style={{display: openSearch ? 'flex' : 'none'}} onClick={() => {
          document.body.style.overflow = 'inherit'
          setOpenSearch(false)
      }}>
        <Search closeHeaderSearch={closeHeaderSearch} onRef={searchRef} openSearch={openSearch} />
      </div>
    </div>
  )
}