import '../utils/constants'
import { assetsUrl } from '../utils/constants'
const logo = assetsUrl + 'images/logo.png'
const logo_white = assetsUrl + 'images/logo_white.png'
const address = assetsUrl + 'images/icon_home_address.png'
const mail = assetsUrl + 'images/icon_home_mail.png'
const contact = assetsUrl + 'images/icon_home_phone.png'
const facebook = assetsUrl + 'images/icon_home_facebook.png'
const youtube = assetsUrl + 'images/icon_home_youtube.png'
const linked = assetsUrl + 'images/icon_home_linkedin.png'
const wechat = assetsUrl + 'images/icon_home_wechat.png'
const banner  = assetsUrl + "images/icon_banner_bg_2.png";
const checkbox = assetsUrl + 'images/icon_home_banner_gouxuan.png'
const arrow = assetsUrl + 'images/icon_more_arrow.png'
const expandDown = assetsUrl + 'images/icon_home_expand_down.png'
const rightArrow = assetsUrl + 'images/icon_home_arrow.png'
const blueRightArrow = assetsUrl + 'images/icon_btn_right.png'
const blackRightArrow = assetsUrl + 'images/icon_btn_right_black.png'
const moreArrow = assetsUrl + 'images/icon_more_arrow_new.png'
const homeSearch = assetsUrl + 'images/icon_home_search.png'
const preservation = assetsUrl + 'images/icon_preservation.png'
const helpCenter = assetsUrl + 'images/icon_help_center.png'
const claims = assetsUrl + 'images/icon_support_claims.png'
const selectArrow = assetsUrl + 'images/icon_select_arrow.png'
const leftControl = assetsUrl + 'images/icon_left_control.png'
const rightControl = assetsUrl + 'images/icon_right_control.png'
const leftBlueControl = assetsUrl + 'images/icon_left_blue_control.png'
const rightBlueControl = assetsUrl + 'images/icon_right_blue_control.png'
const addInsurancePolicy = assetsUrl + 'images/icon_add_insurance_policy.png'
const uploadSuccess = assetsUrl + 'images/icon_upload_success.png'

const selectCheckbox = assetsUrl + 'images/icon_checkbox_s .png'
const normalCheckbox = assetsUrl + 'images/icon_checkbox_n.png'
const loginFacebook = assetsUrl + 'images/icon_login_facebook.png'
const loginGoogle = assetsUrl + 'images/icon_login_google.png'

const company = assetsUrl + 'images/icon_company.png'
const facebookWhite = assetsUrl + 'images/icon_facebook_new.png'
const instagram = assetsUrl + 'images/icon_Instagram.png'
const location = assetsUrl + 'images/icon_location.png'
const mailNew = assetsUrl + 'images/icon_mail.png'
const telephone = assetsUrl + 'images/icon_telephone.png'
const youtubeWhite = assetsUrl + 'images/icon_youtube_new.png'

export {
  logo,
  logo_white,
  mail,
  address,
  contact,
  facebook,
  youtube,
  linked,
  wechat,
  banner,
  checkbox,
  arrow,
  expandDown,
  rightArrow,
  blueRightArrow,
  blackRightArrow,
  moreArrow,
  homeSearch,
  preservation,
  helpCenter,
  claims,
  selectArrow,
  leftControl,
  rightControl,
  leftBlueControl,
  rightBlueControl,
  addInsurancePolicy,
  uploadSuccess,
  selectCheckbox,
  normalCheckbox,
  loginFacebook,
  loginGoogle,
  company,
  facebookWhite,
  instagram,
  location,
  mailNew,
  telephone,
  youtubeWhite
}