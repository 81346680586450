export const tabInfo = [
  {
    title: '理賠',
    type: 'CLAIMS',
    canUploadFile: false,
    reqIsShow: false,
    reqPlaceholder: '',
    reqWidth: '0',
  },
  {
    title: '保單更改服務',
    type: 'CHANGE',
    canUploadFile: false,
    reqIsShow: true,
    reqPlaceholder: '更改內容說明',
    reqWidth: '100%',
  },
  {
    title: '保單診斷',
    type: 'DIAGNOSIS',
    canUploadFile: true,
    reqIsShow: true,
    reqPlaceholder: '簡單說明你要診斷的問題',
    reqWidth: '100%',
  },
  {
    title: '孤兒單跟進',
    type: 'FOLLOWING',
    canUploadFile: true,
    reqIsShow: true,
    reqPlaceholder: '簡單說明跟進內容',
    reqWidth: '100%',
  }
]