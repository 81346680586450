import React from "react";
import { Link } from 'react-router-dom'
import styles from "./Footer.module.scss"
// import { useTranslation } from "react-i18next";
import { assetsUrl } from '../../utils/constants'
import { useSelector } from 'react-redux'
import { setFontSize } from '../../utils/index'
export function Footer ({ isHome }) {
  // const { t } = useTranslation()
  // console.log(isHome || false)
  const scale = useSelector(state => state.font.scale)
  return (
    <div className={styles.footer}>
      <div className={styles.main}>
        <div className={styles['top-box']}>
          <div className={styles['company-profile']} style={setFontSize(14, scale)}>
            <div className={styles['detail-box']}>
              <p className={styles['company-name']} style={setFontSize(16, scale)}>慧擇香港保險經紀有限公司(IA Licence No.保險經紀牌照號碼：FB1661)</p>
            </div>
            <div className={styles['detail-box']}>
              <img src={assetsUrl + 'images/icon_footer_location.png'} alt=""/>
              <p>香港中環皇后大道中99號中環中心1911室</p>
            </div>
            <div className={styles['detail-box']}>
              <img src={assetsUrl + 'images/icon_footer_telephone.png'} alt=""/>
              <p> (+852) 2789 9923</p>
            </div>
            <div className={styles['detail-box']}>
              <img src={assetsUrl + 'images/icon_footer_mail.png'} alt=""/>
              <p>admin@huize.com.hk</p>
            </div>
          </div>
          <div className={styles['contact-us']}>
            <p className={styles['contact-title']} style={setFontSize(16, scale)}>關注慧擇香港</p>
            <div className={styles['share-box']} >
              <a href='https://www.facebook.com/Huize-Hong-Kong-%E6%85%A7%E6%93%87%E9%A6%99%E6%B8%AF-102965525316079/' target="view_window">
                <img src={assetsUrl + 'images/icon_footer_facebook.png'} alt="facebook" />
                {/* <img src={assetsUrl + 'images/icon_facebook_footer.svg'} str alt="facebook" /> */}
              </a>
              <a href='https://www.instagram.com/huize_hk/' target="view_window">
                <img src={assetsUrl + 'images/icon_footer_instagram.png'} alt="instagram"/>
              </a>
              <a href='https://www.youtube.com/channel/UCAda2qgwz2-UgHr6ntLzjyA' target="view_window">
                <img src={assetsUrl + 'images/icon_footer_youtube.png'} alt="youtube" />
              </a>
              {/* <img src='https://www.10life.com/images/user_center_qna/question_white.png' style={{width: '35px', height: '35px'}} alt=""/> */}
            </div>
          </div>
        </div>

        <div className={styles['bottom-box']} style={setFontSize(14, scale)}>
          <p style={setFontSize(12, scale)}>版權 ©2021 慧擇香港保險經紀有限公司。版權所有。</p>
          <div className={styles['intro-box']}>
            <a className={styles['intro-title']} style={{marginRight: '85px'}} href="/terms-of-use" target="_blank">使用條款</a>
            <a className={styles['intro-title']} href="/privacy-policy" target="_blank">私隱政策</a>
          </div>
        </div>
      </div>
    </div>
  )
}