import { assetsUrl } from  '../../utils/constants'
export const categorys = ['全部', '人壽保障', '儲蓄保險', '年金', '醫療保險', '危疾保險', '意外及傷病保險', '投資相連保險']
export const products = [
  {
    cover: assetsUrl + 'images/icon_product_promotion_cover1.png',
    category: '人壽保障',
    desc: '人壽保險為您及您家人提供及時的經濟支援。您甚至可以為退休保障及傳承做好準備，早日達到心目中的財務目標。人壽保險大致分為幾大類。定期人壽保險和終身人壽保險會在投保人身故後提供一筆賠償，給予您的家人或受供養人財務保障，其中定期人壽保險設有一定保障期限，例如10年或20年。定期壽險的特點是保費較低、保額較高，讓您用最實惠價錢為家人提供最大保障。而終身人壽保險兼具保障及儲蓄功能，若投保人在世時取消保單可取回一定現金。另一種萬用人壽保險則擁有高度靈活性，同時擁有人壽保險及資產增值的優勢，助您傳承財富。'
  },
  {
    cover: assetsUrl + 'images/icon_product_promotion_cover2.png',
    category: '儲蓄保險',
    desc: '儲蓄保險讓您在未來日子裡同時享有累積財富及周全保障。您可將現金儲備用於退休後作為穩定收入來源的一部分，再結合人壽保障的防護盾，不但助您早日實現人生目標，更為您和您的摯愛家人提供安心保障或作傳承之用。'
  },
  {
    cover: assetsUrl + 'images/icon_product_promotion_cover3.png',
    category: '年金',
    desc: '讓您在將來退休時，提供豐盛而安穩的收入來源的一部分。年金可分為即期年金和延期年金。即期是指一筆過繳保費後，隨即領取年金。延期是指一筆過或保費繳付期屆滿後，在指定入息期開始提取年金。'
  },
  {
    cover: assetsUrl + 'images/icon_product_promotion_cover4.png',
    category: '醫療保險',
    desc: '面對突如其來的醫療開支可令您大失預算，醫療保險不單提供全面的實報實銷醫療保障，包括住院丶手術丶藥物及其它相關醫療服務的費用外，也提供優質醫療網絡及專科支援，讓您安心配合您的預算及醫療需要。我們的醫療保險亦有住院現金保障計劃，就住院日數提供定額現金賠償，為您提供額外經濟支援，彌補患病或住院期間蒙受的收入損失，避免影響您和您家人的正常生活。'
  },
  {
    cover: assetsUrl + 'images/icon_product_promotion_cover5.png',
    category: '危疾保險',
    desc: '危疾保險讓您有充裕資金作後盾，戰勝病魔。受保人一旦確診指定受保疾病，即可獲一筆過現金賠償。您可以自由運用賠償金，用於支付醫療費用，無需因為擔心昂貴醫療費用而延誤了治療。'
  },
  {
    cover: assetsUrl + 'images/icon_product_promotion_cover6.png',
    category: '意外及傷病保險',
    desc: '意外是意料之外的事，一旦不幸發生在您身上，可影響您及您家人的生活，甚至可能令家庭經濟面對沉重負擔。意外保險計劃及傷病入息保障計劃為意外導致的受傷、傷殘及身故提供保障，讓您及時獲得必要的財政支援。'
  },
  // {
  //   cover: assetsUrl + 'images/icon_product_promotion_cover7.png',
  //   category: '萬用保險',
  //   desc: '讓您享有長遠儲蓄帶來穩定的財富儲備，為傳承所愛作準備。萬用壽險可靈活自主保費安排，可調節身故權益，更可免費部分退保(需要配合部分退保條款)，資金輕易調配，滿足人類生不同階段的需要。'
  // },
  {
    cover: assetsUrl + 'images/icon_product_promotion_cover8.png',
    category: '投資相連保險',
    desc: '讓您可自訂投資選項的人壽保險計劃， 可提供資本增值的長線投資產品同時包含有投資及保險成分，兼顧投資及遺產策劃目標。投資相連壽險通常設有鎖定期，須留意保單價值受投資風險及市場波動影響。'
  }
]