import React, { useState } from 'react'
import { Form, Input, Select, Button, message } from 'antd'
import { useMountedRef } from '../../utils/hook'
import { useHistory ,useLocation } from 'react-router-dom'
import { selectArrow } from '../../assets'
import style from './InsuranceCustomization.module.scss'
import './select.css'
import { assetsUrl } from '../../utils/constants'
import { isLegalEmail  } from '../../utils'
import { sendInsuranceCustomerVerifyCode, submitInsuranceCustomer } from '../../services/api'
let nums = 180
var interval = null
const category = JSON.stringify([
  {
    name: '人壽儲蓄',
    select: false
  }, 
  {
    name: '醫療',
    select: false
  },
  {
    name: '年金',
    select: false
  },
  {
    name: '意外',
    select: false
  },
  {
    name: '危疾',
    select: false
  }
]) 
export function InsuranceCustomization ({scale}) {
  const location = useLocation()
  const history = useHistory()
  const mountedRef = useMountedRef()
  const inputRef = React.useRef(null);
  const initialValues = {
    surname: '',
    name: '',
    gender: undefined,
    phone: '',
    verifyCode: '',
    email: '',
    other: ''
  }
  const [plainOptions, setPlainOptions] = useState(JSON.parse(category))
  const [otherOption, setOtherOption] = useState({
    name: '其他',
    select: false
  })
  const [inputCompleted, setInputCompleted] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [formData, setFormData] = useState({ ...initialValues })
  const [countdown, setCountdown] = useState({
    desc: '發送驗證碼',
    disabled: false
  })
  const sendVerifyCode = () => {
    sendInsuranceCustomerVerifyCode({
      mobile: formData.phone,
      mobileArea: 'HK'
    }).then(res => {
      if (res.data.code === 0) {
        message.success('驗證碼發送成功')
      } else {
        message.error(res.data.message)
        destroyInterval()
      }
    })
  }
  const submit = () => {
    let options = plainOptions.filter(el => el.select).map(el => el.name)
    let insuranceCategory = options.length > 0 ? options : []
    if (otherOption.select) {
      insuranceCategory.push('其他')
    }
    submitInsuranceCustomer({
      surname: formData.surname,
      name: formData.name,
      sex: formData.gender === '先生' ? 1 : 0,
      mobile: formData.phone,
      verifyCode: formData.verifyCode,
      mobileArea: 'HK',
      email: formData.email,
      insuranceCategory: insuranceCategory.length > 0 ? JSON.stringify(insuranceCategory) : '',
      requirement: formData.other
    }).then(res => {
      if (res.data.code === 0) {
        setSubmitSuccess(true)
        destroyInterval()
        form.resetFields()
        setFormData({...initialValues})
        setPlainOptions([...JSON.parse(category)])
        setOtherOption(state => {
          return{
            ...state,
            name: '其他',
            select: false
          }
        })
      } else {
        setSubmitSuccess(false)
        message.error(res.data.message)
      }
    })
  }
  const destroyInterval = () => {
    clearInterval(interval)
    setCountdown(state => {
      return {
        ...state,
        desc: '發送驗證碼',
        disabled: false
      }
    })
    nums = 180; //重置时间
  }
  const sendCode = () => {
    sendVerifyCode()
    setCountdown(state => {
      return {
        ...state,
        desc: nums + '秒後可重新獲取',
        disabled: true
      }
    })
    interval = setInterval(() => {
      if (mountedRef.current) {
        nums--
        if(nums > 0){
          setCountdown(state => {
            return {
              ...state,
              desc: nums + '秒後重新獲取',
            }
          })
        }else{
          destroyInterval()
        }
      } else {
        clearInterval(interval)
      }
    }, 1000)
  }
  const onFinish = () => {
    let options = plainOptions.filter(el => el.select).map(el => el.name)
    if (options.length === 0 && !otherOption.select) {
      message.warning('请選擇您想咨詢的保險')
      return
    }
    submit()
  }
  message.config({
    maxCount: 1
  });
  const [form] = Form.useForm()
  return <div className={style['program-made']}>
    <img className={style['bg-img']} src={assetsUrl + 'images/icon_insurance_custom_new.png'}  alt='個人化保險方案定制'/>
    <div className={style['bg-mask']} style={{display: location.pathname === '/' ? 'block' : 'none'}}></div>
    <div className={style['made-box']}  onClick={e => {
      if (location.pathname === '/') {
        history.push('/exclusive-custom')
      }
    }}>
      <div className={style['form-container']}  onClick={e => e.stopPropagation()}>
        <div style={{display: submitSuccess ? 'flex' : 'none'}} className={style['success-content']}>
          <img src={assetsUrl + 'images/icon_pay_result_success.png'} alt="" />
          <p className={style['title']}>謝謝。我們已經收到你的個人化保險方案定制的請求。</p>
          <p className={style['desc']}>我們的方案顧問會儘快與你聯絡，為你服務。</p>
          <Button onClick={() => {
            if (location.pathname === '/exclusive-custom') {
              history.replace('/')
            } else {
              setSubmitSuccess(false)
            }
          }}>{location.pathname === '/exclusive-custom' ? '回到首頁' : '确定'}</Button>
        </div>
        <p className={style['made-desc']}>個人化保險方案定制</p>
        <Form
        style={{width: '100%'}}
        form={form}
        initialValues={initialValues}
        onFieldsChange={() => setInputCompleted(false)}
        onFinish={onFinish}>
          <Form.Item style={{marginBottom: '0'}}>
            <Input.Group compact>
              <Form.Item 
              name='surname'
              rules={[{ required: true, message: '请填寫您的姓' }]}
              style={{width: '17%', marginRight: '1.5%'}}>
                <Input size={'large'} placeholder="姓(必填)" onChange={ e => {
                  setFormData(state => {
                    return {
                      ...state,
                      surname: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
              <Form.Item 
              name='name'
              rules={[{ required: true, message: '请填寫您的名' }]}
              style={{width: '31%', marginRight: '1.5%'}}>
                <Input size={'large'} placeholder="名(必填)" onChange={ e => {
                  setFormData(state => {
                    return {
                      ...state,
                      name: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
              <Form.Item 
              name='gender'
              style={{width: '49%'}}
              rules={[{ required: true, message: '请選擇您的性別' }]}>
                <Select size={'large'} placeholder="性別(必填)" suffixIcon={<img src={selectArrow} alt='' style={{marginTop: '-12px',height: '8px'}}/>} onChange={value => {
                  setFormData(state => {
                    return {
                      ...state,
                      gender: value
                    }
                  })
                }}>
                  <Select.Option value="先生">先生</Select.Option>
                  <Select.Option value="女士">女士</Select.Option>
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item style={{marginBottom: '0'}}>
            <Input.Group compact>
              <Form.Item 
                style={{width: '17%', marginRight: '1.5%'}}>
                <div className={style['mobile-code']}>
                  <span>+852</span>
                </div>  
              </Form.Item>
              <Form.Item
                style={{width: '31%', marginRight: '1.5%'}}
                name="phone"
                rules={[
                  {validator: (rule, value) =>{
                    if(value.length === 0) {
                      return Promise.reject('请填寫您的手機號碼');
                    } else if(inputCompleted && value.length < 8) {
                      return Promise.reject('请填寫有效的的手機號碼');
                    } else {
                      return Promise.resolve();
                    }
                  }}
                ]}>
                <Input size={'large'} maxLength={8} placeholder="手機號碼(必填)" onChange={ e => {
                  const value = e.target.value.replace(/\D/g, '')
                  form.setFieldsValue({phone: value})
                  setFormData(state => {
                    return {
                      ...state,
                      phone: value
                    }
                  })
                }}/>
              </Form.Item>
              <Form.Item
              style={{width: '28%'}}
              name="verifyCode"
              rules={[
                { validator: (rule, value) => {
                  if(formData.phone.length > 0 && value.length === 0) {
                    return Promise.reject('请填寫您的驗證碼');
                  } else {
                    return Promise.resolve();
                  }
                }}
                ]}>
                <Input size={'large'} maxLength={6} autoComplete="off" placeholder="輸入驗證碼" onChange={ e => {
                  if (formData.phone.length === 0) {
                    form.validateFields(['phone'])
                    form.setFieldsValue({verifyCode: ''})
                    return
                  }
                  const value = e.target.value.replace(/\D/g, '')
                  form.setFieldsValue({verifyCode: value})
                  setFormData(state => {
                    return {
                      ...state,
                      verifyCode: value
                    }
                  })
                }}/>
              </Form.Item>
              <Form.Item style={{width: '21%'}}>
                <span className={style['send-btn']} style={{opacity: formData.phone.length >= 8 ? '1.0' : '0.5', pointerEvents: formData.phone.length >= 8 ? 'auto' : 'none'}} onClick={() => {
                  if (formData.phone.length === 0) {
                    form.validateFields(['phone'])
                    return
                  }
                  if (!countdown.disabled) {
                    sendCode()
                  }
                }}>{countdown.desc}</span>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item style={{marginBottom: '0'}}>
            <Input.Group compact>
              <Form.Item 
                name='email'
                rules={[
                  { validator: (rule, value) => {
                    if (inputCompleted && formData.email.length > 0 && !isLegalEmail(formData.email)) {
                      return Promise.reject('请填寫有效的電郵地址');
                    } else {
                      return Promise.resolve();
                    }
                  }}
                ]} 
                style={{width: '100%', marginBottom: '0'}}>
                  <Input size={'large'} placeholder="電郵地址" onChange={ e => {
                    setFormData(state => {
                      return {
                        ...state,
                        email: e.target.value
                      }
                    })
                  }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <p className={style['check-title']}>您想咨詢的保險(可選擇多項)</p>
          <div className={style['check-box']}>
            {
              plainOptions.map((el, idx) => {
                return <div key={el.name} className={[`${style['check-item']}`, el.select ? `${style['check-item-select']}` : ''].join(' ')} onClick={e => {
                  const options = plainOptions
                  const option = options[idx]
                  option.select = !option.select
                  setPlainOptions([...options])
                }}>
                  <img src={assetsUrl + `images/icon_select_pitchon_${el.select ? 's': 'n'}.png`} alt={el.name}/>
                  <span>{el.name}</span>
                </div>
              })
            }
          </div>
          <Form.Item>
            <Input.Group compact>
              <Form.Item style={{width: '21%', marginBottom: '0'}}>
                <div style={{width: '100%', marginTop: '5px'}} className={[`${style['check-item']}`, otherOption.select ? `${style['check-item-select']}` : ''].join(' ')} onClick={e => {
                  if (!otherOption.select) {
                    inputRef.current.focus({
                      cursor: 'start',
                    });
                  }
                  setOtherOption(state => {
                    return{
                      ...state,
                      select: !state.select
                    }
                  })
                }}>
                  <img src={assetsUrl + `images/icon_select_pitchon_${otherOption.select ? 's': 'n'}.png`} alt={otherOption.name}/>
                  <span>{otherOption.name + (otherOption.select ? '(請說明)' : '')}</span>
                </div>
              </Form.Item>
              <Form.Item style={{width: '79%', marginBottom: '0', opacity: otherOption.select ? 1.0 : 0.0}}>
                <Input ref={inputRef} onChange={ e => {
                  setFormData(state => {
                    return {
                      ...state,
                      other: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div className={style['protocol-box']}>本人提交此表格表示本人已細閱並同意慧擇香港的<a href="/privacy-policy" target="_blank">私隱政策</a>、<a href="/terms-of-use" target="_blank">使用條款</a>及<a href="/personal-information" target="_blank">個人資料收集聲明</a>。我們的專家會盡快聯絡閣下。</div>
          <Form.Item style={{marginBottom: '0'}}>
            <div className={style['receive-box']}>
              <Button type="primary" style={{opacity: (formData.name.length > 0 && formData.surname.length > 0 && formData.gender && formData.phone.length > 0 && formData.verifyCode.length > 0) ? '1.0' : '0.5', pointerEvents: (formData.name.length > 0 && formData.surname.length > 0 && formData.gender && formData.phone.length > 0 && formData.verifyCode.length > 0) ? 'auto' : 'none'}}  onClick={() => {
                setInputCompleted(true)
                form.submit()
              }}>提交</Button>
            </div>
          </Form.Item>
      </Form>
      </div>
    </div>
  </div>
}