import React, {useEffect} from 'react'
import styles from './PersonalInformation.module.scss'
import { useSelector } from 'react-redux'
import { setFontSize } from '../../utils'
export function PersonalInformation () {
  const scale = useSelector(state => state.font.scale)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.main} style={setFontSize(14, scale)}>
        <p className={styles.title} style={setFontSize(28, scale)}>個人資料收集聲明</p>
        <i style={{textDecoration: 'underline'}}>更新及生效日期：2021年8月27日</i>
        <i style={{textDecoration: 'underline'}}>您確認使用本公司的平台和服務或提交參與本公司活動的報名申請將被視為接受本聲明。為了反映我們的服務、業務或適用法律的變更，我們可能需要不時修改或更新本聲明。我們保留定期修改本聲明的權利，恕不另行通知。您可以定期查看此頁面，以隨時了解更新的本聲明。</i>
        <i>定義</i>
        <div className={styles.content}>
          <p style={{marginRight: '6px'}}>1.</p>
          <div >本聲明中使用的「<strong>核對程序</strong>」具有《條例》中規定的含義，但廣義包括對資料當事人兩套或更多套的資料進行比對，以採取不利於資料當事人的行動，例如拒絕申請。</div>
        </div>
        <ul>
          <li>就本聲明而言：</li>
          <li>「<strong>客戶</strong>」指資料當事人，包括（但不限於）保單持有人、受保人、受益人或指定或有權獲得保單下的款項及／或其他利益的其他人士；如果在信託情況下，受託人、財產授予人、保謢人、執行人及信託受益人；及職業退休計劃下的成員；及強積金計劃下的計劃成員；及投資基金的股份／單位持有人。客戶包括現有及潛在客戶。</li>
          <li>「<strong>香港</strong>」指香港特別行政區。</li>
          <li>「<strong>慧擇香港</strong>」指慧擇香港保險經紀有限公司。</li>
          <li>「<strong>慧擇集團</strong>」指<strong>Huize Holding Limited</strong>及其子公司和關聯公司（其中包括但不限於慧擇香港）。慧擇集團每一成員於本聲明下的權利和義務，均為單獨而非連帶的。對於慧擇集團另一成員的任何作為或不作為，慧擇集團的任何其他成員概不承擔任何責任。</li>
          <li>「本聲明」指本慧擇香港的個人資料收集聲明。</li>
          <li>「<strong>條例</strong>」指《個人資料（私隱）條例》。</li>
        </ul>
        <i>個人資料收集</i>
        <div className={styles.content}><p style={{marginRight: '6px'}}>2.</p><div className={styles.desc}>為建立或繼續與客戶的業務關係或提供產品或服務予客戶，客戶需要不時向慧擇香港提供個人資料（例如當客戶要求報價）。如未能提供該等資料可能導致慧擇香港無法建立或繼續業務關係，或無法提供特定產品或服務。</div></div>
        <div className={styles.content}><p style={{marginRight: '6px'}}>3.</p><div className={styles.desc}>另外，慧擇香港在與客戶的業務關係存續的正常過程中（例如，申請變更保單下的受益人／受保人；或僱主通知變更參與職業退休計劃／強積金計劃的僱員成員的僱用情況／地址；或投資基金聯合股份持有人申請基金轉換）從客戶處及／或針對客戶收集或獲得個人資料。</div></div>
        <i>目的</i>
        <div className={styles.content}><p style={{marginRight: '6px'}}>4.</p><div className={styles.desc}> 使用客戶個人資料的目的包括：</div></div>
        <ul>
          <li><div className={styles.content}><p>a.</p><div className={styles.desc}>處理、評估和確定客戶對產品及／或服務的申請或要求；</div></div></li>
          <li><div className={styles.content}><p>b.</p><div className={styles.desc}>執行、維持、管理和運作向客戶提供的產品及／或服務；</div></div></li>
          <li><div className={styles.content}><p>c.</p><div className={styles.desc}>確認客戶身份並識別客戶；</div></div></li>
          <li><div className={styles.content}><p>d.</p><div className={styles.desc}>確認所收集資訊的準確性；</div></div></li>
          <li><div className={styles.content}><p>e.</p><div className={styles.desc}>加深了解客戶的財務狀況、評估客戶申請、評估慧擇香港所承擔的風險並審核提交給慧擇香港的理賠；</div></div></li>
          <li><div className={styles.content}><p>f.</p><div className={styles.desc}>與客戶提出、針對客戶提出或在其他方面涉及客戶的、與任何產品及／或服務相關的任何索賠有關的任何目的，其中包括但不限於提出該等索賠、就其進行辯護、分析、調查、處理、評估、確定和應對；</div></div></li>
          <li><div className={styles.content}><p>g.</p><div className={styles.desc}>根據客戶在慧擇香港持有的帳戶的條款和條件提供投資管理服務、交易和顧問服務、託管服務和其他服務；</div></div></li>
          <li><div className={styles.content}><p>h.</p><div className={styles.desc}>履行與產品及／或服務相關的任何職責和活動，包括但不限於市場推廣、審計、報告、研究、分析、再保險以及一般服務和維持網上及其他服務（例如臉書）；</div></div></li>
          <li><div className={styles.content}><p>i.</p><div className={styles.desc}>為客戶研究及／或設計產品及／或服務，宣傳、改進和改善產品及／或服務的提供；</div></div></li>
          <li><div className={styles.content}><p>j.</p><div className={styles.desc}>開展核對程序；</div></div></li>
          <li><div className={styles.content}><p>k.</p><div className={styles.desc}>根據對慧擇香港或慧擇集團任何成員（無論在香港境內還是境外）有約束力或對其適用的任何法律、法規、規章、守則、指引或指南的規定進行披露，包括但不限於向當地或外國的監管機構、政府機構、諸如保險公司聯會或協會等公認行業組織（無論在香港境內還是境外）、信貸資料服務機構或審計機構進行披露；</div></div></li>
          <li><div className={styles.content}><p>l.</p><div className={styles.desc}>由於慧擇香港或慧擇集團任何成員在相關當地或外國監管機構、政府機構、或公認行業組織（無論在香港境內還是境外）所在司法管轄區的或涉及該等司法管轄區的財務、商業、業務或其他利益或活動而由慧擇香港或慧擇集團任何成員承擔或施加給其的、與該等當地或外國監管機構、政府機構、或公認行業組織之間的任何合同、其他承諾或安排；</div></div></li>
          <li><div className={styles.content}><p>m.</p><div className={styles.desc}>用於經營目的（每項均包括行為分析以及對與慧擇集團之間總體關係的評估，其中包括為遵守關於在慧擇集團內部共用資料和資訊的任何義務、要求、政策、程序、措施或安排而使用該等資料，及／或根據慧擇集團內任何有關遵守制裁或防止或發現洗錢、為恐怖分子提供資金或其他非法活動的計劃而對資料和資訊進行的其他使用），無論是針對資料當事人還是其他人的；</div></div></li>
          <li><div className={styles.content}><p>n.</p><div className={styles.desc}>行使慧擇香港在向客戶提供產品及／或服務方面可能享有的任何權利；</div></div></li>
          <li><div className={styles.content}><p>o.</p><div className={styles.desc}>確定應向客戶支付或客戶應付的任何債務金額，向客戶或向為客戶債務提供任何擔保或承諾的任何人收取和追討任何應收金額；</div></div></li>
          <li><div className={styles.content}><p>p.</p><div className={styles.desc}>使慧擇香港或慧擇集團任何成員的權利或業務的實際或擬議受讓人、承讓人、參與人或次級參與人能對該等轉讓、參與或次級參與擬涉及的交易進行評估；</div></div></li>
          <li><div className={styles.content}><p>q.</p><div className={styles.desc}>慧擇香港提供的任何特定服務或產品中具體規定的目的；</div></div></li>
          <li><div className={styles.content}><p>r.</p><div className={styles.desc}>與上述相關的任何目的（包括尋求專業意見），或根據慧擇香港或慧擇集團任何成員的一般政策進行的、與保險、職業退休計劃、強積金計劃、投資基金、財富管理服務以及慧擇香港或慧擇集團任何成員不時向客戶提供的通知、通告或其他條款和條件中所述的其他金融產品和服務相關的任何其他目的。</div></div></li>
        </ul>
        <i>安保聲明</i>
        <div className={styles.content}><p style={{marginRight: '6px'}}>5.</p><div className={styles.desc}>慧擇香港將遵守《個人資料（私隱）條例》及相關法律法規，我們將採取一切可行的措施，保證您的個人資料不會被未經授權的協力廠商或意外地被查閱、處理、刪除或使用。為滿足相關法律法規的要求，慧擇香港可能在與客戶結束業務關係後，保留客戶的個人資料不超過7年。</div></div>
        <i>承轉人</i>
        <div className={styles.content}><p style={{marginRight: '6px'}}>6.</p><div className={styles.desc}>慧擇香港持有的客戶個人資料將予以保密，但慧擇香港可就上文第4條所載的任何目的將該等資料移轉給下列人士及／或實體（無論在香港境內還是境外）：</div></div>
        <ul>
          <li><div className={styles.content}><p>a.</p><div className={styles.desc}>與客戶、針對客戶或涉及客戶就任何產品及／或服務提起的任何索賠相關的任何人士；</div></div></li>
          <li><div className={styles.content}><p>b.</p><div className={styles.desc}>向慧擇香港或慧擇集團任何成員提供與業務經營相關的行政管理、電信通訊、電腦、資訊技術、付款、資料處理或儲存、市場推廣、郵寄、列印、電話行銷、客戶滿意度分析或其他服務的任何代理、承辦商或第三方服務供應商，包括任何託管人，執行人，投資管理人，投資顧問或分銷商；</div></div></li>
          <li><div className={styles.content}><p>c.</p><div className={styles.desc}>任何信貸資料服務機構或（如出現付款違約）任何債務托收機構；</div></div></li>
          <li><div className={styles.content}><p>d.</p><div className={styles.desc}>任何顧問（包括其僱員）或其他中介人士／機構（包括其僱員）；</div></div></li>
          <li><div className={styles.content}><p>e.</p><div className={styles.desc}>再保險商和醫療服務供應商；</div></div></li>
          <li><div className={styles.content}><p>f.</p><div className={styles.desc}>客戶的僱主；</div></div></li>
          <li><div className={styles.content}><p>g.</p><div className={styles.desc}>已向慧擇香港或慧擇集團任何成員承諾將對該等資料保密的任何人士；</div></div></li>
          <li><div className={styles.content}><p>h.</p><div className={styles.desc}>慧擇香港或慧擇集團的權利或業務的任何實際或擬議受讓人、承讓人、參與人或次級參與人；</div></div></li>
          <li><div className={styles.content}><p>i.</p><div className={styles.desc}>慧擇集團的任何成員；</div></div></li>
          {/* <li><div className={styles.content}><p>j.</p><div className={styles.desc}>慧擇香港或慧擇集團任何成員根據對其有約束力或適用的任何法律、法規、規章、守則、指引或指南的規定有義務或必須向其披露的任何人士，其中包括但不限於任何當地或外國的監管機構、政府機構或公認行業組織；</div></div></li>
          <li><div className={styles.content}><p>k.</p><div className={styles.desc}>根據由於慧擇香港或慧擇集團任何成員在相關當地或外國監管機構、政府機構、或公認行業組織（無論在香港境內還是境外）所在司法管轄區的或涉及該等司法管轄區的財務、商業、業務或其他利益或活動而由慧擇香港或慧擇集團任何成員承擔或施加給其的、與該等當地或外國監管機構、政府機構、公認行業組織之間的任何合同、其他承諾或安排，有義務或必須向其披露的任何人士。</div></div></li> */}
        </ul>
        <i>個人資料在直接促銷中的收集及使用</i>
        <div className={styles.content}><p style={{marginRight: '6px'}}>7.</p><div className={styles.desc}>慧擇香港擬在下列產品和服務的直接促銷中不時使用客戶的個人資料：</div></div>
        <ul>
          <li><div className={styles.content}><p>a.</p><div className={styles.desc}>保險；</div></div></li>
          <li><div className={styles.content}><p>b.</p><div className={styles.desc}>獎勵、忠誠度或特權計劃及相關產品和服務；</div></div></li>
          <li><div className={styles.content}><p>c.</p><div className={styles.desc}>慧擇集團任何成員的合作品牌夥伴的產品和服務（合作品牌夥伴名稱見相關產品和服務（視情況而定）的申請表）。</div></div></li>
          <li>在該等直接促銷中，僅可使用下列類型的客戶個人資料：</li>
          <li><div className={styles.content}><p>a.</p><div className={styles.desc}>姓名；</div></div></li>
          <li><div className={styles.content}><p>b.</p><div className={styles.desc}>性別；</div></div></li>
          <li><div className={styles.content}><p>c.</p><div className={styles.desc}>出生日期；</div></div></li>
          <li><div className={styles.content}><p>d.</p><div className={styles.desc}>身份證或護照號碼的一部分；</div></div></li>
          <li><div className={styles.content}><p>e.</p><div className={styles.desc}>聯絡資料（包括但不限於電話號碼、傳真號碼、電郵地址、通訊地址及住宅地址）；</div></div></li>
          <li><div className={styles.content}><p>f.</p><div className={styles.desc}>客戶已購買或申請的產品及／或服務的資料，包括購買或申請的產品及／或服務的分銷渠道（包括其個人顧問或中介機構）。</div></div></li>
          <li style={{marginTop: '10px'}}>除非慧擇香港已經就擬議使用獲得客戶的同意，否則不得如上所述使用資料。</li>
        </ul>
        {/* <i>提供個人資料作直接促銷</i> */}
        <div className={styles.content} style={{marginTop: '20px'}}><p style={{marginRight: '6px'}}>8.</p><div className={styles.desc}>通過下文第9條所載的渠道，資料當事人有權查閱慧擇香港持有的其個人資料及要求對其任何不準確的個人資料進行改正。</div></div>
        {/* <ul>
          <li><div className={styles.content}><p>a.</p><div className={styles.desc}>保險、公積金及／或公積金計劃、投資基金、財富管理服務或其他金融產品和服務；</div></div></li>
          <li><div className={styles.content}><p>b.</p><div className={styles.desc}>獎勵、忠誠度或特權計劃及相關產品和服務；</div></div></li>
          <li><div className={styles.content}><p>c.</p><div className={styles.desc}>慧擇集團任何成員的合作品牌夥伴的產品和服務（該等合作品牌夥伴名稱見相關產品和服務（視情況而定）的申請表）。</div></div></li>
          <li>僅可向慧擇集團（除慧擇香港本身之外）提供下列類型的客戶個人資料供慧擇集團作該等直接促銷之用：</li>
          <li><div className={styles.content}><p>a.</p><div className={styles.desc}>姓名；</div></div></li>
          <li><div className={styles.content}><p>b.</p><div className={styles.desc}>性別；</div></div></li>
          <li><div className={styles.content}><p>c.</p><div className={styles.desc}>出生日期；</div></div></li>
          <li><div className={styles.content}><p>d.</p><div className={styles.desc}>身份證或護照號碼的一部分；</div></div></li>
          <li><div className={styles.content}><p>e.</p><div className={styles.desc}>聯絡資料（包括但不限於電話號碼、傳真號碼、電郵地址、通訊地址及住宅地址）；</div></div></li>
          <li><div className={styles.content}><p>f.</p><div className={styles.desc}>客戶已購買或申請的產品及／或服務的資料，包括購買或申請的產品及／或服務的分銷渠道（包括其個人顧問或中介機構）。</div></div></li>
          <li>除非慧擇香港已就擬議提供獲得客戶的書面同意，否則不得如上所述提供資料。</li>
        </ul> */}
        <div className={styles.content} style={{marginTop: '20px', marginBottom: '10px'}}><p style={{marginRight: '6px'}}>9.</p><div className={styles.desc}>根據《條例》規定，慧擇香港有權就處理任何查閱資料要求收取合理費用。要求可以書面或電郵形式提交給我們，聯繫方式如下：</div></div>
        <ul>
          <li><div className={styles.content}>電郵地址﹕<a className={styles.desc} style={{color: '#000', textDecoration: 'underline'}} target="_self" href='mailto:admin@huize.com.hk'>admin@huize.com.hk</a></div></li>
          <li><div className={styles.content}>辦事處地址﹕<div className={styles.desc}>香港鰂魚涌英皇道979號太古坊多盛大廈24樓2401室</div></div></li>
          {/* <li><div className={styles.content}><p>c.</p><div className={styles.desc}>查明資料使用者在個人資料方面的政策和慣例；</div></div></li>
          <li><div className={styles.content}><p>d.</p><div className={styles.desc}>了解資料使用者持有的個人資料類型；</div></div></li>
          <li><div className={styles.content}><p>e.</p><div className={styles.desc}>了解資料使用者持有的個人資料的主要目的或主要擬議目的；</div></div></li>
          <li><div className={styles.content}><p>f.</p><div className={styles.desc}>通過下文第9條所載的渠道提出查閱資料要求和改正資料要求。</div></div></li> */}
        </ul>
        {/* <div className={styles.content} style={{marginTop: '20px', marginBottom: '10px'}}><p style={{marginRight: '6px'}}>9.</p><div className={styles.desc}>根據《條例》規定，慧擇香港有權就處理任何查閱資料要求收取合理費用。要求可以書面形式提交給個人資料主任：</div></div> */}
        <p style={{marginTop: '20px', marginBottom: '10px'}}>慧擇香港保險經紀有限公司</p>
        {/* <p>香港鰂魚涌英皇道979號太古坊多盛大廈24樓2401室</p> */}
        {/* <h4 style={{marginTop: '10px', fontWeight: 'bold'}}>(2021年7月21 日版本)</h4> */}
      </div>
    </div>
  )
}