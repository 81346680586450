import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment';
import { Form, Input, DatePicker, Select, Button, message, Spin, Radio } from 'antd'
import { InputVerifyCode, Footer} from '../../components'
import { useDebounce } from '../../utils/hook'
import { assetsUrl } from '../../utils/constants'
import styles from'./AnnualTravelPlan.module.scss'
import scrollIntoView from 'scroll-into-view-if-needed'
import { isLegalEmail, isLegalHKPhone, isLegalHKID, generateRangeNumber } from '../../utils'
import { createOrder, getTravelDestination, queryAddress } from '../../services/api'
import locale from 'antd/es/date-picker/locale/zh_CN'
export function AnnualTravelPlan () {
  const initialValues = {
    idNo: '',
    idLastNo: '',
    gender: '先生',
    surname: '',
    name: '',
    companyName: '',
    businessRegistNo: '',
    birthDate: '',
    phoneNumber: '',
    email: '',
    isPassenger: '',
    destination1: '',
    destId1: '',
    destination2: '',
    destId2: '',
    destination3: '',
    destId3: '',
    room: '',
    dong: '',
    seat: '',
    estate: '',
    street: '',
    area: '',
    verifyCode: ''
  }
  const initTravelerValues = () => {
    return {
      type: '',
      types: ['成人' ,'隨行兒童 / 兒童'],
      birthDate: '',
      age: '',
      surname: '',
      name: '',
      idNo: '',
      idLastNo: ''
    }
  }
  const history = useHistory()
  const { plan } = useParams()
  const param = JSON.parse(decodeURIComponent(window.atob(plan)))
  const { productId, productCode, cmntDate, planType, expiryDate, insuredPlan, optionPlan1, optionPlan2, travelerCount} = param
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({ ...initialValues })
  const [declaration, setDeclaration] = useState([
    {
      desc: '本人確認',
      content: [
        '● 本人已閱讀並同意所有有關此旅遊保險之 聲明、保單之條款及細則 及 慧擇香港之收集個人資料聲明，並同意接受其約束。', 
        '● 上述旅客沒有任何身體殘障或缺陷或正接受醫藥治療或正感染任何疾病及／或過去兩年內，沒有曾因本計劃提到的各類風險而導致意外或損傷。否則，請致電慧擇客戶服務熱線+852 2789 9923。',
        '● 上述旅客於受保期內並不是從事導遊或領隊、與娛樂事業有關或任何體力勞動性工作及／或已於 保單條款 內不承保事項列明的不保工作。否則，請致電慧擇客戶服務熱線+852 2789 9923。'
      ],
      select: null
    },
    {
      desc: '本人／我們不同意 貴公司使用或向第三方提供本人／我們的個人資料作 收集個人資料聲明 中所列之市場推廣用途。',
      content: ['本人／我們不同意慧擇香港使用或向第三方提供本人／我們的個人資料作 慧擇香港之收集個人資料聲明 中所列之市場推廣用途。'],
      select: null
    }
  ])
  const [openPhoneTips, setPhoneTips] = useState(false)
  const [validatePhoneFailed, setPhoneFailed] = useState(false)
  const [openEmailTips, setEmailTips] = useState(false)
  const [validateEmailFailed, setEmailFailed] = useState(false)
  const [destOption, setDestOption] = useState({
    d1: {
      open: false,
      failed: false,
    },
    d2: {
      open: false,
      failed: false,
    },
    d3: {
      open: false,
      failed: false,
    },
  })
  const [passed, setPassed] = useState(false)
  const [loading, setLoading] = useState(false)

  const [isVisibleDest1, setVisibleDest1] = useState(false)
  const [isVisibleDest2, setVisibleDest2] = useState(false)
  const [isVisibleDest3, setVisibleDest3] = useState(false)
  const [inputCompleted, setInputCompleted] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [travelerFormData, setTravelerFormData] = useState(
    generateRangeNumber(1, Number(travelerCount)).map(() => {
      return initTravelerValues()
    })
  )
  const onFinish = () => {

    const tempDeclaration = declaration
    const declItem = tempDeclaration[0]
    if (declItem.select === null) {
      declItem.select = false
      setDeclaration([...tempDeclaration])
      const node = document.getElementById('user-declaration')
      scrollIntoView(node, { behavior: 'smooth'})
      return
    }
    
    if (formData.verifyCode.length === 0) {
      message.warning('請輸入有效的驗證碼')
      const node = document.getElementById('idno-label')
      scrollIntoView(node, { behavior: 'smooth'})
      return
    }

    let protocol = declaration.map(el => {
      if (el.select) {
        return el.content
      }
      return ''
    }).filter(el => el.length > 0)

    const insuranceIssuePolicy = {
      system: {
        productCode: productCode
      },
      insured: {
        title: planType === 'I' ? (formData.gender === '先生' ? 'MR' : 'MRS') : '',
        surname: formData.surname,
        firstName: formData.name,
        fullName: formData.surname + ' ' + formData.name,
        sex: formData.gender === '先生' ? 'M' : 'F',
        idType: planType === 'I' ? 'I' : 'R',
        idNo: planType === 'I' ? (formData.idNo + formData.idLastNo) : formData.businessRegistNo,
        dob: planType === 'I' ?  formData.birthDate.format('DD/MM/YYYY') : '',
        emailAddr: formData.email,
        mobileTel: formData.phoneNumber,
        companyName: formData.companyName,
        addrFlat: formData.room,
        addrFloor: formData.dong,
        addrBlock: formData.seat,
        addrBldg: formData.estate,
        addrStName: formData.street,
        addrEstate: formData.area
      },
      policy: {
        cmntDate: moment(cmntDate).format('DD/MM/YYYY'),
        planType: planType,
        travelDestination1: formData.destId1,
        travelDestination2: formData.destId2,
        travelDestination3: formData.destId3,
        expiryDate: moment(expiryDate).format('DD/MM/YYYY'),
      },
      insuredPerson: {
        repeat: getPolicyholderInfo()
      },
      protocol
    }
    // setLoading(true)
    // createOrder({
    //   verifyCode: formData.verifyCode,
    //   productId,
    //   insuranceProductChannel: 'zurich_travel_insurance_issue_policy',
    //   applicationJsonString: JSON.stringify(insuranceIssuePolicy)
    // }).then(res => {
    //   setLoading(false)
    //   if (res.data.code === 0) {
    //     if (res.data.data && res.data.data.orderNo) {
    //       const jsonStr = window.btoa(JSON.stringify(res.data.data)) 
    //       history.push(`/payment/${jsonStr}`)
    //     } else {
    //       message.error('未知錯誤') 
    //     }
    //   } else {
    //     message.error(res.data.message) 
    //   }
    // })
  }

  const getPolicyholderInfo = () => {
    let repeat = []
    let optionPlan 
    if (optionPlan1.length > 0 && optionPlan2.length > 0) {
      optionPlan = {
        optionPlan1,
        optionPlan2
      }
    } else {
      optionPlan = {
        optionPlan1: optionPlan1.length > 0 ? optionPlan1 : (optionPlan2.length > 0 ? optionPlan2 : '')
      }
    }
    travelerFormData.forEach((el, idx) => {
      const info = {
        itmNo: idx+1,
        insuredDob: el.birthDate.format('DD/MM/YYYY'),
        name: el.surname + ' ' + el.name,
        surname: el.surname,
        firstname: el.name,
        insuredIdNo: el.idNo + el.idLastNo,
        insuredPlan: insuredPlan,
        ...optionPlan,
        relationship: (el.type === '成人' || el.type === '成人(本人)') ? 'AD' : 'AC',
      }
      repeat.push(info)
    })
    return repeat
  }
  const deleteTraveler = (idx) => {
    form.setFieldsValue({
      ['type-'+idx]: undefined, 
      ['birthDate-'+idx]: '', 
      ['name-'+idx]: '', 
      ['surname-'+idx]: '',
      ['idNo-'+idx]: '', 
      ['idLastNo-'+idx]: ''
    })
    const travelers = travelerFormData
    travelers.splice(idx, 1)
    setTravelerFormData([...travelers])
  }
  const convertApplicantToTraveler = (converted) => {
    const travelers = travelerFormData
    let traveler = travelers[0]
    if (converted) {
      traveler.types.unshift('成人(本人)')
      traveler.type = '成人(本人)'
      traveler.birthDate = formData.birthDate
      traveler.name = formData.name
      traveler.surname = formData.surname
      traveler.idNo = formData.idNo
      traveler.idLastNo = formData.idLastNo
    } else {
      traveler.types = ['成人' ,'隨行兒童 / 兒童']
      traveler.type = undefined
      traveler.birthDate = ''
      traveler.name = ''
      traveler.surname = ''
      traveler.idNo = ''
      traveler.idLastNo = ''
    }
    setTravelerFormData([...travelers])
    form.setFieldsValue({
      'type-0': traveler.type, 
      'birthDate-0': traveler.birthDate, 
      'name-0': traveler.name, 
      'surname-0': traveler.surname,
      'idNo-0': traveler.idNo, 
      'idLastNo-0': traveler.idLastNo
    })
  }
  const dismissDestPop = () => {
    setVisibleDest1(false)
    setVisibleDest2(false)
    setVisibleDest3(false)
  }
  return (
    <div className={styles['insured-container']} onClick={e => {
      dismissDestPop()
    }}>
      <div className={styles['top-header']}>
        <div className={styles['header-item']}>
          <div className={styles['receive-status']}>
            <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/>
          </div>
        </div>
        <div className={styles['progress-line']}></div>
        <div className={styles['header-item']}>
          <div className={styles['receive-status']}>
            <span>2</span>
          </div>
        </div>
        <div className={styles['progress-line']} style={{background: passed ? '#1E4994' : '#9D9D9D'}}></div>
        <div className={[`${styles['header-item']}`, `${styles['header-item-preview']}`].join(' ')}>
          <div className={styles['receive-status']} style={{background: passed ? '#1E4994' : '#9D9D9D'}}>
            <span>3</span>
          </div>
        </div>
        <div className={styles['progress-line']} style={{background: passed ? '#1E4994' : '#9D9D9D'}}></div>
        <div className={styles['header-item']}>
          <div className={styles['receive-status']} style={{background: passed ? '#1E4994' : '#9D9D9D'}}>
            {/* <img src={assetsUrl + 'images/icon_receive_form_tick.png'} alt=''/> */}
            <span>4</span>
          </div>
        </div>
      </div>
      <div className={styles['loading-box']} style={{display: loading ? 'flex' : 'none'}}>
        <Spin />
      </div>
      <div className={styles['form-container']}>
        <p className={styles['form-demand']}>所有欄目均必須填寫及請以英文輸入。</p>
        <div className={styles['title-box']}>
          <span>申請人</span>
          <div></div>
        </div>
        <Form
        form={form}
        initialValues={initialValues}
        onFinishFailed={({ errorFields }) => {
          if (errorFields.length > 0) {
            const isSuccess = errorFields.every(field => !(field.name.length > 0 && (field.name[0] === 'phoneNumber' || field.name[0] === 'email')))
            setPhoneFailed(!isSuccess)
            setEmailFailed(!isSuccess)
            const pass = errorFields.every(field => !(field.name.length > 0 && (field.name[0] === 'destination1' || field.name[0] === 'destination2')))
            setDestOption(state => {
              return {
                ...state,
                d1: {...state.d1, failed: !pass},
                d2: {...state.d2, failed: !pass}
              }
            })
            const dPass = errorFields.every(field => !(field.name.length > 0 && (field.name[0] === 'destination3')))
            setDestOption(state => {
              return {
                ...state,
                d3: {...state.d3, failed: !dPass}
              }
            })
          }
          const node = document.getElementById('idno-label')
          scrollIntoView(node, { behavior: 'smooth'})
        }}
        onFieldsChange={() => {
          setInputCompleted(false)
        }}
        onFinish={onFinish}>
          <div className={styles['label-box']} style={{display: planType === 'I' ? 'flex' : 'none'}}>
            <div className={styles['label-desc']}>
              <span>稱謂</span>
            </div>
            <div className={styles['label-desc']}>
              <span>出生日期</span>
            </div>
          </div>
          <Form.Item style={{marginBottom: '0', display: planType === 'I' ? 'block' : 'none'}}>
            <Input.Group compact>
              <Form.Item 
              name='gender'
              rules={[{ required: planType === 'I', message: '請選擇稱謂' }]}
              style={{width: '47%', marginRight: '6%'}}>
                <Select disabled={passed} placeholder='請選擇稱謂' suffixIcon={<img src={assetsUrl + 'images/icon_receive_form_dropdown.png'} alt='' style={{width: '40px', height: '40px'}}/>}>
                  <Select.Option value="先生">先生</Select.Option>
                  <Select.Option value="女士">女士</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item 
                name='birthDate'
                rules={[
                  { validator: (value) => {
                    if (planType === 'C') {
                      return
                    }
                    if ( formData.birthDate.length === 0) {
                      return Promise.reject('請輸入出生日期')
                    } else if (moment(formData.birthDate.format('YYYY-MM-DD')) > moment(cmntDate).subtract(18, "years") || moment(formData.birthDate.format('YYYY-MM-DD')) < moment(cmntDate).subtract(70, "years"))  {
                      return Promise.reject('以保單生效日計，申請人之年齡必須介乎18至70歲。')
                    } else {
                      return Promise.resolve()
                    }
                  }}
                ]}
                style={{width: '47%'}}>
                  <DatePicker placeholder='出生日期' format='DD/MM/YYYY' showToday={false} locale={locale} disabledDate={current => current && current > moment().endOf('day')} onChange={(date) => {
                    setFormData(state => {
                      return{
                        ...state,
                        birthDate: date
                      }
                    })
                  }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div className={styles['label-box']} style={{display: planType === 'C' ? 'flex' : 'none'}}>
            <div className={styles['label-desc']}>
              <span>公司/機構名稱</span>
            </div>
            <div className={styles['label-desc']}>
              <span className={styles['label-normal']}>商業登記號碼</span>
            </div>
          </div>
          <Form.Item style={{marginBottom: '0', display: planType === 'C' ? 'block' : 'none'}}>
            <Input.Group compact>
              <Form.Item 
              name='companyName'
              rules={[{ required: true, message: '請輸入公司/機構名稱' }]}
              style={{width: '47%', marginRight: '6%'}}>
                <Input onChange={e => {
                  setFormData(state => {
                    return{
                      ...state,
                      companyName: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
              <Form.Item 
                name='businessRegistNo'
                style={{width: '47%'}}>
                <Input onChange={e => {
                  setFormData(state => {
                    return{
                      ...state,
                      businessRegistNo: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div id='idno-label' className={styles['label-box']} style={{marginTop: '8px', display: planType === 'I' ? 'flex' : 'none'}}>
            <div className={styles['label-desc']}>
              <span>英文全名</span>
            </div>
            <div className={styles['label-desc']}>
              <span>香港身份證號碼</span>
              <p className={styles['symbol-label']} style={{right: '126px'}}>(</p>
              <p className={styles['symbol-label']}>)</p>
            </div>
          </div>
          <Form.Item style={{marginBottom: '0', display: planType === 'I' ? 'block' : 'none'}}>
            <Input.Group compact>
              <Form.Item 
              name='name'
              rules={[
                { validator: (value) => {
                  if (planType === 'C') {
                    return
                  }
                  if(formData.name.length === 0) {
                    return Promise.reject('請輸入與身份證相符的英文全名')
                  } else if (inputCompleted) {
                    return formData.name.length === 0 || formData.surname.length === 0  ? Promise.reject('請輸入與身份證相符的英文全名') : Promise.resolve()
                  } else {
                    return Promise.resolve()
                  }
                }}
              ]}
              style={{width: '26%', marginRight: '0.7%'}}>
                <Input disabled={passed} placeholder='名：與身份證相符' onChange={e => {
                  let value = e.target.value.replace(/[0-9]/ig,"");
                  value =  value.replace(/[^\w\.\s\/]/ig,'')
                  form.setFieldsValue({name: value})
                  setFormData(state => {
                    return{
                      ...state,
                      name: value
                    }
                  })
                }} onPaste={e => {
                  let value = e.target.value.replace(/[0-9]/ig,"");
                  value =  value.replace(/[^\w\.\s\/]/ig,'')
                  form.setFieldsValue({name: value})
                  setFormData(state => {
                    return{
                      ...state,
                      name: value
                    }
                  })
                }}/>
              </Form.Item>
              <Form.Item 
              name='surname'
              style={{width: '20.3%', marginRight: '6%'}}>
                <Input disabled={passed} placeholder='姓：與身份證相符' onChange={e => {
                  let value = e.target.value.replace(/[0-9]/ig,"");
                  value =  value.replace(/[^\w\.\s\/]/ig,'')
                  form.setFieldsValue({surname: value})
                  setFormData(state => {
                    return{
                      ...state,
                      surname: value
                    }
                  })
                  form.validateFields(['name'])
                }} onPaste={e => {
                  let value = e.target.value.replace(/[0-9]/ig,"");
                  value =  value.replace(/[^\w\.\s\/]/ig,'')
                  form.setFieldsValue({surname: value})
                  setFormData(state => {
                    return{
                      ...state,
                      surname: value
                    }
                  })
                  form.validateFields(['name'])
                }}/>
               </Form.Item>
               <Form.Item 
                name='idNo'
                rules={[
                  { validator: (value) => {
                    if (planType === 'C') {
                      return
                    }
                    if (formData.idLastNo.length === 0 && formData.idNo.length === 0) {
                      return Promise.reject('請輸入香港身份證號碼')
                    } else if ((formData.idNo.length >= 7 && formData.idLastNo.length > 0) || inputCompleted) {
                      return !isLegalHKID(formData.idNo + '(' + formData.idLastNo + ')') ? Promise.reject('不是有效的證件號碼，請核對後重新確認') : Promise.resolve()
                    } else {
                      return Promise.resolve()
                    }
                }}
              ]}
              style={{width: '32%', marginRight: '3.6%'}}>
                <Input disabled={passed} maxLength={8} placeholder="香港身份證號碼" onChange={e => {
                  let value = e.target.value.replace(/[^\w]/g,"");
                  if (value.length > 8) {
                    value = value.slice(0, 8)
                  }
                  form.setFieldsValue({idNo: value})
                  setFormData(state => {
                    return {
                      ...state,
                      idNo: value
                    }
                  })
                }} onPaste={e => {
                  let value = e.target.value.replace(/[^\w]/g,"");
                  if (value.length > 8) {
                    value = value.slice(0, 8)
                  }
                  form.setFieldsValue({idNo: value})
                  setFormData(state => {
                    return {
                      ...state,
                      idNo: value
                    }
                  })
                }}/>
              </Form.Item>
              <Form.Item 
              name='idLastNo'
              style={{width: '8.8%'}}>
                <Input disabled={passed} maxLength={1} onChange={e => {
                  let value = e.target.value.replace(/[^\w]/g,"");
                  if (value.length > 1) {
                    value = value.slice(0, 1)
                  }
                  form.setFieldsValue({idLastNo: value})
                  setFormData(state => {
                    return {
                      ...state,
                      idLastNo: value
                    }
                  })
                  form.validateFields(['idNo'])
                }} onPaste={e => {
                  let value = e.target.value.replace(/[^\w]/g,"");
                  if (value.length > 1) {
                    value = value.slice(0, 1)
                  }
                  form.setFieldsValue({idLastNo: value})
                  setFormData(state => {
                    return {
                      ...state,
                      idLastNo: value
                    }
                  })
                  form.validateFields(['idNo'])
                }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div id='contact-label' className={styles['label-box']} style={{marginTop: '8px'}}>
            <div className={styles['label-desc']}>
              <span>手提號碼</span>
              <img src={assetsUrl + 'images/icon_receive_form_tips.png'} alt='' onClick={() => setPhoneTips(!openPhoneTips)}/>
              <div className={[`${styles['input-tips']}`, openPhoneTips ? `${styles['input-tips-active']}` : ''].join(' ')} style={{top: formData.phoneNumber.length === 0 && validatePhoneFailed ? '131px' : '105px'}}>
                <span>用以發送保單確認短訊</span>
              </div>
            </div>
            <div className={styles['label-desc']}>
              <span>電郵地址</span>
              <img src={assetsUrl + 'images/icon_receive_form_tips.png'} alt='' onClick={() => setEmailTips(!openEmailTips)}/>
              <div className={[`${styles['input-tips']}`, openEmailTips ? `${styles['input-tips-active']}` : ''].join(' ')} style={{width: '272px', top: formData.email.length === 0 && validateEmailFailed ? '131px' : '105px'}}>
                <span>保單將透過電郵發送至閣下</span>
              </div>
            </div>
          </div>
          <Form.Item style={{marginBottom: '0'}}>
            <Input.Group compact>
              <InputVerifyCode form={form} validatPhoneFailed={() => {
                setPhoneFailed(true)
              }} onChange={evt => {
                setFormData(state => {
                  return {
                    ...state,
                    phoneNumber: evt.mobile,
                    verifyCode: evt.verifyCode,
                  }
                })
              }}/>
              <Form.Item 
              name='email'
              rules={[
                { validator: () => {
                  if (formData.email.length === 0) {
                    return Promise.reject('请輸入有效的電郵地址')
                  } else if (inputCompleted) {
                    return !isLegalEmail(formData.email) ?  Promise.reject('請輸入有效的電郵地址') : Promise.resolve()
                  }
                  return Promise.resolve()
                }}
              ]}
              style={{width: '47%'}}>
                <Input disabled={passed} maxLength={254} placeholder='e.g.xxx@abc.com' onChange={e => {
                  if (e.target.value.length === 0) {
                    setEmailFailed(true)
                  }
                  setFormData(state => {
                    return {
                      ...state,
                      email: e.target.value
                    }
                  })
                }} onPaste={e => {
                  if (e.target.value.length === 0) {
                    setEmailFailed(true)
                  }
                  setFormData(state => {
                    return {
                      ...state,
                      email: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div className={styles['label-box']} style={{marginTop: openPhoneTips || openEmailTips ? '64px' : '8px', marginBottom: '8px', display: planType === 'I' ? 'flex' : 'none'}}>
            <div className={styles['label-desc']}>
              <span>申請人是否其中一位旅客</span>
            </div>
          </div>
          <Form.Item name='isPassenger' rules={[{required: planType === 'I', message: '請選擇'}]} style={{display: planType === 'I' ? 'block' : 'none'}}>
            <Radio.Group onChange={e => {
              convertApplicantToTraveler('是' === e.target.value)
              setFormData(state => {
                return {
                  ...state,
                  isPassenger: e.target.value
                }
              })
            }}>
              <Radio value='是'>是</Radio>
              <Radio value='否'>否</Radio>
            </Radio.Group>
          </Form.Item>
          <div id='contact-label' className={styles['label-box']} style={{marginTop: planType === 'C' ? ( openPhoneTips || openEmailTips ? '64px' : '8px') : '8px'}}>
            <div className={styles['label-desc']}>
              <span>旅遊目的地 1</span>
              <img src={assetsUrl + 'images/icon_receive_form_tips.png'} alt='' onClick={() => {
                setDestOption(state => {
                  return {
                    ...state,
                    d1: {...state.d1, open: !state.d1.open}
                  }
                })
              }}/>
              <div className={[`${styles['input-tips']}`, destOption.d1.open ? `${styles['input-tips-active']}` : ''].join(' ')} style={{top: formData.destination1.length === 0 && destOption.d1.failed ? '131px' : '105px', width: '457px'}}>
                <span>如旅遊目的地不在列表內,請聯絡我們的客戶</span>
              </div>
              <Destination isVisible={isVisibleDest1} keyword={formData.destination1} onSelected={el => {
                setDestOption(state => {
                  return {
                    ...state,
                    d1: {...state.d1, field: false}
                  }
                })
                setFormData(state => {
                  return{
                    ...state,
                    destId1: el.placeId,
                    destination1: el.place
                  }
                })
                form.setFieldsValue({destination1: el.place})
              }}/>
            </div>
            <div className={styles['label-desc']}>
              <span>旅遊目的地 2</span>
              <img src={assetsUrl + 'images/icon_receive_form_tips.png'} alt='' onClick={() => {
                setDestOption(state => {
                  return {
                    ...state,
                    d2: {...state.d2, open: !state.d2.open}
                  }
                })
              }}/>
              <div className={[`${styles['input-tips']}`, destOption.d2.open ? `${styles['input-tips-active']}` : ''].join(' ')} style={{top: formData.destination1.length === 0 && destOption.d2.failed ? '131px' : '105px', width: '457px'}}>
                <span>如旅遊目的地不在列表內,請聯絡我們的客戶</span>
              </div>
              <Destination isVisible={isVisibleDest2} keyword={formData.destination2} onSelected={el => {
                setDestOption(state => {
                  return {
                    ...state,
                    d2: {...state.d2, field: false}
                  }
                })
                setFormData(state => {
                  return{
                    ...state,
                    destId2: el.placeId,
                    destination2: el.place
                  }
                })
                form.setFieldsValue({destination2: el.place})
              }}/>
            </div>
          </div>
          <Form.Item style={{marginBottom: '0'}}>
            <Input.Group compact >
              <Form.Item 
              name='destination1' 
              style={{width: '47%', marginRight: '6%'}} 
              rules={[{required: true, message: '請輸入有效的旅遊目的地'}]}>
                <Input placeholder='旅遊目的地' autoComplete='off' prefix={<div className={styles['icon-input-search']}></div>} onChange={e => {
                  setDestOption(state => {
                    return {
                      ...state,
                      d1: {...state.d1, field: e.target.value.length === 0}
                    }
                  })
                  setFormData(state => {
                    return{
                      ...state,
                      destination1: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
              <Form.Item 
              name='destination2' 
              style={{width: '47%'}}
              rules={[{required: true, message: '請輸入有效的旅遊目的地'}]}>
                <Input placeholder='旅遊目的地' autoComplete='off' prefix={<div className={styles['icon-input-search']}></div>} onChange={e => {
                  setDestOption(state => {
                    return {
                      ...state,
                      d2: {...state.d2, field: e.target.value.length === 0}
                    }
                  })
                  setFormData(state => {
                    return{
                      ...state,
                      destination2: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div id='contact-label' className={styles['label-box']} style={{marginTop: destOption.d1.open || destOption.d2.open ? '64px' : '8px'}}>
            <div className={styles['label-desc']}>
              <span>旅遊目的地 3</span>
              <img src={assetsUrl + 'images/icon_receive_form_tips.png'} alt='' onClick={() => {
                setDestOption(state => {
                  return {
                    ...state,
                    d3: {...state.d3, open: !state.d3.open}
                  }
                })
              }}/>
              <div className={[`${styles['input-tips']}`, destOption.d3.open ? `${styles['input-tips-active']}` : ''].join(' ')} style={{top: formData.destination1.length === 0 && destOption.d3.failed ? '139px' : '113px', width: '457px'}}>
                <span>如旅遊目的地不在列表內,請聯絡我們的客戶</span>
              </div>
              <Destination isVisible={isVisibleDest3} keyword={formData.destination3} onSelected={el => {
                setDestOption(state => {
                  return {
                    ...state,
                    d3: {...state.d3, field: false}
                  }
                })
                setFormData(state => {
                  return{
                    ...state,
                    destId3: el.placeId,
                    destination3: el.place
                  }
                })
                form.setFieldsValue({destination3: el.place})
              }}/>
            </div>
          </div>
          <Form.Item 
          name='destination3' 
          style={{width: '47%'}}
          rules={[{required: true, message: '請輸入有效的旅遊目的地'}]}>
            <Input placeholder='旅遊目的地' autoComplete='off' prefix={<div className={styles['icon-input-search']}></div>} onChange={e => {
              setDestOption(state => {
                return {
                  ...state,
                  d3: {...state.d3, field: e.target.value.length === 0}
                }
              })
              setFormData(state => {
                return{
                  ...state,
                  destination3: e.target.value
                }
              })
            }}/>
          </Form.Item>
          <div className={styles['address-box']} style={{marginTop: destOption.d3.open ? '54px' : '0px'}}>
            <div className={styles['title-box']}>
              <span>通訊地址 *</span>
              <div></div>
            </div>
            <span className={styles['address-desc']}>郵政信箱並非有效通訊地址</span>
          </div>
          <div className={styles['label-box']}>
            <div className={styles['label-desc']}>
              <span className={styles['label-normal']}>室</span>
            </div>
            <div className={styles['label-desc']}>
              <span className={styles['label-normal']}>樓</span>
            </div>
          </div>
          <Form.Item>
            <Input.Group compact >
              <Form.Item
              name='room' 
              style={{width: '47%', marginRight: '6%'}}>
                <Input onChange={e => {
                  setFormData(state => {
                    return{
                      ...state,
                      room: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
              <Form.Item
              name='dong' 
              style={{width: '47%'}}>
                <Input onChange={e => {
                  setFormData(state => {
                    return{
                      ...state,
                      dong: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div className={styles['label-box']}>
            <div className={styles['label-desc']}>
              <span className={styles['label-normal']}>座</span>
            </div>
            <div className={styles['label-desc']}>
              <span className={styles['label-normal']}>大廈/屋邨</span>
            </div>
          </div>
          <Form.Item>
            <Input.Group compact >
              <Form.Item
              name='seat' 
              style={{width: '47%', marginRight: '6%'}}>
                <Input onChange={e => {
                  setFormData(state => {
                    return{
                      ...state,
                      seat: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
              <Form.Item
              name='estate' 
              style={{width: '47%'}}>
                <Input onChange={e => {
                  setFormData(state => {
                    return{
                      ...state,
                      estate: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <div className={styles['label-box']}>
            <div className={styles['label-desc']}>
              <span className={styles['label-normal']}>街道</span>
            </div>
            <div className={styles['label-desc']}>
              <span>地區 </span>
            </div>
          </div>
          <Form.Item>
            <Input.Group compact >
              <Form.Item
              name='street' 
              style={{width: '47%', marginRight: '6%'}}>
                <Input onChange={e => {
                  setFormData(state => {
                    return{
                      ...state,
                      street: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
              <Form.Item
              name='area' 
              rules={[{required: true, message: '請選擇地區'}]}
              style={{width: '47%'}}
              >
                <Input autoComplete='off' prefix={<div className={styles['icon-input-search']}></div>}  onChange={e => {
                  setFormData(state => {
                    return{
                      ...state,
                      area: e.target.value
                    }
                  })
                }}/>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          {/* 单个Form管理 */}
          <Form.Item className={styles['traveler-list']}>
            {
              travelerFormData.map((el, idx) => {
                return <Form.Item className={styles['traveler-item']} key={idx}>
                  <div className={styles['title-box']}>
                    <span>旅客 {idx+1}</span>
                    <div></div>
                  </div>
                  <Form.Item>
                    <div className={styles['label-box']}>
                      <div className={styles['label-desc']}>
                        <span>旅客類別</span>
                      </div>
                      <div className={styles['label-desc']}>
                        <span>出生日期</span>
                      </div>
                    </div>
                    <Form.Item>
                      <Input.Group compact>
                        <Form.Item 
                        name={'type-' + idx}
                        rules={[
                          { validator: (value) => {
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            if (traveler.type.length === 0) {
                              return Promise.reject('請選擇旅客類別')
                            } else {
                              if (traveler.birthDate) {
                                const date = moment(traveler.birthDate.format('YYYY-MM-DD'))
                                if (traveler.type === '成人' || traveler.type === '成人(本人)') {
                                  return date > moment(cmntDate).subtract(18, "years") ? Promise.reject('請選擇有效的旅客類別') : Promise.resolve()
                                }
                                return date < moment(cmntDate).subtract(18, "years") ? Promise.reject('請選擇有效的旅客類別') : Promise.resolve()
                              }
                              return Promise.resolve()
                            }
                          }}
                        ]}
                        style={{width: '47%', marginRight: '6%'}}>
                          <Select disabled={passed} placeholder='請選擇旅客類別' suffixIcon={<img src={assetsUrl + 'images/icon_receive_form_dropdown.png'} alt='' style={{width: '40px', height: '40px'}}/>} onChange={value => {
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            traveler.type = value
                            setTravelerFormData([...travelers])
                          }}>
                            {
                              travelerFormData[idx].types.map(item => {
                                return <Select.Option value={item} key={item}>{item}</Select.Option>
                              })
                            }
                          </Select>
                        </Form.Item>

                        <Form.Item 
                          name={'birthDate-'+idx}
                          rules={[
                            { validator: (value) => {
                              const travelers = travelerFormData
                              const traveler = travelers[idx]
                              if ( traveler.birthDate.length === 0) {
                                return Promise.reject('請輸入出生日期')
                              } else {
                                const date = moment(traveler.birthDate.format('YYYY-MM-DD'))
                                if (traveler.type.length > 0) {
                                  if (traveler.type === '成人' || traveler.type === '成人(本人)') {
                                    return (date > moment(cmntDate).subtract(18, "years") || date < moment(cmntDate).subtract(70, "years")) ? Promise.reject('以保單生效日計，申請人之年齡必須介乎18至70歲。') : Promise.resolve()
                                  }
                                  return date < moment(cmntDate).subtract(18, "years") ? Promise.reject('以保單生效日計，申請人之年齡必須為18歲或以上') : Promise.resolve()
                                }
                                return date > moment(cmntDate).subtract(18, "years") ? Promise.reject('以保單生效日計，申請人之年齡必須為18歲或以上') : Promise.resolve()
                              }
                            }}
                          ]}
                          style={{width: '47%'}}>
                          <DatePicker placeholder='出生日期' format='DD/MM/YYYY' showToday={false} locale={locale} disabledDate={current => current && current > moment().endOf('day')} onChange={(date) => {
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            traveler.birthDate = date
                            setTravelerFormData([...travelers])
                          }}/>
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                    <div className={styles['label-box']}>
                      <div className={styles['label-desc']}>
                        <span>英文全名</span>
                      </div>
                      <div className={styles['label-desc']}>
                        <span>香港身份證號碼</span>
                        <p className={styles['symbol-label']} style={{right: '126px'}}>(</p>
                        <p className={styles['symbol-label']}>)</p>
                      </div>
                    </div>
                    <Form.Item style={{marginBottom: '0'}}>
                      <Input.Group compact>
                        <Form.Item 
                        name={'name-'+idx}
                        rules={[
                          { validator: (value) => {
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            if(traveler.name.length === 0) {
                              return Promise.reject('請輸入旅客的英文全名')
                            }  else if (inputCompleted) {
                              return traveler.name.length === 0 || traveler.surname.length === 0  ? Promise.reject('請輸入旅客的英文全名') : Promise.resolve()
                            } else {
                              return Promise.resolve()
                            }
                          }}
                        ]}
                        style={{width: '26%', marginRight: '0.7%'}}>
                          <Input disabled={passed} placeholder='名：與身份證相符' onChange={e => {
                            let value = e.target.value.replace(/[0-9]/ig,"");
                            value =  value.replace(/[^\w\.\s\/]/ig,'')
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            form.setFieldsValue({['name-'+idx]: value})
                            traveler.name = value
                            setTravelerFormData([...travelers])
                          }} onPaste={e => {
                            let value = e.target.value.replace(/[0-9]/ig,"");
                            value =  value.replace(/[^\w\.\s\/]/ig,'')
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            form.setFieldsValue({['name-'+idx]: value})
                            traveler.name = value
                            setTravelerFormData([...travelers])
                          }}/>
                        </Form.Item>
                        <Form.Item 
                        name={'surname-'+idx}
                        style={{width: '20.3%', marginRight: '6%'}}>
                          <Input disabled={passed} placeholder='姓：與身份證相符' onChange={e => {
                            let value = e.target.value.replace(/[0-9]/ig,"");
                            value =  value.replace(/[^\w\.\s\/]/ig,'')
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            form.setFieldsValue({['surname-'+idx]: value})
                            traveler.surname = value
                            setTravelerFormData([...travelers])
                            form.validateFields([`name-${idx}`])
                          }} onPaste={e => {
                            let value = e.target.value.replace(/[0-9]/ig,"");
                            value =  value.replace(/[^\w\.\s\/]/ig,'')
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            form.setFieldsValue({['surname-'+idx]: value})
                            traveler.surname = value
                            setTravelerFormData([...travelers])
                            form.validateFields([`name-${idx}`])
                          }}/>
                        </Form.Item>
                        <Form.Item 
                          name={'idNo-'+idx}
                          rules={[
                            { validator: (value) => {
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            if (traveler.idLastNo.length === 0 && traveler.idNo.length === 0) {
                              return Promise.reject('請輸入旅客的香港身份證號碼')
                            } else if ((traveler.idNo.length >= 7 && traveler.idLastNo.length > 0) || inputCompleted) {
                              return !isLegalHKID(traveler.idNo + '(' + traveler.idLastNo + ')') ? Promise.reject('不是有效的證件號碼，請核對後重新確認') : Promise.resolve()
                            } else {
                              return Promise.resolve()
                            }
                          }}
                        ]}
                        style={{width: '32%', marginRight: '3.6%'}}>
                          <Input disabled={passed} maxLength={8} placeholder="香港身份證號碼" onChange={e => {
                            let value = e.target.value.replace(/[^\w]/g,"");
                            if (value.length > 8) {
                              value = value.slice(0, 8)
                            }
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            traveler.idNo = value
                            form.setFieldsValue({['idNo-'+idx]: value})
                            setTravelerFormData([...travelers])
                          }} onPaste={e => {
                            let value = e.target.value.replace(/[^\w]/g,"");
                            if (value.length > 8) {
                              value = value.slice(0, 8)
                            }
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            traveler.idNo = value
                            form.setFieldsValue({['idNo-'+idx]: value})
                            setTravelerFormData([...travelers])
                          }}/>
                        </Form.Item>
                        <Form.Item 
                        name={'idLastNo'+idx}
                        style={{width: '8.8%'}}>
                          <Input disabled={passed} maxLength={1} onChange={e => {
                            let value = e.target.value.replace(/[^\w]/g,"");
                            if (value.length > 1) {
                              value = value.slice(0, 1)
                            }
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            traveler.idLastNo = value
                            form.setFieldsValue({['idLastNo-'+idx]: value})
                            setTravelerFormData([...travelers])
                            form.validateFields([`idNo-${idx}`])

                          }} onPaste={e => {
                            let value = e.target.value.replace(/[^\w]/g,"");
                            if (value.length > 1) {
                              value = value.slice(0, 1)
                            }
                            const travelers = travelerFormData
                            const traveler = travelers[idx]
                            traveler.idLastNo = value
                            form.setFieldsValue({['idLastNo-'+idx]: value})
                            setTravelerFormData([...travelers])
                            form.validateFields([`idNo-${idx}`])
                          }}/>
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Form.Item>
                  <div className={styles['delete-traveler']}>
                    <span onClick={() => {
                      deleteTraveler(idx)
                    }}>
                      <img src={assetsUrl + 'images/icon_form_delete_traveler..png'} alt=''/>
                    </span>
                  </div>
                </Form.Item >
              })
            }
          </Form.Item>
        </Form>
        <div className={styles['add-traveler']} onClick={() => {
          const travelers = travelerFormData
          travelers.push(initTravelerValues())
          setTravelerFormData([...travelers])
        }}>
          <img src={assetsUrl + 'images/icon_form_add_traveler.png'} alt=''/>
          <span>新增旅客</span>
        </div>
        <div id='user-declaration' className={styles['user-declaration']}>
          {
            declaration.map((el, idx) => {
              return <div key={el.desc} className={styles['declaration-item']}>
                <div className={styles['top-box']} onClick={() => {
                  let tempDeclaration = declaration
                  let item = tempDeclaration[idx]
                  if (item.select === null) {
                    item.select = true
                  } else {
                    item.select = !item.select
                  }
                  setDeclaration([...tempDeclaration])
                }}>
                  <img src={assetsUrl + 'images/icon_receive_form_checkbox_' + (el.select ? 's' : 'n') + '.png'} alt=''/>
                  {
                    idx === 0 ? <div className={styles['declaration-list']}>
                      <p>本人確認</p>
                      <div className={styles['declaration-content']}>
                        <p>● 本人已閱讀並同意所有有關此旅遊保險之 <a href='/personal-information' target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>聲明、</a><a href={assetsUrl + 'document/保障條款及細則.pdf'} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>保單之條款及細則</a>及慧擇香港之<a href='/personal-information' target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>收集個人資料聲明</a>，並同意接受其約束。</p>
                        <p>● 上述旅客沒有任何身體殘障或缺陷或正接受醫藥治療或正感染任何疾病及／或過去兩年內，沒有曾因本計劃提到的類風險而導致意外或損傷。否則，請致電慧擇客戶服務熱線+852 2789 9923。</p>
                        <p>● 上述旅客於受保期內並不是從事導遊或領隊、與娛樂事業有關或任何體力勞動性工作及／或已於 <a href='' target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>保單條款</a> 內不承保事項列明的不保工作。否則，請致電慧擇客戶服務熱線+852 2789 9923。</p>
                      </div>
                    </div> : <p>本人／我們不同意慧擇香港使用或向第三方提供本人／我們的個人資料作 慧擇香港之<a href='/personal-information' target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>收集個人資料聲明</a> 中所列之市場推廣用途。</p>
                  }
                </div>
                <span className={styles['err-msg']} style={{display: el.select === false && idx === 0 ? 'block' : 'none'}}>遞交申請表前，請勾選方格以茲確認</span>
            </div>
            })
          }
        </div>
      </div>
      <div className={styles['next-btn']}>
        <Button 
        style={{border: '1px solid #447EDA', color: '#447EDA', background: 'transparent', marginRight: '32px'}} 
        onClick={() => {
          history.goBack()
        }}>返回</Button>
        <Button onClick={() => {
          dismissDestPop()
          setInputCompleted(true)
          form.submit()
        }}>下一頁</Button>
      </div>
      <Footer />
    </div>
  )
}

const Destination = ({isVisible, keyword, onSelected}) => {
  const debounceDestination = useDebounce(keyword, 200)
  const [list, setList] = useState([])
  useEffect(() => {
    getTravelDestination({
      keyword: debounceDestination,
      channel: 'ZURICH'
    }).then(res => {
      console.log(res)
      if (res.data.code === 0) {
        setList(res.data.data)
      }
    })
  }, [debounceDestination])

  const highlightKeyword = (content) => {
    const values = content.split(keyword)
    const innerHTML = values.join('<span>' + keyword + '</span>')
    return innerHTML
  }

  return <div style={{display: isVisible && list.length > 0 ? 'flex' : 'none'}} className={styles['destination-list']}>
    {
      list.map(el => {
        return <div id='destination-item' className={styles['destination-item']} key={el.place} dangerouslySetInnerHTML={{__html: highlightKeyword(el.place)}}  onClick={e => {
          e.stopPropagation()
          onSelected(el)
        }}></div>
      })
    }
  </div>
}