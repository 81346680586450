/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Footer} from '../../components'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { gapi as GAPI, fbapi } from  '../../utils/constants'
import { loadScript, isLegalChinaPhone, isLegalHKPhone } from '../../utils/index'
import { Button, message, Spin } from 'antd'
import { sendRegisterCode, registerByMobile, loginByMobile, thirdPartyLogin } from '../../services/api'
import { useMountedRef } from '../../utils/hook'
import { selectCheckbox, normalCheckbox, loginFacebook, loginGoogle} from '../../assets'
import { K_LOGIN_TOKEN } from '../../utils/constants'
import { setStore } from '../../utils'
import { setUserData } from '../../redux/user/slice'
import style from './Login.module.scss'
// import fl from '../../assets/images/icon_login_google'
let nums = 60
var interval = null
export function Login () {
  const dispatch = useDispatch()
  const history = useHistory()
  const mountedRef = useMountedRef()
  let auth2 = null
  const [loading, setLoading] = useState(false)
  const [isAgree, setAgree] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [countdown, setCountdown] = useState({
    desc: '發送驗證碼',
    disabled: false
  })
  const [phoneState, setPhoneState] = useState({
    areaCode: '+852',
    phone: '',
    focus: false,
    errMsg: '請輸入有效的信息',
    isError: false
  })
  const [verifyCodeState, setVerifyCodeState] = useState({
    code: '',
    focus: false,
    errMsg: '請輸入有效的信息',
    isError: false
  })

  const [passwordState, setPasswordState] = useState({
    password: '',
    isShow: false,
    focus: false,
    errMsg: '請輸入有效的信息',
    isError: false
  })
  const _initGAPI = () => {
    loadScript(GAPI, (requestState) => {
      if(requestState === 'success'){
        console.log('加载成功')
        gapi.load('auth2',  () => {
          auth2 = gapi.auth2.init({
              client_id: '1078227589608-bievegovav790q2ftlclvad1h2tr381m.apps.googleusercontent.com',  //第二步申请的客户端id
              cookie_policy: "single_host_origin",
              scope: 'profile email'
              // scope: "profile"
          })
          prepareGoogleLogin()
        })
      } else {
        console.log('加载失败')
      }
    })
  }
  const _initFB = () => {
    loadScript(fbapi, (requestState) => {
      if(requestState === 'success'){
        console.log('加载成功')
        FB.init({
          appId: '1780129262196515',
          cookie: true,                   
          xfbml: true,                 
          version: 'v11.0' //版本号具体看官网文档         
        })
      } else {
        console.log('加载失败')
      }
    })
  }
  const fbLogin = () => {
    if (!FB) {
      message.error('網絡異常，請稍後重試')
      return
    }
    FB.getLoginStatus((response) => {   
      if (response.status === 'connected') {  
        const authResponse = response.authResponse
        if (authResponse) {
          handleThirdPartyAuth({
            type: 'facebook',
            param: {
              accessToken: authResponse.accessToken,
              signedRequest: authResponse.signedRequest,
              userID: authResponse.userID
            }
          })
        } else {
          message.error('登錄異常')
        }
      } else {          
        FB.login((response) => {
          if (response.status === 'connected') {
            const authResponse = response.authResponse
            if (authResponse) {
              handleThirdPartyAuth({
                type: 'facebook',
                param: {
                  accessToken: authResponse.accessToken,
                  signedRequest: authResponse.signedRequest,
                  userID: authResponse.userID
                }
              })
            } else {
              message.error('登錄失敗')
            }
          } else {
            message.error('登錄失敗')
          }
        }, {scope: 'public_profile'})                   
      }      
    })
  }
  const prepareGoogleLogin = () => {
    if (!auth2) {
      message.error('網絡異常，請稍後重試')
      return
    }
    const element = document.getElementById('login_google')
    auth2.attachClickHandler(element, {},
        (googleUser) => { 
        handleThirdPartyAuth({
          type: 'google',
          param: {
            idTokenString: googleUser.mc.id_token
          }
        })
        // var profile = googleUser.getBasicProfile();
        // console.log(profile);           
      },  (error) => {
        console.log(JSON.stringify(error, undefined, 2));
      }
    )
  }
  const handleThirdPartyAuth = ({type, param}) => {
    setLoading(true)
    thirdPartyLogin({
      type,
      param
    }).then(res => {
      setLoading(false)
      if (res.data.code === 0) {
        loginSuccess(res.data.data)
      } else {
        message.error(res.data.message)
      }
    }).catch(e => {
      setLoading(false)
      message.error('登錄失敗')
    })
  }
  useEffect(() =>{
    window.scrollTo(0, 0)
    _initGAPI()
    _initFB()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const destroyInterval = () => {
    clearInterval(interval)
    setCountdown(state => {
      return {
        ...state,
        desc: '發送驗證碼',
        disabled: false
      }
    })
    nums = 60
  }
  const sendCode = () => {
    startCountdown()
    sendRegisterCode({
      mobileArea: phoneState.areaCode === '+852' ? 'HK' : 'CN',
      mobile: phoneState.phone
    }).then(res => {
      if (res.data.code === 0) {
        message.success('發送成功')
      } else {
        destroyInterval()
        message.error(res.data.message)
      }
    }).catch(e => {
      destroyInterval()
      message.error('發送失敗')
    })
  }
  const startCountdown = () => {
    setCountdown(state => {
      return {
        ...state,
        desc: nums + '秒後可重新獲取',
        disabled: true
      }
    })
    interval = setInterval(() => {
      if (mountedRef.current) {
        nums--
        if(nums > 0){
          setCountdown(state => {
            return {
              ...state,
              desc: nums + '秒後重新獲取',
            }
          })
        }else{
          destroyInterval()
        }
      } else {
        clearInterval(interval)
      }
    }, 1000)
  }
  const handleSigninClick = () => {
    if (currentIndex === 1) { //注册
      register()
    } else { // 登录
      login()
    }
  }
  const login = () => {
    let pass = true
    if (phoneState.phone.length === 0) {
      pass = false
      setPhoneState(state => {
        return{
          ...state,
          isError: true
        }
      })
    }
    if ((phoneState.areaCode === '+86' && !isLegalChinaPhone(phoneState.phone))) {
      pass = false
      setPhoneState(state => {
        return{
          ...state,
          isError: true
        }
      })
    }  

    if ((phoneState.areaCode === '+852' && !isLegalHKPhone(phoneState.phone))) {
      pass = false
      setPhoneState(state => {
        return{
          ...state,
          isError: true
        }
      })
    } 

    if (passwordState.password.length === 0) {
      pass = false
      setPasswordState(state => {
        return{
          ...state,
          isError: true
        }
      })
    }
  
    if (pass) {
      setLoading(true)
      loginByMobile({
        mobileArea: phoneState.areaCode === '+852' ? 'HK' : 'CN',
        mobile: phoneState.phone,
        password: passwordState.password
      }).then(res => {
        setLoading(false)
        if (res.data.code === 0) {
          console.log(res.data)
          loginSuccess(res.data.data)
        } else {
          message.error(res.data.message)
        }
      }).catch(e => {
        setLoading(false)
        message.error('登錄失敗')
      })
    }
  }
  const register = () => {
    let pass = true
    if (phoneState.phone.length === 0) {
      pass = false
      setPhoneState(state => {
        return{
          ...state,
          isError: true
        }
      })
    }
    if ((phoneState.areaCode === '+86' && !isLegalChinaPhone(phoneState.phone))) {
      pass = false
      setPhoneState(state => {
        return{
          ...state,
          isError: true
        }
      })
    }  

    if ((phoneState.areaCode === '+852' && !isLegalHKPhone(phoneState.phone))) {
      pass = false
      console.log('123456')
      setPhoneState(state => {
        return{
          ...state,
          isError: true
        }
      })
    } 

    if (verifyCodeState.code.length === 0) {
      pass = false
      setVerifyCodeState(state => {
        return{
          ...state,
          isError: true
        }
      })
    }
    if (passwordState.password.length === 0) {
      pass = false
      setPasswordState(state => {
        return{
          ...state,
          isError: true
        }
      })
    }
    if (!isAgree) {
      pass = false
      message.warn('請同意慧擇香港的私隱政策及使用條款')
    }

    if (pass) {
      setLoading(true)
      registerByMobile({
        mobileArea: phoneState.areaCode === '+852' ? 'HK' : 'CN',
        mobile: phoneState.phone,
        verifyCode: verifyCodeState.code,
        password: passwordState.password
      }).then(res => {
        setLoading(false)
        if (res.data.code === 0) {
          resetForm()
          message.success('註冊成功，請用你的賬號登錄吧')
          setCurrentIndex(0)
        } else {
          message.error(res.data.message)
        }
      }).catch(e => {
        setLoading(false)
        message.error(e.message)
      })
    }
  }
  const loginSuccess = (data) => {
    setStore(K_LOGIN_TOKEN, data.token)
    dispatch(setUserData(data.user))
    history.replace('/')
  }
  const resetForm = () => {
    setPhoneState(state => {
      return{
        ...state,
        focus: false,
        isError: false
      }
    })
    setVerifyCodeState(state => {
      return{
        ...state,
        code: '',
        focus: false,
        isError: false
      }
    })
    setPasswordState(state => {
      return{
        ...state,
        password: '',
        isShow: false,
        focus: false,
        isError: false
      }
    })
    if (interval) {
      destroyInterval()
    }
  }
  message.config({
    maxCount: 1
  })
  console.log(passwordState)
  return (
    <div className={style['login-container']}>
      {/* <Header /> */}
      <Spin spinning={loading} style={{maxHeight: '100%'}}>
        <div className={style['main']}>
          <div className={style['title-box']}>
            <p>您好</p>
            <div className={style['tab-box']}>
              <label className={currentIndex === 1 ? style['active-label'] : {}} onClick={() => {
                setCurrentIndex(0)
                resetForm()
              }}>登錄慧澤香港</label>
              <label>或</label>
              <label className={currentIndex === 0 ? style['active-label'] : {}} onClick={() => {
                setCurrentIndex(1)
                resetForm()
              }}>創建帳戶</label>
            </div>
          </div>
          <div className={style['input-phone-box']}>
            <div className={style['select-box']}>
              <select onChange={(e) => {
                setPhoneState( state => {
                  return {
                    ...state,
                    isError: false,
                    areaCode: e.target.value
                  }
                })
              }}>
                <option value='+852'>+852</option>
                <option value='+86'>+86</option>
              </select>
            </div>
            <div className={style['input-box']}>
              <input id='phone' className={style['input-control']} maxLength={11} style={{borderBottomColor: phoneState.isError ? '#FF0000' : ''}} type='number' onFocus={() => {
                setPhoneState( state => {
                  return {
                    ...state,
                    focus: true
                  }
                })
              }} onBlur={() => {
                setPhoneState( state => {
                  return {
                    ...state,
                    focus: false
                  }
                })
              }} onPaste={e => {
                setPhoneState( state => {
                  return {
                    ...state,
                    focus: e.target.value.length !== 0,
                    phone: e.target.value,
                    isError: e.target.value.length === 0
                  }
                })
              }} onChange={(e) => {
                setPhoneState( state => {
                  return {
                    ...state,
                    focus: e.target.value.length !== 0,
                    phone: e.target.value,
                    isError: e.target.value.length === 0
                  }
                })
              }}/>
              <label className={[`${style['input-label']}`, (phoneState.phone.length >  0 ? `${style['input-label-animate']}` : (phoneState.focus ? `${style['input-label-animate']}` : `${style['input-label-normal']}`))].join(' ')}>手機號碼</label>
              <span className={[`${style['error-msg']}`, phoneState.isError ? `${style['error-msg-animate']}` : ''].join(' ')}>{phoneState.errMsg}</span>
            </div>
          </div>
          <div className={style['send-code-box']} style={{display: currentIndex === 1 ? 'flex' : 'none'}}>
            <div className={style['input-code-box']}>
              <input value={verifyCodeState.code} className={style['input-control']} maxLength={6}  style={{borderBottomColor: verifyCodeState.isError ? '#FF0000' : ''}} autoComplete='off' type='number' onFocus={(e) => {
                setVerifyCodeState(state => {
                  return {
                    ...state,
                    focus: true
                  }
                })
              }} onBlur={() => {
                setVerifyCodeState(state => {
                  return {
                    ...state,
                    focus: false
                  }
                })
              }} onPaste={e => {
                setVerifyCodeState( state => {
                  return {
                    ...state,
                    code: e.target.value,
                    focus: e.target.value.length !== 0,
                    isError: e.target.value.length === 0
                  }
                })
              }} onChange={(e) => {
                setVerifyCodeState( state => {
                  return {
                    ...state,
                    code: e.target.value,
                    focus: e.target.value.length !== 0,
                    isError: e.target.value.length === 0
                  }
                })
              }}/>
              <label className={[`${style['input-label']}`, (verifyCodeState.code.length >  0 ? `${style['input-label-animate']}` : (verifyCodeState.focus ? `${style['input-label-animate']}` : `${style['input-label-normal']}`))].join(' ')}>驗證碼</label>
              <span className={[`${style['error-msg']}`, verifyCodeState.isError ? `${style['error-msg-animate']}` : ''].join(' ')} >{verifyCodeState.errMsg}</span>
              {/* <span className={style['error-msg']} style={{opacity: verifyCodeState.isError ? 1.0 : 0.0}}>{verifyCodeState.errMsg}</span> */}
            </div>
            <span className={style['send-btn']} onClick={() => {
              if (phoneState.phone.length === 0) {
                setPhoneState(state => {
                  return{
                    ...state,
                    isError: true
                  }
                })
                return
              }
              if ((phoneState.areaCode === '+86' && !isLegalChinaPhone(phoneState.phone))) {
                setPhoneState(state => {
                  return{
                    ...state,
                    isError: true
                  }
                })
                return
              }  
          
              if ((phoneState.areaCode === '+852' && !isLegalHKPhone(phoneState.phone))) {
                setPhoneState(state => {
                  return{
                    ...state,
                    isError: true
                  }
                })
                return
              } 
              if (!countdown.disabled) {
                sendCode()
              }
            }}>{countdown.desc}</span>
          </div>
          <div className={style['input-password-box']}>
            <div className={style['input-password']}>
              <input value={passwordState.password} type={passwordState.isShow ? 'text' : 'password'} maxLength={16} minLength={6} className={style['input-control']} style={{paddingRight: '68px', borderBottomColor: passwordState.isError ? '#FF0000' : ''}} autoComplete='new-password'  onFocus={() => {
                setPasswordState( state => {
                  return {
                    ...state,
                    focus: true,
                  }
                }) // 获取焦点
              }} onBlur={() => {
                setPasswordState( state => {
                  return {
                    ...state,
                    focus: false,
                  }
                }) // 失去焦点
              }} onPaste={(e) => [
                setPasswordState( state => {
                  return {
                    ...state,
                    password: e.target.value,
                    focus: e.target.value.length !== 0,
                    isError: e.target.value.length === 0
                  }
                })
              ]} onChange={(e) => {
                setPasswordState( state => {
                  return {
                    ...state,
                    password: e.target.value,
                    focus: e.target.value.length !== 0,
                    isError: e.target.value.length === 0
                  }
                })
              }}/>
              <label className={[`${style['input-label']}`, (passwordState.password.length >  0 ? `${style['input-label-animate']}` : (passwordState.focus ? `${style['input-label-animate']}` : `${style['input-label-normal']}`))].join(' ')}>密碼</label>
              {/* <span className={style['error-msg']} style={{opacity: passwordState.isError ? 1.0 : 0.0}}>{passwordState.errMsg}</span> */}
              <span className={[`${style['error-msg']}`, passwordState.isError ? `${style['error-msg-animate']}` : ''].join(' ')} >{passwordState.errMsg}</span>
            </div>
            <div className={style['show-password']} onClick={(e) => {
              setPasswordState(state => {
                return{
                  ...state,
                  isShow: !state.isShow
                }
              })
            }}>
              <img src={passwordState.isShow ? selectCheckbox : normalCheckbox} alt=''/>
              <span >顯示</span>
            </div>
          </div>
          <span className={style['forgot-password']} style={{display: currentIndex === 0 ? 'inline-block' : 'none'}}>忘记密码？</span>
          <div className={style['protocol']} style={{display: currentIndex === 1 ? 'flex' : 'none'}}>
            <img src={isAgree ? selectCheckbox : normalCheckbox} alt='' onClick={e => {
              setAgree(!isAgree)
            }}/>
            <div className={style['protocol-content']}>我同意慧擇香港的<Link className={style['intro-title']} to="/privacy-policy">私隱政策</Link>及<Link className={style['intro-title']} to="/privacy-policy">使用條款</Link></div>
          </div>
          <Button 
            style={{marginTop: '8px', width: '100%', height: '46px', background: '#447EDA', fontSize: '14px', lineHeight: '14px', color: '#fff', fontFamily: 'SourceHanSansCN-Medium', fontWeight: 500}}
            onClick={() => {
              handleSigninClick()
            }}
          >{currentIndex === 1 ? '立即創建' : '登录'}</Button>
          <div className={style['separator']}>
            <div className={style['separator-line']}></div>
            <div className={style['separator-content']}>
              <mark>或</mark>
            </div>
          </div>
          <div className={style['facebook-login']} onClick={() => fbLogin()}>
            <img src={loginFacebook} alt=''/>
            <p>Continue with Facebook</p>
          </div>
          <div id='login_google' className={style['facebook-login']} 
            style={{marginTop: '16px', border: '1px solid #D3D3D3', background: '#fff'}}
            onClick={() => prepareGoogleLogin()}
          >
            <img src={loginGoogle} alt=''/>
            <p style={{color: '#333333'}}>Continue with Google</p>
          </div>
        </div>
      </Spin>
      <Footer />
    </div>
  )
}