import React, { useEffect, useState, useCallback } from 'react'
import { Pagination, ConfigProvider, message, Spin, Empty } from 'antd'
import { setFontSize } from '../../utils/index'
import { useSelector } from 'react-redux'
import { fstLevelCategoryList, secLevelCategoryList, travelAttrList } from './mockup'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import { Footer} from '../../components'
import style from './CompareProduct.module.scss'
import { assetsUrl } from '../../utils/constants'
import { useParams } from 'react-router-dom'
import { InsuranceProduct, SectionCompare, ProductComparisonModal } from '../../components'
import { searchInsuranceProduct, searchInsuranceProductList, getInsuranceBrandList, getInsuranceAttrList } from '../../services/api'
let searchParam = {
  pageNum: 1,
  pageSize: 10,
  key: '',
  categoryPid: -1,
  insuranceCategoryId: -1,
  insuranceBrandIds: [],
  travelPlans: []
}
export function CompareProduct () {
  useEffect(() => {
    // 初期化
    searchParam = {
      ...searchParam,
      pageNum: 1,
      pageSize: 10,
      key: '',
      categoryPid: -1,
      insuranceCategoryId: -1,
      insuranceBrandIds: [],
      travelPlans: []
    }
  }, [])
  const { id } = useParams()
  // const [isMultipleSelected, setMultipleSelected] = useState(false)
  const [selectCompany, setCompanyState] = useState([])
  const [attrList, setAttrList] = useState([])
  const [sort, setSort] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [productList, setProductList] = useState(null)
  const [compareProduct, setCompareProduct] = useState([])
  const [loading, setLoading] = useState(false)
  const [comparing, setComparing] = useState(false)
  const [insuranceCategoryId, setInsuranceCategoryId] = useState(id)
  const [fstLevelCategoryId, setFstLevelCategoryId] = useState(searchParam.categoryPid)
  const [secLevelCategory, setSecLevelCategory] = useState({
    pId: '',
    pName: '',
    id: '',
    name: '',
    icon: '',
    subTitle: '',
    desc: []
  })
  const [productTotal, setProductTotal] = useState(0)
  const [secLevelCategoryFilterList, setSecLevelCategoryFilterList] = useState([])
  const [travelAttrFilterList, setTravelAttrFilterList] = useState([])
  const [isShowTravelInfo, setIsShowTravelInfo] = useState(false)
  let modalRef = React.createRef();
  const [productInputState, setProductInputState] = useState({
    value: '',
    focus: false
  })
  const setCategoryInfo = (secLevelCategoryId) => {
    let findCategoryId = Number(secLevelCategoryId);
    let findCategory = null;

    // 终止for循环，使用break
    for (let idx=0; idx < secLevelCategoryList.length; idx++) {
      let item = secLevelCategoryList[idx];
      if (item.id === findCategoryId) {
        searchParam.categoryPid = item.pId;

        // 顯示用
        setSecLevelCategory(item);
        setFstLevelCategoryId(item.pId);
        findCategory = item;
        break;
      }
    }

    // 檢索用
    setSecLevelCategoryFilterList(
      secLevelCategoryList.filter(item => {
        return item.pId === findCategory.pId;
    }))

    // 關聯旅遊險的項目
    const isTravel = '旅遊保險' === findCategory.name;
    setIsShowTravelInfo(isTravel)
    if (isTravel) {
      const travelKey = '計劃'
      let list = travelAttrFilterList.filter(item => item.attrName === travelKey)
      list = list.length > 0 ? list[0] : travelAttrList.filter(item => item.attrName === travelKey)[0]
      initSearchTravelInfo(travelKey, list.valueSelect);
    } else {
      searchParam.travelPlans = []
    }
  }

  useEffect(() => {
    searchParam.insuranceCategoryId = insuranceCategoryId
    setCategoryInfo(insuranceCategoryId);
    search()
    // 顯示用
    // if (insuranceCategoryId !== secLevelCategory.id) {
    //   let findCategoryId = Number(insuranceCategoryId)
    //   // 终止for循环，使用break
    //   for (let idx=0; idx < secLevelCategoryList.length; idx++) {
    //     let item = secLevelCategoryList[idx];
    //     if (item.id === findCategoryId) {
    //       // 顯示用
    //       setSecLevelCategory(preState => {
    //         return {...preState, ...item}
    //       });
    //       break;
    //     }
    //   }
    // }
  }, [insuranceCategoryId])

  // 獲取保險公司數據
  const fetchInsuranceBrandList = () => {
    getInsuranceBrandList().then(res => {
      if (res.data.code === 0) {
        let list = res.data.data ? JSON.parse(JSON.stringify(res.data.data)) : [];
        list.forEach(item => {
          item.selected = false;
        });
        setCompanyState(list)
      } else {
        console.error(res.data.message)
      }
    }).catch(e => {
      console.error(e)
    });
  }

  // 獲取旅遊險相關數據
  const fetchTravelAttrList = () => {
    setTravelAttrFilterList(JSON.parse(JSON.stringify(travelAttrList)));
  }

  // 設定旅遊信息
  const initSearchTravelInfo = (attrName, selectList) => {
    let list = [];
    selectList.forEach((item, index) => {
      if (item.selected) {
        list.push(item.alias);
      }
    })
    if (attrName === '計劃') {
      searchParam.travelPlans = list
    } else {
      // null
    }
  }

  // 暫時不用
  const fetchAttrList = useCallback(() => {
    getInsuranceAttrList({insuranceCategoryId: 1}).then(res => {
      if (res.data.code === 0) {
        if (res.data.data) {
          const list = res.data.data
          list.forEach(el => {
            el.selectedIndex = 0
          })
          setAttrList(list)
        }
      } else {
        console.error(res.data.message)
      }
    }).catch(e =>{
      console.error(e)
    })
  }, [insuranceCategoryId])

  const search = useCallback(() => {
    console.log('compareProduct:' + JSON.stringify(searchParam));
    setLoading(true)
    searchInsuranceProductList({...searchParam}).then(res => {
      setLoading(false)
      // 搜索增加保险比较功能
      if (res.data.code === 0) {
        if (res.data.data) {
          // 總數設定
          setProductTotal(res.data.data.total)
          if (res.data.data.records.length > 0) {
            const products = res.data.data.records
            products.forEach(el => {
              el.selected = false
              if (compareProduct.length > 0) {
                compareProduct.forEach(item => {
                  if (item.productId === el.productId) {
                    el.selected = true
                    return
                  }
                })
              }
            })
            setProductList([...products])
          } else {
            setProductList([])
          }
        } else {
          setProductList(null)
          setProductTotal(0)
        }
        setTotalPages(res.data.data.total)
      } else {
        message.error(res.data.message)
      }
    }).catch(e => {
      console.error(e)
      setLoading(false)
    })
  }, [compareProduct])

  useEffect(() => {
    window.scrollTo(0, 0)
    // fetchAttrList()
    fetchInsuranceBrandList()
    fetchTravelAttrList()
  }, [])
  const compare = () => {
    modalRef.current.compare((loading) => {
      setComparing(loading)
    })
  }
  const add = (el, idx) => {
    let ps = productList
    ps[idx].selected = true
    setProductList(ps)
    setCompareProduct(state => {
      return [...state, el]
    })
  }
  const updateProduct = (ids) => {
    let ps = productList
    ids.forEach(el => {
      ps.forEach(item => {
        if (item.productId === el) {
          item.selected = false
          return
        }
      })
    })
    setProductList(state => {
      return [...ps]
    })
  }
  const reset = () => {
    const ids = compareProduct.map(el => {
      return el.productId
    })
    updateProduct(ids)
    setCompareProduct(state => {
      return []
    })
  }
  const deleteProduct = (idx) => {
    updateProduct([compareProduct[idx].productId])
    let cp = compareProduct
    cp.splice(idx, 1)
    setCompareProduct(state => {
      return [...cp]
    })
  }
  const update = (idx) => {
    updateProduct([compareProduct[idx].productId])
    let cp = compareProduct
    cp.splice(idx, 1)
    setCompareProduct(state => {
      return [...cp]
    })
  }

  const scale = useSelector(state => state.font.scale)
  return <div className={style['compare-product']}>
    <div className={style['main']}>
      <div className={[`${style['search-nav']}`, `${style['search-nav-animate']}`].join(' ')} >
          <div className={style['input-box']} style={{borderColor: productInputState.focus ? '#1E4994' : (productInputState.value.length > 0 ? '#333333' : '#D3D3D3')}}>
            <img src={assetsUrl + 'images/icon_search_gloable.png'} alt='' />
            <input id='input-box' value={productInputState.value} maxLength="20" placeholder='搜索保險產品' onChange={e => {
              setProductInputState(state => {
                searchParam.key = e.target.value
                return {
                  ...state,
                  value: e.target.value
                }
              })
            }} onFocus={e => {
              setProductInputState(state => {
                return {
                  ...state,
                  focus: true
                }
              })
            }} onBlur={e => {
              setProductInputState(state => {
                return {
                  ...state,
                  focus: false
                }
              })
            }} onKeyPress={e => {
              if (e.code === 'Enter') {
                e.preventDefault()
                search()
              }
            }}/>
          </div>
          <span style={setFontSize(16, scale)} onClick={e => {
            search()
          }}>搜索</span>
        </div>
      <div className={style['filter-title']} style={setFontSize(16, scale)}>
        <p>篩選</p>
        <img src={assetsUrl + 'images/icon_right_fliter.png'} alt=''/>
      </div>
      <div className={style['filter-box']} style={setFontSize(16, scale)}>
        {/* 保險類別 */}
        <div className={style['currency-box']}>
          <p>保險類別</p>
          <div className={style['currency-list']}>
            {
              fstLevelCategoryList.map((item, idx) => {
                return <div className={style['currency-menu-box']} key={item.id} onClick={e => {
                  searchParam.pageNum = 1;
                  searchParam.categoryPid = item.id
                  setFstLevelCategoryId(item.id);
                  setInsuranceCategoryId(item.cId);
                }}>
                  <span className={[`${style['currency-menu']}`, item.id === fstLevelCategoryId ? `${style['currency-menu-active']}` : `${style['currency-menu-normal']}`].join(' ')}>{item.name}</span>
                </div>
              })
            }
          </div>
        </div>
        {/* 險種 */}
        <div className={style['currency-box']}>
          <p>險種</p>
          <div className={style['currency-list']}>
            {
              secLevelCategoryFilterList.map((item, idx) => {
                return <div className={style['currency-menu-box']} key={item.id} onClick={e => {
                  searchParam.pageNum = 1;
                  setInsuranceCategoryId(item.id);
                }}>
                  <span className={[`${style['currency-menu']}`, item.id === Number(insuranceCategoryId) ? `${style['currency-menu-active']}` : `${style['currency-menu-normal']}`].join(' ')}>{item.name}</span>
                </div>
              })
            }
          </div>
        </div>
        {/* 保險公司 */}
        <div className={style['select-insurance-company']}>
          <div className={style['company-box']}>
            <p>保險公司</p>
            <div className={style['species-list']}>
              {
                selectCompany.map((el, idx) => {
                  return  <div className={style['species-menu-box']} key={idx} onClick={e => {
                    const company = [...selectCompany]
                    company[idx].selected = !company[idx].selected
                    setCompanyState(company)
                    searchParam.insuranceBrandIds = []
                    // 遍历数组开始搜索
                    company.forEach((item, index) => {
                      if (item.selected) {
                        searchParam.insuranceBrandIds.push(item.id)
                      }
                    })
                    search()
                  }} >
                    <img src={assetsUrl + 'images/icon_check_company_' + (el.selected ? 's' : 'n') + '.png'} style={{display: true ? 'none' : 'inline-block'}} alt='selectCompany'/>
                    <span className={[`${style['species-menu']}`, el.selected ? `${style['species-menu-active']}` : ''].join(' ')}>{el.name}</span>
                  </div>
                })
              }
            </div>
            <span style={setFontSize(14, scale)}className={style['more-btn']} onClick={() => {
              const company = [...selectCompany]
              company.forEach((el) => {
                el.selected = false
              })
              setCompanyState(company)
              searchParam.insuranceBrandIds = []
              search()
            }}>清空</span>
          </div>
        </div>

        {/* 動態旅遊篩選項 */}
        {
          travelAttrFilterList.map((el, idx) => {
            return <div className={style['currency-box']} key={el.attrName} style={{display: isShowTravelInfo ? 'flex' : 'none'}}>
              <p>{el.attrName}</p>
              <div className={style['currency-list']}>
                {
                  el.valueSelect.map((p, i) => {
                    return <div className={style['currency-menu-box']} key={p.desc} onClick={e => {
                      const alist = [...travelAttrFilterList];
                      alist[idx].valueSelect[i].selected = !p.selected
                      setTravelAttrFilterList(alist);

                      // 重新設定檢索信息
                      initSearchTravelInfo(el.attrName, alist[idx].valueSelect);
                      search()
                    }}>
                      <span className={[`${style['currency-menu']}`, p.selected ? `${style['currency-menu-active']}` : `${style['currency-menu-normal']}`].join(' ')}>{p.desc}</span>
                    </div>
                  })
                }
              </div>
            </div>
          })
        }
        { // 動態的篩選項，暫時不用
          attrList.map((el, idx) => {
            return <div className={style['currency-box']} key={el.attrName}>
              <p>{el.attrName}</p>
              <div className={style['currency-list']}>
                {
                  el.valueSelect.map((p, i) => {
                    return <div className={style['currency-menu-box']} key={p.desc} onClick={e => {
                      if (el.selectedIndex !== i) {
                        const alist = attrList
                        alist[idx].selectedIndex = i
                        setAttrList(state => {
                          return [...alist]
                        })
                      }
                    }}>
                      <span className={[`${style['currency-menu']}`, el.selectedIndex === i ? `${style['currency-menu-active']}` : `${style['currency-menu-normal']}`].join(' ')}>{p.desc}</span>
                    </div>
                  })
                }
              </div>
            </div>
          })
        }
      </div>

      <div className={style['sort-box']}>
        <span className={[sort === 0 ? `${style['sort-active']}` : '']} onClick={e => setSort(0)}>最低投保額排序</span>
        <span className={[sort === 1 ? `${style['sort-active']}` : '']} onClick={e => setSort(1)}>5年保證IRR</span>
      </div>

      <div className={style['category-box']}>
        <div style={setFontSize(20, scale)} className={style['category-content']}>
          <div className={style['filter-result']}>
            <span>篩選結果：共</span>
            <span> {productTotal} </span>
            <span>款產品</span>
          </div>
          <div className={style['category-title']}>
            {
              secLevelCategory.icon === '' ? '' : <img src={assetsUrl + secLevelCategory.icon} alt=''/>
            }
            <span style={setFontSize(26, scale)}>{secLevelCategory.pName}</span>
          </div>
          <div className={style['category-item-title']}>
            <span style={setFontSize(40, scale)}>{secLevelCategory.name}</span>
          </div>
          <div className={style['category-item-sub-title']}>
            <span>{secLevelCategory.subTitle}</span>
          </div>
          {
            secLevelCategory.desc.map(el => {
              return <div className={style['category-item-info']} key={el}>
                        <span></span>
                        <span>{el}</span>
                     </div>
            })
          }
        </div>
      </div>

      <Spin spinning={loading} style={{width: '900px', height: '45vh', position: 'static'}}>
        {
          productList ? (productList.length > 0 ?
          <div className={style['product-box']}>
            <div className={style['product-list']}>
              {
                productList.map((el, idx) =>{
                  return <InsuranceProduct product={el} productLength={compareProduct.length} index={idx} key={el.productId} addCompareAction={add} closeSearchAction={() => {}}/>
                })
              }
            </div>
            <ConfigProvider locale={zh_CN}>
              <Pagination style={{display: totalPages > 10 ? 'block' : 'none', marginTop: '40px'}} current={searchParam.pageNum} total={totalPages} onChange={(page, pSize) => {
                window.scrollTo(0, 0)
                searchParam.pageNum = page
                searchParam.pageSize = pSize
                search()
              }}/>
            </ConfigProvider>
          </div> : <Empty description='暫未保險產品'  style={{width: '900px', height: '45vh', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}} />) : <div></div>
        }
      </Spin>
    </div>
    <Footer />
    <SectionCompare compareProduct={compareProduct} comparing={comparing} compareAction={compare} resetAction={reset} deleteAction={deleteProduct}/>
    <ProductComparisonModal onRef={modalRef} compareProduct={compareProduct} categoryName={secLevelCategory.name} updateAction={update}/>
  </div>
}