import React from 'react'
import styles from'./ClaimsCustomerService.module.scss'
import { InsuranceClaimsService, Footer } from '../../components'

export function ClaimsCustomerService () {
  return (
    <div className={styles['exclusive-container']}>
      <InsuranceClaimsService />
      <div className={styles['space-div']} />
      <Footer />
    </div>
  )
}