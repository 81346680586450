import { getActivityList } from '../../services/api'
export const K_FETCH_ACTIVITY_LIST_START = "k_fetch_activity_list_start"
export const K_FETCH_ACTIVITY_LIST_SUCCESS = "k_fetch_activity_list_success"
export const K_FETCH_ACTIVITY_LIST_FAIL = "k_fetch_activity_list_fail"

export const activityListStartActionCreator = () => {
  return {
    type: K_FETCH_ACTIVITY_LIST_START
  }
}

export const activityListSuccessActionCreator = (data) => {
  return {
    type: K_FETCH_ACTIVITY_LIST_SUCCESS,
    payload: data //请求成功返回的数据
  }
}

export const activityListFailActionCreator = (errMsg) => {
  return {
    type: K_FETCH_ACTIVITY_LIST_FAIL,
    payload: errMsg //请求失败返回的数据
  }
}

export const getActivityListActionCreator = (param) => (dispatch, getState) => {
  dispatch(activityListStartActionCreator())
  getActivityList({
    ...param
  }).then(res => {
    if (res.data.code === 0) {
      dispatch(activityListSuccessActionCreator(res.data.data))
    } else {
      dispatch(activityListFailActionCreator(res.data.message))
    }
  }).catch(e =>{
    if (e) {
      dispatch(activityListFailActionCreator(e.message))
    } else {
      dispatch(activityListFailActionCreator("獲取活動信息失敗"))
    }
  })
}