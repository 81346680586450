export const sideMenuList = [
  // {
  //   id: 0,
  //   title: "首頁",
  //   subMenu: [
  //     {
  //       id: 0,
  //       title: "Option 1"
  //     },
  //     {
  //       id: 1,
  //       title: "Option 2"
  //     },
  //     {
  //       id: 2,
  //       title: "Option 3"
  //     },
  //     {
  //       id: 993,
  //       title: "Option 4"
  //     },
  //   ],
  // },
  {
    id: 1,
    title: "公司簡介",
    subMenu: [
      {
        id: 3,
        title: "Option 1"
      },
      {
        id: 4,
        title: "Option 2"
      },
      {
        id: 5,
        title: "Option 3"
      },
      {
        id: 6,
        title: "Option 4"
      },
    ],
  },
  {
    id: 2,
    title: "服务范畴",
    subMenu: [
      {
        id: 7,
        title: "Option 1"
      },
      {
        id: 8,
        title: "Option 2"
      },
      {
        id: 9,
        title: "Option 3"
      },
      {
        id: 10,
        title: "Option 4"
      },
    ],
  },
  {
    id: 3,
    title: "合作伙伴",
    subMenu: [
      {
        id: 110,
        title: "Option 1"
      },
      {
        id: 11,
        title: "Option 2"
      },
      {
        id: 112,
        title: "Option 3"
      },
      {
        id: 113,
        title: "Option 4"
      },
    ],
  },
  {
    id: 4,
    title: "互動中心",
    subMenu: [
      {
        id: 220,
        title: "Option 1"
      },
      {
        id: 221,
        title: "Option 2"
      },
      {
        id: 2222,
        title: "Option 3"
      },
      {
        id: 2223,
        title: "Option 4"
      },
    ],
  },
  {
    id: 5,
    title: "聯絡我們",
    subMenu: [
      {
        id: 3330,
        title: "Option 1"
      },
      {
        id: 331,
        title: "Option 2"
      },
      {
        id: 332,
        title: "Option 3"
      },
      {
        id: 333,
        title: "Option 4"
      },
    ],
  },
  {
    id: 6,
    title: "優惠禮遇",
    subMenu: [
      {
        id: 660,
        title: "Option 1"
      },
      {
        id: 661,
        title: "Option 2"
      },
      {
        id: 662,
        title: "Option 3"
      },
      {
        id: 663,
        title: "Option 4"
      },
    ],
  }
]

export const navTItles = [
  {
    id: 1001,
    title: '關於我們',
    path: '/about'
  },
  // {
  //   id: 1002,
  //   title: '優惠禮遇',
  //   path: ''
  // },
  {
    id: 1003,
    title: '理賠及客服',
    path: '/claims-customer-service'
  },
  {
    id: 1004,
    title: '聯絡我們',
    path: '/contact'
  }
]

export const menus = [
  {
    id: 1007,
    title: '保險產品',
    path: '/compare-product/10'
  },
  {
    id: 1005,
    title: '精彩活動',
    path: '/activities'
  },
  {
    id: 1006,
    title: '最新資訊',
    path: '/news-list'
  },
  {
    id: 1008,
    title: '專屬定制',
    path: '/exclusive-custom'
  }
  // {
  //   id: 1009,
  //   title: '保單託管',
  //   path: '/insurance-policy'
  // }
]
export const species = ['人壽保險', '意外及傷病保險', '年金', '醫療保險', '危疾保險']
export const brand = ['保险品牌1', '保险品牌2', '保险品牌3', '保险品牌4','保险品牌5']
export const searchResult = [
  {
    id: 6001,
    logo: 'https://img0.baidu.com/it/u=788149167,2861985435&fm=26&fmt=auto&gp=0.jpg',
    title: '具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保體保體具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保體保體...',
    price: '$188',
    tags: ['1年供款', '網上投', '網上', '網', '1年供款1年供款'],
    first: '最高150%基本保额',
    second: '6万元',
    third: '15万元',
    fourth: '6万元'
  },
  {
    id: 6001,
    logo: 'https://img0.baidu.com/it/u=3153405721,1524067674&fm=26&fmt=auto&gp=0.jpg',
    title: '具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保體保體...具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保體保體...',
    price: '$188',
    tags: ['1年供款', '網上投', '網上', '網', '1年供款1年供款'],
    first: '最高150%基本保额',
    second: '6万元',
    third: '15万元',
    fourth: '6万元'
  },
  {
    id: 6001,
    logo: 'https://img0.baidu.com/it/u=3599690714,1456928921&fm=26&fmt=auto&gp=0.jpg',
    title: '具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保體保體...具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保險產品名稱保險名稱具體的保體保體...',
    price: '$188',
    tags: ['1年供款', '網上投', '網上', '網', '1年供款1年供款'],
    first: '最高150%基本保额',
    second: '6万元',
    third: '15万元',
    fourth: '6万元'
  }
]