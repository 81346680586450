import React, { useState } from 'react'
import { Form, Input, message, Button, Modal} from 'antd'
import { isLegalHKPhone } from '../../utils/index'
import styles from'./InputVerifyCode.module.scss'
import { useMountedRef } from '../../utils/hook'
import { assetsUrl } from '../../utils/constants'
import { sendInsuredVerifyCode } from '../../services/api'
let nums = 180
var interval = null
export function InputVerifyCode({ form, initMobile = '', initVerifyCode = '', inputCompleted=false, width = '47%', marginRight = '6%', validatPhoneFailed, onChange}) {
  const mountedRef = useMountedRef()
  const [modalForm] = Form.useForm()
  const [mobile, setMobile] = useState(initMobile)
  const [verifyCode, setVerifyCode] = useState(initVerifyCode)
  const [visibleModal, setModalVisible] = useState(false)
  const [countdown, setCountdown] = useState({
    desc: '180秒',
    disabled: false
  })
  const prefixSelector = (
    <Form.Item style={{width: '95px', height: '61px', margin: 0, background: '#D3D3D3'}}>
      <span className={styles['phone-prefix']}>+852</span>
    </Form.Item>
  )
  const destroyInterval = () => {
    clearInterval(interval)
    setCountdown(state => {
      return {
        ...state,
        desc: '180秒',
        disabled: false
      }
    })
    nums = 180
  }
  const startCountdown = () => {
    sendVerifyCode()
    setCountdown(state => {
      return {
        ...state,
        desc: nums + '秒',
        disabled: true
      }
    })
    interval = setInterval(() => {
      if (mountedRef.current) {
        nums--
        if(nums > 0){
          setCountdown(state => {
            return {
              ...state,
              desc: nums + '秒',
            }
          })
        }else{
          destroyInterval()
        }
      } else {
        clearInterval(interval)
      }
    }, 1000)
  }
  const sendVerifyCode = () => {
    sendInsuredVerifyCode({
      mobile,
      mobileArea: 'HK'
    }).then(res => {
      if (res.data.code === 0) {
        message.success('驗證碼發送成功')
      } else {
        message.error(res.data.message)
        destroyInterval()
      }
    })
  }
  return <Form.Item style={{width, marginRight, marginBottom: '0'}}>
    <span className='send-code-btn' style={{display: mobile.length >= 8 ? 'block' : 'none'}} onClick={() => {
      if (!isLegalHKPhone(mobile)) {
        message.warning('請輸入有效的手機號碼')
        return
      }
      setModalVisible(true)
      if (!countdown.disabled) {
        startCountdown()
      }
    }}>{!countdown.disabled ? '發送驗證碼驗證' : (countdown.desc + '後重新獲取')}</span>
    <Form.Item 
      name='mobile'
      rules={[
      { validator: (value) => {
        if (mobile.length === 0) {
          return Promise.reject('請輸入有效的手提號碼')
        } else if(inputCompleted && mobile.length < 8) {
          return Promise.reject('請輸入有效的八位數字香港手提號碼')
        } else {
          return Promise.resolve()
        }
      }}
    ]}>
    <Input maxLength={8} addonBefore={prefixSelector} placeholder='請輸入您的手提號碼' onChange={e => {
      if (e.target.value.length === 0) {
        validatPhoneFailed()
      }
      // if (!/^[0-9]*$/.test(e.target.value)) {
      //   const value = e.target.value.slice(0, e.target.value.length - 1)
      //   form.setFieldsValue({mobile: value})
      //   return
      // }
      const value = e.target.value.replace(/\D/g, '')
      form.setFieldsValue({mobile: value})
      onChange({
        mobile: value,
        verifyCode
      })
      setMobile(value)
    }} onPaste={e => {
      if (e.target.value.length === 0) {
        validatPhoneFailed()
      }
      // if (!/^[0-9]*$/.test(e.target.value)) {
      //   const value = e.target.value.slice(0, e.target.value.length - 1)
      //   form.setFieldsValue({mobile: value})
      //   return
      // }
      const value = e.target.value.replace(/\D/g, '')
      form.setFieldsValue({mobile: value})
      onChange({
        mobile: value,
        verifyCode
      })
      setMobile(value)
    }}/>
    </Form.Item>
    <Modal
      destroyOnClose
      closable={false}
      style={{ top: 347 }}
      visible={visibleModal}
      width={600}
      footer={null}>
      <div className={styles['modal-content']}>
        <div className={styles['close-btn']}>
          <img src={assetsUrl + 'images/icon_modal_cancel.png'} alt='' onClick={() => {
            modalForm.resetFields()
            setModalVisible(false)
          }}/>
        </div>
        <p>已發送驗證碼到{mobile}，驗證碼5分鐘內有效，請及時填寫。</p>
        <Form 
        form={modalForm} 
        initialValues={{verifyCode: initVerifyCode}} 
        style={{width: '100%', padding: '32px', position: 'relative'}}
        onFinish={ values => {
          onChange({
            mobile,
            verifyCode: values.verifyCode
          })
          setVerifyCode(values.verifyCode)
          setModalVisible(false)
        }}>
          <span className={styles['countdown-tip']}>{countdown.desc}</span>
          <Form.Item 
          name='verifyCode'
          rules={[{ required: true, message: '请輸入簡訊驗證碼' }]}
          style={{width: '100%'}}>
            <Input maxLength={6} placeholder='簡訊驗證碼' onChange={e => {
              const value = e.target.value.replace(/\D/g, '')
              modalForm.setFieldsValue({verifyCode: value})
              setVerifyCode(value)
            }}/>
          </Form.Item>
          <Form.Item style={{width: '100%', marginTop: '8px'}}>
            <div className={styles['footer-box']}>
              <Button onClick={() => {
                modalForm.resetFields()
                setModalVisible(false)
              }}>取消</Button>
              <Button  htmlType='submit'>確定</Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  </Form.Item>
}