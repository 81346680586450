import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getNewsList } from '../../services/api'
const initialState = {
  data: null,
  loading: true,
  errMsg: ''
}

export const fetchNewsList = createAsyncThunk(
  "newsList/getNewsList",
  async (param) => {
    const { data } = await getNewsList({...param})
    return data
  }
)

export const newsListSlice = createSlice({
  name: "newsList",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchNewsList.pending.type]: (state) => {
      state.loading = true
    },
    [fetchNewsList.fulfilled.type]: (state, action) => {
      if (action.payload.code === 0) {
        state.errMsg = ''
        state.data = action.payload.data
      } else {
        state.errMsg = action.payload.message
        state.data = null
      }
      state.loading = false
    },
    [fetchNewsList.rejected.type]: (state, action) => {
      state.loading = false;
      state.errMsg = action.payload;
      state.data = null
    }
  }
})