import React from 'react'
import styles from'./ExclusiveCustom.module.scss'
import { assetsUrl } from '../../utils/constants'
import { InsuranceCustomization, Footer } from '../../components'
import { useSelector } from 'react-redux'
import { setFontSize } from '../../utils'
const services = [
  {
    title: '提交預約',
    icon: 'icon_submit_booking.png'
  },
  {
    title: '溝通需求',
    icon: 'icon_communication_requirements.png'
  },
  {
    title: '制定方案',
    icon: 'icon_develop_proposa.png'
  },
  {
    title: '協助投保',
    icon: 'icon_assist_to_insure.png'
  },
  {
    title: '持續服務',
    icon: 'icon_continuous_service.png'
  }
]
export function ExclusiveCustom () {
  const scale = useSelector(state => state.font.scale)
  return (
    <div className={styles['exclusive-container']}>
      <InsuranceCustomization />
      <div className={styles['service-process']}>
        <div className={styles['service-title']} style={setFontSize(40, scale)}>
          <span>服务</span>
          <span>流程</span>
        </div>
        
        <div className={styles['process-list']}>
          {
            services.map(el => {
              return <div key={el.title} className={styles['process-item']}>
                <img src={assetsUrl + `images/${el.icon}`} alt={el.title}/>
                <span style={setFontSize(24, scale)}>{el.title}</span>
              </div>
            })
          }
        </div>
      </div>
      <Footer />
    </div>
  )
}