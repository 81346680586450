import React, { useEffect, useState } from "react";
import { Carousel as AntCarousel, Spin } from 'antd';
import { useHistory } from 'react-router-dom'
import "./Carousel.scss"
import styles from "./Carousel.module.scss";
import LazyLoad from 'react-lazyload'
import { assetsUrl } from '../../utils/constants'
// import { checkbox, arrow } from '../../assets/index'
import { fetchBannerList } from '../../redux/banner/slice'
import { useSelector, useDispatch } from 'react-redux'
// const titles = ['助您挑選貼合需要的產品', '奉行以客為先的服務模式', '提供緊貼生活的增值服務']
export function Carousel() {
  const bannerState = useSelector(state => state.bannerList)
  const history = useHistory()
  const dispatch = useDispatch()
  const [activeDotIdx, setActiveDotIdx] = useState(0)
  let swiperRef = null
  useEffect(() => {
    if (!bannerState.data) {
      dispatch(fetchBannerList())
    }
  }, [bannerState.data, dispatch])
  const [display, setDisplay] = useState('none')
  return (
    <div className={styles['carousel-container']} onMouseMove={() => {
      if (bannerState.data && bannerState.data.length > 1 && display !== 'flex') {
        setDisplay('flex')
      }
      }} onMouseOut={() => {
        setDisplay('none')
      }}>
      {
      bannerState.loading ? <div className={styles['loading-box']}> <Spin /></div> :
      (bannerState.data ? <AntCarousel ref={ref => swiperRef = ref} autoplay dots={false} className={styles.slider} beforeChange={(from, to) => {
        setActiveDotIdx(to)
      }}>
        {
          bannerState.data.map((el, index) => {
            return <div className={styles['slider-item']} key={index} onClick={() => {
              if (el.type === 'ACTIVITY' && el.promoKey) {
                history.push(`/activity-detail/${el.promoKey}`)
              } else if (el.type === 'NEWS' && el.promoKey) {
                history.push(`/news-detail/${el.promoKey}`)
              } else if (el.type === 'WEBSITE' && el.promoLink) {
                console.log(el.promoLink.indexOf('hzhk://'))
                if (el.promoLink.indexOf('hzhk://') !== -1) {
                  history.push(el.promoLink.slice('hzhk:/'.length, el.promoLink.length))
                } else {
                  window.open(el.promoLink)
                }
              }
            }}>
              <div className={styles['img-box']}>
                <LazyLoad once>
                  <img src={el.promoImageUrl} className={styles.banner} alt=''/>
                </LazyLoad>
              </div>
              <div className={styles.bubble} style={{display: index === 0 ? 'absolute' : 'none'}}>
                <span>幫你揀</span>
                <span>助你賠</span>
              </div>
              {/* <div className={styles.inner} style={{display: index === 0 ? 'absolute' : 'none'}}>
                <span className={styles.title}>慧擇香港</span>
                {
                  titles.map((el, index) => {
                    return <div className={styles['desc-box']} key={index}>
                      <img src={checkbox}  alt=''/>
                      <span className={styles.title}>{el}</span>
                    </div>
                  })
                }
                <div className={styles['more-box']}>
                  <span>了解更多</span>
                  <img src={arrow}  alt=''/>
                </div>
              </div> */}
            </div>
          })
        }
      </AntCarousel> : <div style={{display: 'none'}}></div>)
    }
    <div className={styles['page-dot']} style={{display: bannerState.data && bannerState.data.length > 1 ? 'flex' : 'none'}} >
      {
        bannerState.data ? bannerState.data.map((el, i) => {
          return <div className={styles['dot-item']} key={i}>
            <img src={assetsUrl + (activeDotIdx ===  i ? 'images/icon_dot_active.png' : 'images/icon_dot_normal.png')} alt='dot' onClick={e => {
              if (swiperRef) {
                swiperRef.goTo(i)
              }
            }}/>
          </div>
        }) : <div style={{display: 'none'}}></div>
      }
    </div>
    <div className={styles['page-control']} style={{display: display}} >
      <img src={assetsUrl + 'images/icon_left_control_white.png'} alt='' onClick={() => {
        if (swiperRef) {
          swiperRef.prev()
        }
      }}/>
      <img src={assetsUrl + 'images/icon_right_control_white.png'} alt='' onClick={() => {
        if (swiperRef) {
          swiperRef.next()
        }
      }}/>
    </div>
    </div>
  )
}