import React, { useEffect, useState, useCallback } from 'react'
// import LazyLoad from 'react-lazyload'
import { Spin, message, Empty } from 'antd'
import { Footer} from '../../components'
import { useHistory, useParams } from 'react-router-dom'
import { getNewsDetail } from '../../services/api'
import styles from'./NewsDetail.module.scss'
import { rightArrow } from '../../assets'
import time from '../../assets/images/icon_activity_time.png'
import { useSelector } from 'react-redux'
import { setFontSize } from '../../utils'
export function NewsDetail () {
  message.config({
    maxCount: 1
  })
  const scale = useSelector(state => state.font.scale)
  const history = useHistory()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [newsDetail, setNewsDetail] = useState(null)
  const fetchNewsDetail = useCallback(() => {
    getNewsDetail({id}).then(res => {
      setLoading(false)
      if (res.data.code === 0) {
        setNewsDetail(res.data.data)
      } else {
        message.error(res.data.message) 
      }
    }).catch(e => {
      setLoading(false)
      message.error(e.message) 
    })
  }, [id])
  useEffect(() => {
    window.scrollTo(0, 0)
    fetchNewsDetail()
  }, [fetchNewsDetail])
  return (
    <div className={styles['news-detail-container']}>
      {/* <Header /> */}
      <div className={styles['nav-back']} style={setFontSize(16, scale)}>
        <span onClick={() => history.push('/')} >首頁</span>
        <img src={rightArrow } alt=''/>
        <span>資訊詳情</span>
      </div>
      {
        loading === false ? ( newsDetail ?
          <div className={styles['main']}>
            <p className={styles['news-title']} style={setFontSize(32, scale)}>{newsDetail.title}</p>
            <div className={styles['news-info']}>
              <div className={styles['news-date-time']}>
                <img src={time} alt=''/>
                <p style={{marginRight: '32px', ...setFontSize(16, scale)}}>發佈時間：{newsDetail.deliveryDate}</p>
              </div>
            </div>
            {/* <LazyLoad height={676} once>
              <img className={styles['news-cover']} src={newsDetail.detailImageUrl} alt='' />
            </LazyLoad> */}
            <p className={styles['news-title']} style={{marginLeft: 0, marginTop: '40px', ...setFontSize(32, scale)}} >資訊詳情</p>
            <div className={styles['detail-content']} dangerouslySetInnerHTML={{__html: newsDetail.detailContent}}></div>
            {/* <div className={styles['news-bottom']}>
              <a href={newsDetail.enterUrl} target="view_window" style={{pointerEvents: newsDetail.enterUrl ? 'auto' : 'none'}}  className={styles['news-apply']} >
                <p>立即報名</p>
                <img src={arrow} alt=''/>
              </a>
            </div> */}
          </div> : <Empty style={{height: '45vh', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}} description={false} />) : <div className={styles['loading-box']}> <Spin /></div>
      }
      {/* {
        <Footer style={{display : (loading === false || newsDetail) ?  'flex' : 'none'}} /> 
      }  */}
      <Footer />
    </div>
  )
}